import {
    useAppSelector,
    selectLoggedInAndReadyToListDomains,
    selectListingDefaults,
    selectBulkCrosslistingIsInitialized,
    selectBulkCrosslistingDomains,
    getDomainLinkingStatus,
    getListingDefaults,
    useAppDispatch,
    createBulkCrosslisting,
    Logger,
    setBulkCrosslistingDomains
} from '@foxtail-dev/user-clients'
import { useEffect, useState } from 'react'
import { bulkCrosslistingMarketOptions } from '../../constants/bulkCrosslistingMarketOptions'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { Box, Divider } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { delayMs, DomainCommonName } from '@foxtail-dev/datacontracts'
import { generateToast } from '../../lib/clients/ToastClient'
import { ImportListingSkeleton } from '../skeletons/ImportListingSkeleton'
import { BulkCrosslistingMarketSelectionField } from './BulkCrosslistMarketSelectionField'
import { ListingDefaultsModal } from '../../modals/account/ListingDefaultsModal'

type SetBulkCrosslistDomainsProps = {
    selectedCrosslistingDomains: DomainCommonName[]
}

type NewBulkCrosslistProps = {}

export const NewBulkCrosslist = (props: NewBulkCrosslistProps) => {
    const dispatch = useAppDispatch()

    const loggedInMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)
    const options = bulkCrosslistingMarketOptions.filter((option) => loggedInMarkets.includes(option[0]))
    const listingDefaults = useAppSelector(selectListingDefaults)
    const isInitialized = useAppSelector(selectBulkCrosslistingIsInitialized)
    const domains = useAppSelector(selectBulkCrosslistingDomains)
    const [isLoadingSynthetic, setIsLoadingSynthetic] = useState<boolean>(true)

    const [showListingDefaultsModal, setShowListingDefaultsModal] = useState<boolean>(false)
    const [selectedDomains, setSelectedDomains] = useState<DomainCommonName[]>([])

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingSynthetic(false)
        }, 1000)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        dispatch(getListingDefaults())
    }, [])

    const handleSetBulkCrosslistDomains = async (values: SetBulkCrosslistDomainsProps, actions: FormikHelpers<SetBulkCrosslistDomainsProps>) => {
        const selectedDomains = values.selectedCrosslistingDomains

        try {
            if (!isInitialized) {
                await dispatch(createBulkCrosslisting()).unwrap()
            }
            const populatedSession = await dispatch(setBulkCrosslistingDomains(selectedDomains)).unwrap()
            const areListingDefaultsSet = selectedDomains.every((domain) => !!listingDefaults.config.domainDetails[domain])
            Logger.I().log({
                level: 'info',
                message: 'User created bulk crosslist',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        selectedDomains,
                        bulkCrosslistingSessionId: populatedSession.sessionInfo._id,
                        bulkCrosslistingSessionData: populatedSession.sessionData._id,
                        areListingDefaultsSet
                    }
                }
            })

            if (!areListingDefaultsSet) {
                setSelectedDomains(selectedDomains)
                setShowListingDefaultsModal(true)
            }
        } catch (error) {
            Logger.I().log(
                {
                    message: 'Error creating bulk crosslist',
                    level: 'error',
                    payload: {
                        kind: 'BulkCrosslistError'
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error creating bulk crosslist', subText: 'Please try again' })
        }
    }

    const onCloseListingDefaultsModal = () => {
        setShowListingDefaultsModal(false)
    }

    const initialValues: SetBulkCrosslistDomainsProps = {
        selectedCrosslistingDomains: domains
    }

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSetBulkCrosslistDomains}>
                {({ isSubmitting, handleSubmit, values }: FormikProps<SetBulkCrosslistDomainsProps>) => {
                    return isLoadingSynthetic ? (
                        <ImportListingSkeleton />
                    ) : (
                        <Box sx={styles.container}>
                            <Box sx={{ flexGrow: 1, marginLeft: '20px' }}>
                                <BulkCrosslistingMarketSelectionField />
                            </Box>
                            <Divider sx={{ marginBottom: '12px' }} />
                            <Box sx={styles.actionButtonContainer}>
                                <FoxButton
                                    variant='contained'
                                    size='large'
                                    text='Begin crosslisting'
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onFoxClick={{
                                        kind: 'button',
                                        onClick: async () => {
                                            await handleSubmit()
                                        },
                                        preventDoubleClick: true
                                    }}
                                    sx={styles.button}
                                    primary
                                />
                            </Box>
                        </Box>
                    )
                }}
            </Formik>
            <ListingDefaultsModal open={showListingDefaultsModal} onClose={onCloseListingDefaultsModal} domains={selectedDomains} fromBulkCrosslist={true} />
        </>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        flexDirection: 'column'
    },
    actionButtonContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '20px'
    },
    button: {
        marginRight: '16px',
        fontWeight: 'bold',
        width: '180px',
        justifyContent: 'center',
        height: '48px'
    }
}
