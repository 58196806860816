import React, { useCallback, useEffect, useRef } from 'react'
import debounce from 'just-debounce-it'
import { useFormikContext } from 'formik'
import { setAutosaving, useAppDispatch } from '@foxtail-dev/user-clients'

export type AutosaverProps<T> = {
    debounceMs: number
    isSubmitting?: boolean
    onSave: (values: T) => Promise<any>
}

export const Autosaver = <T extends unknown>({ debounceMs, onSave, isSubmitting }: AutosaverProps<T>) => {
    const dispatch = useAppDispatch()
    const formik = useFormikContext<T>()
    const valuesRef = useRef<T>(formik.values)
    const isSubmittingRef = useRef(isSubmitting)
    valuesRef.current = formik.values
    isSubmittingRef.current = isSubmitting

    const onSaveCallback = useCallback(async () => {
        if (isSubmittingRef.current) {
            return
        }
        dispatch(setAutosaving(true))
        await onSave(valuesRef.current)
        dispatch(setAutosaving(false))
    }, [isSubmittingRef, valuesRef, onSave])

    const debouncedAutosaveRef = useRef(debounce(onSaveCallback, debounceMs))

    useEffect(() => {
        if (formik.dirty) {
            debouncedAutosaveRef.current()
        }
    }, [formik.values, formik.dirty, isSubmitting])

    // Clean up the throttled function on component unmount
    useEffect(() => {
        return () => {
            debouncedAutosaveRef.current.cancel()
        }
    }, [])

    return <></>
}
