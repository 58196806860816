import { SvgIcon } from '@mui/material'

type ArrowRightIconProps = {
    color?: string
    height?: number
    width?: number
}

export const ArrowRightIcon = (props: ArrowRightIconProps) => {
    const height = props.height ?? 25
    const width = props.width ?? 34
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='34px' height='25px' viewBox='0 0 34 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>zero-arrow@1.5x</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Home---zero' transform='translate(-36.000000, -692.000000)' fill={props.color ?? '#000000'} fillRule='nonzero'>
                        <g id='Group-10' transform='translate(13.772455, 589.000000)'>
                            <g id='zero-arrow' transform='translate(22.227545, 103.000000)'>
                                <path
                                    d='M19.25,23.9 C18.8166667,23.4333333 18.5916667,22.875 18.575,22.225 C18.5583333,21.575 18.7666667,21.0333333 19.2,20.6 L25.2,14.65 L2.35,14.65 C1.68333333,14.65 1.125,14.425 0.675,13.975 C0.225,13.525 0,12.9666667 0,12.3 C0,11.6333333 0.225,11.075 0.675,10.625 C1.125,10.175 1.68333333,9.95 2.35,9.95 L25.2,9.95 L19.15,3.9 C18.7166667,3.46666667 18.5083333,2.93333333 18.525,2.3 C18.5416667,1.66666667 18.7666667,1.11666667 19.2,0.65 C19.6666667,0.216666667 20.225,0 20.875,0 C21.525,0 22.0666667,0.216666667 22.5,0.65 L32.45,10.65 C32.6833333,10.8833333 32.8666667,11.1416667 33,11.425 C33.1333333,11.7083333 33.2,12 33.2,12.3 C33.2,12.6 33.1333333,12.8916667 33,13.175 C32.8666667,13.4583333 32.6833333,13.7166667 32.45,13.95 L22.55,23.9 C22.0833333,24.3666667 21.5333333,24.5916667 20.9,24.575 C20.2666667,24.5583333 19.7166667,24.3333333 19.25,23.9 L19.25,23.9 Z'
                                    id='Path'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
