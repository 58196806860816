import { Colors } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'

type EngagementBoosterRowProps = {
    isActive: boolean
    boosterName: string
    editEngagementBooster: () => void
    showDivider?: boolean
}

export const EngagementBoosterRow = (props: EngagementBoosterRowProps) => {
    const { isActive, boosterName, showDivider = true, editEngagementBooster } = props

    return (
        <FoxButtonBase
            onFoxClick={{
                kind: 'button',
                onClick: async () => {
                    editEngagementBooster()
                }
            }}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
            <Box sx={styles.container}>
                <FoxTypography variant='body1' sx={{ marginLeft: '25px', marginTop: '22px' }}>
                    {boosterName}
                </FoxTypography>
                <FlexGrow />
                <FoxTypography variant='body1' sx={isActive ? styles.activeText : styles.inActiveText}>
                    {isActive ? 'Active' : 'Inactive'}
                </FoxTypography>
                <Box sx={styles.containerChevron}>
                    <ChevronRightIcon height={17} width={10} color={Colors.light.chevronGrey} />
                </Box>
            </Box>
            {showDivider && <Divider />}
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        height: '64px',
        display: 'flex',
        flexDirection: 'row'
    },
    containerChevron: {
        marginRight: '23px',
        marginTop: '24px'
    },
    activeText: {
        marginRight: '21px',
        fontSize: '12px',
        lineHeight: '16px',
        marginTop: '24px',
        color: Colors.light.success
    },
    inActiveText: {
        marginRight: '21px',
        fontSize: '12px',
        lineHeight: '16px',
        marginTop: '24px',
        color: Colors.light.lightText
    }
}
