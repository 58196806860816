import { Search } from '@mui/icons-material'
import { Box, CircularProgress, TextField, TextFieldProps } from '@mui/material'
import { FoxCircleLoader } from '../LoadingCircle'

type FoxSearchBarProps = {
    label: string
    value: string
    autoFocus?: boolean
    onChange: (val: string) => void
    loading?: boolean
    fullWidth?: boolean
    sx?: TextFieldProps['sx']
}

// TODO: Improve search bar so that typing doesn't re-create the search icon over and over again, resetting its progress
// TODO: See if auto focus is working, also check in a modal
export const FoxSearchBar = (props: FoxSearchBarProps) => {
    const { label, value, autoFocus, onChange, loading, fullWidth, sx } = props

    const fullWidthCSS = fullWidth ? styles.fullWidth : {}
    return (
        <Box sx={{ width: '366px', height: '40px', borderRadius: '10px', ...fullWidthCSS, ...sx }}>
            <TextField
                label={label}
                value={value}
                variant='filled'
                autoFocus={autoFocus}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: loading ? <FoxCircleLoader size={24} /> : <Search sx={[styles.searchIcon]} />,
                    disableUnderline: true,
                    sx: {
                        borderRadius: '10px',
                        height: '40px',
                        justifyContent: 'center'
                    }
                }}
                InputLabelProps={{
                    sx: {
                        alignItems: 'center'
                    }
                }}
                sx={{
                    width: '100%',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: 'initial'
                    }
                }}
            />
        </Box>
    )
}

const styles = {
    searchIcon: {
        height: '24px',
        width: '24px'
    },
    searchBox: {
        borderRadius: '12px'
    },
    fullWidth: {
        width: '100%'
    }
}
