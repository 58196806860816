import { Box } from '@mui/material'
import { ListingsIcon } from '../icons/ListingsIcon'
import { Colors } from '@foxtail-dev/user-clients'
import { FoxTypography } from '../common/FoxTypography'
import { FoxButton } from '../common/FoxButton'
import { FlexGrow } from '../common/FlexGrow'

type NoLinkedImportMarketsProps = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const NoLinkedImportMarkets = (props: NoLinkedImportMarketsProps) => {
    const { setIsRequestingCancel } = props
    return (
        <Box sx={styles.container}>
            <ListingsIcon height={70} width={200} />
            <FoxTypography variant='h6' sx={styles.text}>
                You must link Poshmark or Ebay in order to import listings into Foxtail
            </FoxTypography>
            <FlexGrow />
            <FoxButton primary sx={styles.button} text='Connect markets' onFoxClick={{ kind: 'internal', to: '/app/account/marketplace-connections' }} />
        </Box>
    )
}

const styles = {
    container: {
        height: '350px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginTop: '45px',
        flexDirection: 'column'
    },
    button: {
        width: '100%',
        justifyContent: 'center'
    },
    text: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    }
}
