import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { AccountRequirementsModalProps } from '../../../containers/account/AccountRequirementsModal'
import { useState } from 'react'
import { FoxCheckbox } from '../../../components/common/FoxCheckbox'

export const EbayAccountRequirementsModal = ({ open, onClose }: AccountRequirementsModalProps) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
        <FoxModalDialog open={open} title={'Notice for linking eBay'} leftButtonKind={'close'} onClose={() => onClose(false)} closeOnBackdropClick>
            <Box sx={{ textAlign: 'center' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    eBay listings created with Foxtail can be managed in Foxtail or eBay's web portal (browser).
                    <br />
                    <br />
                    These listings cannot be managed in the eBay app.{' '}
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginBottom: '40px' }}>
                    For more info{' '}
                    <Link href='https://help.foxtail.ai/en/articles/8054964-marketplace-setup-and-requirements#h_19c10794ef' target='_blank' rel='noopener'>
                        click here.
                    </Link>
                </FoxTypography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <FoxCheckbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} iconType='Checkbox' />
                    <FoxTypography variant='subtitle2'>I understand</FoxTypography>
                </Box>

                <FoxButton
                    primary
                    disabled={!isChecked}
                    onFoxClick={{ kind: 'button', onClick: async () => onClose(true) }}
                    text={'Link'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
