import { useEffect, useMemo, useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
    addBulkCrosslistItemsByListingId,
    getBulkCrosslistingSessionPopulated,
    Logger,
    rejectBulkCrosslistItemsByListingId,
    selectBulkCrosslistingFailedExtraRulesListingIds,
    selectBulkCrosslistingItemsByKind,
    selectBulkCrosslistingSessionId,
    selectListingsByIds,
    smartSearch,
    submitBulkCrosslistingSessionReview,
    cancelBulkCrosslistingSession,
    selectBulkCrosslistingSessionStatus,
    selectBulkCrosslistingIsCancelling
} from '@foxtail-dev/user-clients'
import { z } from '@foxtail-dev/datacontracts'
import { Box, Tabs, Tab } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxSearchBar } from '../../components/common/FoxSearchBar'
import { Header } from '../../components/common/Header'
import { ListingTable } from '../../containers/listing/ListingTable'
import { sharedStyles } from '../../theme/SharedStyling'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { generateToast } from '../../lib/clients/ToastClient'
import { BulkCrosslistReviewRow } from '../../components/bulkcrosslist/BulkCrosslistReviewRow'
import { BulkCrosslistReviewSummaryModal } from '../../modals/bulkCrosslist/BulkCrosslistReviewSummaryModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { BulkCrosslistSubmitConfirmationModal } from '../../modals/bulkCrosslist/BulkCrosslistSubmitConfirmationModal'
import { BulkCrosslistCancelConfirmationModal } from '../../modals/bulkCrosslist/BulkCrosslistCancelConfirmationModal'
import { BulkCrosslistListingModal } from '../../modals/bulkCrosslist/BulkCrosslistListingModal'
import { BulkCrosslistMoreMenu } from '../../components/bulkcrosslist/BulkCrosslistMoreMenu'
import { BulkCrosslistCancellingModal } from '../../modals/bulkCrosslist/BulkCrosslistCancellingModal'

export const GeneratedListingTabKind = z.enum(['readyToCrosslist', 'pendingReview', 'rejected', 'failed'])
export type GeneratedListingTabKind = z.infer<typeof GeneratedListingTabKind>

const TAB_MAPPING: Record<GeneratedListingTabKind, string> = {
    pendingReview: 'Pending',
    readyToCrosslist: 'Ready',
    rejected: 'Rejected',
    failed: 'Failed'
}

// TODO: Combine this with the BulkCrosslistScreen and make this one stage of content instead of its own screen
export const BulkCrosslistReviewScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const queryTab = queryParams.get('tab')

    const changeTab = (newTab: GeneratedListingTabKind, replace: boolean = false) => {
        const newParams = new URLSearchParams(location.search)
        newParams.set('tab', newTab)
        navigate(`${location.pathname}?${newParams.toString()}`, { replace })
    }

    useEffect(() => {
        const parseResult = GeneratedListingTabKind.safeParse(queryParams.get('tab'))

        if (parseResult.success) {
            setTab(parseResult.data)
        } else {
            changeTab('pendingReview', true)
        }
    }, [queryTab])

    const showSummary = queryParams.get('showSummary') === 'true'

    const setSeenReview = () => {
        const newParams = new URLSearchParams(location.search)
        newParams.set('showSummary', 'false')
        navigate(`${location.pathname}?${newParams.toString()}`, { replace: true })
    }

    useEffect(() => {
        setShowSummaryModal(showSummary)
    }, [showSummary])

    const sessionStatus = useAppSelector(selectBulkCrosslistingSessionStatus)
    const isCancelling = useAppSelector(selectBulkCrosslistingIsCancelling)

    const [tab, setTab] = useState<GeneratedListingTabKind>(GeneratedListingTabKind.Enum.pendingReview)
    const [search, setSearch] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isApproving, setIsApproving] = useState<boolean>(false)
    const [isRejecting, setIsRejecting] = useState<boolean>(false)

    const listingIdsPerTabMap: Record<GeneratedListingTabKind, string[]> = {
        readyToCrosslist: useAppSelector(selectBulkCrosslistingItemsByKind.readyToCrosslist),
        pendingReview: useAppSelector(selectBulkCrosslistingItemsByKind.pendingReview),
        rejected: useAppSelector(selectBulkCrosslistingItemsByKind.rejected),
        failed: useAppSelector(selectBulkCrosslistingItemsByKind.failed)
    }
    const failedExtraRuleListingIds = useAppSelector(selectBulkCrosslistingFailedExtraRulesListingIds)
    const listings = useAppSelector(selectListingsByIds(listingIdsPerTabMap[tab]))
    const hasReadyToCrosslistListings = listingIdsPerTabMap.readyToCrosslist.length > 0
    const bulkCrosslistingSessionId = useAppSelector(selectBulkCrosslistingSessionId)

    //Filter listings by search term
    const filteredListings = useMemo(() => {
        if (search === '') {
            return listings
        }

        const key = 'listingDescription.listingDetails.commonDetails.title'
        const results = smartSearch<ListingSchema>({ input: search, list: listings, keys: [key] })

        return results.map((result) => result.item)
    }, [search, listings])

    const filteredListingIds = useMemo(() => {
        return filteredListings.map((li) => li._id)
    }, [filteredListings])

    const listingIdsCanAutoApprove = useMemo(() => {
        return filteredListingIds.filter((li) => !failedExtraRuleListingIds.includes(li))
    }, [filteredListings, failedExtraRuleListingIds])

    const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false)
    const [showListingModal, setShowListingModal] = useState(false)
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false)
    const [showCancellingModal, setShowCancellingModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(50)

    const [showSummaryModal, setShowSummaryModal] = useState(false)

    useEffect(() => {
        if (sessionStatus === 'draft' || sessionStatus === 'generating') {
            navigate('/app/bulk-crosslist', { replace: true })
        } else if (isCancelling) {
            setShowCancellingModal(true)
        } else if (sessionStatus === 'listing') {
            setShowListingModal(true)
        }
    }, [sessionStatus, isCancelling])

    const onCloseSummaryModal = () => {
        setShowSummaryModal(false)
        setSeenReview()
    }

    const submitCheck = async () => {
        if (listingIdsPerTabMap.pendingReview.length > 0 || listingIdsPerTabMap.failed.length > 0) {
            setShowSubmitConfirmationModal(true)
        } else {
            await submitBulkCrosslist()
        }
    }

    const onSubmitModalReturn = async (result: boolean) => {
        setShowSubmitConfirmationModal(false)
        if (result) {
            await submitBulkCrosslist()
        }
    }

    const submitBulkCrosslist = async () => {
        setIsSubmitting(true)
        try {
            const result = await dispatch(submitBulkCrosslistingSessionReview()).unwrap()
            setShowListingModal(true)
            Logger.I().log({
                level: 'info',
                message: 'User submitted bulk crosslist review',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        bulkCrosslistingSessionId,
                        sessionResult: result.sessionInfo
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to submit bulk crosslist review',
                    payload: {
                        kind: 'BulkCrosslistSubmitError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Failed to submit bulk crosslist review', subText: 'Please try again' })
        } finally {
            setIsSubmitting(false)
        }
    }

    const approveAll = async () => {
        setIsApproving(true)
        try {
            await dispatch(addBulkCrosslistItemsByListingId(listingIdsCanAutoApprove)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User approved all listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        bulkCrosslistingSessionId
                    }
                }
            })
            generateToast({ kind: 'info', message: 'Approved all selected listings' })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to approve all',
                    payload: {
                        kind: 'BulkCrosslistApproveAllError',
                        entry: {
                            listingIds: listingIdsCanAutoApprove,
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Failed to approve all listings', subText: 'Please try again' })
        } finally {
            setIsApproving(false)
        }
    }

    const rejectAll = async () => {
        setIsRejecting(true)
        try {
            await dispatch(rejectBulkCrosslistItemsByListingId(filteredListingIds)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User rejected all listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        bulkCrosslistingSessionId
                    }
                }
            })
            generateToast({ kind: 'info', message: 'Rejected all selected listings' })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to reject all',
                    payload: {
                        kind: 'BulkCrosslistRejectAllError',
                        entry: {
                            listingIds: filteredListingIds,
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Failed to reject all listings', subText: 'Please try again' })
        }
        setIsRejecting(false)
    }

    const onCancel = () => {
        setShowCancelConfirmationModal(true)
    }

    const onCancelModalReturn = async (result: boolean) => {
        setShowCancelConfirmationModal(false)
        if (!result) {
            return
        }

        try {
            const result = await dispatch(cancelBulkCrosslistingSession()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User cancelled bulk crosslisting',
                payload: {
                    kind: 'bulkCrosslist',
                    entry: {
                        sessionId: bulkCrosslistingSessionId
                    }
                }
            })
            setShowCancellingModal(true)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to cancel bulk crosslist review',
                    payload: {
                        kind: 'BulkCrosslistCancelError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Failed to cancel bulk crosslist review', subText: 'Please try again' })
        }
    }

    useEffect(() => {
        dispatch(getBulkCrosslistingSessionPopulated())
    }, [])

    const onChangeTab = (e: any, tab: string) => {
        Logger.I().log({
            level: 'info',
            message: 'User changed bulk crosslist review tab',
            payload: {
                kind: 'UserAction',
                entry: {
                    tab
                }
            }
        })
        changeTab(GeneratedListingTabKind.parse(tab))
    }

    const pluralizedListingStr = listingIdsPerTabMap.readyToCrosslist.length === 1 ? 'listing' : 'listings'

    return (
        <Box sx={[sharedStyles.flexColumn, sharedStyles.fullSize]}>
            <Header
                title='Review listings'
                rightComponent={
                    <>
                        {hasReadyToCrosslistListings && (
                            <FoxButton
                                primary
                                variant='contained'
                                text={`Crosslist ${listingIdsPerTabMap.readyToCrosslist.length} ${pluralizedListingStr}`}
                                onFoxClick={{
                                    onClick: submitCheck,
                                    kind: 'button',
                                    preventDoubleClick: true
                                }}
                                disabled={isApproving || isRejecting || isSubmitting}
                                sx={{ height: '48px', marginLeft: '16px', width: 'fit-content' }}
                            />
                        )}
                    </>
                }
                rightComponent2={<BulkCrosslistMoreMenu onCancel={onCancel} />}
            />
            <Tabs value={tab} onChange={onChangeTab}>
                {Object.entries(TAB_MAPPING).map(([tabKey, tabValue]) => {
                    return <Tab key={tabKey} value={tabKey} label={`${tabValue} (${listingIdsPerTabMap[GeneratedListingTabKind.parse(tabKey)].length})`} />
                })}
            </Tabs>
            <Box sx={{ display: 'flex', marginTop: '28px', marginBottom: '28px', alignItems: 'center' }}>
                <FoxSearchBar label={'Search listings'} value={search} onChange={setSearch} sx={{ margin: 0 }} />
                <FlexGrow />

                {tab === 'pendingReview' && listingIdsPerTabMap['pendingReview'].length > 0 && (
                    <>
                        <FoxButton
                            primary
                            variant='contained'
                            text={'Approve all'}
                            onFoxClick={{
                                onClick: approveAll,
                                kind: 'button',
                                preventDoubleClick: true
                            }}
                            disabled={isApproving || isRejecting || isSubmitting}
                            sx={{ height: '48px', width: 'fit-content' }}
                        />
                        <FoxButton
                            variant='contained'
                            grey
                            text={'Reject all'}
                            onFoxClick={{
                                onClick: rejectAll,
                                kind: 'button',
                                preventDoubleClick: true
                            }}
                            disabled={isApproving || isRejecting || isSubmitting}
                            sx={{ height: '48px', marginLeft: '16px', width: 'fit-content' }}
                        />
                    </>
                )}
            </Box>
            <ListingTable
                listingIds={filteredListingIds}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                hasMoreListings={false}
                showAllListings={true}
                tab={tab}
                listingRowComponent={BulkCrosslistReviewRow}
                isLoading={false}
            />
            <BulkCrosslistReviewSummaryModal open={showSummaryModal} onClose={onCloseSummaryModal} />
            <BulkCrosslistSubmitConfirmationModal open={showSubmitConfirmationModal} onClose={onSubmitModalReturn} />
            <BulkCrosslistCancelConfirmationModal open={showCancelConfirmationModal} onClose={onCancelModalReturn} />
            <BulkCrosslistListingModal open={showListingModal} />
            <BulkCrosslistCancellingModal open={showCancellingModal} />
        </Box>
    )
}
