import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { Colors } from '@foxtail-dev/user-clients'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxButtonBase } from '../common/FoxButtonBase'

type ZeroListingsProps = {
    listingKind: string
}

export const ZeroListings = (props: ZeroListingsProps) => {
    return (
        <Box sx={{ marginTop: '38px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ height: '130px', flex: 1, justifyContent: 'center', display: 'flex', marginBottom: '44px' }}>
                <FoxImage src={CommonImages.general.zeroListings} style={{ height: '130px' }} />
            </Box>
            <FoxTypography variant='h5' sx={{ marginBottom: '5px' }}>
                No listings
            </FoxTypography>
            <FoxTypography light variant='body2' sx={{ lineHeight: '26px', marginBottom: '23px' }}>
                {props.listingKind} listings will show up here
            </FoxTypography>
            <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/create-listing/new' }}>
                <FoxTypography variant='subtitle1' sx={{ color: Colors.light.primary }}>
                    + Add new listing
                </FoxTypography>
            </FoxButtonBase>
        </Box>
    )
}
