import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import React from 'react'
import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { assert } from '@foxtail-dev/datacontracts'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { clearMercariSizeInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'

export const MercariCategoryModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const taxonomyProvider = useTaxonomyProvider()
    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = (categoryNames: string[]) => {
        assert(taxonomyProvider, 'Taxonomy provider is not initialized')

        const node = taxonomyProvider.mercari.base.findNodeCategoryNames(categoryNames)
        updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
            paramName: 'categories',
            domainName: 'mercari',
            isRequired: true,
            value: [node.ids.L0_id, node.ids.L1_id, node.ids.L2_id]
        })

        clearMercariSizeInFormik(setFieldValue, setFieldTouched)
        closeModal()
    }

    return (
        <CategorySelection
            title='Mercari Category'
            renderItem={(item) => (
                <DetailSelection key={item.categoryId} onDetail={() => handleSave(item.categoryNameArray)} value={item.categoryNameArray.join(', ')} />
            )}
            domain='mercari'
        />
    )
}
