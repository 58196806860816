import { Menu, MenuItem, Box, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { FoxTypography } from '../../components/common/FoxTypography'
import { CreditCardIcon } from '../../components/icons/CreditCardIcon'
import { ListingsIcon } from '../../components/icons/ListingsIcon'
import { ProfileIcon } from '../../components/icons/ProfileIcon'
import { SignOutIcon } from '../../components/icons/SignOutIcon'

type AvatarMenuProps = {
    anchorEl: HTMLElement | null
    open: boolean
    handleClose: () => void
    onLogout: (e: React.MouseEvent) => Promise<void>
}

export const AvatarMenu = (props: AvatarMenuProps): JSX.Element => {
    const { anchorEl, handleClose, open, onLogout } = props
    return (
        <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
            slotProps={{
                paper: {
                    sx: styles.menu
                }
            }}
        >
            <MenuItem component={Link} to={'/app/account/marketplace-connections'} onClick={handleClose}>
                <Box sx={styles.containerMenuRow}>
                    <Box sx={styles.containerMenuIcon}>
                        <ListingsIcon height={19} width={19} />
                    </Box>
                    <FoxTypography sx={styles.textMenu}>Connections</FoxTypography>
                </Box>
            </MenuItem>
            <MenuItem component={Link} to={'/app/account/profile'} onClick={handleClose}>
                <Box sx={styles.containerMenuRow}>
                    <Box sx={styles.containerMenuIcon}>
                        <ProfileIcon height={19} width={19} />
                    </Box>
                    <FoxTypography sx={styles.textMenu}>Edit profile</FoxTypography>
                </Box>
            </MenuItem>
            <MenuItem component={Link} to={'/app/account/subscription'} onClick={handleClose}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={styles.containerMenuIcon}>
                        <CreditCardIcon height={15} width={19} />
                    </Box>
                    <FoxTypography sx={styles.textMenu}>Subscription</FoxTypography>
                </Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={onLogout}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={styles.containerMenuIcon}>
                        <SignOutIcon height={19} width={19} />
                    </Box>
                    <FoxTypography>Log out</FoxTypography>
                </Box>
            </MenuItem>
        </Menu>
    )
}

const styles = {
    containerMenuRow: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px'
    },
    containerMenuIcon: {
        marginLeft: '21px',
        marginTop: '8px',
        marginRight: '22px'
    },
    textMenu: {
        marginTop: '6px'
    },
    menu: {
        width: '204px',
        maxHeight: '400px',
        borderRadius: '10px',
        overflow: 'visible' // Prevents scroll view
    }
}
