import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { FoxTypography } from '../../../components/common/FoxTypography'

export type LongCategoryPathProps = {
    path?: string[]
} & BoxProps

export const LongCategoryPath = ({ path, ...otherProps }: LongCategoryPathProps) => {
    if (!path || !path.length) return null

    const lastPathItem = path[path.length - 1]
    const pathWithoutLastItem = path.slice(0, path.length - 1)
    return (
        <Box {...otherProps}>
            <FoxTypography variant='body1'>{lastPathItem}</FoxTypography>
            <FoxTypography light sx={[styles.path]}>
                {pathWithoutLastItem.join(', ')}
            </FoxTypography>
        </Box>
    )
}

const styles = {
    path: {
        marginTop: '4px'
    }
}
