import { FormikField } from './FormikField'
import { z } from '@foxtail-dev/datacontracts'
import { Logger, RadioOption } from '@foxtail-dev/user-clients'
import { FoxCheckbox, FoxCheckboxType } from '../common/FoxCheckbox'
import { Box, Divider } from '@mui/material'
import { sharedStyles } from '../../theme/SharedStyling'

export type RadioGroupFieldProps = {
    name: string
    label?: string
    options: RadioOption[]
    iconType: FoxCheckboxType
    ignoreError?: boolean
    logOnChange?: boolean
}

export const getRadioOptionLabel = (option: RadioOption): string => (typeof option === 'string' ? option : option[1])
export const getRadioOptionValue = (option: RadioOption): string => (typeof option === 'string' ? option : option[0])

// TODO: Improve divider position
export const RadioGroupField = ({ name, label, iconType, options, ignoreError, logOnChange }: RadioGroupFieldProps) => {
    return (
        <FormikField
            name={name}
            label={label}
            ignoreError={ignoreError}
            render={({ value, onChange }) => (
                <Box sx={{ ...sharedStyles.flexColumn }}>
                    {options.map((option) => (
                        <Box key={getRadioOptionValue(option)} sx={{ height: '48px' }}>
                            <FoxCheckbox
                                key={getRadioOptionValue(option)}
                                iconType={iconType}
                                label={getRadioOptionLabel(option)}
                                checked={value === getRadioOptionValue(option)}
                                onChange={() => {
                                    onChange(getRadioOptionValue(option))
                                    if (logOnChange) {
                                        Logger.I().log({
                                            level: 'info',
                                            message: 'User selected a radio option',
                                            payload: {
                                                kind: 'UserAction',
                                                entry: {
                                                    name,
                                                    value
                                                }
                                            }
                                        })
                                    }
                                }}
                            />
                            <Divider sx={{ marginLeft: '40px' }} />
                        </Box>
                    ))}
                </Box>
            )}
        />
    )
}

export const RadioOptionBool = z.tuple([z.boolean(), z.string()])
export type RadioOptionBool = z.infer<typeof RadioOptionBool>

export type RadioBoolGroupFieldProps = {
    name: string
    label?: string
    options: RadioOptionBool[]
    iconType: FoxCheckboxType
    titleSubtext?: string
    logOnChange?: boolean
}

export const getRadioOptionBoolLabel = (option: RadioOptionBool): string => (typeof option === 'string' ? option : option[1])
export const getRadioOptionBoolValue = (option: RadioOptionBool): boolean => (typeof option === 'boolean' ? option : option[0])

export const RadioBoolGroupField = ({ name, label, iconType, options, titleSubtext, logOnChange }: RadioBoolGroupFieldProps) => {
    return (
        <FormikField
            name={name}
            label={label}
            titleSubtext={titleSubtext}
            render={({ value, onChange }) => (
                <Box sx={{ ...sharedStyles.flexColumn }}>
                    {options.map((option) => (
                        <Box key={getRadioOptionBoolLabel(option)}>
                            <FoxCheckbox
                                key={getRadioOptionBoolLabel(option)}
                                iconType={iconType}
                                label={getRadioOptionBoolLabel(option)}
                                checked={value === getRadioOptionBoolValue(option)}
                                onChange={() => {
                                    onChange(getRadioOptionBoolValue(option))
                                    if (logOnChange) {
                                        Logger.I().log({
                                            level: 'info',
                                            message: 'User selected a radio option',
                                            payload: {
                                                kind: 'UserAction',
                                                entry: {
                                                    name,
                                                    value
                                                }
                                            }
                                        })
                                    }
                                }}
                            />
                            <Divider variant='middle' />
                        </Box>
                    ))}
                </Box>
            )}
            sx={{ marginBottom: '26px' }}
        />
    )
}
