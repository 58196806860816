import { createDateFromMonth } from './createDateFromMonth'
import { getLastDayOfWeek } from './getLastDayOfTheWeek'

export type GetXAxisLabelsFromTimeRangeParams = {
    timeRange: string
    graphData: {
        labels: string[]
        yAxisInterval: number
        hidePointsAtIndex: number[]
        datasets: {
            data: number[]
            market: string
            color: string
        }[]
    }
}
export const getXAxisLabelsFromTimeRange = (params: GetXAxisLabelsFromTimeRangeParams): Date[] => {
    const { timeRange, graphData } = params
    if (timeRange === 'week') {
        return graphData.labels.map((label) => getLastDayOfWeek(label))
    } else if (timeRange === 'month') {
        const currentYear = new Date().getFullYear()
        return graphData.labels.map((label) => new Date(`${label}/${currentYear}`))
    } else if (timeRange === 'year') {
        return graphData.labels.map((monthStr) => createDateFromMonth(monthStr))
    } else {
        throw new Error('Invalid time range')
    }
}
