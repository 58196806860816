import { Colors, Logger } from '@foxtail-dev/user-clients'
import { BoxProps, Box, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { FoxTypography } from '../common/FoxTypography'
import { useFormikContext } from 'formik'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { FoxListingImage, ListingImageInfo } from '../common/FoxListingImage'
import { PencilIcon } from '../icons/PencilIcon'
import { FoxButtonBase } from '../common/FoxButtonBase'

export type ImageEditParams = {
    uri: string
    imageId: string
}

export type ProductImageProps = {
    imageId: string
    existingImageIds: string[]
    onEdit: (params: ImageEditParams) => void
    description?: string
    isCoverShot?: boolean
    selectingCovershot: boolean
    setSelectingCovershot: React.Dispatch<React.SetStateAction<boolean>>
} & BoxProps

const ProductImage = ({ imageId, onEdit, description, sx, selectingCovershot, setSelectingCovershot, isCoverShot, existingImageIds }: ProductImageProps) => {
    const theme = useTheme()
    const iconStyle = { backgroundColor: Colors.light.tertiary }
    const listingImageInfoRef = useRef<ListingImageInfo>({ loaded: false, imageUri: '' })
    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        if (listingImageInfoRef.current.loaded !== imageLoaded) {
            setImageLoaded(listingImageInfoRef.current.loaded)
        }
    }, [listingImageInfoRef.current.loaded, imageLoaded])

    const handlePress = async () => {
        const listingImageInfo = listingImageInfoRef.current
        if (listingImageInfo.loaded && listingImageInfo.imageUri) {
            onEdit({ uri: listingImageInfo.imageUri, imageId })
        }
    }

    const { setFieldTouched, setFieldValue } = useFormikContext<ListingDetailsDraft>()

    const handleSelectCovershot = async () => {
        setFieldTouched('imageIds', true)
        setFieldValue('imageIds', [imageId, ...existingImageIds.filter((id) => id !== imageId)])
        setSelectingCovershot(false)
        Logger.I().log({
            level: 'info',
            message: 'User selected covershot',
            payload: {
                kind: 'UserAction',
                entry: {
                    imageId
                }
            }
        })
    }

    return (
        <FoxButtonBase
            sx={{
                ...sx,
                ...{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '12px'
                }
            }}
            onFoxClick={{ kind: 'button', onClick: selectingCovershot ? handleSelectCovershot : handlePress }}
            sentry-label='ProductImage'
        >
            <Box
                sx={{
                    position: 'relative',
                    height: '80px',
                    width: '80px',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: Colors.light.divider
                }}
            >
                <FoxListingImage
                    listingImageInfoRef={listingImageInfoRef}
                    imageId={imageId}
                    style={{
                        width: '80px',
                        height: '80px',
                        borderWidth: isCoverShot ? '4px' : selectingCovershot ? '4px' : 0,
                        borderColor: Colors.light.tertiary,
                        borderStyle: 'solid',
                        borderRadius: '10px'
                    }}
                />
                {imageLoaded && selectingCovershot === false && (
                    <Box sx={{ ...styles.editIcon, ...iconStyle }}>
                        <PencilIcon width={13} height={13} color={Colors.light.background} />
                    </Box>
                )}
            </Box>

            {description && (
                <Box
                    sx={{
                        ...styles.description,
                        ...{
                            backgroundColor: 'grey',
                            borderRadius: '12px',
                            paddingHorizontal: '4px',
                            marginTop: '8px'
                        }
                    }}
                >
                    <FoxTypography variant='body1' sx={{ color: theme.palette.background.default, textAlign: 'center' }}>
                        {description}
                    </FoxTypography>
                </Box>
            )}
        </FoxButtonBase>
    )
}

const styles = {
    description: {
        marginTop: '4px',
        alignItems: 'center'
    },
    editIcon: {
        height: '24px',
        width: '24px',
        top: '48px',
        left: '48px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        opacity: 0.8,
        zIndex: 1
    }
}

export default ProductImage
