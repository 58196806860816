import { Box, useTheme } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxtailLogoAndNameIcon } from '../components/icons/FoxtailLogoAndName'

type OnBoardingHeaderProps = {
    title: string
    subtitle: string
}

export const OnboardingHeader = (props: OnBoardingHeaderProps): JSX.Element => {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Box
                sx={{
                    marginTop: '120px',
                    marginBottom: '47px',
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <FoxtailLogoAndNameIcon />
            </Box>

            <FoxTypography variant='h4' sx={{ marginBottom: '17px' }}>
                {props.title}
            </FoxTypography>

            <FoxTypography variant='body1' sx={{ marginBottom: '47px', color: theme.palette.text.secondary, textAlign: 'center' }}>
                {props.subtitle}
            </FoxTypography>
        </Box>
    )
}
