import { Colors } from '@foxtail-dev/user-clients'
import { Box, SxProps, Theme } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'

type AnalyticsBoxProps = {
    title: string
    value: string
    includeDollarSign?: boolean
    sx?: SxProps<Theme>
}
export const AnalyticsBox = (props: AnalyticsBoxProps) => {
    const { title, value, includeDollarSign, sx } = props
    return (
        <Box sx={{ ...styles.container, ...sx }}>
            <FoxTypography sx={styles.title} variant='subtitle1'>
                {title}
            </FoxTypography>
            <FoxTypography sx={styles.value} variant='h4'>
                {includeDollarSign && '$'}
                {value}
            </FoxTypography>
        </Box>
    )
}

const styles = {
    container: {
        borderColor: Colors.light.divider,
        borderRadius: '10px',
        borderWidth: '1px',
        borderStyle: 'solid',
        height: '133px',
        flex: 1
    },
    title: {
        marginTop: '20px',
        marginLeft: '25px',
        marginBottom: '27px'
    },
    value: {
        marginLeft: '25px'
    }
}
