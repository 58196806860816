import { Outlet } from 'react-router-dom'
import { AppHeader } from './AppHeader'
import { AppSidebar } from './AppSidebar'
import { Box, Divider } from '@mui/material'
import { FoxStyle, sharedStyles } from '../theme/SharedStyling'
import { AccountSideBar } from './AccountSideBar'

const AccountLayout = (): JSX.Element => {
    return (
        <Box sx={styles.container}>
            <AccountSideBar />
            <Box component='main' className='mainContent' sx={[sharedStyles.fullSize]}>
                <Outlet />
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flex: 1
    }
}

export default AccountLayout
