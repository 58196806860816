import React from 'react'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { keyframes } from '@mui/material'

const shimmer = keyframes`
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`

export const ShimmerText = styled(Typography)`
    display: inline-block;
    background: linear-gradient(to right, #e87155 0%, #fff 20%, #e87155 40%, #e87155 100%);
    background-size: 1000px 100%;
    animation: ${shimmer} 2s infinite linear;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
