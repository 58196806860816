import { EtsyConditionalAttribute } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { RadioOption, getFacebookCategoryNamesFromId } from '@foxtail-dev/user-clients'
import { BoxProps } from '@mui/material'
import { FormikField } from './FormikField'
import { DetailSelection } from '../common/DetailSelection'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { IDomainSpecificTaxonomyDict } from '@foxtail-dev/datacontracts/dist/lib/datastructures/domainTaxonomies/DomainTaxonomyDict'

type GetValueToRenderParams = {
    renderValue?: (value: any) => JSX.Element | null
    value: any
    name: string
    label?: string
    taxonomyProvider: IDomainSpecificTaxonomyDict | null
}

// TODO: Move to user clients potentially
// TODO: Cleanup function, it's a bit hard to understand
const getValueToRender = ({ renderValue, value, name, label, taxonomyProvider }: GetValueToRenderParams) => {
    if (name === 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs') {
        if (!value) {
            return { value: '' }
        }
        const conditionalInputValue = value.find((attribute: EtsyConditionalAttribute) => label && attribute.attributeName === label)
        if (!conditionalInputValue) {
            return { value: '' }
        }

        return { value: conditionalInputValue.valueId }
    } else if (name === 'domainSpecificDetails.mercari.requiredDetails.categories') {
        if (!value || !taxonomyProvider) {
            return { value: '' }
        }
        const mercariTaxonomy = taxonomyProvider.mercari
        const mercariCategoryNode = mercariTaxonomy.base.findNodeCategoryIds(value)
        return { value: Object.values(mercariCategoryNode?.values).join(', ') }
    } else if (name === 'domainSpecificDetails.depop.requiredDetails.categoryId') {
        if (!value || !taxonomyProvider) {
            return { value: '' }
        }
        const depopTaxonomy = taxonomyProvider.depop
        const node = depopTaxonomy.findChildById(value)
        return { value: node.fullNodeNameArray.join(', ') }
    } else if (name === 'domainSpecificDetails.facebook.requiredDetails.categoryId') {
        if (!value || !taxonomyProvider) {
            return { value: '' }
        }
        const facebookTaxonomy = taxonomyProvider.facebook
        const categoryNames = getFacebookCategoryNamesFromId(facebookTaxonomy, value)

        return { value: categoryNames.join(', ') }
    }
    return renderValue ? { children: renderValue(value) } : { value }
}

type DetailSelectionFieldProps = {
    name: string
    label?: string
    placeholder?: string
    options?: RadioOption[]
    renderValue?: (value: any) => JSX.Element | null
    onDetail: () => void
    titleSubtext?: string
    ignoreError?: boolean
    styleOverride?: BoxProps['sx']
    sentryLabel?: string
}

export const DetailSelectionField = (props: DetailSelectionFieldProps) => {
    const { name, label, placeholder, options, renderValue, onDetail, titleSubtext, ignoreError, styleOverride, sentryLabel } = props
    const taxonomyProvider = useTaxonomyProvider()

    return (
        <FormikField
            name={name}
            label={label}
            titleSubtext={titleSubtext}
            ignoreError={ignoreError}
            sx={{ marginBottom: '28px', ...styleOverride }}
            render={({ value }) => {
                return (
                    <DetailSelection
                        options={options}
                        placeholder={placeholder}
                        onDetail={onDetail}
                        {...getValueToRender({ renderValue, value, name, label, taxonomyProvider })}
                    />
                )
            }}
        />
    )
}
