import { useAppSelector, selectListingById, normalizePrice, Colors } from '@foxtail-dev/user-clients'
import { BaseListingRow } from '../listing/BaseListingRow'

type BulkCrosslistReviewSummaryRowProps = {
    listingId: string
}

export const BulkCrosslistReviewSummaryRow = (props: BulkCrosslistReviewSummaryRowProps) => {
    const { listingId } = props

    const listing = useAppSelector(selectListingById(listingId))
    const listingDetails = listing?.listingDescription.listingDetails
    const imageIds = listingDetails?.imageIds ?? []

    const title = listingDetails?.commonDetails?.title ?? 'Title'
    const price = listingDetails?.commonDetails?.price ?? '0.00'

    return <BaseListingRow title={title} onClick={() => {}} description={`$${normalizePrice(price)}`} imageId={imageIds[0]} sx={{ flexShrink: '0' }} />
}
