import { Colors } from '@foxtail-dev/user-clients'
import { SvgIcon } from '@mui/material'

type ChevronLeftIconProps = {
    color?: string
    height?: number
    width?: number
}

export const ChevronLeftIcon = (props: ChevronLeftIconProps) => {
    const height = props.height ?? 20
    const width = props.width ?? 12
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='12px' height='20px' viewBox='0 0 12 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>icon-chevron-left</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Select---categories' transform='translate(-20.000000, -74.000000)' fill={props.color ?? Colors.light.chevronGrey} fillRule='nonzero'>
                        <g id='Group-2' transform='translate(0.000000, 48.000000)'>
                            <g id='Group' transform='translate(0.000000, 10.000000)'>
                                <g id='arrow_back_ios_FILL0_wght700_GRAD0_opsz48' transform='translate(20.000000, 16.000000)'>
                                    <path
                                        d='M9.48051948,19.6002574 L0.363636364,10.8309043 C0.242424242,10.7143128 0.151515152,10.5852292 0.0909090909,10.4436537 C0.0303030303,10.3020782 3.4604354e-16,10.1563387 3.4604354e-16,10.0064353 C3.4604354e-16,9.85653178 0.0303030303,9.71079229 0.0909090909,9.56921679 C0.151515152,9.42764129 0.242424242,9.29855775 0.363636364,9.18196616 L9.48051948,0.41261309 C9.75757576,0.14611803 10.1038961,0.00870651474 10.5194805,0.000378544119 C10.9350649,-0.00794942651 11.2813853,0.121134118 11.5584416,0.387629178 C11.8528139,0.670780179 12,1.01222698 12,1.41196957 C12,1.81171216 11.8528139,2.15315895 11.5584416,2.43630995 L3.68831169,10.0064353 L11.5584416,17.5765605 C11.8528139,17.8597115 12,18.1969944 12,18.588409 C12,18.9798236 11.8614719,19.3087784 11.5844156,19.5752735 C11.2900433,19.8584245 10.9350649,20 10.5194805,20 C10.1038961,20 9.75757576,19.8667525 9.48051948,19.6002574 Z'
                                        id='icon-chevron-left'
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
