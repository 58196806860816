import { Logger } from '../../lib/clients/Logger'
import { generateToast } from '../../lib/clients/ToastClient'

type FoxVideoProps = {} & React.HTMLProps<HTMLVideoElement>

export const FoxVideo = ({ ...rest }: FoxVideoProps) => {
    const onError = () => {
        Logger.I().log({
            message: 'Error loading video',
            level: 'error',
            payload: {
                kind: 'UserAction',
                entry: {
                    videoSrc: rest.src
                }
            }
        })
        generateToast({
            message: 'Error loading video',
            kind: 'error',
            shouldAutoHide: true
        })
    }

    return <video {...rest} onError={onError} />
}
