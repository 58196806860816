import { useAppDispatch, maybeGetEbayAspects, getEbayConditions, getEbayFulfillmentPolicies, Logger } from '@foxtail-dev/user-clients'
import React from 'react'
import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

// TODO: Replace all of the dispatches with the functions that edit the formik field directly
export const EbayCategoryScreen = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const dispatch = useAppDispatch()
    const [isSaving, setIsSaving] = React.useState(false)

    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = async (result: CategorySimilarityMatch) => {
        setIsSaving(true)
        try {
            updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'category',
                domainName: 'ebay',
                isRequired: true,
                value: {
                    id: result.categoryId,
                    name: result.categoryFullname
                }
            })

            await Promise.all([
                dispatch(maybeGetEbayAspects({ categoryId: result.categoryId })),
                dispatch(
                    getEbayConditions({
                        categoryId: result.categoryId,
                        categoryName: result.categoryFullname
                    })
                ),
                dispatch(getEbayFulfillmentPolicies())
            ])

            Logger.I().log({
                level: 'info',
                message: 'Ebay category selected',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        domainName: 'ebay',
                        categorySimilarityMatch: result
                    }
                }
            })

            closeModal()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Ebay category selection failed',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            domainName: 'ebay',
                            categorySimilarityMatch: result
                        }
                    }
                },
                error
            )
        }

        setIsSaving(false)
    }

    return (
        <CategorySelection
            title='eBay Category'
            renderItem={(item) => {
                return (
                    <DetailSelection
                        key={item.categoryId}
                        onDetail={async () => {
                            await handleSave(item)
                        }}
                        value={item.categoryNameArray.join(', ')}
                        disabled={isSaving}
                    />
                )
            }}
            domain='ebay'
        />
    )
}
