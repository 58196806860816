import { cancelImportListingSession, Colors, Logger, selectImportListingSessionId, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { DeleteIcon } from '../icons/DeleteIcon'
import { FoxButton } from '../common/FoxButton'
import { FlexGrow } from '../common/FlexGrow'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { set } from 'lodash'

type AreYouSureYouWantToCancelImport = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const AreYouSureYouWantToCancelImport = (props: AreYouSureYouWantToCancelImport) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const importListingSessionId = useAppSelector(selectImportListingSessionId)
    const [isCancelling, setIsCancelling] = React.useState<boolean>(false)

    const onKeepImportSession = async () => {
        props.setIsRequestingCancel(false)
    }

    const onCancelImportSession = async () => {
        try {
            if (isCancelling) return
            setIsCancelling(true)
            Logger.I().log({
                level: 'info',
                message: 'User cancelled import listing session because no scraped listings were found',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        importListingSessionId
                    }
                }
            })
            await dispatch(cancelImportListingSession()).unwrap()
            setIsCancelling(false)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error cancelling import listing session',
                    payload: {
                        kind: 'ImportListingCancelError',
                        entry: {
                            importListingSessionId
                        }
                    }
                },
                error
            )
            props.setIsRequestingCancel(false)
            navigate('/app/listings')
            setIsCancelling(false)
        }
        props.setIsRequestingCancel(false)
        navigate('/app/listings')
    }

    return (
        <Box
            sx={{
                height: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                padding: '20px'
            }}
        >
            <Box sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <DeleteIcon height={70} width={200} />
                <FoxTypography
                    variant='h6'
                    sx={{ color: Colors.light.tertiary, marginTop: '48px', textAlign: 'center', marginLeft: '30px', marginRight: '30px' }}
                >
                    Are you sure you want to cancel your import session?
                </FoxTypography>
            </Box>
            <FoxButton
                primary
                sx={{ width: '100%', justifyContent: 'center', marginBottom: '12px' }}
                text='No, keep my current import'
                onFoxClick={{
                    kind: 'button',
                    onClick: onKeepImportSession
                }}
            />
            <FoxButton
                grey
                sx={{ width: '100%', justifyContent: 'center' }}
                text='Yes, cancel my import'
                onFoxClick={{
                    kind: 'button',
                    onClick: onCancelImportSession, // TODO is it okay that we pass this in like this instead of
                    /**
                     * onClick: async () => {
                     *   await onCancelImportSession()
                     * }
                     */
                    preventDoubleClick: true
                }}
                loading={isCancelling}
            />
        </Box>
    )
}
