import { Box, SxProps, Theme } from '@mui/material'
import { SubscriptionCardFeatureRow } from '../../containers/subscription/SubscriptionCardFeatureRow'
import { Colors } from '../../theme/Colors'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { Logger, selectSubscription, selectSubscriptionTier, selectUserId, useAppSelector, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { generateToast } from '../../lib/clients/ToastClient'
import { SubscriptionInfoMap } from './SubscriptionCardMobile'
import { UpdateUserInfo, UserSyncSubscriptionActionDescription } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/concreteSchemas/user'
import { assert } from '@foxtail-dev/datacontracts'

const isSubscriptionUpgrade = (params: { currentSubscriptionTier: UserSubscriptionTier; newSubscriptionTier: UserSubscriptionTier }) => {
    const { currentSubscriptionTier, newSubscriptionTier } = params
    const subscriptionTierOrder = ['freetrial', 'basic', 'premium', 'pro']
    return subscriptionTierOrder.indexOf(newSubscriptionTier) > subscriptionTierOrder.indexOf(currentSubscriptionTier)
}

type SubscriptionCardProps = {
    subscriptionKind: UserSubscriptionTier
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    sx?: SxProps<Theme>
}
export const SubscriptionCard = (props: SubscriptionCardProps) => {
    const { subscriptionKind, setModalOpen } = props
    const userSubscriptionTier = useAppSelector(selectSubscriptionTier)
    const isCurrentSubscription = userSubscriptionTier === subscriptionKind
    const userSubscription = useAppSelector(selectSubscription)
    const userId = useAppSelector(selectUserId)

    const onChoose = async () => {
        if (isCurrentSubscription) {
            return
        }

        if (!userSubscription) {
            return
        }

        if (userSubscription.kind === 'deactivated' || userSubscription.kind === 'inactive' || userSubscription.kind === 'none') {
            return
        }

        if (userSubscription.kind === 'freeplan') {
            try {
                const context = await UserRuntimeContextProvider.getContext()

                await context.userActionClient.executeActionRequest<UpdateUserInfo>({
                    kind: 'user/updateUserInfo',
                    params: {
                        preferredSubscriptionProvider: 'stripe'
                    }
                })
                const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                    cancelUrl: `${window.location.origin}/app/account/subscription`,
                    successUrl: `${window.location.origin}/subscription-success?subscriptionTier=${subscriptionKind}&isUpgrade=${isSubscriptionUpgrade({
                        newSubscriptionTier: subscriptionKind,
                        currentSubscriptionTier: 'freetrial'
                    })}`,
                    subscriptionTier: subscriptionKind
                })

                window.location.href = sessionUrl
            } catch (error) {
                Logger.I().log({
                    level: 'error',
                    message: 'Error creating stripe session',
                    payload: {
                        kind: 'StripeCheckoutSessionError',
                        entry: {
                            error,
                            subscription: userSubscription,
                            subscriptionKind,
                            isCurrentSubscription
                        }
                    }
                })
            }
        } else {
            if (userSubscription.details.kind === 'stripe') {
                try {
                    const context = await UserRuntimeContextProvider.getContext()
                    if (userSubscription.details.tier === 'freetrial') {
                        await context.userActionClient.executeActionRequest<UpdateUserInfo>({
                            kind: 'user/updateUserInfo',
                            params: {
                                preferredSubscriptionProvider: 'stripe'
                            }
                        })
                        const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                            cancelUrl: `${window.location.origin}/app/account/subscription`,
                            successUrl: `${window.location.origin}/subscription-success?subscriptionTier=${subscriptionKind}&isUpgrade=${isSubscriptionUpgrade({
                                newSubscriptionTier: subscriptionKind,
                                currentSubscriptionTier: userSubscription.details.tier
                            })}`,
                            subscriptionTier: subscriptionKind
                        })
                        window.location.href = sessionUrl
                    } else {
                        const { sessionUrl } = await context.userApiClient.stripe.createUpdateSubscriptionConfirmBillingPortalSession({
                            returnUrl: `${window.location.origin}/app/account/subscription`,
                            subscriptionTier: subscriptionKind
                        })
                        window.location.href = sessionUrl
                    }
                } catch (error) {
                    Logger.I().log({
                        level: 'error',
                        message: 'Error creating stripe session',
                        payload: {
                            kind: 'StripeBillingPortalSessionError',
                            entry: {
                                error,
                                subscription: userSubscription,
                                subscriptionKind,
                                isCurrentSubscription
                            }
                        }
                    })
                }
            } else if (userSubscription.details.kind === 'custom') {
                try {
                    assert(userId)
                    const context = await UserRuntimeContextProvider.getContext()
                    await context.userActionClient.executeActionRequest<UpdateUserInfo>({
                        kind: 'user/updateUserInfo',
                        params: {
                            preferredSubscriptionProvider: 'stripe'
                        }
                    })

                    const maybeStripeSubscription = await context.userApiClient.stripe.getStripeSubscriptionStatus()
                    if (maybeStripeSubscription.kind === 'found-active-stripe-subscription') {
                        await context.userActionClient.executeActionRequest<UserSyncSubscriptionActionDescription>({
                            kind: 'user/syncSubscription',
                            params: {
                                providerKind: 'stripe',
                                subscriptionLocator: userId
                            }
                        })
                        location.reload()
                    } else {
                        const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                            cancelUrl: `${window.location.origin}/app/account/subscription`,
                            successUrl: `${window.location.origin}/subscription-success?subscriptionTier=${subscriptionKind}&isUpgrade=${isSubscriptionUpgrade({
                                newSubscriptionTier: subscriptionKind,
                                currentSubscriptionTier: userSubscription.details.tier
                            })}`,
                            subscriptionTier: subscriptionKind
                        })
                        window.location.href = sessionUrl
                    }
                } catch (error) {
                    generateToast({ kind: 'error', message: 'Unable to subscribe. Please contact support' })
                    Logger.I().log({
                        level: 'error',
                        message: 'Error creating stripe session',
                        payload: {
                            kind: 'StripeBillingPortalSessionError',
                            entry: {
                                error,
                                subscription: userSubscription,
                                subscriptionKind,
                                isCurrentSubscription
                            }
                        }
                    })
                }
            } else if (userSubscription.details.kind === 'apple' || userSubscription.details.kind === 'googleplay') {
                setModalOpen(true)
            } else {
                Logger.I().log({
                    level: 'error',
                    message: 'Unknown subscription kind',
                    payload: {
                        kind: 'UnknownSubscriptionKind',
                        entry: {
                            subscriptionKind: userSubscription.details.kind
                        }
                    }
                })
                generateToast({ kind: 'error', message: 'Subscription error. Please contact support' })
            }
        }
    }

    return (
        <Box
            sx={{
                height: '420px',
                borderRadius: '30px',
                borderColor: Colors.light.divider,
                width: '254px',
                borderWidth: '1px',
                borderStyle: 'solid',
                ...props.sx
            }}>
            <FoxTypography sx={{ marginTop: '31px', marginLeft: '22px', marginBottom: '7px' }} variant='h6'>
                {SubscriptionInfoMap[subscriptionKind].displayName}
            </FoxTypography>
            <FoxTypography light sx={{ marginLeft: '22px' }} variant='body1'>
                {SubscriptionInfoMap[subscriptionKind].description}
            </FoxTypography>
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginLeft: '22px',
                    marginTop: '12px',
                    height: '32px',
                    alignItems: 'flex-end',
                    marginBottom: '8px'
                }}>
                <Box sx={{ marginRight: '6px' }}>
                    <FoxTypography sx={{ fontSize: '28.8px', fontWeight: 700, lineHeight: '32px' }}>
                        ${SubscriptionInfoMap[subscriptionKind].price}
                    </FoxTypography>
                </Box>
                <Box>
                    <FoxTypography light variant='body1'>
                        / month
                    </FoxTypography>
                </Box>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', marginLeft: '22px', marginBottom: '18px' }}>
                <FoxTypography variant='body1' sx={{ lineHeight: '26px', fontWeight: 700 }}>
                    {subscriptionKind === 'pro' ? 'Unlimited' : SubscriptionInfoMap[subscriptionKind].listingLimit} listings
                </FoxTypography>
                <FoxTypography variant='body1' sx={{ lineHeight: '26px' }}>
                    <span>&nbsp;</span>
                    per Month
                </FoxTypography>
            </Box>
            <FoxTypography variant='body1' sx={{ fontWeight: 500, marginLeft: '22px', marginBottom: '16px' }}>
                Plus
            </FoxTypography>

            <SubscriptionCardFeatureRow feature='AI content generation' />
            <SubscriptionCardFeatureRow feature='Importing' />
            <SubscriptionCardFeatureRow feature='Bulk crosslisting' />
            <SubscriptionCardFeatureRow feature='Engagement boosters' />
            <Box sx={{ display: 'flex', marginTop: '24px' }}>
                <FoxButton
                    onFoxClick={{ kind: 'button', onClick: onChoose, preventDoubleClick: true }}
                    sx={{
                        justifyContent: 'center',
                        marginBottom: '57px',
                        radius: '10px',
                        height: '48px',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        marginLeft: '30px',
                        marginRight: '30px',
                        width: '100%',
                        color: isCurrentSubscription ? Colors.light.tertiary : Colors.light.background
                    }}
                    primary={!isCurrentSubscription}
                    grey={isCurrentSubscription}
                    text={
                        isCurrentSubscription
                            ? 'Current plan'
                            : userSubscriptionTier === 'freetrial'
                            ? `Choose ${SubscriptionInfoMap[subscriptionKind].displayName}`
                            : `Upgrade to ${SubscriptionInfoMap[subscriptionKind].displayName}`
                    }
                />
            </Box>
        </Box>
    )
}
