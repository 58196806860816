import { Colors, selectImportListingDomain, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { FoxTypography } from '../../components/common/FoxTypography'
import { SaveIcon } from '../../components/icons/SaveIcon'
import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

export const ImportReadyForReviewButton = (): JSX.Element => {
    const importListingSesionDomain = useAppSelector(selectImportListingDomain)
    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/import-listing' }} sx={styles.containerImportReadyForReviewButton}>
            <Box sx={styles.saveIcon}>
                <SaveIcon height={14} width={11} color={Colors.light.secondary} />
            </Box>
            <FoxTypography sx={styles.textUpgradeButton}>
                Import from {Domains[DomainCommonName.parse(importListingSesionDomain)].displayName} ready for review
            </FoxTypography>
        </FoxButtonBase>
    )
}

const styles = {
    containerImportReadyForReviewButton: {
        height: '32px',
        borderRadius: '16px',
        backgroundColor: '#FDF7F6',
        marginLeft: '33px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'normal',
        cursor: 'pointer',
        overflow: 'hidden',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.light.secondary,
        flexShrink: 1
    },
    saveIcon: {
        marginLeft: '15px',
        marginTop: '8.5px',
        marginRight: '8px'
    },
    textUpgradeButton: {
        backgroundColor: 'blue',
        fontWeight: 700,
        background: Colors.light.foxGradient,
        marginLeft: '5px',
        marginTop: '5.5px',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        lineHight: '20px',
        fontSize: '14px',
        cursor: 'pointer',
        marginRight: '15px'
    }
}
