import { time } from '@foxtail-dev/datacontracts'
import {
    useAppDispatch,
    useAppSelector,
    selectIsSyncingInProgressByDomain,
    selectLastSyncedAtByDomain,
    getDomainLinkingStatus,
    Logger,
    syncUserMarketDetailsCampaign,
    selectEtsyShippingProfiles
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { generateToast } from '../../../lib/clients/ToastClient'
import { sharedStyles } from '../../../theme/SharedStyling'
import { FoxButton } from '../../../components/common/FoxButton'

export type CategorySelectionProps = {
    title: string
    renderItem: (item: any) => JSX.Element
}

export const EtsyShippingProfileDetailSelection = ({ title, renderItem }: CategorySelectionProps) => {
    const dispatch = useAppDispatch()
    const currentlySyncingOnEtsy = useAppSelector(selectIsSyncingInProgressByDomain['etsy'])
    const shippingProfiles = useAppSelector(selectEtsyShippingProfiles)
    const lastSyncedAt = useAppSelector(selectLastSyncedAtByDomain['etsy'])
    // Can request sync if last sync was more than 1 minute ago
    const [nextSyncTime, setNextSyncTime] = useState(new Date())
    const [syntheticSyncing, setSyntheticSyncing] = useState(false)
    const [showNextSyncTime, setShowNextSyncTime] = useState(false)
    const shouldShowSyncing = currentlySyncingOnEtsy || syntheticSyncing
    const secondsUntilNextSync = Math.round((nextSyncTime.getTime() - new Date().getTime()) / 1000)

    useEffect(() => {
        if (lastSyncedAt) {
            const nextSyncTime = new Date(new Date(lastSyncedAt).getTime() + time.minutes(1))
            setNextSyncTime(nextSyncTime)
        }
    }, [lastSyncedAt])

    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (syntheticSyncing) {
            // We don't have a good way of tracking when the sync starts and completes, especially given that we pull the sync state every 5 seconds
            // Put an synthetic loading state for 10 seconds, after that we base it on what the back end tells us
            timeout = setTimeout(() => {
                setSyntheticSyncing(false)
            }, time.seconds(10))
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [syntheticSyncing])

    // TODO: Consider not running if the site is in the background, seems fine for now
    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    const onSyncShippingProfiles = async () => {
        const canRequestSync = new Date() > nextSyncTime

        if (canRequestSync) {
            try {
                Logger.I().log({
                    message: 'User is requesting to sync etsy shipping profile',
                    level: 'info',
                    payload: {
                        kind: 'UserAction'
                    }
                })
                setShowNextSyncTime(false)
                setSyntheticSyncing(true)
                await dispatch(syncUserMarketDetailsCampaign('etsy')).unwrap()
            } catch (error) {
                Logger.I().log(
                    {
                        message: 'Error syncing etsy shipping profiles',
                        level: 'error',
                        payload: {
                            kind: 'EtsySyncError'
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Error syncing shipping profiles' })
            }
        } else {
            setShowNextSyncTime(true)
        }
    }

    return (
        <Box sx={[sharedStyles.screenContainer]} sentry-label='EtsyShippingProfilesDetailSelection'>
            {shouldShowSyncing ? (
                <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginTop: '140px', marginHorizontal: '20px' }}>
                    Syncing your Shipping Profiles from Etsy. Please wait... this takes about 1 minute.
                </FoxTypography>
            ) : (
                <Box sx={sharedStyles.flex1}>
                    {shippingProfiles?.map((i) => renderItem(i))}
                    <Box sx={sharedStyles.flexGrow} />
                    <FoxTypography variant='body1' light sx={{ textAlign: 'center', marginBottom: '12px', marginTop: '48px' }}>
                        If you don't see your shipping profile here or want to add a new shipping profile, manually add it to your Etsy account and click Sync
                        shipping profiles.
                    </FoxTypography>

                    {showNextSyncTime && (
                        <FoxTypography variant='body1' danger sx={{ marginBottom: '12px' }}>
                            You can only sync once every minute. Please try again in {secondsUntilNextSync} seconds
                        </FoxTypography>
                    )}
                    <FoxButton
                        primary
                        onFoxClick={{ kind: 'button', onClick: onSyncShippingProfiles, preventDoubleClick: true }}
                        text='Sync shipping profiles'
                        sx={{ marginBottom: '60px' }}
                        loading={shouldShowSyncing}
                    />
                </Box>
            )}
        </Box>
    )
}
