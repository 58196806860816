import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { AccountHeader } from '../../layouts/AccountHeader'
import { NotificationSettingRow } from '../../containers/account/NotificationSettingRow'

const NotificationSettingsScreen: React.FC = () => {
    return (
        <Box sx={styles.container}>
            <AccountHeader title='Notification settings' />
            <Box>
                <NotificationSettingRow settingName='Notify when listing is relisted' notificationKind='listing/relisted-item' />
                <NotificationSettingRow settingName='Notify when listing is published' notificationKind='listing/successful-publish' />
                <NotificationSettingRow settingName='Notify when listing is sold' notificationKind='listing/sold-item' />
                <NotificationSettingRow settingName='Notify when listing is delisted' notificationKind='listing/deleted-item' />
                <NotificationSettingRow settingName='Notify when listing is created' notificationKind='listing/created' />
                <NotificationSettingRow settingName='Notify when market is linked' notificationKind='listing/market-successful-link' />
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    }
}

export default NotificationSettingsScreen
