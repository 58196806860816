import { IconButton, IconButtonProps, SxProps, Theme } from '@mui/material'
import { Link } from 'react-router-dom'
import { OnFoxClick } from './FoxButtonBase'
import React from 'react'
import { Colors } from '@foxtail-dev/user-clients'

// TODO: Add grey prop
type FoxIconButtonProps = IconButtonProps & {
    primary?: boolean
    grey?: boolean
    disabled?: boolean
    loading?: boolean
    onFoxClick: OnFoxClick
    sx?: SxProps<Theme>
}

export const FoxIconButton = (props: FoxIconButtonProps) => {
    const { primary, grey, disabled, loading, onFoxClick, onClick: _, sx = {}, ...rest } = props

    const color = primary ? 'primary' : 'info'
    const greyCSS = grey ? { backgroundColor: Colors.light.buttonGrey } : {}
    const disabledCSS = disabled ? styles.disabled : {}

    const component = onFoxClick.kind === 'external' ? 'a' : onFoxClick.kind === 'internal' ? Link : 'button'
    const href = onFoxClick.kind === 'external' ? onFoxClick.href : undefined
    const to = onFoxClick.kind === 'internal' ? onFoxClick.to : undefined
    const [awaitingOnClick, setAwaitingOnClick] = React.useState(false)
    const isButtonLoading = loading || awaitingOnClick

    let onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined
    if (onFoxClick.kind === 'button') {
        if (onFoxClick.preventDoubleClick) {
            onClick = async (e) => {
                if (awaitingOnClick) {
                    return
                }

                setAwaitingOnClick(true)
                try {
                    await onFoxClick.onClick(e)
                } finally {
                    setAwaitingOnClick(false)
                }
            }
        } else {
            onClick = onFoxClick.onClick
        }
    }

    return (
        <IconButton
            color={color}
            disabled={disabled || isButtonLoading}
            disableRipple
            href={href}
            to={to}
            target={onFoxClick.kind === 'external' ? '_blank' : undefined}
            referrer={onFoxClick.kind === 'external' ? 'noreferrer' : undefined}
            onClick={onClick}
            component={component}
            sx={{ ...styles.button, ...disabledCSS, ...greyCSS, ...sx }}
            {...rest}
        />
    )
}

const styles = {
    button: {
        justifyContent: 'center',
        width: 'fit-content',
        borderRadius: '4px'
    },
    disabled: {
        opacity: 0.7
    }
}
