import { ChevronLeft, Close } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, SxProps, Theme } from '@mui/material'
import { FoxIconButton } from './FoxIconButton'
import React from 'react'

type ModalHeaderCloseKind = 'back' | 'close'

type FoxModalDialogProps = {
    open: boolean
    title: string
    children: React.ReactNode
    actions?: React.ReactNode
    leftButtonKind: ModalHeaderCloseKind
    onClose: () => Promise<void> | void
    iconOverrideOnClose?: () => Promise<void> | void
    closeOnBackdropClick?: boolean
    fullHeight?: boolean
    removeOverlay?: boolean
    contentStyleOverride?: any
} & DialogProps

export const FoxModalDialog = (props: FoxModalDialogProps) => {
    const {
        open,
        children,
        leftButtonKind,
        title,
        onClose,
        fullHeight,
        closeOnBackdropClick,
        actions,
        removeOverlay,
        iconOverrideOnClose,
        contentStyleOverride,
        ...rest
    } = props

    const icon = leftButtonKind === 'back' ? <ChevronLeft /> : <Close />

    const fullHeightCSS = fullHeight ? styles.fullHeight : {}
    const overlaySlot = removeOverlay ? { backdrop: () => null } : undefined

    const handleClose = async (event: React.MouseEvent<Element>) => {
        event.stopPropagation()
        onClose()
    }

    return (
        <Dialog
            open={open}
            scroll={'paper'}
            PaperProps={{
                onClose: handleClose,
                sx: { ...styles.dialog, ...rest.PaperProps?.sx, ...fullHeightCSS }
            }}
            {...rest}
            slots={overlaySlot}
            onClose={closeOnBackdropClick ? handleClose : undefined}
        >
            {title && (
                <DialogTitle sx={{ ...styles.header }} variant='h6'>
                    {title}
                </DialogTitle>
            )}
            <FoxIconButton
                onFoxClick={{
                    kind: 'button',
                    onClick: async (e) => {
                        if (!!iconOverrideOnClose) {
                            await iconOverrideOnClose()
                        } else {
                            handleClose(e)
                        }
                    }
                }}
                sx={{ ...styles.closeIcon }}
            >
                {icon}
            </FoxIconButton>
            <DialogContent sx={{ ...styles.content, ...contentStyleOverride }} dividers>
                {children}
            </DialogContent>
            {actions && <DialogActions sx={{ width: '100%', padding: '16px' }}>{actions}</DialogActions>}
        </Dialog>
    )
}

const styles = {
    dialog: {
        width: '600px',
        maxWidth: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none'
        }
    },
    fullHeight: {
        minHeight: 'calc(100vh - 64px)'
    },
    header: {
        width: '100%',
        textAlign: 'center'
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0px'
    },
    closeIcon: {
        position: 'absolute',
        left: '12px',
        top: '12px'
    }
}
