import {
    GeneratedListingTabKind,
    useAppDispatch,
    useAppSelector,
    selectListingById,
    selectBulkCrosslistingSessionId,
    selectBulkCrosslistingReadyToCrosslistDetailsByListingId,
    selectBulkCrosslistingHasExtraRuleFailureByListingId,
    addBulkCrosslistItems,
    removeBulkCrosslistItem,
    getSelectedMarkets
} from '@foxtail-dev/user-clients'
import { useState, useMemo } from 'react'
import { generateToast } from '../../lib/clients/ToastClient'
import { Logger } from '../../lib/clients/Logger'
import { ListingRowAction } from '../listing/BaseListingRow'
import { EditIcon } from '../icons/EditIcon'
import { Approval, Cancel } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { BaseListingTableRow } from '../listing/BaseListingTableRow'
import { ListingRowComponentProps } from '../../containers/listing/ListingTable'

export const BulkCrosslistReviewRow = (props: ListingRowComponentProps) => {
    const { listingId, tab } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const parsedTab = GeneratedListingTabKind.parse(tab)

    const listing = useAppSelector(selectListingById(listingId))
    const listingDetails = listing?.listingDescription.listingDetails
    const imageIds = listingDetails?.imageIds ?? []
    const bulkCrosslistingSessionId = useAppSelector(selectBulkCrosslistingSessionId)

    const title = listingDetails?.commonDetails?.title ?? 'Title'
    const price = listingDetails?.commonDetails?.price ?? 0

    const existingMarkets = getSelectedMarkets(listingDetails)

    const domainSpecificDetails = useAppSelector(selectBulkCrosslistingReadyToCrosslistDetailsByListingId(listingId))
    const hasExtraRuleFailure = useAppSelector(selectBulkCrosslistingHasExtraRuleFailureByListingId(listingId))

    const [isUpdating, setIsUpdating] = useState(false)

    const handleApprove = async () => {
        setIsUpdating(true)
        if (!domainSpecificDetails) {
            Logger.I().log({
                level: 'error',
                message: 'domainSpecificDetails is undefined',
                payload: {
                    kind: 'BulkCrosslistApproveError',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId
                    }
                }
            })
            setIsUpdating(false)
            generateToast({ kind: 'info', message: 'Failed to approve listing' })
            return
        }
        const item = {
            listingId,
            details: domainSpecificDetails
        }
        try {
            await dispatch(addBulkCrosslistItems([item])).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User approved listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'BulkCrosslistApproveError',
                        entry: {
                            listingId,
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )

            generateToast({ kind: 'error', message: 'Failed to approve listing' })
        }

        setIsUpdating(false)
    }

    const handleReview = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User is reviewing listing',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId,
                    bulkCrosslistingSessionId
                }
            }
        })
        navigate('/app/bulk-crosslist/edit-item/' + listingId)
    }

    const handleReject = async () => {
        setIsUpdating(true)
        try {
            await dispatch(removeBulkCrosslistItem(listingId)).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User rejected listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId
                    }
                }
            })
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'BulkCrosslistRejectError',
                        entry: {
                            listingId,
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'error', message: 'Failed to reject listing' })
        }
        setIsUpdating(false)
    }

    const actions: ListingRowAction[] = useMemo(() => {
        switch (parsedTab) {
            case GeneratedListingTabKind.Enum.readyToCrosslist:
                return [
                    { label: 'Review', onAction: handleReview, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Reject', onAction: handleReject, icon: <Cancel /> }
                ]
            case GeneratedListingTabKind.Enum.pendingReview:
                if (hasExtraRuleFailure) {
                    return [
                        { label: 'Fix issues', onAction: handleReview, icon: <EditIcon height={24} width={24} /> },
                        { label: 'Reject', onAction: handleReject, icon: <Cancel /> }
                    ]
                } else {
                    return [
                        { label: 'Approve', onAction: handleApprove, icon: <Approval /> },
                        { label: 'Review', onAction: handleReview, icon: <EditIcon height={24} width={24} /> },
                        { label: 'Reject', onAction: handleReject, icon: <Cancel /> }
                    ]
                }

            case GeneratedListingTabKind.Enum.rejected:
                if (hasExtraRuleFailure) {
                    return [{ label: 'Fix issues', onAction: handleReview, icon: <EditIcon height={24} width={24} /> }]
                } else {
                    return [
                        { label: 'Approve', onAction: handleApprove, icon: <Approval /> },
                        { label: 'Review', onAction: handleReview, icon: <EditIcon height={24} width={24} /> }
                    ]
                }
            case GeneratedListingTabKind.Enum.failed:
                return [
                    { label: 'Fix issues', onAction: handleReview, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Reject', onAction: handleReject, icon: <Cancel /> }
                ]
        }
    }, [parsedTab, hasExtraRuleFailure])

    return (
        <BaseListingTableRow
            imageId={imageIds[0]}
            title={title}
            disabled={isUpdating}
            isUpdating={isUpdating}
            onClick={handleReview}
            actions={actions}
            listingId={listingId}
            price={price}
            selectedMarkets={existingMarkets}
        />
    )
}
