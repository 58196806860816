import { NotificationDetails } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/NotificationDetails'
import { Logger } from '../lib/clients/Logger'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { useAppSelector, getListingById, useAppDispatch, selectUser } from '@foxtail-dev/user-clients'
import React, { useEffect, useCallback } from 'react'
import { generateToast } from '../lib/clients/ToastClient'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { FoxListingImage } from '../components/common/FoxListingImage'
import { FoxButton } from '../components/common/FoxButton'
import { useNavigate } from 'react-router-dom'
import * as Intercom from '@intercom/messenger-js-sdk'

type NotificationInfoProps = {
    notificationDetails: NotificationDetails
    notificationBody: string
}

// TODO: This (+ the ios implementation) needs a rewrite. Need to create a set of a few components and then map each notification to a component
export const NotificationInfo = ({ notificationDetails, notificationBody }: NotificationInfoProps) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const navigate = useNavigate()

    const handleContactUs = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User clicked on contact us button',
            payload: {
                kind: 'UserAction',
                entry: {
                    screen: 'NotificationInfo'
                }
            }
        })
        Intercom.show()
    }

    const handleCreateListing = async () => {
        navigate('/app/create-listing/new')
    }

    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    const [listing, setListing] = React.useState<ListingSchema | undefined>(undefined)
    const imageIds = listing?.listingDescription.listingDetails.imageIds
    const imageId = imageIds ? imageIds[0] : undefined

    const getListingByIdAsync = async (listingId: string) => {
        try {
            const listing = await dispatch(getListingById(listingId)).unwrap()
            setListing(listing)
        } catch (error) {
            logError('Failed to get listing by id', error)
            generateToast({ kind: 'info', message: 'Error finding listing' })
        }
    }

    useEffect(() => {
        const notificationKind = notificationDetails.kind
        // TODO: Do this in a better way. Listing all of the notification kinds that have listingIds
        if (
            notificationKind === 'listing/created' ||
            notificationKind === 'listing/deleted-item' ||
            notificationKind === 'listing/failed-to-create' ||
            notificationKind === 'listing/failed-to-delist' ||
            notificationKind === 'listing/failed-to-relist' ||
            notificationKind === 'listing/relisted-item' ||
            notificationKind === 'listing/sold-item' ||
            notificationKind === 'listing/sold-out' ||
            notificationKind === 'listing/successful-import' ||
            notificationKind === 'listing/successful-publish'
        ) {
            getListingByIdAsync(notificationDetails.listingId)
        }
    }, [notificationDetails])

    const logError = useCallback(
        (message: string, error?: any) => {
            Logger.I().log(
                {
                    level: 'error',
                    message: message,
                    payload: {
                        kind: 'NotificationInfoError',
                        entry: {
                            notificationDetails,
                            notificationBody
                        }
                    }
                },
                error
            )
        },
        [notificationDetails]
    )

    if (notificationDetails.kind === 'listing/market-successful-link') {
        return (
            <>
                <FoxImage src={CommonImages.markets[notificationDetails.domainName]} style={styles.image} />
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    {Domains[notificationDetails.domainName].displayName} is now linked!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    You can now cross list items to {Domains[notificationDetails.domainName].displayName} using your account{' '}
                    {user?.domainDetails[notificationDetails.domainName as DomainCommonName]?.accountName}
                </FoxTypography>
                <FoxButton text='Create a listing now' onFoxClick={{ kind: 'button', onClick: handleCreateListing }} primary sx={styles.button} />
            </>
        )
    } else if (notificationDetails.kind === 'listing/created') {
        const imageId = imageIds ? imageIds[0] : undefined
        if (!listing || !imageId) {
            return <></>
        }

        return (
            <>
                <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ width: '80px', marginTop: '48px' }} />
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    {listing?.listingDescription.listingDetails.commonDetails?.title} are scheduled for listing!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    Check listing progress by selecting the upcoming listing in the listing tab.
                </FoxTypography>
                <FoxButton
                    text='View listing'
                    onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }}
                    primary
                    sx={styles.button}
                />
            </>
        )
    } else if (notificationDetails.kind === 'listing/successful-publish') {
        if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
            return <></>
        }
        const listingInstanceId = notificationDetails.listingInstanceId
        if (!listingInstanceId) {
            logError('Listing instance id should exist')
            return <></>
        }

        const listingInstance = listing.listingDescription.listingInstances.find((li) => {
            return li._id === listingInstanceId
        })

        if (!listingInstance) {
            logError('Listing instance should exist')
            return <></>
        }

        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FoxImage src={CommonImages.markets[listingInstance.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                    <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    {listing?.listingDescription.listingDetails.commonDetails?.title} has been published to {Domains[listingInstance.domainName].displayName}!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    Foxtail will monitor your listing for sales until inventory depletes; View the summary by clicking the product image in the listing tab.
                </FoxTypography>
                <FoxButton
                    text='View listing'
                    onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }}
                    primary
                    sx={styles.button}
                />
            </>
        )
    } else if (notificationDetails.kind === 'listing/relisted-item') {
        if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
            return <></>
        }
        const listingInstanceId = notificationDetails.listingInstanceId
        if (!listingInstanceId) {
            logError('Listing instance id should exist')
            return <></>
        }

        const listingInstance = listing.listingDescription.listingInstances.find((li) => {
            return li._id === listingInstanceId
        })

        if (!listingInstance) {
            logError('Listing instance should exist')
            return <></>
        }

        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FoxImage src={CommonImages.markets[listingInstance.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                    <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    {listing?.listingDescription.listingDetails.commonDetails?.title} has been relisted to {Domains[listingInstance.domainName].displayName}!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    You can view your listing summary by going to the listing tab and clicking on the product image.
                </FoxTypography>
                <FoxButton
                    text='View listing'
                    onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }}
                    primary
                    sx={styles.button}
                />
            </>
        )
    } else if (notificationDetails.kind === 'listing/successful-import') {
        if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
            return <></>
        }

        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FoxImage src={CommonImages.markets[notificationDetails.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                    <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    {listing?.listingDescription.listingDetails.commonDetails?.title} has been imported from{' '}
                    {Domains[notificationDetails.domainName].displayName}!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    You can now edit or crosslist this item to other marketplaces from the listing tab.
                </FoxTypography>
                <FoxButton
                    text='View listing'
                    onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }}
                    primary
                    sx={styles.button}
                />
            </>
        )
    } else if (notificationDetails.kind === 'listing/failed-to-create') {
        if (!listing || !imageId) {
            return <></>
        }

        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FoxImage src={CommonImages.markets[notificationDetails.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                    <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    Unable to publish {listing?.listingDescription.listingDetails.commonDetails?.title}
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    Our development team has been notified of this and will check into it as soon as possible.
                </FoxTypography>
                <FoxButton grey text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} sx={{ ...styles.button }} />
            </>
        )
    } else if (notificationDetails.kind === 'listing/failed-to-relist') {
        if (!listing || !imageId) {
            return <></>
        }

        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <FoxImage src={CommonImages.markets[notificationDetails.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                    <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    Uh oh, we were unable to relist {listing?.listingDescription.listingDetails.commonDetails?.title} to{' '}
                    {Domains[notificationDetails.domainName].displayName} at this time
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    Our development team has been notified of this and will check into it as soon as possible. For more help please contact us through the
                    support portal.
                </FoxTypography>
                <FoxButton grey text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} sx={{ ...styles.button }} />
            </>
        )
    } else if (notificationDetails.kind === 'importListing/finishedScraping') {
        const scrapedListingCount = notificationDetails.scrapedListingItemCount
        const isPlural = scrapedListingCount !== 1

        const itemStr = isPlural ? 'items have been' : 'item has been'

        return (
            <>
                <FoxImage src={CommonImages.markets[notificationDetails.domainName]} style={{ ...styles.image }} />
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    Finished finding listings from {Domains[notificationDetails.domainName].displayName}!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    {scrapedListingCount} {itemStr} found from {Domains[notificationDetails.domainName].displayName}. You may now choose which items to import
                    into Foxtail.
                </FoxTypography>
            </>
        )
    } else if (notificationDetails.kind === 'bulkCrosslisting/finishedGeneration') {
        const domains = notificationDetails.selectedCrosslistingDomains

        return (
            <>
                <Box sx={{ display: 'flex' }}>
                    {domains.map((domain, index) => {
                        // Don't need margin on the last FoxButton
                        const margin = index === domains.length - 1 ? 0 : 20
                        return (
                            <FoxImage
                                src={CommonImages.markets[domain]}
                                style={{ ...styles.image, ...{ marginRight: margin } }}
                                key={`bulkCrosslistNotificationFoxImage-${domain}`}
                            />
                        )
                    })}
                </Box>
                <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px' }}>
                    AI Listing Generation complete!
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                    Review listings to begin crosslisting.
                </FoxTypography>
            </>
        )
    } else {
        return (
            <>
                <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '64px' }}>
                    {notificationBody}
                </FoxTypography>
                <FoxButton grey text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} sx={styles.button} />
            </>
        )
    }
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
