import { Box } from '@mui/material'
import { AccountHeader } from '../../layouts/AccountHeader'
import { selectHydratedQuestsSorted, useAppSelector } from '@foxtail-dev/user-clients'
import { useMemo } from 'react'
import { ProgressChecklistRow } from '../../components/progress/ProgressChecklistRow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { UserQuestKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { ChecklistBanner } from '../../containers/home/ChecklistBanner'

export const questKindToPage: Record<UserQuestKind, string> = {
    connectYourMarkets: '/app/account/marketplace-connections',
    importExistingListings: '/app/import-listing',
    listAnItem: '/app/create-listing/new'
}

export const ProgressChecklistScreen = () => {
    const quests = useAppSelector(selectHydratedQuestsSorted)

    const areAllQuestsCompleted = useMemo(() => {
        return quests.every((quest) => quest.quest.details.status === 'completed')
    }, [quests])

    return (
        <Box sx={styles.container}>
            <ChecklistBanner imageSize={'small'} />
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    }
}
