import React from 'react'
import { Box, Divider, Skeleton, Fade } from '@mui/material'

export const ListingTableSkeleton = (props: { isLoading: boolean }) => {
    const { isLoading } = props

    return isLoading ? (
        <Fade in={isLoading} timeout={{ enter: 500, exit: 400 }}>
            <Box sx={styles.container}>
                <Divider sx={styles.divider} />
                {Array.from({ length: 20 }, (_, index) => (
                    <React.Fragment key={index}>
                        <Box sx={styles.containerRow}>
                            <Skeleton animation='wave' variant='rounded' sx={styles.imageSkeleton} />
                            <Box sx={styles.containerTitleSkeleton}>
                                <Skeleton animation='wave' variant='rounded' sx={styles.titleSkeleton} />
                            </Box>
                            <Box sx={styles.containerMarketItconSkeleton}>
                                <Skeleton animation='wave' variant='rounded' sx={styles.marketIconSkeleton} />
                                <Skeleton animation='wave' variant='rounded' sx={styles.marketIconSkeleton} />
                                <Skeleton animation='wave' variant='rounded' sx={styles.marketIconSkeleton} />
                            </Box>
                            <Box sx={styles.containerPrice}>
                                <Skeleton animation='wave' variant='rounded' sx={styles.priceSkeleton} />
                            </Box>
                            <Skeleton animation='wave' variant='rounded' sx={styles.moreSkeleton} />
                        </Box>
                        <Divider sx={styles.divider} />
                    </React.Fragment>
                ))}
            </Box>
        </Fade>
    ) : (
        <></>
    )
}
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        height: '1px',
        width: '100%'
    },
    containerRow: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        height: '95px'
    },
    imageSkeleton: {
        width: '63px',
        height: '63px',
        marginLeft: '12px',
        marginRight: '29px'
    },
    titleSkeleton: {
        width: '500px',
        height: '20px'
    },
    containerTitleSkeleton: {
        flexGrow: 1
    },
    containerMarketItconSkeleton: {
        width: '280px',
        display: 'flex',
        flexDirection: 'row'
    },
    marketIconSkeleton: {
        width: '35px',
        height: '35px',
        marginRight: '17.5px'
    },
    containerPrice: {
        width: '140px'
    },
    priceSkeleton: {
        width: '48px',
        height: '20px'
    },
    moreSkeleton: {
        width: '35px',
        height: '32px',
        marginRight: '16px'
    }
}
