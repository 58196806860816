import { TreeNode } from '@foxtail-dev/datacontracts/dist/lib/datastructures/domainTaxonomies/Tree'
import { useAppDispatch, getEtsyConditionalAttributes, Logger } from '@foxtail-dev/user-clients'
import React, { useState } from 'react'
import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { generateToast } from '../../lib/clients/ToastClient'
import { useFormikContext } from 'formik'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { clearEtsyConditionalAttributesInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { assert } from '@foxtail-dev/datacontracts/src/lib/utils/assert'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

export const EtsyCategoryModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const dispatch = useAppDispatch()
    const [isSaving, setIsSaving] = useState(false)
    const taxonomyProvider = useTaxonomyProvider()
    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = async (result: CategorySimilarityMatch) => {
        setIsSaving(true)

        try {
            assert(taxonomyProvider, 'taxonomyProvider')

            const ids = []
            const etsyTaxonomy = taxonomyProvider.etsy
            let node: TreeNode<{ value: string }> = etsyTaxonomy.categories.tree.getNodeById(result.categoryId)
            while (node.parentId) {
                ids.unshift(node.id)
                const maybeNode = etsyTaxonomy.categories.tree.getParentNode(node)
                if (maybeNode) {
                    node = maybeNode
                }
            }

            updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'categoryIds',
                domainName: 'etsy',
                isRequired: true,
                value: ids
            })

            clearEtsyConditionalAttributesInFormik(setFieldValue, setFieldTouched)
            await dispatch(getEtsyConditionalAttributes(result.categoryId)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Etsy category selected',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        domainName: 'etsy',
                        categorySimilarityMatch: result
                    }
                }
            })
            closeModal()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to get etsy conditional attributes',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            domainName: 'etsy',
                            categorySimilarityMatch: result
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Failed to set etsy category', subText: 'Please try again' })
        }
        setIsSaving(false)
    }

    return (
        <CategorySelection
            title='Etsy Category'
            renderItem={(item) => {
                return (
                    <DetailSelection
                        key={item.categoryId}
                        onDetail={async () => {
                            await handleSave(item)
                        }}
                        value={item.categoryNameArray.join(', ')}
                        disabled={isSaving}
                    />
                )
            }}
            domain='etsy'
        />
    )
}
