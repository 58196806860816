import React, { useMemo } from 'react'
import { Box, BoxProps, CircularProgress, Divider, Icon } from '@mui/material'
import { RadioOption, Logger } from '@foxtail-dev/user-clients'
import { getRadioOptionValue, getRadioOptionLabel } from '../formik/RadioGroupField'
import { FoxButton } from './FoxButton'
import { ChevronRight } from '@mui/icons-material'
import { FoxTypography } from './FoxTypography'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxButtonBase } from './FoxButtonBase'
import { FoxCircleLoader } from '../LoadingCircle'

export type DetailSelectionProps = {
    iconElement?: JSX.Element
    placeholder?: string
    children?: JSX.Element | null
    value?: string
    options?: RadioOption[]
    onDetail: () => void | Promise<void>
    disabled?: boolean
} & BoxProps

export const DetailSelection = (props: DetailSelectionProps) => {
    const { children, value, placeholder, options, onDetail, iconElement, disabled, ...rest } = props
    const [isLoading, setIsLoading] = React.useState(false)
    const hasValue = Boolean(value)

    const text = useMemo(() => {
        if (!options) {
            return value
        }

        const option = options.find((opt) => getRadioOptionValue(opt) === value)

        if (!option) {
            return value
        }
        return getRadioOptionLabel(option)
    }, [value, options])

    const isDisabled = disabled || isLoading
    const disabledStyling = isDisabled ? styles.disabled : {}

    const onDetailAsync = async () => {
        setIsLoading(true)
        try {
            await onDetail()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'DetailSelection onDetail failed',
                    payload: {
                        kind: 'UserAction'
                    }
                },
                error
            )
        }
        setIsLoading(false)
    }

    const icon = isLoading ? (
        <CircularProgress color={'primary'} sx={{ position: 'absolute', right: '8px' }} />
    ) : (
        <ChevronRight color={'info'} height={'17px'} width={'10px'} sx={{ position: 'absolute', right: '8px', top: '14px' }} />
    )

    // TODO: The icon should be farther over to the left
    // TODO: Use shared pressable button
    return (
        <>
            <FoxButtonBase onFoxClick={{ kind: 'button', onClick: onDetailAsync, preventDoubleClick: true }} sx={styles.detail}>
                <Box sx={{ ...sharedStyles.flex, ...styles.container }}>
                    {iconElement}

                    <Box sx={[styles.content]}>
                        {children || (
                            <FoxTypography variant='body1' light={!hasValue}>
                                {text || placeholder}
                            </FoxTypography>
                        )}
                    </Box>

                    <Box sx={styles.icon}>{isLoading ? <FoxCircleLoader color={'primary'} size={32} /> : <ChevronRight />}</Box>
                </Box>
            </FoxButtonBase>
            <Divider sx={iconElement && styles.dividerWithIcon} />
        </>
    )
}

const styles = {
    disabled: {
        opacity: 0.6
    },
    detail: {
        width: '100%'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '48px',
        width: '100%',
        paddingLeft: 0
    },
    dividerWithIcon: {
        marginLeft: '32px'
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '90%',
        paddingVertical: '8px'
    },
    icon: {
        marginLeft: '10px',
        justifyContent: 'center',
        alignItems: 'flex-end'
    }
}
