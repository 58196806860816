import React, { useState, useEffect, memo } from 'react'
import { FoxTypography } from '../common/FoxTypography'
import _ from 'lodash'

type FadingTextProps = {
    texts: string[]
}

const fadeOutMs = 1000
const fadeInMs = 3000
const totalIntervalMs = fadeOutMs + fadeInMs

export const FadingText = memo(
    ({ texts }: FadingTextProps) => {
        const [currentIndex, setCurrentIndex] = useState(0)
        const [visible, setVisible] = useState(false)

        useEffect(() => {
            const cycleText = () => {
                setVisible(true)
                setTimeout(() => {
                    setVisible(false)

                    setTimeout(() => {
                        setCurrentIndex((prevIndex) => {
                            const nextIndex = (prevIndex + 1) % texts.length
                            return nextIndex
                        })
                    }, fadeOutMs)
                }, fadeInMs)
            }
            cycleText()
            const interval = setInterval(cycleText, totalIntervalMs)

            return () => clearInterval(interval)
        }, [texts.length])

        return (
            <FoxTypography
                variant='subtitle1'
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    transition: 'opacity 1s ease, transform 1s ease',
                    opacity: visible ? 1 : 0,
                    transform: visible ? 'translateY(0px)' : 'translateY(20px)'
                }}
            >
                {texts[currentIndex]}
            </FoxTypography>
        )
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps.texts, nextProps.texts)
    }
)
