import {
    createListingDraftsFromVideo,
    selectLoggedInAndReadyToListDomains,
    uploadVideo,
    UploadVideoResponse,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { FileRejection, DropEvent } from 'react-dropzone'
import { FFmpegClient } from '../../lib/clients/VideoCompressionClient'
import { FoxTypography } from '../../components/common/FoxTypography'
import { assert } from '@foxtail-dev/datacontracts'
import { Logger } from '../../lib/clients/Logger'
import { AwaitingUserInputContent } from '../../components/videoToListings/AwaitingUserInputContent'
import { FinishedContent } from '../../components/videoToListings/FinishedContent'
import { SelectingVideoContent } from '../../components/videoToListings/SelectingVideoContent'
import { UploadingVideoContent } from '../../components/videoToListings/UploadingVideoContent'
import { CreatingListingsContent } from '../../components/videoToListings/CreatingListingsContent'

export type AiGenerateFromVideoStage = 'selectingVideo' | 'awaitingUserInput' | 'uploadingVideo' | 'creatingListings' | 'finished'

export type StageContentAndActions = {
    actions: JSX.Element | null
    content: JSX.Element
}

type ListingsFromVideoScreenProps = {}
export const ListingsFromVideoScreen = ({}: ListingsFromVideoScreenProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const loggedInMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)

    const [processedVideo, setProcessedVideo] = React.useState<File | null>(null)
    const [processedVideoUrl, setProcessedVideoUrl] = React.useState<string | null>(null)
    const [stage, setStage] = React.useState<AiGenerateFromVideoStage>('selectingVideo')
    const [errorText, setErrorText] = React.useState<string | null>(null)

    useEffect(() => {
        if (processedVideo) {
            setProcessedVideoUrl(URL.createObjectURL(processedVideo))
        }
    }, [processedVideo])

    const onClose = () => {
        navigate('/app/listings')
    }

    const resetError = () => {
        setErrorText(null)
    }

    const onAddVideo = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        resetError()
        if (acceptedFiles.length <= 0) {
            const isFileTooLarge = fileRejections.some((fileRejection) => fileRejection.errors.some((error) => error.code === 'file-too-large'))

            if (isFileTooLarge) {
                setErrorText('File is too large. Please upload a video that is 100MB or less.')
            } else {
                setErrorText('There was a problem selecting your video. Please try again')
            }

            Logger.I().log({
                message: 'Failed to select video',
                level: 'error',
                payload: {
                    kind: 'UserActionError',
                    entry: {
                        acceptedFiles,
                        fileRejections,
                        isFileTooLarge
                    }
                }
            })
            return
        }

        const file = acceptedFiles[0]

        const videoUrl = URL.createObjectURL(file)

        setProcessedVideo(file)
        setProcessedVideoUrl(videoUrl)
        setStage('awaitingUserInput')
    }

    const onCreateListings = async () => {
        resetError()
        assert(processedVideo, 'processed video must be set')

        let videoUploadResult: UploadVideoResponse
        let encodedVideo: File
        try {
            Logger.I().log({
                message: 'Creating listings from video',
                level: 'info',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        videoSize: processedVideo.size,
                        videoName: processedVideo.name
                    }
                }
            })

            setStage('uploadingVideo')

            encodedVideo = await FFmpegClient.compressVideo(processedVideo)

            // Logger.I().log({
            //     message: 'Compressed video',
            //     level: 'info',
            //     payload: {
            //         kind: 'UserAction',
            //         entry: {
            //             videoSize: encodedVideo.size,
            //             videoName: encodedVideo.name
            //         }
            //     }
            // })

            videoUploadResult = await dispatch(uploadVideo({ uri: processedVideo.name, file: processedVideo })).unwrap()

            Logger.I().log({
                message: 'Uploaded video',
                level: 'info',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        videoSize: encodedVideo.size,
                        videoName: encodedVideo.name,
                        videoUploadResult
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    message: 'Failed to upload video',
                    level: 'error',
                    payload: {
                        kind: 'UserActionError',
                        entry: {
                            videoSize: processedVideo.size,
                            videoName: processedVideo.name
                        }
                    }
                },
                error
            )
            setErrorText('Failed to upload video')
            return
        }

        try {
            setStage('creatingListings')

            const response = await dispatch(
                createListingDraftsFromVideo({ videoName: processedVideo.name, videoUrl: videoUploadResult.downloadUrl, destinations: loggedInMarkets })
            ).unwrap()

            setStage('finished')
        } catch (error) {
            Logger.I().log(
                {
                    message: 'Failed to create listings from video',
                    level: 'error',
                    payload: {
                        kind: 'UserActionError',
                        entry: {
                            videoSize: encodedVideo.size,
                            videoName: encodedVideo.name,
                            videoUploadResult
                        }
                    }
                },
                error
            )
        }
    }

    let stageContentAndActions: StageContentAndActions

    switch (stage) {
        case 'selectingVideo':
            stageContentAndActions = SelectingVideoContent({ onAddVideo })
            break
        case 'awaitingUserInput':
            assert(processedVideoUrl, 'processed video url must be set')
            stageContentAndActions = AwaitingUserInputContent({ processedVideoUrl, onAddVideo, onCreateListings })
            break
        case 'uploadingVideo':
            stageContentAndActions = UploadingVideoContent({})
            break
        case 'creatingListings':
            stageContentAndActions = CreatingListingsContent({})
            break
        case 'finished':
            stageContentAndActions = FinishedContent({})
            break
    }

    const actions: JSX.Element | null = stageContentAndActions.actions
    const content: JSX.Element = stageContentAndActions.content

    return (
        <FoxModalDialog title={'Create listings from video'} open={true} leftButtonKind={'close'} onClose={onClose} actions={actions}>
            <Box sx={{ minHeight: '480px', marginTop: '32px' }}>
                {content}
                {errorText && (
                    <FoxTypography variant='body1' sx={{ marginTop: '16px' }} danger>
                        {errorText}
                    </FoxTypography>
                )}
            </Box>
        </FoxModalDialog>
    )
}
