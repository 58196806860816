import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { AccountRequirementsModalProps } from '../../../containers/account/AccountRequirementsModal'

export const MercariAccountRequirementsModal = ({ open, onClose }: AccountRequirementsModalProps) => {
    return (
        <FoxModalDialog open={open} title={'Requirements for linking Mercari'} leftButtonKind={'close'} onClose={() => onClose(false)} closeOnBackdropClick>
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    At least one account address
                    <br />
                    <br />
                    At least one card payment method
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginBottom: '40px' }}>
                    For more info{' '}
                    <Link href='https://help.foxtail.ai/en/articles/8054964-marketplace-setup-and-requirements-etsy-mercari' target='_blank' rel='noopener'>
                        click here.
                    </Link>
                </FoxTypography>
                <FoxButton
                    primary
                    onFoxClick={{ kind: 'button', onClick: async () => onClose(true) }}
                    text={'Link'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
