import { SvgIcon } from '@mui/material'

type FoxtailIconProps = {
    height?: number
    width?: number
}

export const FoxtailIcon = (props: FoxtailIconProps) => {
    const height = props.height ?? 34
    const width = props.width ?? 39
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='39px' height='34px' viewBox='0 0 39 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>foxtail-icon</title>
                <defs>
                    <linearGradient x1='32.8097502%' y1='4.20187702%' x2='79.7850204%' y2='76.2494231%' id='linearGradient-1'>
                        <stop stopColor='#E84856' offset='0%'></stop>
                        <stop stopColor='#E77155' offset='100%'></stop>
                    </linearGradient>
                </defs>
                <g id='Web-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Notifications' transform='translate(-633, -196)' fill='url(#linearGradient-1)' fillRule='nonzero'>
                        <g id='Group-4' transform='translate(590, 162)'>
                            <path
                                d='M81.4443001,34.6010421 C80.8263214,33.9730168 79.8620477,33.8208878 79.0757399,34.2273654 L62.5001802,42.8176324 L45.9246206,34.2273654 C45.1388075,33.8185925 44.1730979,33.9714146 43.5569299,34.602049 C42.9407619,35.2326834 42.822985,36.1887856 43.2681691,36.9461849 L50.4174697,49.0928224 L46.4916792,51.1330108 C45.9086893,51.4268994 45.5090181,51.9834998 45.4220017,52.6226927 C45.3349853,53.2618856 45.5716764,53.9024817 46.0554803,54.3371804 L61.0345518,67.5017645 C61.3952335,67.8181613 61.860122,67.995163 62.3431486,68 L62.4783703,68 L62.6179539,68 C63.1008295,67.9943894 63.5654523,67.8174888 63.9265508,67.5017645 L78.9405182,54.3371804 C79.4270723,53.9074994 79.6694166,53.270644 79.5894216,52.6319306 C79.5094266,51.9932172 79.1172086,51.4334193 78.5392152,51.1330108 L74.6134247,49.0928224 L81.7321914,36.9461849 C82.1766142,36.1884269 82.0592887,35.2327022 81.4443001,34.6010421 Z M50.1819222,40.8719369 L58.2167067,45.0382164 L53.9288711,47.2630956 L50.1819222,40.8719369 Z M50.4174697,53.293463 L52.733686,52.0951207 L56.6594765,58.7826436 L50.4174697,53.293463 Z M62.4827323,61.8837299 L55.9266622,50.6691364 L62.5001802,47.2588004 L69.0736982,50.6691364 L62.4827323,61.8837299 Z M68.3103501,58.7912338 L72.2361405,52.103711 L74.5523569,53.3020532 L68.3103501,58.7912338 Z M71.0540414,47.2716858 L66.7836538,45.0382164 L74.8446102,40.8719369 L71.0540414,47.2716858 Z'
                                id='foxtail-icon'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
