import { Box } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'
import { FoxTypography } from '../common/FoxTypography'
import { useAppSelector, selectListingsLimit, selectSubscriptionTier, selectActiveListingCount } from '@foxtail-dev/user-clients'

export const ListingManagementTitle = () => {
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const listingsLimit = useAppSelector(selectListingsLimit)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    return (
        <Box sx={styles.container}>
            <FoxTypography variant='subtitle1'>Listing management</FoxTypography>
            <FlexGrow />
            <FoxTypography sx={styles.text} variant='body1'>
                Managing
            </FoxTypography>
            <FoxTypography variant='body1'>
                <span>&nbsp;</span>
                {currentActiveListingCount}/{subscriptionTier === 'pro' ? 'Unlimited' : listingsLimit} listings
            </FoxTypography>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '54px',
        marginBottom: '11px',
        height: '20px'
    },
    text: {
        fontWeight: 700
    }
}
