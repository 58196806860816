import { FileRejection, DropEvent } from 'react-dropzone'
import { StageContentAndActions } from '../../screens/listing/ListingsFromVideoScreen'
import { FoxButton } from '../common/FoxButton'
import { FoxVideo } from '../common/FoxVideo'
import { AddVideoButton } from '../upload/AddVideoButton'

type AwaitingUserInputContentProps = {
    processedVideoUrl: string
    onAddVideo: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void
    onCreateListings: () => Promise<void>
}

export const AwaitingUserInputContent = ({ processedVideoUrl, onAddVideo, onCreateListings }: AwaitingUserInputContentProps): StageContentAndActions => {
    const actions = (
        <>
            <AddVideoButton
                onAddVideo={onAddVideo}
                buttonProps={{ onFoxClick: { kind: 'button', onClick: async () => {} }, text: 'Upload a different video', sx: { height: '48px' }, grey: true }}
            />
            <FoxButton
                primary
                onFoxClick={{ kind: 'button', onClick: onCreateListings, preventDoubleClick: true }}
                text={'Create listings'}
                sx={{ width: '141px', height: '48px', display: 'flex', justifyContent: 'center' }}
            />
        </>
    )

    const content = (
        <FoxVideo
            src={processedVideoUrl}
            controls
            style={{ width: '100%', height: 'auto', maxHeight: '280px', marginTop: '32px', backgroundColor: 'black', borderRadius: '10px' }}
        />
    )

    return {
        actions,
        content
    }
}
