export interface IVideoCompressionClient {
    compressVideo: (video: File) => Promise<File>
}

export const FFmpegClient: IVideoCompressionClient = {
    compressVideo: async (video: File) => video
}

// TODO: Can't get web assembly working, so ignoring for now.
// export const compressVideo = async (video: File): Promise<File> => {
//     console.log('encoding video:', video.name)

//     // TODO: Remove logging
//     ffmpeg.on('log', (message) => console.log('ffmpeg log:', message))

//     console.log('started logging')

//     await ffmpeg.load({
//         coreURL: `/ffmpeg/ffmpeg-core.js`,
//         wasmURL: `/ffmpeg/ffmpeg-core.wasm`
//     })

//     console.log('ffmpeg loaded')

//     const inputFileName = video.name
//     const uint8Array = await fetchFile(video)

//     console.log('fetched file with this size', uint8Array.byteLength)
//     await ffmpeg.writeFile(inputFileName, uint8Array)

//     console.log('wrote file')
//     await ffmpeg.exec(['-i', 'input', '-vcodec', 'libx264', '-crf', '28', '-preset', 'fast', outputFileName])

//     console.log('executed command')
//     const data = await ffmpeg.readFile(outputFileName)

//     console.log('read file')

//     return new File([data], outputFileName, { type: 'video/mp4' })
// }
