import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useEffect, useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
    getDomainLinkingStatus,
    selectLoggedInAndReadyToListDomains,
    selectImportListingSessionStatus,
    selectImportListingCancelInfo
} from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'
import { generateImportListingsTitleBasedOnListingStatus } from '../../utils/generateImportListingsTitleBasedOnListingStatus'
import { renderImportListingComponent } from '../../components/importListings/renderImportListingComponent'

export const ImportListingScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const loggedInMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)
    const importListingStatus = useAppSelector(selectImportListingSessionStatus)
    const importListingCancelInfo = useAppSelector(selectImportListingCancelInfo)
    const [isRequestingCancel, setIsRequestingCancel] = useState(false)
    const [isPublishing, setIsPublishing] = useState(false)
    const [isViewingEbayWarning, setIsViewingEbayWarning] = useState(false)

    useEffect(() => {
        if (importListingStatus === 'importing') {
            setIsPublishing(true)
        }
    })

    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    const onClose = () => {
        navigate('/app/listings')
    }

    return (
        <FoxModalDialog
            leftButtonKind='close'
            open={true}
            title={generateImportListingsTitleBasedOnListingStatus({
                importListingCancelInfo,
                importListingStatus,
                loggedInMarkets,
                isRequestingCancel,
                setIsRequestingCancel,
                isPublishing,
                isViewingEbayWarning
            })}
            onClose={onClose}
            contentStyleOverride={{ padding: '0px' }}>
            {renderImportListingComponent({
                loggedInMarkets,
                importListingStatus,
                importListingCancelInfo,
                isRequestingCancel,
                setIsRequestingCancel,
                isPublishing,
                setIsPublishing,
                isViewingEbayWarning,
                setIsViewingEbayWarning
            })}
        </FoxModalDialog>
    )
}
