import { Colors } from '@foxtail-dev/user-clients'

export type FoxStyle = Record<string, any>

export const sharedStyles: FoxStyle = {
    flex: {
        display: 'flex'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    fullSize: {
        width: '100%',
        height: '100%'
    },
    textField: {
        '& .MuiInputBase-root': {
            backgroundColor: Colors.light.unfocusedInput,
            borderRadius: '10px',
            alignItems: 'center',
            '& fieldset': {
                borderColor: 'transparent' // Sets outline to transparent when not focused
            },
            '&:hover fieldset': {
                borderColor: 'gray' // Optional: Changes border color on hover, adjust as needed
            },
            '&.Mui-focused fieldset': {
                borderColor: Colors.light.text, // Changes border color when focused
                borderWidth: '1px' // Optional: Adjusts border width when focused
            }
        }
    }
}
