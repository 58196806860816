import { Box, BoxProps, useTheme } from '@mui/material'
import { FoxTypography } from './FoxTypography'
import { FoxIconButton } from './FoxIconButton'
import { Delete } from '@mui/icons-material'

export type TagProps = {
    onDelete: () => void
    children: string
}

export const Tag = (props: TagProps & BoxProps) => {
    const theme = useTheme()
    const { children, onDelete, sx = {}, ...otherProps } = props
    const tagBg = {
        backgroundColor: theme.palette.action.disabled,
        borderRadius: theme.shape.borderRadius
    }

    // TODO: Can't pass in other sx props for some reason
    return (
        <Box sx={{ ...styles.container, ...tagBg }} sentry-label='Tag' {...otherProps}>
            <Box sx={styles.text}>
                <FoxTypography variant='body2'>{children}</FoxTypography>
            </Box>
            <FoxIconButton
                onFoxClick={{
                    kind: 'button',
                    onClick: async () => {
                        onDelete()
                    }
                }}
                color={'default'}
                sx={styles.icon}
            >
                <Delete />
            </FoxIconButton>
        </Box>
    )
}

const styles = {
    container: {
        height: '38px',
        marginBottom: '16px',
        marginRight: '16px',
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#F5F5F5'
    },
    text: {
        marginLeft: '12px',
        marginRight: '8px'
    },
    icon: {
        marginRight: '4px',
        width: '30px',
        height: '30px'
    }
}
