import { useState, useEffect } from 'react'
import { IDomainSpecificTaxonomyDict } from '@foxtail-dev/datacontracts/dist/lib/datastructures/datastructures.exports'

let taxonomyProvider: IDomainSpecificTaxonomyDict | null = null
let listeners: React.Dispatch<React.SetStateAction<IDomainSpecificTaxonomyDict | null>>[] = []

export const initializeTaxonomyProvider = (taxonomies: IDomainSpecificTaxonomyDict) => {
    taxonomyProvider = taxonomies
    // Notify all listeners to update their state
    listeners.forEach((listener) => listener(taxonomyProvider))
}

export const useTaxonomyProvider = (): IDomainSpecificTaxonomyDict | null => {
    const [provider, setProvider] = useState<IDomainSpecificTaxonomyDict | null>(taxonomyProvider)

    useEffect(() => {
        // Register this component's setProvider as a listener
        listeners.push(setProvider)

        // Optionally, set the current provider in case it was updated before this listener was added
        setProvider(taxonomyProvider)

        // Cleanup: Remove the listener when the component unmounts
        return () => {
            listeners = listeners.filter((listener) => listener !== setProvider)
        }
    }, [])

    return provider
}
