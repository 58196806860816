import {
    Colors,
    selectCurrentPayPeriodEndsAt,
    selectIsSubscriptionCancellingAtEndOfBillingPeriod,
    selectListingsLimit,
    selectSubscription,
    selectSubscriptionTier,
    SubscriptionTierInfoMap,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Divider, Box } from '@mui/material'
import { SubscriptionFeatureRow } from '../../containers/subscription/SubscriptionFeatureRow'
import { FoxTypography } from '../common/FoxTypography'
import moment from 'moment'
import { BetaChip } from '../../containers/account/BetaChip'

const generateSubscriptionTitle = (subscriptionTier: 'none' | 'basic' | 'freetrial' | 'premium' | 'pro') => {
    switch (subscriptionTier) {
        case 'none':
            return 'No Subscription'
        case 'freetrial':
            return 'Free Plan'
        case 'basic':
            return 'Basic'
        case 'premium':
            return 'Premium'
        case 'pro':
            return 'Pro'
    }
}

export const CurrentSubscriptionCard = () => {
    const subscription = useAppSelector(selectSubscription)
    const listingsLimit = useAppSelector(selectListingsLimit)
    const renewalDate = useAppSelector(selectCurrentPayPeriodEndsAt)
    const isSubscriptionEnding = useAppSelector(selectIsSubscriptionCancellingAtEndOfBillingPeriod)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const subscriptionText = isSubscriptionEnding ? 'Ends' : 'Renews'

    return (
        <>
            <FoxTypography sx={styles.textAccountType}>Account type</FoxTypography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FoxTypography sx={styles.textSubscription} variant='h5'>
                    Foxtail{' '}
                    {subscription?.kind === 'active' || subscription?.kind === 'freeplan' ? generateSubscriptionTitle(subscriptionTier) : 'No Subscription'}
                </FoxTypography>
                {subscription?.kind === 'active' && subscription.details.kind === 'custom' && (
                    <Box sx={styles.container}>
                        <Box sx={styles.containerText}>
                            <FoxTypography sx={{ marginLeft: '10px', marginRight: '10px' }} color={Colors.light.background}>
                                Custom subscription
                            </FoxTypography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Divider />
            <Box sx={styles.containerFeatureRows}>
                <SubscriptionFeatureRow feature={subscriptionTier === 'pro' ? `Manage Unlimited listings` : `Manage up to ${listingsLimit} listings`} />
                <SubscriptionFeatureRow feature='AI content generation' />
                <SubscriptionFeatureRow feature='Mobile application' />
                <SubscriptionFeatureRow feature='Customer support' />
            </Box>
            {subscriptionTier !== 'freetrial' && (
                <FoxTypography light variant='body1'>
                    {subscriptionText} on {moment(renewalDate).format('LL')}
                </FoxTypography>
            )}
        </>
    )
}

const styles = {
    textAccountType: {
        fontSize: '16px',
        lineHeight: '26px'
    },
    textSubscription: {
        marginTop: '1px',
        marginBottom: '12px'
    },
    containerFeatureRows: {
        marginTop: '25px'
    },
    container: {
        marginTop: '6px'
    },
    containerText: {
        borderRadius: '10px',
        height: '20px',
        background: 'linear-gradient(84.45deg, #E84855 0%, #E87155 100%)',
        marginLeft: '30px',
        marginRight: '10px'
    }
}
