import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { DepopAccountRequirementsModal } from '../../modals/account/marketConnections/DepopAccountRequirementsModal'
import { MercariAccountRequirementsModal } from '../../modals/account/marketConnections/MercariAccountRequirementsModal'
import { EtsyAccountRequirementsModal } from '../../modals/account/marketConnections/EtsyAccountRequirementsModal'
import { EbayAccountRequirementsModal } from '../../modals/account/marketConnections/EbayAccountRequirementsModal'

export type AccountRequirementsModalProps = {
    open: boolean
    onClose: (result: boolean) => void
}

// Poshmark and Facebook don't have account requirements that we need to show to the user
export const AccountRequirementsModalMap = {
    [DomainCommonName.Enum.etsy]: EtsyAccountRequirementsModal,
    [DomainCommonName.Enum.mercari]: MercariAccountRequirementsModal,
    [DomainCommonName.Enum.ebay]: EbayAccountRequirementsModal,
    [DomainCommonName.Enum.depop]: DepopAccountRequirementsModal
} as const
