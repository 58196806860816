import { Actions, FoxError, assert } from '@foxtail-dev/datacontracts'
import { CreateVideoDocAndUploadUrl, IVideoClient, VideoInfo } from '@foxtail-dev/user-clients'

export type SubmitVideoUploadCompleteResponse = Promise<Actions.UserActionSuccessResult<Actions.ActionDescriptions.UploadVideoActionDescription>>

// Right now this only uploads videos, it does not download them
// For videos, it will most likely be too large to cache in the file system, the way we do with images
export type VideoClientParams = {
    getVideoDownloadUrl: (videoId: string) => Promise<string>
    createVideoDocAndUploadUrl: CreateVideoDocAndUploadUrl
    submitUploadComplete: (videoId: string) => SubmitVideoUploadCompleteResponse
}

export class VideoClient implements IVideoClient {
    readonly #getVideoDownloadUrl
    readonly #createVideoDocAndUploadUrl
    readonly #submitUploadComplete

    constructor(params: VideoClientParams) {
        this.#getVideoDownloadUrl = params.getVideoDownloadUrl
        this.#createVideoDocAndUploadUrl = params.createVideoDocAndUploadUrl
        this.#submitUploadComplete = params.submitUploadComplete
    }

    get = async (videoId: string): Promise<string> => {
        throw new Error('Method not implemented.')
    }

    getDownloadUrl = async (videoId: string): Promise<string> => {
        return await this.#getVideoDownloadUrl(videoId)
    }

    upload = async (videoInfo: VideoInfo): Promise<string> => {
        const { uri, file } = videoInfo
        assert(file, 'Video file must be provided to web video client')

        const uploadUrlResponse = await this.#createVideoDocAndUploadUrl()

        assert(uploadUrlResponse.kind === 'gotUploadUrl', 'Expected gotUploadUrl result kind')

        const { videoId, uploadUrl } = uploadUrlResponse

        const arrayBuffer = await file.arrayBuffer()

        const response = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            body: arrayBuffer
        })

        if (!response.ok) {
            throw new FoxError({
                message: `Upload failed with status: ${response.status}`,
                serializableObjects: { uploadUrl, response }
            })
        }

        await this.#submitUploadComplete(videoId)

        return videoId
    }
}
