import React from 'react'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Divider } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'

export type MarketSpecificFieldsLayoutProps = {
    domain: DomainCommonName
    children: JSX.Element
    divider?: boolean
}

export const MarketSpecificFieldsLayout = ({ domain, children, divider }: MarketSpecificFieldsLayoutProps) => {
    return (
        <>
            <FoxTypography variant='h6' sx={styles.text} bold>
                {Domains[domain].displayName}
            </FoxTypography>
            {children}
            {divider && <Divider sx={styles.divider} />}
        </>
    )
}

const styles = {
    divider: {
        marginTop: '16px'
    },
    text: {
        marginBottom: '28px',
        marginTop: '24px'
    }
}
