import { Box, SvgIcon } from '@mui/material'

type AiGuyIconProps = {
    height?: number
    width?: number
}
export const AiGuyIcon = (props: AiGuyIconProps) => {
    const { height, width } = props
    return (
        <SvgIcon viewBox='0 0 158 120' style={{ height, width }}>
            <title>AI-guy</title>
            <defs>
                <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='linearGradient-1'>
                    <stop stopColor='#E8E2EA' offset='0%'></stop>
                    <stop stopColor='#FAF7FA' offset='100%'></stop>
                </linearGradient>
                <linearGradient x1='27.2600234%' y1='76.9990952%' x2='94.0858754%' y2='72.0543354%' id='linearGradient-2'>
                    <stop stopColor='#E84855' offset='0%'></stop>
                    <stop stopColor='#E87155' offset='100%'></stop>
                </linearGradient>
                <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='linearGradient-3'>
                    <stop stopColor='#E8E2EA' offset='0%'></stop>
                    <stop stopColor='#FAF7FA' offset='100%'></stop>
                </linearGradient>
                <linearGradient x1='16.0718769%' y1='37.723946%' x2='59.9331749%' y2='32.9015809%' id='linearGradient-4'>
                    <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%'></stop>
                    <stop stopColor='#E87155' offset='100%'></stop>
                </linearGradient>
                <linearGradient x1='5.84161556%' y1='48.3049815%' x2='62.9283%' y2='47.6391325%' id='linearGradient-5'>
                    <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%'></stop>
                    <stop stopColor='#E87155' offset='100%'></stop>
                </linearGradient>
            </defs>
            <g id='AI-listing' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='Import-actions' transform='translate(-98, -400)'>
                    <g id='AI-guy' transform='translate(98, 400)'>
                        <g id='Group-11' transform='translate(0, 15)'>
                            <g id='Group-10'>
                                <rect
                                    id='Rectangle'
                                    stroke='#CBC0CD'
                                    strokeWidth='0.69888'
                                    fill='#FFFFFF'
                                    x='0.34944'
                                    y='0.34944'
                                    width='45.30112'
                                    height='11.3599435'
                                    rx='2.09664001'
                                ></rect>
                                <polyline
                                    id='Path-2'
                                    stroke='#CBC0CD'
                                    strokeWidth='1.2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    points='38.2309236 5.59816142 39.741749 7.33187547 41.5214438 5.59816142'
                                ></polyline>
                                <rect id='Rectangle' fill='#F2ECF3' x='3.83435747' y='4.40548559' width='25.4210526' height='3.61764706' rx='1.80882353'></rect>
                            </g>
                            <g id='Group-10' transform='translate(0.6053, 19.2941)'>
                                <rect
                                    id='Rectangle'
                                    stroke='#CBC0CD'
                                    strokeWidth='0.69888'
                                    fill='#FFFFFF'
                                    x='0.34944'
                                    y='0.34944'
                                    width='8.98533053'
                                    height='8.94817882'
                                    rx='4.47408941'
                                ></rect>
                                <rect id='Rectangle' fill='#CBC0CD' x='2.42105263' y='2.41176471' width='4.84210526' height='4.82352941' rx='2.41176471'></rect>
                                <rect id='Rectangle' fill='#F2ECF3' x='15.3343575' y='3.19960323' width='20.5789474' height='3.61764706' rx='1.80882353'></rect>
                                <rect
                                    id='Rectangle'
                                    stroke='#CBC0CD'
                                    strokeWidth='0.69888'
                                    fill='#FFFFFF'
                                    x='0.34944'
                                    y='12.4082635'
                                    width='8.98533053'
                                    height='8.94817882'
                                    rx='4.47408941'
                                ></rect>
                                <rect id='Rectangle' fill='#F2ECF3' x='15.3343575' y='15.2584268' width='25.4210526' height='3.61764706' rx='1.80882353'></rect>
                            </g>
                        </g>
                        <g id='Group-24' transform='translate(23.2619, 0)'>
                            <g id='Group-31' transform='translate(63.5746, 45.1475)'>
                                <path
                                    d='M64.5440106,5.01495736 C64.6649008,6.66350825 64.7269036,8.33762693 64.7269036,10.0327869 C64.7269036,35.8905778 50.3000182,56.852459 32.5035533,56.852459 C14.7070884,56.852459 0.280203046,35.8905778 0.280203046,10.0327869 C0.280203046,8.33762693 0.342205818,6.66350825 0.463096011,5.01495736 Z'
                                    id='Combined-Shape'
                                    fill='#F2ECF3'
                                >
                                    {/*TODO: This is supposed to be fill='url(#linearGradient=1), but it isn't working for some reason*/}
                                </path>
                                <path
                                    d='M46.1144522,18.1614276 C45.6704535,17.7097656 44.9776524,17.6003578 44.4127143,17.8926876 L32.5036828,24.0706193 L20.5946513,17.8926876 C20.0300686,17.5987071 19.3362359,17.7086134 18.8935381,18.1621518 C18.4508402,18.6156902 18.3662212,19.303298 18.6860723,19.848003 L23.8226256,28.5835984 L21.0020655,30.0508572 C20.5832051,30.2622154 20.2960536,30.6625102 20.233535,31.1222037 C20.1710164,31.5818973 20.3410717,32.0426 20.6886699,32.3552257 L31.4506737,41.8229061 C31.7098124,42.0504517 32.0438206,42.1777475 32.3908604,42.1812261 L32.488013,42.1812261 L32.5882996,42.1812261 C32.9352309,42.1771911 33.2690481,42.0499681 33.5284863,41.8229061 L44.3155617,32.3552257 C44.6651359,32.0462086 44.8392529,31.5881961 44.7817789,31.1288474 C44.7243049,30.6694987 44.4425083,30.2669044 44.0272378,30.0508572 L41.2066777,28.5835984 L46.3212933,19.848003 C46.6405974,19.30304 46.5563027,18.6157037 46.1144522,18.1614276 Z M24.1604289,22.7449775 L29.8274568,25.8103328 L26.803196,27.4472957 L24.1604289,22.7449775 Z M24.1604289,31.4247864 L25.7959862,30.5821745 L28.5681173,35.2844927 L24.1604289,31.4247864 Z M32.333601,37.7923958 L27.6236126,29.6192465 L32.3461359,27.1338078 L37.0686591,29.6192465 L32.333601,37.7923958 Z M36.4389893,35.2844927 L39.2111204,30.5821745 L40.8466777,31.4247864 L36.4389893,35.2844927 Z M38.4966602,27.4472957 L35.4944847,25.8062182 L41.1615126,22.7449775 L38.4966602,27.4472957 Z'
                                    id='Shape'
                                    fill='url(#linearGradient-2)'
                                ></path>
                                <ellipse id='Oval' fill='#CBC0CD' cx='32.5035533' cy='5.01639344' rx='32.5035533' ry='5.01639344'></ellipse>
                            </g>
                            <g id='Group-31' transform='translate(58.8112, 0)'>
                                <ellipse id='Oval' fill='url(#linearGradient-3)' cx='37.5472081' cy='24.5245902' rx='37.5472081' ry='24.5245902'></ellipse>
                                <ellipse id='Oval' fill='#301A36' cx='32.8347024' cy='24.5245902' rx='22.1869866' ry='14.4918033'></ellipse>
                                <ellipse id='Oval' fill='#D3C5FF' cx='37.2670051' cy='24.8032787' rx='3.0822335' ry='3.06557377'></ellipse>
                                <ellipse id='Oval' fill='#D3C5FF' cx='21.0152284' cy='24.8032787' rx='3.0822335' ry='3.06557377'></ellipse>
                            </g>
                            <g id='Group-3' opacity='0.449707031' transform='translate(0, 0.231)'>
                                <polygon id='Path-6' fill='url(#linearGradient-4)' opacity='0.258045015' points='0 0 0 81 71 24.2941207'></polygon>
                                <polygon
                                    id='Path-6'
                                    fill='url(#linearGradient-5)'
                                    opacity='0.482352121'
                                    points='6.63333333 17.7059552 6.63333333 41.1157913 69.6333333 24.7272069'
                                ></polygon>
                            </g>
                        </g>
                        <ellipse id='Oval' fillOpacity='0.0267427885' fill='#000000' cx='118.667513' cy='116' rx='27' ry='4'></ellipse>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}
