import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxTypography } from '../common/FoxTypography'
import { ArrowRightIcon } from '../icons/ArrowRightIcon'
import { FoxImage } from '../common/FoxImage'
import { FoxButtonBase } from '../common/FoxButtonBase'

export const LinkMarketsBanner = () => {
    return (
        <FoxButtonBase
            onFoxClick={{ kind: 'internal', to: '/app/account/marketplace-connections' }}
            sx={{
                height: '214px',
                backgroundColor: Colors.light.unfocusedInput,
                borderColor: Colors.light.divider,
                borderRadius: '10px',
                borderWidth: '1px',
                borderStyle: 'solid',
                marginBottom: '33px',
                flexDirection: 'row',
                display: 'flex'
            }}
        >
            <Box sx={{ flex: 1 }}>
                <FoxTypography variant='h6' sx={{ marginTop: '37px', marginLeft: '22.23px' }}>
                    Link marketplaces
                </FoxTypography>
                <FoxTypography variant='body2' light sx={{ lineHeight: '26px', marginLeft: '22.23px', marginBottom: '44px' }}>
                    Link your first marketplace to get started
                </FoxTypography>

                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '22.23px', alignItems: 'center' }}>
                    <FoxTypography variant='subtitle1' sx={{ color: Colors.light.primary, marginRight: '9.5px', lineHeight: '24px' }}>
                        Link marketplaces
                    </FoxTypography>
                    <ArrowRightIcon height={16} width={16} color={Colors.light.primary} />
                </Box>
            </Box>
            <Box sx={{ height: '214px', flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <FoxImage src={CommonImages.general.linkMarketsBanner} style={{ height: '214px' }} />
            </Box>
        </FoxButtonBase>
    )
}
