import { CancellingBulkCrosslist } from './CancellingBulkCrosslist'
import { BulkCrosslistDraft } from './BulkCrosslistDraft'
import { BulkCrosslistGenerating } from './BulkCrosslistGenerating'
import { NewBulkCrosslist } from './NewBulkCrosslist'
import { BulkCrosslistEditListingDefaults } from './BulkCrosslistEditListingDefaults'

export type RenderBulkCrosslistingComponentProps = {
    isBulkCrosslistCancelling?: boolean
    bulkCrosslistStatus?: 'draft' | 'generating' | 'reviewing' | 'listing' | 'completed'
    areListingDefaultsSet: boolean
    isRequestingCancel: boolean
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const renderBulkCrosslistingListingComponent = (props: RenderBulkCrosslistingComponentProps) => {
    const { bulkCrosslistStatus, isBulkCrosslistCancelling, areListingDefaultsSet, isRequestingCancel, setIsRequestingCancel } = props

    if (isBulkCrosslistCancelling || isRequestingCancel) {
        return <CancellingBulkCrosslist />
    } else if (bulkCrosslistStatus === 'draft') {
        if (!areListingDefaultsSet) {
            return <BulkCrosslistEditListingDefaults />
        } else {
            return <BulkCrosslistDraft setRequestCancel={setIsRequestingCancel} />
        }
    } else if (bulkCrosslistStatus === 'generating') {
        return <BulkCrosslistGenerating setIsRequestingCancel={setIsRequestingCancel} />
    } else {
        return <NewBulkCrosslist />
    }
}
