import { Box, ButtonBaseProps, CircularProgress, SxProps } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButtonBase } from '../common/FoxButtonBase'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { CheckBoxCheckedIcon } from '../icons/CheckBoxChecked'
import { CheckBoxUncheckedIcon } from '../icons/CheckBoxUnchecked'
import { ListingActionsButton } from './ListingActionButton'
import { Colors } from '../../theme/Colors'
import { memo } from 'react'

export type ListingRowAction = {
    label: string
    icon: JSX.Element
    onAction: (e: React.MouseEvent) => void
}

type BaseListingRowProps = {
    imageId?: string
    title: string
    description?: string
    onClick: () => void
    isChecked?: boolean
    onChecked?: (checked: boolean) => void
    actions?: ListingRowAction[]
    isUpdating?: boolean
    sx?: any // TODO: Fix typing, couldn't get FoxButtonBase to accept them
}

export const BaseListingRow = memo(({ imageId, title, description, onClick, isChecked, onChecked, actions, isUpdating, sx }: BaseListingRowProps) => {
    return (
        <FoxButtonBase sx={{ ...styles.container, ...sx }} onFoxClick={{ kind: 'button', onClick }}>
            {onChecked && <Box>{isChecked ? <CheckBoxCheckedIcon /> : <CheckBoxUncheckedIcon />}</Box>}
            <Box sx={styles.containerImage}>
                {imageId ? (
                    <FoxListingImage imageId={imageId} style={{ ...styles.image }} />
                ) : (
                    <FoxImage src={CommonImages.general.foxtailLogo} alt='foxtail logo' style={{ ...styles.image }} />
                )}
            </Box>
            <Box sx={styles.containerText}>
                <FoxTypography variant='body2' bold>
                    {title}
                </FoxTypography>
                <Box sx={styles.descriptionTitle}>
                    <FoxTypography light variant='body1'>
                        {description}
                    </FoxTypography>
                    {isUpdating && <CircularProgress size={20} sx={{ marginLeft: '10px' }} />}
                </Box>
            </Box>

            {actions && <ListingActionsButton actions={actions} />}
        </FoxButtonBase>
    )
})

const styles = {
    container: {
        height: '96px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.light.background
    },
    containerImage: {
        height: '61px',
        width: '61px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',
        marginLeft: '30px'
    },
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    },
    containerText: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: '20px'
    },
    descriptionTitle: {
        display: 'flex'
    }
}
