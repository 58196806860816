import { Typography, TypographyProps, useTheme } from '@mui/material'

type FoxTypographyProps = {
    light?: boolean
    danger?: boolean
    bold?: boolean
} & TypographyProps

export const FoxTypography = ({ light, danger, bold, sx, ...rest }: FoxTypographyProps) => {
    const theme = useTheme()
    const textColor = light ? theme.palette.text.secondary : danger ? theme.palette.error.main : theme.palette.text.primary
    const fontWeight = bold ? { fontWeight: 500 } : {}

    return <Typography color={textColor} {...rest} sx={{ ...fontWeight, ...sx }} />
}
