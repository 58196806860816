import { SvgIcon } from '@mui/material'

type LaunchIconProps = {
    color?: string
    height?: number
    width?: number
}

export const LaunchIcon = (props: LaunchIconProps) => {
    const height = props.height ?? 12
    const width = props.width ?? 12
    return (
        <SvgIcon viewBox='0 0 12 12' style={{ height, width }}>
            <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>Shape</title>
                <g id='Web-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Listing' transform='translate(-996, -705)' fill={props.color ?? '#301A36'} fillRule='nonzero'>
                        <g id='Group-3' transform='translate(306, 674)'>
                            <g id='Group-2' transform='translate(555, 19)'>
                                <g id='open_in_new_24dp_FILL0_wght600_GRAD0_opsz20' transform='translate(135, 12)'>
                                    <path
                                        d='M1.6553122,12 C1.19362648,12 0.802319689,11.8395361 0.481391814,11.5186082 C0.160463938,11.1976803 0,10.8063735 0,10.3446878 L0,1.6553122 C0,1.19362648 0.160463938,0.802319689 0.481391814,0.481391814 C0.802319689,0.160463938 1.19362648,0 1.6553122,0 L6,0 L6,1.6553122 L1.6553122,1.6553122 L1.6553122,10.3446878 L10.3446878,10.3446878 L10.3446878,6 L12,6 L12,10.3446878 C12,10.8063735 11.8395361,11.1976803 11.5186082,11.5186082 C11.1976803,11.8395361 10.8063735,12 10.3446878,12 L1.6553122,12 Z M4.72507179,8.43229548 L3.56770452,7.27492821 L9.18715162,1.6553122 L7.62153032,1.6553122 L7.62153032,0 L12,0 L12,4.37846968 L10.3446878,4.37846968 L10.3446878,2.81284838 L4.72507179,8.43229548 Z'
                                        id='Shape'
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
