import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Colors } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { CommonImages } from '../../../theme/CommonImages'
import { FlexGrow } from '../../common/FlexGrow'
import { FoxImage } from '../../common/FoxImage'
import { FoxTypography } from '../../common/FoxTypography'
import { LaunchIcon } from '../../icons/LaunchIcon'
import { FoxButtonBase } from '../../common/FoxButtonBase'

type LiveListingTableProps = {
    activeMarkets: DomainCommonName[]
    onViewLiveListing: (domainName: DomainCommonName) => void
}
export const LiveListingTable = (props: LiveListingTableProps) => {
    const { activeMarkets, onViewLiveListing } = props
    return (
        activeMarkets.length > 0 && (
            <Box sx={styles.container}>
                <Box sx={styles.containerTitle}>
                    <FoxTypography variant='subtitle1'>Listed on</FoxTypography>
                </Box>
                <Divider />

                <Box sx={styles.containerRows}>
                    {activeMarkets.map((domainName, i) => {
                        return (
                            <Box key={'liveListingTable' + domainName}>
                                {i !== 0 && <Divider />}
                                <Box sx={styles.containerRow}>
                                    <Box sx={styles.containerImage}>
                                        <FoxImage src={CommonImages.markets[DomainCommonName.parse(domainName)]} alt={domainName} style={styles.image} />
                                    </Box>
                                    <Box sx={{ marginTop: '26px' }}>
                                        <FoxTypography variant='body1'>{Domains[domainName].displayName}</FoxTypography>
                                    </Box>
                                    <FlexGrow />
                                    <FoxButtonBase
                                        onFoxClick={{ kind: 'button', onClick: async () => onViewLiveListing(domainName) }}
                                        sx={styles.buttonViewLiveListing}
                                    >
                                        <FoxTypography variant='subtitle2' sx={styles.textViewLiveListing}>
                                            View live listing
                                        </FoxTypography>
                                        <LaunchIcon height={12} />
                                    </FoxButtonBase>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        )
    )
}

const styles = {
    container: {
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        borderRadius: '10px',
        marginRight: '19px',
        marginBottom: '27px'
    },
    containerTitle: {
        paddingTop: '23px',
        marginLeft: '22px',
        height: '64px'
    },
    containerRows: {
        marginTop: '5px',
        marginBottom: '9px'
    },
    containerRow: {
        height: '74px',
        display: 'flex',
        flexDirection: 'row'
    },
    containerImage: {
        marginTop: '19px',
        marginLeft: '30px',
        marginRight: '20px',
        height: '34px',
        width: '33px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.light.divider,
        borderRadius: '7.62px'
    },
    buttonViewLiveListing: {
        height: '36px',
        width: '168px',
        backgroundColor: Colors.light.divider,
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '23px',
        marginTop: '19px',
        cursor: 'pointer'
    },
    textViewLiveListing: {
        marginRight: '12px'
    },
    image: {
        height: '100%',
        width: '100%',
        borderRadius: '7.62px'
    }
}
