import { SvgIcon } from '@mui/material'

type NoInternetConnectionIconProps = {
    height?: number
    width?: number
}

export const NoInternetConnectionIcon = (props: NoInternetConnectionIconProps) => {
    const height = props.height ?? 287
    const width = props.width ?? 252
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='252px' height='287px' viewBox='0 0 252 287' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>No internet</title>
                <defs>
                    <linearGradient x1='27.2600234%' y1='85.4463871%' x2='94.0858754%' y2='78.9545447%' id='linearGradient-1'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='27.2600234%' y1='85.4463871%' x2='94.0858754%' y2='78.9545447%' id='linearGradient-2'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='27.2600234%' y1='85.4463871%' x2='94.0858754%' y2='78.9545447%' id='linearGradient-3'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='27.2600234%' y1='85.4463871%' x2='94.0858754%' y2='78.9545447%' id='linearGradient-4'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='27.2600234%' y1='85.4463871%' x2='94.0858754%' y2='78.9545447%' id='linearGradient-5'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='27.2600234%' y1='78.0129695%' x2='94.0858754%' y2='72.8825233%' id='linearGradient-6'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                </defs>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='No-internet' transform='translate(-69.000000, -176.000000)'>
                        <g transform='translate(69.986728, 176.987473)'>
                            <ellipse id='Oval' fill='url(#linearGradient-1)' opacity='0.085030692' cx='125.013272' cy='125' rx='125.013272' ry='125'></ellipse>
                            <ellipse id='Oval' fill='url(#linearGradient-2)' opacity='0.085030692' cx='125.013272' cy='125' rx='88.0093433' ry='88'></ellipse>
                            <ellipse id='Oval' fill='url(#linearGradient-3)' opacity='0.085030692' cx='125.013272' cy='125' rx='55.0058396' ry='55'></ellipse>
                            <g id='Group-12' transform='translate(77.263272, 87.503981)'>
                                <line
                                    x1='47.75'
                                    y1='39.7525874'
                                    x2='47.75'
                                    y2='194.508546'
                                    id='Path-11'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                ></line>
                                <line
                                    x1='47.75'
                                    y1='39.7525874'
                                    x2='95.5'
                                    y2='172.73166'
                                    id='Path-11'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                ></line>
                                <line
                                    x1='0.25'
                                    y1='39.7525874'
                                    x2='48'
                                    y2='172.73166'
                                    id='Path-11'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                    transform='translate(24.000000, 106.245528) scale(-1, 1) translate(-24.000000, -106.245528) '
                                ></line>
                                <polyline
                                    id='Path-12'
                                    strokeOpacity='0.333369755'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                    points='28.75 95.1731201 47.75 106.171952 66.75 95.1731201'
                                ></polyline>
                                <polyline
                                    id='Path-12'
                                    strokeOpacity='0.333369755'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                    points='17.25 130.169404 47.75 144.667865 78.25 130.169404'
                                ></polyline>
                                <polyline
                                    id='Path-12'
                                    strokeOpacity='0.333369755'
                                    stroke='#301A36'
                                    strokeWidth='7'
                                    strokeLinecap='round'
                                    points='4.75 162.166007 47.75 180.164096 90.75 162.166007'
                                ></polyline>
                                <ellipse
                                    id='Oval'
                                    stroke='url(#linearGradient-4)'
                                    strokeWidth='5.5'
                                    fillOpacity='0'
                                    fill='url(#linearGradient-4)'
                                    cx='47.75'
                                    cy='37.4960189'
                                    rx='34.75'
                                    ry='34.7460189'
                                ></ellipse>
                                <ellipse id='Oval' fill='url(#linearGradient-5)' cx='47.75' cy='37.4960189' rx='9.5' ry='9.49899145'></ellipse>
                                <path
                                    d='M69.1088078,19.2156544 C68.3957554,18.4768011 67.2831319,18.2978259 66.3758537,18.7760349 L47.250208,28.8822313 L28.1245622,18.7760349 C27.2178548,18.2951255 26.1035746,18.4749163 25.3926114,19.2168391 C24.6816483,19.9587619 24.545752,21.083588 25.0594258,21.974646 L33.3086188,36.2648078 L28.7788606,38.6650294 C28.1061799,39.0107807 27.6450209,39.6656047 27.5446174,40.4175963 C27.4442139,41.1695879 27.7173189,41.9232305 28.2755542,42.4346407 L45.5590982,57.9223867 C45.9752694,58.2946182 46.5116792,58.5028555 47.0690176,58.5085461 L47.2250426,58.5085461 L47.3861007,58.5085461 C47.9432648,58.5019454 48.479368,58.293827 48.8960201,57.9223867 L66.2198287,42.4346407 C66.7812373,41.9291337 67.0608654,41.179892 66.9685634,40.4284644 C66.8762615,39.6770369 66.4237022,39.0184512 65.7567868,38.6650294 L61.2270286,36.2648078 L69.4409901,21.974646 C69.9537856,21.083166 69.8184101,19.9587839 69.1088078,19.2156544 Z'
                                    id='Shape'
                                    fill='url(#linearGradient-6)'
                                    fillRule='nonzero'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
