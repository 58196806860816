import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useEffect, useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
    selectBulkCrosslistingIsCancelling,
    selectBulkCrosslistingSessionStatus,
    selectAreListingDefaultsSetForDomains
} from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'
import { generateBulkCrosslistTitleBasedOnListingStatus } from '../../utils/generateBulkCrosslistingBasedOnSessionStatus'
import { renderBulkCrosslistingListingComponent } from '../../components/bulkcrosslist/renderBulkCrosslistingComponent'

export const BulkCrosslistScreen = () => {
    const navigate = useNavigate()
    const isBulkCrosslistCancelling = useAppSelector(selectBulkCrosslistingIsCancelling)
    const bulkCrosslistStatus = useAppSelector(selectBulkCrosslistingSessionStatus)
    const areListingDefaultsSet = useAppSelector(selectAreListingDefaultsSetForDomains)

    const [isRequestingCancel, setIsRequestingCancel] = useState(false)

    useEffect(() => {
        if (!isBulkCrosslistCancelling && (bulkCrosslistStatus === 'reviewing' || bulkCrosslistStatus === 'listing')) {
            navigate('/app/bulk-crosslist/review?showSummary=true', { replace: true })
        }
    }, [bulkCrosslistStatus, isBulkCrosslistCancelling])

    const onClose = () => {
        navigate('/app/listings')
    }

    // TODO: Have a stage variable that we can set manually, instead of making it purely based on state. Bulk crosslist allows for the user to go back and edit things and they can't right now.

    return (
        <FoxModalDialog
            leftButtonKind='close'
            open={true}
            title={generateBulkCrosslistTitleBasedOnListingStatus({
                isBulkCrosslistCancelling,
                bulkCrosslistStatus
            })}
            onClose={onClose}
            contentStyleOverride={{ padding: '0px' }}>
            {renderBulkCrosslistingListingComponent({
                isBulkCrosslistCancelling,
                bulkCrosslistStatus,
                areListingDefaultsSet,
                isRequestingCancel,
                setIsRequestingCancel
            })}
        </FoxModalDialog>
    )
}
