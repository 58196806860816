import ReactGA from 'react-ga4'
import { Logger } from '../../lib/clients/Logger'
import { PostHog } from 'posthog-js/react'

export const setPosthogUserInformation = (posthog: PostHog, userId?: string) => {
    if (!userId) {
        Logger.I().log({
            level: 'error',
            payload: {
                kind: 'System'
            },
            message: 'UserId is not defined when trying to set Posthog user information'
        })

        return
    }

    posthog.identify(userId)

    posthog.onSessionId((sessionId: string) => {
        const replayUrl = createReplayUrl(sessionId)
        Logger.I().setPosthogSessionReplayUrl(replayUrl)
    })
}

// Note hardcoded project id
const createReplayUrl = (sessionId: string) => {
    return `https://us.posthog.com/project/85434/replay/${sessionId}`
}

export const resetPosthogUserInformation = () => {
    ReactGA.set({
        userId: null,
        email: null
    })
}
