// TODO move these to the user-clients package
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'

export const generateStripeReturnUrl = (params: { isMobile: boolean }) => {
    const domain = FoxtailWebConfig.config.env === 'prod' ? 'web' : 'web-beta'

    if (params.isMobile) {
        return `https://${domain}.foxtail.ai/subscription-mobile`
    } else {
        return `https://${domain}.foxtail.ai/app/account/subscription`
    }
}

export const generateStripeCancelUrl = (params: { isMobile: boolean }) => {
    const domain = FoxtailWebConfig.config.env === 'prod' ? 'web' : 'web-beta'

    if (params.isMobile) {
        return `https://${domain}.foxtail.ai/subscription-mobile`
    } else {
        return `https://${domain}.foxtail.ai/app/account/subscription`
    }
}

export const generateStripeSuccessUrl = (params: { isMobile: boolean }) => {
    const domain = FoxtailWebConfig.config.env === 'prod' ? 'web' : 'web-beta'

    if (params.isMobile) {
        return `https://${domain}.foxtail.ai/subscription-mobile`
    } else {
        return `https://${domain}.foxtail.ai/app/subscription-success`
    }
}
