import { SvgIcon } from '@mui/material'

type BoltUnfilledIconProps = {
    color?: string
    height?: number
    width?: number
}

export const BoltUnfilledIcon = (props: BoltUnfilledIconProps) => {
    const height = props.height ?? 21
    const width = props.width ?? 19
    const color = props.color ?? '#E87155'

    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='16px' height='22px' viewBox='0 0 16 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>nav-boost</title>
                <g id='Upgrade-components' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='nav-boost' fill={color} fillRule='nonzero'>
                        <path
                            d='M6.14366136,17.9257191 L12.6083506,10.707796 L8.18463486,10.707796 L9.01937204,4.57241809 L3.1728238,12.3729567 L7.00200955,12.3729567 L6.14366136,17.9257191 Z M4.47381932,14.4042531 L1.18135726,14.4042531 C0.713899536,14.4042531 0.369416919,14.2140883 0.147909404,13.8337587 C-0.0735981107,13.4534453 -0.0463101918,13.0825729 0.229773161,12.7211415 L9.3889802,0.508416414 C9.56382599,0.283580488 9.78528973,0.131673732 10.0533714,0.0526961451 C10.3214706,-0.0262814415 10.5900076,-0.0164841399 10.8589822,0.0820880499 C11.1279569,0.174555144 11.3390202,0.337587101 11.4921723,0.57118392 C11.6453068,0.804780739 11.7027191,1.0525796 11.6644092,1.31458049 L10.6667587,8.67649963 L14.8282057,8.67649963 C15.3247285,8.67649963 15.6748315,8.88068835 15.8785148,9.28906579 C16.0821981,9.69742703 16.0297583,10.0777486 15.7211956,10.4300304 L5.70668697,21.5724529 C5.52086298,21.7800585 5.29309597,21.9126865 5.02338593,21.9703367 C4.75369339,22.027825 4.48942868,22.0017528 4.23059178,21.8921202 C3.97835581,21.7884794 3.78372475,21.625083 3.6466986,21.4019313 C3.50967245,21.1787633 3.46032308,20.9341709 3.49865048,20.6681539 L4.47381932,14.4042531 Z'
                            id='Shape'></path>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
