import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { FoxTypography } from '../../components/common/FoxTypography'
import { BoltIcon } from '../../components/icons/BoltIcon'

export const UpgradeButtonHeader = () => {
    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/account/subscription' }} sx={styles.containerUpgradeButton}>
            <Box sx={styles.boltIcon}>
                <BoltIcon height={14} width={11} color={Colors.light.secondary} />
            </Box>
            <FoxTypography sx={styles.textUpgradeButton}>Upgrade</FoxTypography>
        </FoxButtonBase>
    )
}

const styles = {
    containerUpgradeButton: {
        height: '32px',
        width: '108px',
        borderRadius: '16px',
        backgroundColor: '#FDF7F6',
        marginRight: '33px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'normal',
        cursor: 'pointer',
        overflow: 'hidden'
    },
    boltIcon: {
        marginLeft: '15px',
        marginTop: '8.5px'
    },
    textUpgradeButton: {
        backgroundColor: 'blue',
        fontWeight: 700,
        background: Colors.light.foxGradient,
        marginLeft: '5px',
        marginTop: '5.5px',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        lineHight: '20px',
        fontSize: '14px',
        cursor: 'pointer'
    }
}
