import { SvgIcon } from '@mui/material'

type CopyAsNewIconProps = {
    color?: string
    height?: number
    width?: number
}

export const CopyAsNewIcon = (props: CopyAsNewIconProps) => {
    const height = props.height ?? 32
    const width = props.width ?? 32
    return (
        <SvgIcon viewBox='0 0 32 32' style={{ height, width }}>
            <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>action-copy-as-new</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Actions---active-listing' transform='translate(-16.000000, -688.000000)'>
                        <g id='action-copy-as-new' transform='translate(16.000000, 688.000000)'>
                            <rect id='Rectangle' fill={props.color ?? '#301A36'} x='0' y='0' width='32' height='32' rx='10'></rect>
                            <g id='content_copy_FILL0_wght700_GRAD0_opsz48' transform='translate(7.000000, 5.000000)' fill='#FFFFFF' fillRule='nonzero'>
                                <path
                                    d='M6.17624021,18.1512415 C5.53133159,18.1512415 4.98150566,17.9236268 4.5267624,17.4683973 C4.07201915,17.0131678 3.84464752,16.462754 3.84464752,15.8171558 L3.84464752,2.35891648 C3.84464752,1.71331828 4.07201915,1.15876599 4.5267624,0.695259594 C4.98150566,0.231753198 5.53133159,0 6.17624021,0 L16.6436031,0 C17.2885117,0 17.8424717,0.231753198 18.305483,0.695259594 C18.7684943,1.15876599 19,1.71331828 19,2.35891648 L19,15.8171558 C19,16.462754 18.7684943,17.0131678 18.305483,17.4683973 C17.8424717,17.9236268 17.2885117,18.1512415 16.6436031,18.1512415 L6.17624021,18.1512415 Z M6.17624021,15.8171558 L16.6436031,15.8171558 L16.6436031,2.35891648 L6.17624021,2.35891648 L6.17624021,15.8171558 Z M2.35639687,22 C1.71148825,22 1.15752829,21.7682468 0.694516971,21.3047404 C0.231505657,20.841234 0,20.2866817 0,19.6410835 L0,6.05869074 C0,5.72761475 0.111618799,5.4503386 0.334856397,5.2268623 C0.558093995,5.003386 0.843342037,4.89164786 1.19060052,4.89164786 C1.52132289,4.89164786 1.79830287,5.003386 2.02154047,5.2268623 C2.24477807,5.4503386 2.35639687,5.72761475 2.35639687,6.05869074 L2.35639687,19.6410835 L12.7245431,19.6410835 C13.0552654,19.6410835 13.3322454,19.7528217 13.555483,19.976298 C13.7787206,20.1997743 13.8903394,20.4853273 13.8903394,20.8329571 C13.8903394,21.1640331 13.7787206,21.4413093 13.555483,21.6647856 C13.3322454,21.8882619 13.0552654,22 12.7245431,22 L2.35639687,22 Z M6.17624021,2.35891648 L6.17624021,15.8171558 L6.17624021,2.35891648 Z'
                                    id='Shape'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
