import { normalizePrice } from '@foxtail-dev/user-clients'
import { TableRow, TableCell, Box, CircularProgress } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ListingActionsButton } from './ListingActionButton'
import { ListingRowAction } from './BaseListingRow'
import { Colors } from '../../theme/Colors'

type BaseListingTableRowProps = {
    listingId: string
    title: string
    price: number
    disabled?: boolean
    onClick: () => void
    imageId?: string
    isUpdating?: boolean
    selectedMarkets: DomainCommonName[]
    actions: ListingRowAction[]
}

export const BaseListingTableRow = ({
    listingId,
    disabled,
    onClick,
    imageId,
    actions,
    price,
    selectedMarkets,
    title,
    isUpdating
}: BaseListingTableRowProps) => {
    const disabledCSS = disabled ? { pointerEvents: 'none', opacity: 0.7 } : {}

    return (
        <TableRow onClick={onClick} sx={{ ...styles.listingRow, height: '95px', ...disabledCSS }}>
            <TableCell align='left'>
                <Box sx={{ ...sharedStyles.flex, ...{ alignItems: 'center' } }}>
                    <Box sx={{ ...styles.listingImageContainer }}>{imageId && <FoxListingImage imageId={imageId} style={{ ...styles.listingImage }} />}</Box>
                    <FoxTypography variant='body2' sx={[styles.listingTitle]}>
                        {title ?? 'No title set yet'}
                    </FoxTypography>
                    {isUpdating && <CircularProgress size='16px' color={'info'} sx={{ marginLeft: '8px' }} />}
                </Box>
            </TableCell>
            <TableCell align='left' width={280}>
                {selectedMarkets
                    .sort((a, b) => {
                        return a.localeCompare(b)
                    })
                    .map((market) => (
                        <FoxImage key={market} src={CommonImages.markets[market]} alt={`listed on ${market}`} style={{ ...styles.marketImage }} />
                    ))}
            </TableCell>
            <TableCell align='left' width={140}>
                ${normalizePrice(price)}
            </TableCell>

            <TableCell align='left' sx={{ display: 'flex', height: '95px', alignItems: 'center' }}>
                <ListingActionsButton actions={actions} />
            </TableCell>
        </TableRow>
    )
}

const styles = {
    listingImageContainer: {
        height: '60px',
        width: '60px',
        marginRight: '12px'
    },
    listingImage: {
        borderRadius: '8px'
    },
    listingTitle: {
        lineHeight: '60px'
    },
    listingRow: {
        borderTop: '2px solid lightgrey'
    },
    marketImage: {
        height: '36px',
        width: '36px',
        marginRight: '8px',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStroke: Colors.light.divider,
        BorderStyle: 'solid'
    }
}
