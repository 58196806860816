import { Colors, selectScrapedMarketListingById, setIsScrapedMarketListingRemoved, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { CheckBoxCheckedIcon } from '../icons/CheckBoxChecked'
import { CheckBoxUncheckedIcon } from '../icons/CheckBoxUnchecked'
import { FoxButtonBase } from '../common/FoxButtonBase'
import { MouseEvent } from 'react'

type ImportListingItemRowProps = {
    scrapedMarketListingId: string
}
export const ImportListingItemRow = (props: ImportListingItemRowProps) => {
    const { scrapedMarketListingId } = props
    const dispatch = useAppDispatch()

    const scrapedMarketListing = useAppSelector(selectScrapedMarketListingById(scrapedMarketListingId))
    if (!scrapedMarketListing) {
        return null
    }

    const itemDetails = scrapedMarketListing.scrapedMarketListing.scrapedData
    const imageIds = itemDetails.imageIds

    const title = itemDetails.commonDetails.title
    const price = itemDetails.commonDetails.price
    const isChecked = !scrapedMarketListing.removed

    const handleChecked = (checked: boolean) => {
        if (checked) {
            dispatch(setIsScrapedMarketListingRemoved({ scrapedMarketListingId, removed: false }))
        } else {
            dispatch(setIsScrapedMarketListingRemoved({ scrapedMarketListingId, removed: true }))
        }
    }

    return (
        <FoxButtonBase
            sx={styles.container}
            onFoxClick={{
                kind: 'button',
                onClick: () => handleChecked(!isChecked)
            }}>
            <Box>{isChecked ? <CheckBoxCheckedIcon /> : <CheckBoxUncheckedIcon />}</Box>
            <Box sx={styles.containerImage}>
                {imageIds[0] ? (
                    <FoxListingImage imageId={imageIds[0]} style={{ ...styles.image }} />
                ) : (
                    <FoxImage src={CommonImages.general.foxtailLogo} alt='foxtail logo' style={{ ...styles.image }} />
                )}
            </Box>
            <Box sx={styles.containerTitle}>
                <FoxTypography variant='body2' bold>
                    {title}
                </FoxTypography>
            </Box>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        height: '96px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.light.background
    },
    containerImage: {
        height: '61px',
        width: '61px',
        backgroundColor: 'rgba(255, 255, 255, 0.93)',
        marginLeft: '30px'
    },
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    },
    containerTitle: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: '20px'
    }
}
