type GetInitialsParams = {
    firstName?: string
    lastName?: string
}
export const getInitials = ({ firstName, lastName }: GetInitialsParams) => {
    if (!firstName || !lastName) {
        return null
    } else {
        const initials = firstName.charAt(0) + lastName.charAt(0)
        return initials.toUpperCase()
    }
}
