import { time } from '@foxtail-dev/datacontracts'
import {
    useAppDispatch,
    useAppSelector,
    selectIsSyncingInProgressByDomain,
    selectLastSyncedAtByDomain,
    selectFacebookIsShippingEnabled,
    getDomainLinkingStatus,
    Logger,
    syncUserMarketDetailsCampaign
} from '@foxtail-dev/user-clients'
import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FoxTypography } from '../../components/common/FoxTypography'
import { generateToast } from '../../lib/clients/ToastClient'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxButton } from '../../components/common/FoxButton'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'

export const FacebookSyncShippingModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const dispatch = useAppDispatch()

    const currentlySyncingOnFacebook = useAppSelector(selectIsSyncingInProgressByDomain['facebook'])
    // Can request sync if last sync was more than 1 minute ago
    const lastSyncedAt = useAppSelector(selectLastSyncedAtByDomain['facebook'])
    const isShippingEnabled = useAppSelector(selectFacebookIsShippingEnabled)

    const [nextSyncTime, setNextSyncTime] = useState(new Date())
    const [syntheticSyncing, setSyntheticSyncing] = useState(false)
    const [showNextSyncTime, setShowNextSyncTime] = useState(false)
    const shouldShowSyncing = currentlySyncingOnFacebook || syntheticSyncing
    const secondsUntilNextSync = Math.round((nextSyncTime.getTime() - new Date().getTime()) / 1000)

    useEffect(() => {
        if (lastSyncedAt) {
            const nextSyncTime = new Date(new Date(lastSyncedAt).getTime() + time.minutes(1))
            setNextSyncTime(nextSyncTime)
        }
    }, [lastSyncedAt])

    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (syntheticSyncing) {
            // We don't have a good way of tracking when the sync starts and completes, especially given that we pull the sync state every 5 seconds
            // Put an synthetic loading state for 10 seconds, after that we base it on what the back end tells us
            timeout = setTimeout(() => {
                setSyntheticSyncing(false)
            }, time.seconds(10))
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [syntheticSyncing])

    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    const onSyncFacebookShipping = async () => {
        const canRequestSync = new Date() > nextSyncTime

        if (canRequestSync) {
            try {
                Logger.I().log({
                    message: 'User is requesting to sync facebook shipping',
                    level: 'info',
                    payload: {
                        kind: 'UserAction'
                    }
                })
                setShowNextSyncTime(false)
                setSyntheticSyncing(true)
                await dispatch(syncUserMarketDetailsCampaign('facebook')).unwrap()
            } catch (error) {
                Logger.I().log(
                    {
                        message: 'Error syncing facebook shipping',
                        level: 'error',
                        payload: {
                            kind: 'FacebookSyncError'
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Error syncing facebook shipping' })
            }
        } else {
            setShowNextSyncTime(true)
        }
    }

    const onBack = async () => {
        closeModal()
    }

    // TODO: Go over the text
    // TODO: Add a link to an article explaining how to set up shipping on facebook

    return (
        <Box sentry-label='FacebookSyncShippingScreen'>
            {shouldShowSyncing ? (
                <FoxTypography variant='h5' light sx={{ textAlign: 'center', marginTop: '140px', marginHorizontal: '20px' }}>
                    Syncing your ability to ship on Facebook. Please wait... this takes about 1 minute.
                </FoxTypography>
            ) : isShippingEnabled ? (
                <Box sx={sharedStyles.flex1}>
                    <FoxTypography variant='h5'>You are able to ship on Facebook</FoxTypography>
                    <Box sx={sharedStyles.flexGrow} />
                    <FoxButton primary onFoxClick={{ kind: 'button', onClick: onBack }} text='Back' sx={{ marginBottom: '32px' }} />
                </Box>
            ) : (
                <Box sx={sharedStyles.flex1}>
                    <FoxTypography variant='h5'>You are not set up to ship on Facebook</FoxTypography>

                    <Box sx={sharedStyles.flexGrow} />
                    <FoxTypography variant='body2' light sx={{ textAlign: 'center', marginBottom: '12px', marginTop: '48px' }}>
                        If you want to enable shipping through facebook, please set it up on your Facebook account and click the 'Sync' button below
                    </FoxTypography>

                    {showNextSyncTime && (
                        <FoxTypography variant='body2' danger sx={{ marginBottom: '12px' }}>
                            You can only sync once every minute. Please try again in {secondsUntilNextSync} seconds
                        </FoxTypography>
                    )}
                    <FoxButton
                        primary
                        onFoxClick={{ kind: 'button', onClick: onSyncFacebookShipping, preventDoubleClick: true }}
                        text='Sync Facebook shipping'
                        sx={{ marginBottom: 69 }}
                        loading={shouldShowSyncing}
                    />
                </Box>
            )}
        </Box>
    )
}
