import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { CheckIcon } from '../../components/icons/CheckIcon'
import { Colors } from '../../theme/Colors'

type SubscriptionCardFeatureRowProps = {
    feature: string
}
export const SubscriptionCardFeatureRow = (props: SubscriptionCardFeatureRowProps) => {
    return (
        <Box sx={{ flexDirction: 'row', display: 'flex', marginLeft: '22px', marginBottom: '8px' }}>
            <Box sx={{ marginRight: '12.43px' }}>
                <CheckIcon height={12} width={12} color={Colors.light.tertiary} />
            </Box>
            <Box>
                <FoxTypography>{props.feature}</FoxTypography>
            </Box>
        </Box>
    )
}
