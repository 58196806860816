import { UserActionSuccessResult } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/UserActionDescriptions'
import { UploadImage } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/concreteSchemas/user'
import { DummyImageClient, ImageClient } from './ImageClient'
import { IImageClientFactory, ImageClientFactoryParams, IImageClient } from '@foxtail-dev/user-clients'
import { Actions, IFileSystem } from '@foxtail-dev/datacontracts'

export class ImageClientFactory implements IImageClientFactory {
    create = async (params: ImageClientFactoryParams): Promise<IImageClient> => {
        const { executeActionRequest, getImageDownloadUrl } = params

        const createImageDocAndUploadUrl = async () => {
            const executedAction = await executeActionRequest<UploadImage>({
                kind: 'user/uploadImage',
                params: {
                    kind: 'getUploadUrl'
                }
            })

            if (!executedAction.wasSuccessfullyExecuted) {
                throw new Error('expected upload image result')
            }
            if (executedAction.successResult.kind !== 'gotUploadUrl') {
                throw new Error('expected gotUploadUrl result kind')
            }
            const result: UserActionSuccessResult<UploadImage> = executedAction.successResult
            return result
        }

        const submitUploadComplete = async (imageId: string) => {
            const executedAction = await executeActionRequest<UploadImage>({
                kind: 'user/uploadImage',
                params: {
                    kind: 'submitUploadComplete',
                    imageId
                }
            })

            if (!executedAction.wasSuccessfullyExecuted) {
                throw new Error('expected upload image result')
            }
            if (executedAction.successResult.kind !== 'uploadCompleted') {
                throw new Error('expected uploadComplete result kind')
            }
            const result: Actions.UserActionSuccessResult<Actions.ActionDescriptions.UploadImage> = executedAction.successResult
            return result
        }

        return new ImageClient({
            createImageDocAndUploadUrl,
            getImageDownloadUrl,
            submitUploadComplete
        })
    }
}

export class DummyImageClientFactory implements IImageClientFactory {
    create = async (): Promise<IImageClient> => {
        return new DummyImageClient()
    }
}
