import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '@foxtail-dev/user-clients'
import { ListingsIcon } from '../../components/icons/ListingsIcon'

export const UpcomingListingsZeroState = (): JSX.Element => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ marginTop: '168px', marginBottom: '24px' }}>
                <ListingsIcon color={Colors.light.divider} height={86} width={115} />
            </Box>
            <FoxTypography light variant='h6' sx={{ marginBottom: '16px' }}>
                No listings processing
            </FoxTypography>
            <FoxTypography variant='body1' sx={{ textAlign: 'center', width: '70%', color: 'rgba(0, 0, 0, 0.6)' }}>
                Your listing tasks will show up here
            </FoxTypography>
        </Box>
    )
}
