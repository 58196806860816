import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'

export const BetaChip = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerText}>
                <FoxTypography color={Colors.light.background}>beta</FoxTypography>
            </Box>
        </Box>
    )
}

const styles = {
    container: { alignItems: 'center', display: 'flex' },
    containerText: {
        borderRadius: '10px',
        height: '20px',
        background: 'linear-gradient(84.45deg, #E84855 0%, #E87155 100%)',
        marginLeft: '30px',
        marginRight: '10px',
        width: '50px',
        justifyContent: 'center',
        display: 'flex'
    }
}
