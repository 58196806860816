import { IToastClient, ToastParams } from '@foxtail-dev/user-clients'
import toast from 'react-hot-toast'

export const toastClient: IToastClient = {
    generateToast: (params: ToastParams) => {
        if (params.kind === 'success') {
            toast.success(params.message, { duration: 5000 })
        } else if (params.kind === 'error') {
            toast.error(params.message, { duration: 5000 })
        } else if (params.kind === 'info') {
            toast(params.message, { duration: 5000 })
        }
    }
}

export const generateToast = toastClient.generateToast
