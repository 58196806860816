import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
import { ChangeEvent } from 'react'
import { CheckBoxUncheckedIcon } from '../icons/CheckBoxUnchecked'
import { CheckBoxCheckedIcon } from '../icons/CheckBoxChecked'
import { FoxTypography } from './FoxTypography'
import { RadioButtonCheckedIcon } from '../icons/RadioButtonChecked'
import { RadioButtonUncheckedIcon } from '../icons/RadioButtonUnchecked'

export type FoxCheckboxType = 'Checkbox' | 'Radio'

type FoxCheckboxProps = {
    iconType: FoxCheckboxType
    checked: boolean
    onChange: (event: ChangeEvent, checked: boolean) => void
    label?: string
    subtext?: string
} & CheckboxProps

// TODO: Use subtext
export const FoxCheckbox = (props: FoxCheckboxProps) => {
    const { checked, onChange, label, iconType, subtext, ...rest } = props

    const isRadio = iconType === 'Radio'
    const checkedIcon = isRadio ? <RadioButtonCheckedIcon height={22} width={22} /> : <CheckBoxCheckedIcon />
    const unCheckedIcon = isRadio ? <RadioButtonUncheckedIcon height={22} width={22} /> : <CheckBoxUncheckedIcon />
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...rest}
                    sx={{
                        ...rest.sx,
                        ...styles.button,
                        ...{
                            '&.Mui-checked': {
                                color: 'initial'
                            }
                        }
                    }}
                    checked={checked}
                    onChange={onChange}
                    icon={unCheckedIcon}
                    checkedIcon={checkedIcon}
                />
            }
            label={
                <>
                    <FoxTypography sx={{ marginLeft: '12px' }} variant='body1'>
                        {label}
                    </FoxTypography>
                    {subtext && (
                        <FoxTypography variant='body1' light sx={{ paddingLeft: '4px', fontSize: '12px', lineHeight: '12px', paddingTop: '4px' }}>
                            {subtext}
                        </FoxTypography>
                    )}
                </>
            }
            sx={{}}
        />
    )
}

const styles = {
    button: {
        paddingLeft: '8px'
    }
}
