import { Colors, selectBulkCrosslistingDomains, useAppSelector } from '@foxtail-dev/user-clients'
import { ListingDefaultsModal } from '../../modals/account/ListingDefaultsModal'
import { ImportListingSkeleton } from '../skeletons/ImportListingSkeleton'

type BulkCrosslistEditListingDefaultsProps = {}

export const BulkCrosslistEditListingDefaults = (props: BulkCrosslistEditListingDefaultsProps) => {
    const bulkCrosslistDomains = useAppSelector(selectBulkCrosslistingDomains)

    return (
        <>
            <ImportListingSkeleton />
            <ListingDefaultsModal open={true} onClose={() => {}} domains={bulkCrosslistDomains} fromBulkCrosslist={true} />
        </>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    text: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    }
}
