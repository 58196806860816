import { time } from '@foxtail-dev/datacontracts'
import {
    useAppDispatch,
    useAppSelector,
    selectIsSyncingInProgressByDomain,
    selectEtsyProductionPartners,
    selectLastSyncedAtByDomain,
    selectIsAppInBackground,
    getDomainLinkingStatus,
    Logger,
    syncUserMarketDetailsCampaign
} from '@foxtail-dev/user-clients'
import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { generateToast } from '../../../lib/clients/ToastClient'
import { sharedStyles } from '../../../theme/SharedStyling'
import { FoxButton } from '../../../components/common/FoxButton'

export type CategorySelectionProps = {
    title: string
    renderItem: (item: any) => JSX.Element
}

export const EtsyProductionPartnersDetailSelection = ({ title, renderItem }: CategorySelectionProps) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const currentlySyncingOnEtsy = useAppSelector(selectIsSyncingInProgressByDomain['etsy'])
    const productionPartners = useAppSelector(selectEtsyProductionPartners)
    const lastSyncedAt = useAppSelector(selectLastSyncedAtByDomain['etsy'])
    // Can request sync if last sync was more than 1 minute ago
    const [nextSyncTime, setNextSyncTime] = useState(new Date())
    const [syntheticSyncing, setSyntheticSyncing] = useState(false)
    const [showNextSyncTime, setShowNextSyncTime] = useState(false)
    const shouldShowSyncing = currentlySyncingOnEtsy || syntheticSyncing
    const secondsUntilNextSync = Math.round((nextSyncTime.getTime() - new Date().getTime()) / 1000)

    useEffect(() => {
        if (lastSyncedAt) {
            const nextSyncTime = new Date(new Date(lastSyncedAt).getTime() + time.minutes(1))
            setNextSyncTime(nextSyncTime)
        }
    }, [lastSyncedAt])

    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (syntheticSyncing) {
            // We don't have a good way of tracking when the sync starts and completes, especially given that we pull the sync state every 5 seconds
            // Put an synthetic loading state for 10 seconds, after that we base it on what the back end tells us
            timeout = setTimeout(() => {
                setSyntheticSyncing(false)
            }, time.seconds(10))
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [syntheticSyncing])

    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    const onSyncProductionPartners = async () => {
        const canRequestSync = new Date() > nextSyncTime
        if (canRequestSync) {
            try {
                Logger.I().log({
                    message: 'Syncing etsy production partners',
                    level: 'info',
                    payload: {
                        kind: 'UserAction'
                    }
                })
                setSyntheticSyncing(true)
                await dispatch(syncUserMarketDetailsCampaign('etsy')).unwrap()
            } catch (error) {
                Logger.I().log(
                    {
                        message: 'Error syncing etsy production partners',
                        level: 'error',
                        payload: {
                            kind: 'EtsySyncError'
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Error syncing shipping profiles' })
            }
        } else {
            setShowNextSyncTime(true)
        }
    }

    return (
        <Box sx={[sharedStyles.screenContainer]}>
            {shouldShowSyncing ? (
                <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginTop: '140px', marginHorizontal: '20px' }}>
                    Syncing your Production partners from Etsy. Please wait... This takes about 1 minute.
                </FoxTypography>
            ) : productionPartners?.length === 0 ? (
                <Box sx={sharedStyles.flex1}>
                    <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginBottom: '28px', marginTop: '140px' }}>
                        When selecting "Another person from my shop" for Etsy's Who Made it input, you must select a production partner. {`\n\n`}We didn't find
                        any Production partners on your account. Please add them manually on Etsy and click sync to populate in foxtail. {`\n\n`}If you need
                        more help visit our support center.
                    </FoxTypography>
                    <Box sx={sharedStyles.flexGrow} />
                    {showNextSyncTime && (
                        <FoxTypography variant='body1' danger sx={{ marginBottom: '12px' }}>
                            You can only sync once every minute. Please try again in {secondsUntilNextSync} seconds
                        </FoxTypography>
                    )}
                    <FoxButton
                        primary
                        onFoxClick={{ kind: 'button', onClick: onSyncProductionPartners, preventDoubleClick: true }}
                        text='Sync production partners'
                        sx={{ marginBottom: '60px', width: '100%', height: '48px', justifyContent: 'center' }}
                        loading={shouldShowSyncing}
                    />
                </Box>
            ) : (
                <Box sx={sharedStyles.flex1}>
                    {productionPartners?.map((i) => renderItem(i))}
                    <Box sx={sharedStyles.flexGrow} />
                    <FoxTypography variant='body1' light sx={{ textAlign: 'center', marginBottom: '12px', marginTop: '12px' }}>
                        If you don't see your production partner here or want to add a new production partner, you must manually add it to your Etsy account and
                        click the Sync production partner button below.
                    </FoxTypography>
                    {showNextSyncTime && (
                        <FoxTypography variant='body1' danger sx={{ marginBottom: '12px' }}>
                            You can only sync once every minute. Please try again in {secondsUntilNextSync} seconds
                        </FoxTypography>
                    )}
                    <FoxButton
                        primary
                        onFoxClick={{ kind: 'button', onClick: onSyncProductionPartners, preventDoubleClick: true }}
                        text='Sync production partners'
                        sx={{ marginBottom: '60px', width: '100%', height: '48px', justifyContent: 'center' }}
                        loading={shouldShowSyncing}
                    />
                </Box>
            )}
        </Box>
    )
}
