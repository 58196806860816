import { useEffect, useState } from 'react'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import {
    AiListingConfigDetails,
    DepopAiListingSuggestionDefaultsConfig,
    EbayAiListingSuggestionDefaultsConfig,
    EtsyAiListingSuggestionDefaultsConfig,
    FacebookAiListingSuggestionDefaultsConfig
} from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import {
    useAppDispatch,
    getListingDefaults,
    parseListingDefaults,
    RuleFailure,
    setListingDefaults,
    shouldDepopListingAiConfigDetailsHaveShippingPrice,
    shouldEtsyListingAiConfigDetailsHaveProductionPartner
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { MarketSpecificFieldsLayout } from '../../layouts/MarketSpecificFieldsLayout'
import { shouldDisplayErrorMessage } from '../../utils/shouldDisplayErrorMessage'
import { Logger } from '../../lib/clients/Logger'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { generateToast } from '../../lib/clients/ToastClient'
import { ListingDefaultFieldsMap } from '../../containers/forms/domainSpecificFields/bulkCrosslist/ListingDefaultsFieldsMap'
import { createListingDefaultsConfig } from '../../utils/createListingDefaultsConfig'

type ListingDefaultsModalProps = {
    open: boolean
    onClose: (result: boolean) => void
    domains: DomainCommonName[]
    fromBulkCrosslist: boolean
}

export const ListingDefaultsModal = ({ open, onClose, domains, fromBulkCrosslist }: ListingDefaultsModalProps) => {
    const dispatch = useAppDispatch()

    const [initialConfig, setInitialConfig] = useState<AiListingConfigDetails | undefined>(undefined)

    const handleSubmit = async (values: AiListingConfigDetails, actions: FormikHelpers<AiListingConfigDetails>) => {
        const { setFieldError, setFieldTouched } = actions

        Logger.I().log({
            level: 'info',
            message: 'User is attempting to update listing defaults',
            payload: {
                kind: 'UserAction',
                entry: {
                    domains,
                    values,
                    fromBulkCrosslist
                }
            }
        })

        const result = parseListingDefaults(values)

        if (!result.success && result.errors) {
            result.errors.forEach((error) => {
                error.error.error.issues.forEach((issue) => {
                    const path = `domainDetails.${error.domain}.` + issue.path.join('.')
                    setFieldError(path, issue.message)
                    setFieldTouched(path)
                })
            })
            Logger.I().log(
                {
                    level: 'info',
                    message: 'User failed to update listing defaults',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            cause: 'Parsing error',
                            domains,
                            values: values
                        }
                    }
                },
                result.errors
            )
            return
        }

        const parsedListingDefaults = result.listingDefaults
        const parsedDomainDetails = parsedListingDefaults.domainDetails
        const extraRuleErrors: RuleFailure[] = []
        if (domains && domains.includes('etsy')) {
            // Re-parse to get the correct type
            const etsySuggestionDefaults = EtsyAiListingSuggestionDefaultsConfig.parse(parsedDomainDetails.etsy?.suggestionDefaults)

            if (shouldEtsyListingAiConfigDetailsHaveProductionPartner(etsySuggestionDefaults)) {
                extraRuleErrors.push({
                    field: 'domainDetails.etsy.suggestionDefaults.optionalDetails.productionPartner',
                    rule: 'EtsyProductionPartnerRequired',
                    message: 'Required'
                })
            }
        }

        if (domains && domains.includes('depop')) {
            // Re-parse to get the correct type
            const depopSuggestionDefaults = DepopAiListingSuggestionDefaultsConfig.parse(parsedDomainDetails.depop?.suggestionDefaults)

            if (shouldDepopListingAiConfigDetailsHaveShippingPrice(depopSuggestionDefaults)) {
                extraRuleErrors.push({
                    field: 'domainDetails.depop.suggestionDefaults.optionalDetails.shippingPrice',
                    rule: 'DepopShippingPriceRequired',
                    message: 'Shipping price is required'
                })
            }
        }

        if (domains && domains.includes('ebay')) {
            // Re-parse to get the correct type
            const ebaySuggestionDefaults = EbayAiListingSuggestionDefaultsConfig.parse(parsedDomainDetails.ebay?.suggestionDefaults)
            if (!ebaySuggestionDefaults.optionalDetails.dimensionUnit) {
                extraRuleErrors.push({
                    field: 'domainDetails.ebay.suggestionDefaults.optionalDetails.dimensionUnit',
                    rule: 'EbayDimensionUnitRequired',
                    message: 'Required'
                })
            }
        }

        if (domains && domains.includes('facebook')) {
            const facebookListingDetails = FacebookAiListingSuggestionDefaultsConfig.parse(values.domainDetails.facebook?.suggestionDefaults)
            const requiredDetails = facebookListingDetails.requiredDetails
            const optionalDetails = facebookListingDetails.optionalDetails

            const isShipping = requiredDetails.deliveryMethods.includes('Shipping')
            if (isShipping) {
                if (!optionalDetails.shippingOption) {
                    extraRuleErrors.push({
                        rule: 'facebookShippingOptionRequired',
                        field: 'domainDetails.facebook.suggestionDefaults.optionalDetails.shippingOption',
                        message: 'Required'
                    })
                }

                const usePrepaidLabel = optionalDetails.shippingOption === 'Use a prepaid shipping label'

                if (usePrepaidLabel) {
                    if (!optionalDetails.shippingCarrier) {
                        extraRuleErrors.push({
                            rule: 'facebookShippingCarrierRequired',
                            field: 'domainDetails.facebook.suggestionDefaults.optionalDetails.shippingCarrier',
                            message: 'Required'
                        })
                    }
                } else {
                    if (!optionalDetails.shippingRate) {
                        extraRuleErrors.push({
                            rule: 'facebookShippingRateRequired',
                            field: 'domainDetails.facebook.suggestionDefaults.optionalDetails.shippingRate',
                            message: 'Required'
                        })
                    }
                }

                if (optionalDetails.freeShipping === undefined) {
                    extraRuleErrors.push({
                        rule: 'facebookFreeShippingRequired',
                        field: 'domainDetails.facebook.suggestionDefaults.optionalDetails.freeShipping',
                        message: 'Required'
                    })
                }

                if (optionalDetails.allowOffers === undefined) {
                    extraRuleErrors.push({
                        rule: 'facebookAllowOffersRequired',
                        field: 'domainDetails.facebook.suggestionDefaults.optionalDetails.allowOffers',
                        message: 'Required'
                    })
                }
            }
        }

        if (extraRuleErrors.length > 0) {
            for (const error of extraRuleErrors) {
                setFieldError(error.field, error.message)
                setFieldTouched(error.field, true)
            }

            Logger.I().log({
                level: 'info',
                message: 'User failed to update listing defaults',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        cause: 'Extra rule error',
                        domains,
                        values,
                        extraRuleErrors,
                        fromBulkCrosslist
                    }
                }
            })
            return
        }

        try {
            await dispatch(setListingDefaults(parsedListingDefaults)).unwrap()
            generateToast({ kind: 'success', message: 'Listing defaults updated successfully' })

            Logger.I().log({
                level: 'info',
                message: 'User successfully updated listing defaults',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        domains,
                        values,
                        fromBulkCrosslist
                    }
                }
            })
            onClose(true)
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to set listing defaults',
                    payload: {
                        kind: 'ListingDefaultUpdateError',
                        entry: {
                            domains,
                            values,
                            fromBulkCrosslist
                        }
                    }
                },
                error
            )

            generateToast({ kind: 'info', message: 'Unable to update listing defaults' })
        }
    }

    const getDefaults = async () => {
        try {
            const config = await dispatch(getListingDefaults()).unwrap()
            const newConfig = createListingDefaultsConfig(config, domains)

            setInitialConfig(newConfig)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to get listing defaults',
                    payload: {
                        kind: 'ListingDefaultGetError'
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to get listing defaults' })
        }
    }

    useEffect(() => {
        if (initialConfig) {
            return
        }

        getDefaults()
    }, [initialConfig])

    const onDiscardChanges = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User discarded changes to listing defaults',
            payload: {
                kind: 'UserAction'
            }
        })

        onClose(false)
    }

    if (!initialConfig) {
        return null
    }

    return (
        <Formik initialValues={initialConfig} onSubmit={handleSubmit} enableReinitialize={false} validateOnBlur={false} validateOnChange={false}>
            {({ values, handleSubmit, isSubmitting, touched, errors }: FormikProps<AiListingConfigDetails>) => {
                return (
                    <FoxModalDialog
                        open={open}
                        title={'Edit listing defaults'}
                        leftButtonKind={'back'}
                        onClose={onDiscardChanges}
                        removeOverlay={fromBulkCrosslist}
                        actions={
                            <FoxButton
                                primary
                                text='Save changes'
                                loading={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        await handleSubmit()
                                    },
                                    preventDoubleClick: true
                                }}
                                sx={{ height: '48px', width: '100%', justifyContent: 'center' }}
                            />
                        }
                    >
                        <Box>
                            <FoxTypography light variant='body1' style={{ textAlign: 'center', marginTop: '12px' }}>
                                Foxtail's AI excels in creating listing details, however certain information is best provided by you. All fields you fill out
                                here will be used when generating listing details.
                            </FoxTypography>
                        </Box>

                        {domains &&
                            domains.map((market) => {
                                const Fields = ListingDefaultFieldsMap[market]
                                return (
                                    <MarketSpecificFieldsLayout key={market} domain={market}>
                                        <Fields values={values} />
                                    </MarketSpecificFieldsLayout>
                                )
                            })}

                        {shouldDisplayErrorMessage(touched, errors) && (
                            <Box style={styles.containerValidation}>
                                <FoxTypography variant='body1' danger>
                                    Fix form errors to save ai listing defaults
                                </FoxTypography>
                            </Box>
                        )}
                    </FoxModalDialog>
                )
            }}
        </Formik>
    )
}

const styles = {
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    }
}
