import { Colors } from '@foxtail-dev/user-clients'
import { Box, Divider, ImageList, ImageListItem } from '@mui/material'
import { FoxListingImage } from '../../common/FoxListingImage'
import { FoxTypography } from '../../common/FoxTypography'

type ListingMediaProps = {
    imageIds: string[]
}

export const ListingMedia = ({ imageIds }: ListingMediaProps) => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitle}>
                <FoxTypography variant='subtitle1'>Media</FoxTypography>
            </Box>
            <Divider />
            <Box sx={styles.containerImageList}>
                <ImageList cols={2} gap={17}>
                    {imageIds.map((imageId, index) => (
                        <ImageListItem key={index}>
                            <FoxListingImage
                                imageId={imageId}
                                alt={'listing image number ' + index}
                                loading='lazy'
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    borderWidth: '1px',
                                    borderColor: '#EBE7F1',
                                    borderStyle: 'solid'
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        borderRadius: '10px',
        marginRight: '19px',
        marginBottom: '28px'
    },
    containerTitle: {
        paddingTop: '23px',
        marginLeft: '22px',
        height: '64px'
    },
    containerImageList: {
        margin: '22px',
        overflowY: 'auto'
    }
}
