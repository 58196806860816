import { EtsyWhoMadeItRadioOptions, EtsyWhenDidYouMakeItRadioOptions, productionPartnerRequiredWhenDidYouMakeItValues } from '@foxtail-dev/user-clients'
import { useState } from 'react'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { EtsyProductionPartnersModal } from '../../../../modals/domainSpecific/EtsyProductionPartnersModal'
import { EtsyShippingProfilesModal } from '../../../../modals/domainSpecific/EtsyShippingProfilesModal'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { EtsyAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'

export const EtsyListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props
    const suggestionDefaults = values.domainDetails.etsy?.suggestionDefaults
    const parseResult = EtsyAiListingSuggestionDefaultsConfig.safeParse(suggestionDefaults)
    const suggestionDefaultsParsed = parseResult.success ? parseResult.data : undefined

    const [modalOpen, setModalOpen] = useState<string>('none')
    const onCloseModal = () => {
        setModalOpen('none')
    }

    return (
        <>
            <DetailSelectionField
                name='domainDetails.etsy.suggestionDefaults.requiredDetails.shippingProfile'
                label='Shipping Profile'
                placeholder='Select shipping profile'
                onDetail={() => setModalOpen('EtsyShippingProfiles')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'EtsyShippingProfiles'} title='Shipping Profiles' closeModal={onCloseModal}>
                <EtsyShippingProfilesModal listingOperationKind={'setDefault'} closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            <RadioGroupField
                label='Who made it'
                name='domainDetails.etsy.suggestionDefaults.requiredDetails.whoMadeIt'
                iconType='Radio'
                options={EtsyWhoMadeItRadioOptions}
                logOnChange
            />

            <DetailSelectionField
                name='domainDetails.etsy.suggestionDefaults.requiredDetails.whenDidYouMakeIt'
                label='When was it made'
                placeholder='Select when product was made'
                options={EtsyWhenDidYouMakeItRadioOptions}
                onDetail={() => setModalOpen('EtsyWhenDidYouMakeIt')}
            />
            <DetailSelectionModal
                open={modalOpen === 'EtsyWhenDidYouMakeIt'}
                headerTitle='When was it made'
                options={EtsyWhenDidYouMakeItRadioOptions}
                paramName='whenDidYouMakeIt'
                currentValue={suggestionDefaultsParsed?.requiredDetails?.whenDidYouMakeIt}
                isRequired={true}
                listingOperationKind={'setDefault'}
                domainName='etsy'
                closeModal={onCloseModal}
            />

            {suggestionDefaultsParsed?.requiredDetails.whoMadeIt === 'someone_else' &&
                suggestionDefaultsParsed?.requiredDetails.whenDidYouMakeIt &&
                productionPartnerRequiredWhenDidYouMakeItValues.includes(suggestionDefaultsParsed.requiredDetails.whenDidYouMakeIt) && (
                    <>
                        <DetailSelectionField
                            name='domainDetails.etsy.suggestionDefaults.optionalDetails.productionPartner'
                            label='Production partner'
                            placeholder='Select production partner'
                            onDetail={() => setModalOpen('EtsyProductionPartners')}
                        />
                        <DomainSpecificDetailModal open={modalOpen === 'EtsyProductionPartners'} title='Production Partners' closeModal={onCloseModal}>
                            <EtsyProductionPartnersModal listingOperationKind={'setDefault'} closeModal={onCloseModal} />
                        </DomainSpecificDetailModal>
                    </>
                )}
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
