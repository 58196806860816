import { useAppSelector, selectCurrentEbayFulfillmentPolicy } from '@foxtail-dev/user-clients'
import React, { useState } from 'react'
import { EbayFulfillmentPolicyCard } from '../../containers/forms/listingDetails/EbayFulfillmentPolicyCard'
import { EbayFulfillmentPolicyDetailSelection } from '../../containers/forms/listingDetails/EbayFulfillmentPolicyDetailSelection'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { updateEbayFulfillmentPolicyInFormik } from './DomainSpecificFormikUtils'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'

export const EbayFulfillmentPolicyModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const currentlySelectedEbayFulfillmentPolicy = useAppSelector(selectCurrentEbayFulfillmentPolicy)
    const [selectedPolicyId, setSelectedPolicyId] = useState(currentlySelectedEbayFulfillmentPolicy ?? '')

    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = async (fulfillmentPolicyId: string) => {
        updateEbayFulfillmentPolicyInFormik(setFieldValue, setFieldTouched, fulfillmentPolicyId)
        closeModal()
    }

    return (
        <EbayFulfillmentPolicyDetailSelection
            title='Shipping Policy'
            handleSave={async () => await handleSave(selectedPolicyId)}
            renderItem={(item) => {
                return (
                    <EbayFulfillmentPolicyCard
                        key={item.fulfillmentPolicyId}
                        cardPolicy={item.fulfillmentPolicyId}
                        currentPolicySelection={selectedPolicyId ?? ''}
                        title={item.name}
                        setPolicy={() => setSelectedPolicyId(item.fulfillmentPolicyId)}
                        globalShipping={false}
                        localPickup={false}
                        handlingTimeValue={item.handlingTime && item.handlingTime.value ? item.handlingTime.value : undefined}
                        handlingTimeUnit={
                            item.handlingTime && item.handlingTime.value && item.handlingTime.unit
                                ? Number.parseInt(item.handlingTime.value) !== 1
                                    ? `${item.handlingTime.unit.toLowerCase()}s`
                                    : item.handlingTime.unit.toLowerCase()
                                : undefined
                        }
                        shippingOptions={item.shippingOptions}
                        fulfillmentPolicyId={item.fulfillmentPolicyId}
                    />
                )
            }}
        />
    )
}
