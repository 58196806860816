import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ListingTableSkeleton } from '../../components/skeletons/ListingsTableSkeleton'
import { ListingTablePagination } from './ListingTablePagination'
import { RowsPerPage } from './RowsPerPage'

export type ListingRowComponentProps = {
    listingId: string
    tab: string
}

type ListingTableProps = {
    listingIds: string[]
    currentPage: number
    setCurrentPage: (page: number) => void
    pageSize: number
    setPageSize: (pageSize: number) => void
    hasMoreListings: boolean
    showAllListings: boolean
    isLoading: boolean
    tab: string
    listingRowComponent: React.FC<ListingRowComponentProps>
}

export const ListingTable = ({
    listingIds,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    hasMoreListings,
    showAllListings,
    isLoading,
    tab,
    listingRowComponent
}: ListingTableProps) => {
    const onPageChange = async (page: number) => {
        setCurrentPage(page)
    }

    const onPageSizeChange = (pageSize: RowsPerPage) => {
        setPageSize(parseInt(pageSize))
        setCurrentPage(0)
    }

    // TODO: Allow passing in the column header text, (e.g. for bulk crosslisting to change the listed on text to something else)
    return (
        <>
            <TableContainer>
                {isLoading ? (
                    <ListingTableSkeleton isLoading={isLoading} />
                ) : (
                    <>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow sx={{ ...styles.listingHeader }}>
                                    <TableCell align='left'>Name</TableCell>
                                    <TableCell align='left'>{tab === 'untracked' ? 'Selected Markets' : 'Listed on'}</TableCell>
                                    <TableCell align='left' width={140}>
                                        Price
                                    </TableCell>
                                    <TableCell align='left' width={35}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ borderBottom: '2px solid lightgrey' }}>
                                {listingIds.map((id) => {
                                    const Field = listingRowComponent
                                    return <Field key={id} listingId={id} tab={tab} />
                                })}
                            </TableBody>
                        </Table>
                        <ListingTablePagination
                            count={listingIds.length}
                            page={currentPage}
                            rowsPerPage={pageSize}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onPageSizeChange}
                            hasMoreListings={hasMoreListings}
                            showAllListings={showAllListings}
                        />
                    </>
                )}
            </TableContainer>
        </>
    )
}

const styles = {
    listingHeader: {
        height: '24px',
        lineHeight: '24px'
    }
}
