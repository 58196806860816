import { useMemo } from 'react'
import {
    selectListingStatusesFlattened,
    useAppSelector,
    selectAiTasks,
    selectImportListingSession,
    selectBulkCrosslistingSession
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { UpcomingListingsZeroState } from '../../containers/home/UpcomingListingsZeroState'
import { ListingStatusRow } from '../../containers/home/ListingStatusRow'
import { AiTaskStatusRow } from '../../containers/home/AiTaskStatusRow'
import { ImportListingSessionStatusRow } from '../../containers/home/ImportListingSessionStatusRow'
import { BulkCrosslistingSessionStatusRow } from '../../containers/home/BulkCrosslistingSessionStatusRow'

export const UpcomingListingScreen = (): JSX.Element => {
    const listingStatuses = useAppSelector(selectListingStatusesFlattened)
    const aiTasks = useAppSelector(selectAiTasks)
    const importListingSession = useAppSelector(selectImportListingSession)
    const bulkCrosslistingSession = useAppSelector(selectBulkCrosslistingSession)

    const sortedListingStatuses = useMemo(() => listingStatuses.sort((listingStatus) => (listingStatus.inProgress ? -1 : 1)), [listingStatuses])

    const zeroListingsProcessing = sortedListingStatuses.length === 0 && aiTasks.length === 0 && !importListingSession && !bulkCrosslistingSession
    const needDivider = sortedListingStatuses.length > 0 && aiTasks.length > 0

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerInner}>
                <FoxTypography variant='h5' sx={styles.text}>
                    Upcoming Tasks
                </FoxTypography>

                {zeroListingsProcessing ? (
                    <UpcomingListingsZeroState />
                ) : (
                    <>
                        {sortedListingStatuses.map((listingStatus) => (
                            <ListingStatusRow
                                key={'listingStatus' + listingStatus.listingId + listingStatus.description}
                                description={listingStatus.description}
                                imageId={listingStatus.imageId}
                                title={listingStatus.title}
                                listingId={listingStatus.listingId}
                            />
                        ))}

                        {aiTasks.map((aiTask) => (
                            <AiTaskStatusRow key={'aiTask' + aiTask.id} aiTask={aiTask} />
                        ))}

                        {importListingSession && <ImportListingSessionStatusRow importListingSession={importListingSession} />}
                        {bulkCrosslistingSession && <BulkCrosslistingSessionStatusRow bulkCrosslistingSession={bulkCrosslistingSession} />}
                    </>
                )}
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        alignItems: 'center'
    },
    containerInner: {
        width: '560px'
    },
    text: {
        marginTop: '15px',
        marginBottom: '20px'
    }
}
