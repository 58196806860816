import { Box } from '@mui/material'
import { sharedStyles } from '../theme/SharedStyling'
import { ListingsIcon } from '../components/icons/ListingsIcon'
import { FlexGrow } from '../components/common/FlexGrow'
import { FoxTypography } from '../components/common/FoxTypography'
import { Colors } from '../theme/Colors'
import { AccountSideBarRow } from '../containers/account/AccountSideBarRow'
import { BellIcon } from '../components/icons/BellIcon'
import { CreditCardIcon } from '../components/icons/CreditCardIcon'
import { ProfileIcon } from '../components/icons/ProfileIcon'
import { useLocation, useNavigation } from 'react-router-dom'
import { Checklist } from '@mui/icons-material'
import { SaveIcon } from '../components/icons/SaveIcon'
import { SchoolHatIcon } from '../components/icons/SchoolHatIcon'

export const AccountSideBar = () => {
    // TODO: When the sidebar is collapsed, it should only show icons

    const location = useLocation()
    const pathName = location.pathname
    const isMarketConnectionsScreen = pathName === '/app/account/marketplace-connections'
    const isProfileScreen = pathName === '/app/account/profile'
    const isNotificationSettingsScreen = pathName === '/app/account/notification-settings'
    const isCrosslistingDefaultsScreen = pathName === '/app/account/crosslisting-defaults'
    const isSubscriptionScreen = pathName === '/app/account/subscription'
    const isChecklistScreen = pathName === '/app/account/checklist'

    // TODO: Convert to use built in Drawer
    return (
        <Box sx={[sharedStyles.flexColumn, styles.sidebar]}>
            <FoxTypography sx={styles.textAccount} variant='h5'>
                Account
            </FoxTypography>

            <AccountSideBarRow
                Icon={<ListingsIcon height={18} width={19} color={isMarketConnectionsScreen ? Colors.light.primary : Colors.light.text} />}
                active={isMarketConnectionsScreen}
                to='/app/account/marketplace-connections'
                title='Marketplace connections'
            />
            <AccountSideBarRow
                Icon={<ProfileIcon height={19} width={19} color={isProfileScreen ? Colors.light.primary : Colors.light.text} />}
                active={isProfileScreen}
                to='/app/account/profile'
                title='Profile'
            />
            <AccountSideBarRow
                Icon={<BellIcon width={16} height={19} color={isNotificationSettingsScreen ? Colors.light.primary : Colors.light.text} />}
                active={isNotificationSettingsScreen}
                to='/app/account/notification-settings'
                title='Notification settings'
            />
            <AccountSideBarRow
                Icon={<SaveIcon height={19} width={19} color={isCrosslistingDefaultsScreen ? Colors.light.primary : Colors.light.text} />}
                active={isCrosslistingDefaultsScreen}
                to='/app/account/crosslisting-defaults'
                title='Crosslisting defaults'
            />
            <AccountSideBarRow
                Icon={<CreditCardIcon height={15} width={19} color={isSubscriptionScreen ? Colors.light.primary : Colors.light.text} />}
                active={isSubscriptionScreen}
                to='/app/account/subscription'
                title='Subscription'
            />
            <AccountSideBarRow
                Icon={<SchoolHatIcon height={18} width={19} color={isChecklistScreen ? Colors.light.primary : Colors.light.text} />}
                active={isChecklistScreen}
                to='/app/account/checklist'
                title='Onboarding checklist'
            />
            <FlexGrow />
        </Box>
    )
}

const styles = {
    sidebar: {
        width: '270px',
        height: 'calc(100vh - 100px)',
        paddingLeft: '10px',
        paddingRight: '8px'
    },
    textAccount: {
        marginBottom: '29px'
    }
}
