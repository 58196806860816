import {
    getActiveListingCount,
    getListingStatusReport,
    selectAiTasks,
    selectBulkCrosslistingSession,
    selectImportListingSession,
    selectListingStatusesFlattened,
    selectListingStatusInterval,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { FoxButton } from '../common/FoxButton'
import { CircularProgress } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { FoxIconButton } from '../common/FoxIconButton'
import { useEffect, useMemo, useState } from 'react'
import { time } from '@foxtail-dev/datacontracts'

type UpcomingListingsIndicatorProps = {
    collapsed?: boolean
}

export const UpcomingListingsIndicator = ({ collapsed }: UpcomingListingsIndicatorProps) => {
    const dispatch = useAppDispatch()

    const listingStatuses = useAppSelector(selectListingStatusesFlattened)
    const aiTasks = useAppSelector(selectAiTasks)
    const importListingSession = useAppSelector(selectImportListingSession)
    const bulkCrosslistingSession = useAppSelector(selectBulkCrosslistingSession)
    const statusReportInterval = useAppSelector(selectListingStatusInterval)

    const sortedListingStatuses = useMemo(() => listingStatuses.sort((listingStatus) => (listingStatus.inProgress ? -1 : 1)), [listingStatuses])

    const processingCount = sortedListingStatuses.length + aiTasks.length + (importListingSession ? 1 : 0) + (bulkCrosslistingSession ? 1 : 0)
    const areAnyProcessing = processingCount > 0

    // We run the listing status report once when we come into the app, and then subsequently on a timer
    useEffect(() => {
        dispatch(getListingStatusReport())
    }, [])

    useEffect(() => {
        if (!statusReportInterval) {
            return
        }

        const statusInterval = setInterval(() => {
            dispatch(getListingStatusReport())
        }, statusReportInterval + 2000) // To account for how long it takes to process the status report

        return () => {
            clearInterval(statusInterval)
        }
    }, [statusReportInterval])

    // We want to refresh the activeListingCount every so often, so we can update the status report interval
    // We want to avoid a situation where the user imports 1000 listings, but we still call the status report every 5 seconds
    useEffect(() => {
        dispatch(getActiveListingCount())

        const statusInterval = setInterval(() => {
            dispatch(getActiveListingCount())
        }, time.minutes(1))

        return () => clearInterval(statusInterval)
    }, [])

    const upcomingListingsCount = listingStatuses.length

    const text = areAnyProcessing ? `Processing` : `Task queue`
    const activeActivityIndicator = listingStatuses.length > 0 || aiTasks.length > 0
    const startIcon = activeActivityIndicator ? <CircularProgress size={16} /> : null

    return (
        <>
            {collapsed ? (
                <>
                    {upcomingListingsCount === 0 ? null : (
                        <FoxIconButton onFoxClick={{ kind: 'button', onClick: async () => {} }}>
                            <CircularProgress size={16} />
                        </FoxIconButton>
                    )}
                </>
            ) : (
                <FoxButton
                    onFoxClick={{ kind: 'button', onClick: async () => {} }}
                    text={text}
                    startIcon={startIcon}
                    endIcon={<ChevronRight />}
                    sx={{ fontSize: 12, width: '100%', justifyContent: 'center' }}
                />
            )}
        </>
    )
}
