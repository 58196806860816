import { FoxModalDialog } from '../../../components/common/FoxModalDialog'

// TODO: Horrible name, gotta fix this
export type DomainSpecificDetailModalChildProps = {
    closeModal: () => void
}

type DomainSpecificDetailModalProps = {
    open: boolean
    title: string
    children: React.ReactNode
    actions?: React.ReactNode
    closeModal: () => void
}

export const DomainSpecificDetailModal = ({ children, open, closeModal, title, actions }: DomainSpecificDetailModalProps) => {
    return (
        <FoxModalDialog leftButtonKind='back' onClose={closeModal} open={open} title={title} fullHeight removeOverlay actions={actions}>
            {children}
        </FoxModalDialog>
    )
}
