import { ListingOperationKind } from '@foxtail-dev/user-clients'
import { DetailSelection } from '../../components/common/DetailSelection'
import { EtsyShippingProfileDetailSelection } from '../../containers/forms/listingDetails/EtsyShippingProfilesDetailSelection'
import { useFormikContext } from 'formik'
import { updateListingDefaultFieldInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'

type EtsyShippingProfilesModalParams = {
    listingOperationKind: ListingOperationKind
} & DomainSpecificDetailModalChildProps

export const EtsyShippingProfilesModal = (props: EtsyShippingProfilesModalParams) => {
    const { listingOperationKind, closeModal } = props
    const { setFieldTouched, setFieldValue } = useFormikContext() // TODO: Figure out a way to type this either listingDetailsDraft or AiListingConfigDetails

    const handleSave = (item: any) => {
        if (listingOperationKind === 'create' || listingOperationKind === 'edit' || listingOperationKind === 'bulkCrosslist') {
            updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'shippingProfile',
                domainName: 'etsy',
                isRequired: true,
                value: item
            })
        } else if (listingOperationKind === 'setDefault') {
            updateListingDefaultFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'shippingProfile',
                domainName: 'etsy',
                isRequired: true,
                value: item
            })
        }
        closeModal()
    }

    return (
        <EtsyShippingProfileDetailSelection
            title='Shipping Profiles'
            renderItem={(item) => <DetailSelection key={item} value={item} onDetail={() => handleSave(item)} />}
        />
    )
}
