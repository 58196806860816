import { DomainCommonName } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { AiListingConfigDetails } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { EtsyListingDefaultFields } from './EtsyListingDefaultFields'
import { PoshmarkListingDefaultFields } from './PoshmarkListingDefaultFields'
import { MercariListingDefaultFields } from './MercariListingDefaultFields'
import { EbayListingDefaultFields } from './EbayListingDefaultFields'
import { DepopListingDefaultFields } from './DepopListingDefaultFields'
import { FacebookListingDefaultFields } from './FacebookListingDefaultFields'

export const ListingDefaultFieldsMap = {
    [DomainCommonName.Enum.etsy]: EtsyListingDefaultFields,
    [DomainCommonName.Enum.poshmark]: PoshmarkListingDefaultFields,
    [DomainCommonName.Enum.mercari]: MercariListingDefaultFields,
    [DomainCommonName.Enum.ebay]: EbayListingDefaultFields,
    [DomainCommonName.Enum.depop]: DepopListingDefaultFields,
    [DomainCommonName.Enum.facebook]: FacebookListingDefaultFields
} as const

export type ListingDefaultFieldsMapProps = {
    values: AiListingConfigDetails
}
