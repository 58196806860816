import { Colors, UserRuntimeContextProvider, setIsExtensionInstalled, useAppDispatch } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxtailWebConfig } from '../../lib/config/FoxtailWebConfig'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { ExtensionIcon } from '../icons/ExtensionIcon'

type ChromeExtensionNotInstalledMessageProps = {
    setExtensionReloadModalOpen: (result: boolean) => void
}

export const ChromeExtensionNotInstalledMessage = (props: ChromeExtensionNotInstalledMessageProps): JSX.Element => {
    const dispatch = useAppDispatch()

    const onInstallExtension = async () => {
        try {
            window.open(FoxtailWebConfig.config.extensionUrl)
            props.setExtensionReloadModalOpen(true)
            const context = await UserRuntimeContextProvider.getContext()
            await context.extensionClient.ping(4000)
            dispatch(setIsExtensionInstalled(true))
        } catch (error) {
            dispatch(setIsExtensionInstalled(false))
        }
    }
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerExtensionIcon}>
                <ExtensionIcon height={165} width={316} />
            </Box>
            <FoxTypography variant='subtitle1' sx={styles.textTitle}>
                Link markets with chrome extension
            </FoxTypography>
            <FoxTypography variant='body1' sx={styles.textBody}>
                Install the Foxtail Chrome extension to connect your marketplace accounts.
            </FoxTypography>

            <FoxButton
                onFoxClick={{
                    kind: 'button',
                    onClick: onInstallExtension,
                    preventDoubleClick: true
                }}
                text='Install'
                primary
                sx={styles.button}
            />
        </Box>
    )
}

const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: Colors.light.divider,
        marginLeft: '32px',
        marginRight: '254px',
        height: '526px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    containerExtensionIcon: {
        marginTop: '80px',
        marginBottom: '40px'
    },
    textTitle: {
        lineHeight: '24px',
        marginTop: '38px'
    },
    textBody: {
        marginTop: '11px',
        textAlign: 'center',
        marginHorizontal: '32px'
    },
    button: {
        color: 'white',
        marginTop: '32px',
        width: '167px',
        height: '48px',
        justifyContent: 'center'
    }
}
