import React, { useEffect, useState } from 'react'
import {
    CurrentBrowser,
    getDomainLinkingStatus,
    selectIsExtensionInstalled,
    selectIsLoggedInOrAuthingToAtLeastOneDomain,
    selectLoggedInAndReadyToListDomains,
    selectLoggedInButNotReadyToListDomains,
    selectLoggedOutOrNoAccountDomains,
    selectLoggingInDomains,
    selectLoggingOutDomains,
    selectSetUpRequiredDomains,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { LinkedMarketplaceRow } from '../../containers/account/LinkedMarketplaceRow'
import { ConnectAMarketplaceRow } from '../../containers/account/ConnectAMarketplaceRow'
import { AccountHeader } from '../../layouts/AccountHeader'
import { EbayInventoryLocationModal } from '../../modals/account/marketConnections/EbayInventoryLocationModal'
import { MustUseChromeToLinkMarketPlaces } from '../../components/account/MustUseChromeToLinkMarketPlaces'
import { ChromeExtensionNotInstalledMessage } from '../../components/account/ChromeExtensionNotInstalledMessage'
import { ExtensionReloadModal } from '../../modals/account/marketConnections/ExtensionReloadModal'

const MarketConnectionsScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const isLoggedInOrAuthingToOneDomain = useAppSelector(selectIsLoggedInOrAuthingToAtLeastOneDomain)
    const loggedInAndReadyToListDomains = useAppSelector(selectLoggedInAndReadyToListDomains)
    const loggedInNotReadyToListDomains = useAppSelector(selectLoggedInButNotReadyToListDomains)
    const loggingInDomains = useAppSelector(selectLoggingInDomains)
    const loggingOutDomains = useAppSelector(selectLoggingOutDomains)
    const setupRequiredDomains = useAppSelector(selectSetUpRequiredDomains)
    const loggedOutOrNoAccountDomains = useAppSelector(selectLoggedOutOrNoAccountDomains)
    const isChromeExtensionInstalled = useAppSelector(selectIsExtensionInstalled)
    const [ebayInventoryLocationModal, setEbayInventoryLocationModalOpen] = useState<boolean>(false)
    const [isUsingChromeBrowser, setIsUsingChromeBrowser] = useState<boolean>(true)
    const [extensionReloadModalOpen, setExtensionReloadModalOpen] = useState<boolean>(false)

    const closeExtensionReloadModal = (result: boolean) => {
        setExtensionReloadModalOpen(false)
    }
    useEffect(() => {
        const isChromeBrowser = CurrentBrowser.getBrowserKind() === 'Chrome'
        setIsUsingChromeBrowser(isChromeBrowser)
    }, [])

    useEffect(() => {
        dispatch(getDomainLinkingStatus())

        const interval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    const closeEbayInventoryLocationModal = () => {
        setEbayInventoryLocationModalOpen(false)
    }

    const showMarkets = isLoggedInOrAuthingToOneDomain || isChromeExtensionInstalled

    return (
        <Box sx={styles.container}>
            <AccountHeader title="Marketplace connections" hideDivider={isChromeExtensionInstalled === false} />

            {isUsingChromeBrowser === false ? (
                <MustUseChromeToLinkMarketPlaces />
            ) : (
                <Box>
                    {showMarkets ? (
                        <>
                            {isLoggedInOrAuthingToOneDomain && (
                                <Box sx={{ marginBottom: '32px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '32px' }}>
                                        <FoxTypography sx={{ marginBottom: '12px' }} variant="subtitle1">
                                            Linked accounts
                                        </FoxTypography>
                                    </Box>
                                    <Divider sx={{ marginLeft: '31px' }} />
                                    <Box sx={{}}>
                                        {loggedInAndReadyToListDomains.map(domain => (
                                            <LinkedMarketplaceRow
                                                key={domain}
                                                domainName={domain}
                                                status="Logged In"
                                                setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                            />
                                        ))}
                                        {loggedInNotReadyToListDomains.map(domain => (
                                            <LinkedMarketplaceRow
                                                key={domain}
                                                domainName={domain}
                                                status="Logged In"
                                                setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                            />
                                        ))}
                                        {loggingInDomains.map(domain => (
                                            <LinkedMarketplaceRow
                                                key={domain}
                                                domainName={domain}
                                                status="Logging In"
                                                setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                            />
                                        ))}
                                        {loggingOutDomains.map(domain => (
                                            <LinkedMarketplaceRow
                                                key={domain}
                                                domainName={domain}
                                                status="Logging Out"
                                                setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            {[...setupRequiredDomains, ...loggedOutOrNoAccountDomains].length > 0 && (
                                <Box sx={{ marginLeft: '32px', marginBottom: '11px' }}>
                                    <FoxTypography sx={{ marginBottom: '11px' }} variant="subtitle1">
                                        Connect a marketplace
                                    </FoxTypography>
                                    <Divider />
                                </Box>
                            )}
                            <Box>
                                {setupRequiredDomains.map(domain => (
                                    <ConnectAMarketplaceRow
                                        key={domain}
                                        domainName={domain}
                                        setupRequired={true}
                                        setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                    />
                                ))}
                                {loggedOutOrNoAccountDomains.map(domain => (
                                    <ConnectAMarketplaceRow
                                        key={domain}
                                        domainName={domain}
                                        setupRequired={false}
                                        setEbayInventoryLocationModalOpen={setEbayInventoryLocationModalOpen}
                                    />
                                ))}
                            </Box>
                        </>
                    ) : (
                        <ChromeExtensionNotInstalledMessage setExtensionReloadModalOpen={setExtensionReloadModalOpen} />
                    )}
                </Box>
            )}

            <EbayInventoryLocationModal open={ebayInventoryLocationModal} onClose={closeEbayInventoryLocationModal} />
            <ExtensionReloadModal open={extensionReloadModalOpen} onClose={closeExtensionReloadModal} />
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    }
}

export default MarketConnectionsScreen
