import { ImportListingSessionSchema, ImportListingSessionStatus } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { BaseStatusRow } from './BaseStatusRow'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'

type ImportListingSessionStatusRowProps = {
    importListingSession: ImportListingSessionSchema
}

const subtextMap: Record<ImportListingSessionStatus, string> = {
    'awaiting-user-input': 'Awaiting user input',
    'scraping-listings': 'Finding listings',
    importing: 'Importing listings',
    completed: 'Completed',
    draft: 'Not started'
}

export const ImportListingSessionStatusRow = ({ importListingSession }: ImportListingSessionStatusRowProps) => {
    const domain = importListingSession.sourceOfTruthMarket
    const status = importListingSession.status
    const text = `Importing listings from ${domain}`
    let subtext: string
    if (importListingSession.hasUserRequestedCancellation) {
        subtext = 'Cancelling'
    } else {
        subtext = subtextMap[status]
    }
    const image = <FoxImage src={CommonImages.markets[domain]} style={{ ...styles.image }} />

    // TODO: Link to import listing screen
    return <BaseStatusRow onFoxClick={{ kind: 'internal', to: '/app/import-listing' }} text={text} subtext={subtext} image={image} />
}

const styles = {
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    }
}
