import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box/Box'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxButton } from '../components/common/FoxButton'
import { Link, useMediaQuery, useTheme } from '@mui/material'
import { OnboardingHeader } from '../layouts/OnboardingHeader'
import { useNavigate } from 'react-router-dom'
import { Logger } from '@foxtail-dev/user-clients'
import { getDeviceType } from '../utils/getDeviceType'
import { FoxtailLogoAndNameIcon } from '../components/icons/FoxtailLogoAndName'
import { FoxtailIcon } from '../components/icons/FoxtailIcon'
import { generateToast } from '../lib/clients/ToastClient'
import { DownloadOnTheAppStoreIcon } from '../components/icons/DownloadOnTheAppStore'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { createLogoutUrl } from '../utils/createLogoutUrl'
import { FlexGrow } from '../components/common/FlexGrow'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'

const generateButtonBasedOnDeviceKind = (deviceKind: 'ios' | 'android' | 'web', onDownloadApp: () => Promise<void>) => {
    if (deviceKind === 'ios') {
        return (
            <Box onClick={onDownloadApp} sx={{ cursor: 'pointer' }}>
                <DownloadOnTheAppStoreIcon height={62} width={203} />
            </Box>
        )
    } else if (deviceKind === 'android') {
        return (
            <Box sx={{ cursor: 'pointer' }} onClick={onDownloadApp}>
                <FoxImage src={CommonImages.general.googlePlayStoreDownloadButton} style={{ height: '62px', width: '203px' }} />
            </Box>
        )
    } else {
        return (
            <FoxButton
                sx={{
                    justifyContent: 'center',
                    marginBottom: '33px',
                    height: '48px',
                    width: '90%',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px'
                }}
                primary
                text='Download Foxtail'
                variant='contained'
                onFoxClick={{
                    kind: 'button',
                    onClick: onDownloadApp,
                    preventDoubleClick: true
                }}
            />
        )
    }
}

export const MobileDownloadScreen = (): JSX.Element => {
    const theme = useTheme()
    const { logout } = useAuth0()
    const isMobile = useMediaQuery('(max-width:600px)')
    const navigate = useNavigate()
    const deviceKind = getDeviceType()

    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        // Initial calculation
        handleResize()

        // Recalculate on resize
        window.addEventListener('resize', handleResize)

        // Cleanup event listener
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const onLogout = async () => {
        await logout({
            logoutParams: {
                returnTo: createLogoutUrl(window.location.origin, 'logout')
            },
            clientId: FoxtailWebConfig.config.auth0.clientId
        })
    }

    const onManageSubscription = async () => {
        navigate('/subscription-mobile')
    }

    const onDownloadApp = async () => {
        if (deviceKind === 'ios') {
            window.open('https://apps.apple.com/us/app/foxtail-ai/id6443426423')
        } else if (deviceKind === 'android') {
            window.open('https://play.google.com/store/apps/details?id=ai.foxtail')
        } else {
            Logger.I().log({
                level: 'info',
                message: 'found device type to not be unknown or web',
                payload: {
                    kind: 'onDonwloadApp',
                    entry: {
                        deviceKind
                    }
                }
            })
            generateToast({ kind: 'error', message: 'Unable to open app store', subText: 'Please contact support' })
        }
    }

    useEffect(() => {
        if (!isMobile) {
            navigate('/app/home')
        }
    }, [isMobile])

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: 'calc(var(--vh, 1vh) * 100)', justifyContent: 'center' }}>
            <FlexGrow />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        marginBottom: '50px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        heigth: '48px'
                    }}
                >
                    <FoxtailIcon height={48} width={48} />
                    <FoxTypography variant='h3' sx={{ marginLeft: '17px', fontSize: '48px', lineHeight: '48px' }}>
                        {' '}
                        Foxtail
                    </FoxTypography>
                </Box>

                <FoxTypography
                    sx={{
                        textAlign: 'center',
                        lineHeight: '29px',
                        fontSize: '26px',
                        fontWeight: 500,
                        marginLeft: '38px',
                        marginRight: '38px',
                        marginBottom: '25px'
                    }}
                >
                    Looks like you're on a mobile device.
                </FoxTypography>

                <FoxTypography variant='body2' light sx={{ textAlign: 'center', lineHeight: '26px', marginLeft: '34px', marginRight: '34px' }}>
                    Foxtail isn't avaliable on mobile browsers. <br />
                    For full functionality, switch to desktop
                </FoxTypography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 1, minHeight: '0px', height: '168px' }}>
                    {generateButtonBasedOnDeviceKind(deviceKind, onDownloadApp)}
                </Box>
            </Box>
            <FlexGrow />
            <Box sx={{ marginTop: '16px', display: 'flex', marginLeft: '12px', marginRight: '12px' }}>
                <FoxButton
                    primary
                    sx={{ width: '100%', justifyContent: 'center', fontSize: '16px', height: '48px' }}
                    text='Manage subscription'
                    onFoxClick={{ kind: 'button', onClick: onManageSubscription }}
                />
            </Box>
            <Box sx={styles.containerAlreadyHaveAccount}>
                <FoxTypography>
                    Or, you can{' '}
                    <Link fontStyle={{ color: theme.palette.text.primary }} sx={{ cursor: 'pointer' }} onClick={onLogout}>
                        sign out
                    </Link>
                </FoxTypography>
            </Box>
        </Box>
    )
}

const styles = {
    containerAlreadyHaveAccount: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        marginTop: '18px',
        marginBottom: '60px'
    }
}
