import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'

type BulkCrosslistCancelConfirmationModalProps = {
    open: boolean
    onClose: (result: boolean) => void
}

export const BulkCrosslistCancelConfirmationModal = ({ open, onClose }: BulkCrosslistCancelConfirmationModalProps) => {
    return (
        <FoxModalDialog
            open={open}
            title={'Confirm submission'}
            leftButtonKind={'close'}
            onClose={() => onClose(false)}
            closeOnBackdropClick={true}
            actions={
                <>
                    <FoxButton
                        primary
                        text='Confirm'
                        onFoxClick={{ kind: 'button', onClick: () => onClose(true), preventDoubleClick: true }}
                        sx={{ height: '48px', width: '100%', justifyContent: 'center' }}
                    />
                    <FoxButton
                        grey
                        text='Go back'
                        onFoxClick={{ kind: 'button', onClick: () => onClose(false), preventDoubleClick: true }}
                        sx={{ height: '48px', width: '100%', justifyContent: 'center' }}
                    />
                </>
            }>
            <Box sx={{ minHeight: '120px' }}>
                <FoxTypography variant='body1'>
                    Are you sure you want to cancel your bulk crosslist? You will need to start over again from the beginning.
                </FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}
