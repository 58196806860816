import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ImportListingCancelInfo } from '@foxtail-dev/user-clients'
import { isLoggedIntoAMarketThatCanImport } from '../../utils/isLoggedIntoAMarketThatCanImport'
import { AreYouSureYouWantToCancelImport } from './AreYouSureYouWantToCancelImport'
import { CancelingImportListingSession } from './CancelingImportListingSession'
import { ImportingImportListingSession } from './ImportingImportListingSession'
import { ImportListingsEbayWarning } from './ImportListingEbayWarning'
import { ImportSuccess } from './ImportSuccess'
import { NewImportListingSession } from './NewImportListingSession'
import { NoLinkedImportMarkets } from './NoLinkedImportMarkets'
import { ScrapingListingsImportListingSession } from './ScrapingListingsImportListingSession'
import { UserInputImportListingSession } from './UserInputImportListingSession'
import { SetStateAction } from 'react'

export type RenderImportListingComponentProps = {
    loggedInMarkets: DomainCommonName[]
    importListingStatus: 'importing' | 'awaiting-user-input' | 'scraping-listings' | 'draft' | 'completed' | undefined
    importListingCancelInfo: ImportListingCancelInfo
    isRequestingCancel: boolean
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
    isPublishing: boolean
    setIsPublishing: React.Dispatch<React.SetStateAction<boolean>>
    isViewingEbayWarning: boolean
    setIsViewingEbayWarning: React.Dispatch<React.SetStateAction<boolean>>
}

export const renderImportListingComponent = (props: RenderImportListingComponentProps) => {
    const {
        loggedInMarkets,
        importListingStatus,
        importListingCancelInfo,
        isRequestingCancel,
        setIsRequestingCancel,
        isPublishing,
        setIsPublishing,
        isViewingEbayWarning,
        setIsViewingEbayWarning
    } = props

    if (isRequestingCancel) {
        return <AreYouSureYouWantToCancelImport setIsRequestingCancel={setIsRequestingCancel} />
    } else if (isLoggedIntoAMarketThatCanImport(loggedInMarkets) === false) {
        return <NoLinkedImportMarkets setIsRequestingCancel={setIsRequestingCancel} />
    } else if (importListingCancelInfo.isCancelling) {
        return <CancelingImportListingSession />
    } else if (isViewingEbayWarning) {
        return <ImportListingsEbayWarning setIsViewingEbayWarning={setIsViewingEbayWarning} />
    } else if (importListingStatus === 'scraping-listings' || importListingStatus === 'draft') {
        return <ScrapingListingsImportListingSession setIsRequestingCancel={setIsRequestingCancel} />
    } else if (importListingStatus === 'importing' || (isPublishing && importListingStatus === 'awaiting-user-input')) {
        return <ImportingImportListingSession setIsRequestingCancel={setIsRequestingCancel} />
    } else if (importListingStatus === 'awaiting-user-input') {
        return <UserInputImportListingSession setIsRequestingCancel={setIsRequestingCancel} setIsPublishing={setIsPublishing} />
    } else if (importListingStatus === 'completed' && isPublishing) {
        return <ImportSuccess />
    } else {
        return <NewImportListingSession loggedInMarkets={loggedInMarkets} setIsViewingEbayWarning={setIsViewingEbayWarning} />
    }
}
