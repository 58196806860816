import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '../../theme/Colors'
import { NotificationSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/Notification'
import { notificationDateFormatBasedOnDate } from '../../utils/notificationDateFromatBasedOnDate'
import FoxtailLogo from '../../assets/images/foxtailLogo.png'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'

type NotificationRowProps = {
    notification: NotificationSchema
    openModal: (notificationId: NotificationSchema) => void
}
export const NotificationRow = ({ notification, openModal }: NotificationRowProps): JSX.Element => {
    const isRead = notification.status === 'read'
    return (
        <FoxButtonBase
            onFoxClick={{ kind: 'button', onClick: async () => openModal(notification) }}
            sx={{
                ...styles.container,
                ...{
                    backgroundColor: isRead ? Colors.light.background : 'rgb(232, 113, 85, 0.05)'
                }
            }}>
            <Box sx={styles.containerIsRead}>{!isRead && <Box sx={styles.isNotRed} />}</Box>
            <Box sx={styles.containerLogo}>
                <Box
                    sx={{
                        height: '52px',
                        width: '52px',
                        borderRadius: '10px',
                        backgroundColor: isRead ? Colors.light.background : 'rgba(232,113,85,0.09)'
                    }}>
                    {/* TODO get center aligned Foxtail logo image */}
                    <Box component={'img'} src={FoxtailLogo} alt='Foxtail logo' style={styles.image} />
                </Box>
            </Box>
            <Box sx={styles.containerNotificationText}>
                <FoxTypography variant='body1' sx={styles.textTitle}>
                    {notification.body.title}
                </FoxTypography>
                <FoxTypography variant='body1' light>
                    {notificationDateFormatBasedOnDate(new Date(notification.createdAt))}
                </FoxTypography>
            </Box>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        height: '96px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },
    containerLogo: {
        height: '61px',
        width: '61px',
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
    },
    containerNotificationText: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: '15px'
    },
    containerIsRead: {
        width: '31px',
        display: 'flex',
        justifyContent: 'center'
    },
    isNotRed: {
        background: Colors.light.foxGradient,
        width: '9px',
        height: '9px',
        borderRadius: '4.5px'
    },
    image: {
        height: '52px',
        width: '52px',
        display: 'flex',
        borderRadius: '10px',
        justifySelf: 'center'
    },
    textTitle: {
        marginBottom: '2px'
    }
}
