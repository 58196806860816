import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

type GenerateMainTextForConnectAndSetupMarketsBeforeLinking = { title: string; subtitle: string }

export const generateMainTextForConnectAndSetupMarketsBeforeLinking = (params: {
    marketsNeedingSetup: DomainCommonName[]
    marketsNeedingLinking: DomainCommonName[]
}): GenerateMainTextForConnectAndSetupMarketsBeforeLinking => {
    if (params.marketsNeedingLinking.length === 1) {
        return {
            title: `${Domains[params.marketsNeedingLinking[0]].displayName} has not completed linking`,
            subtitle: `You can save the draft and publish once ${
                Domains[params.marketsNeedingLinking[0]].displayName
            } finishes linking. Contact support if your markets are not linking.`
        }
    } else if (params.marketsNeedingLinking.length > 1) {
        return {
            title: 'Selected markets still linking',
            subtitle: 'You can save the draft and publish once your markets finish linking. Contact support if your markets are not linking.'
        }
    } else if (params.marketsNeedingSetup.length === 1) {
        return {
            title: `${Domains[params.marketsNeedingLinking[0]].displayName} requires setup`,
            subtitle: 'Go to the market connection screen to finish setting up your market. Contact support if you need help.'
        }
    } else {
        // case params.marketsNeedingSetup.length > 1
        return {
            title: `Market setup required`,
            subtitle: 'Go to the market connection screen to finish setting up your markets. Contact support if you need help.'
        }
    }
}
