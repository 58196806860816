import { ButtonBase } from '@mui/material'
import { CSSProperties } from 'react'

export type FoxBadgeProps = {
    color: string
    textColor: string
    text: string
    style?: CSSProperties
}

export const FoxBadge = (props: FoxBadgeProps) => {
    const { color, textColor, text, style = {} } = props

    return <ButtonBase sx={[styles.badge, style, { backgroundColor: color, color: textColor }]}>{text}</ButtonBase>
}

const styles = {
    badge: {
        borderRadius: 4,
        padding: '4px 8px',
        fontSize: 12,
        fontWeight: 'bold',
        width: 'fit-content'
    }
}
