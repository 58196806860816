import { ExtendedDomainList } from '@foxtail-dev/user-clients'
import craigsListLogo from '../assets/images/markets/craigslist@3x.png'
import chronoLogo from '../assets/images/markets/chrono24@3x.png'
import depopLogo from '../assets/images/markets/depop@3x.png'
import facebookLogo from '../assets/images/markets/facebook@3x.png'
import grailedLogo from '../assets/images/markets/grailed@3x.png'
import kidizenLogo from '../assets/images/markets/kidizen@3x.png'
import mercariLogo from '../assets/images/markets/mercari@3x.png'
import poshmarkLogo from '../assets/images/markets/poshmark@3x.png'
import shopifyLogo from '../assets/images/markets/shopify@3x.png'
import stockXLogo from '../assets/images/markets/stockx@3x.png'
import tradesyLogo from '../assets/images/markets/tradesy@3x.png'
import vestiaireLogo from '../assets/images/markets/vestiaire@3x.png'
import ebayLogo from '../assets/images/markets/ebay@3x.png'
import etsyLogo from '../assets/images/markets/etsy@3x.png'
import foxtailWhiteLogo from '../assets/images/foxtail-white-icon.png'
import linkMarketsZero from '../assets/images/link-markets-zero@3x.png'
import checklistBanner from '../assets/images/checklist-banner@3x.png'
import zeroListingsHome from '../assets/images/listings-zero-home@3x.png'
import zeroListings from '../assets/images/listings-zero@3x-1.png'
import subscriptionExpired from '../assets/images/enable-notifications@3x-3.png' // TODO: Fix
import enableNotifications from '../assets/images/enable-notifications@3x-3.png'
import welcome from '../assets/images/welcome-image@3x.png'
import foxtailLogo from '../assets/images/foxtailLogo.png'
import googlePlayStoreDownloadButton from '../assets/images/GetItOnGooglePlay_Badge_Web_color_English.png'

// TODO: Move to user clients, consider listing the general images as well
type Images = {
    general: Record<string, any>
    markets: Record<ExtendedDomainList, any>
}

export const CommonImages: Images = {
    general: {
        whiteIcon: foxtailWhiteLogo,
        foxtailLogo: foxtailLogo,
        welcome: welcome,
        enableNotifications: enableNotifications,
        subscriptionExpired: subscriptionExpired,
        linkMarketsBanner: linkMarketsZero,
        zeroListingsHome: zeroListingsHome,
        zeroListings: zeroListings,
        googlePlayStoreDownloadButton: googlePlayStoreDownloadButton,
        checklistBanner: checklistBanner
    },
    markets: {
        'All markets': foxtailWhiteLogo,
        craigslist: craigsListLogo,
        chrono24: chronoLogo,
        depop: depopLogo,
        ebay: ebayLogo,
        etsy: etsyLogo,
        facebook: facebookLogo,
        grailed: grailedLogo,
        kidizen: kidizenLogo,
        mercari: mercariLogo,
        poshmark: poshmarkLogo,
        shopify: shopifyLogo,
        stockX: stockXLogo,
        tradesy: tradesyLogo,
        vestiaire: vestiaireLogo
    }
}

export type ImageType = keyof typeof CommonImages
