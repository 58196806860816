import { BaseStatusRow } from './BaseStatusRow'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'
import { BulkCrosslistingSessionSchema, BulkCrosslistingSessionStatus } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'

type BulkCrosslistingSessionStatusRowProps = {
    bulkCrosslistingSession: BulkCrosslistingSessionSchema
}

const subtextMap: Record<BulkCrosslistingSessionStatus, string> = {
    draft: 'Not started',
    generating: 'Generating market details',
    reviewing: 'Awaiting user input',
    listing: 'Listing on new markets',
    completed: 'Completed'
}

export const BulkCrosslistingSessionStatusRow = ({ bulkCrosslistingSession }: BulkCrosslistingSessionStatusRowProps) => {
    const destinationDomains = bulkCrosslistingSession.selectedCrosslistingDomains
    const status = bulkCrosslistingSession.status
    const text = `Crosslisting listings to ${destinationDomains.join(', ')}`
    let subtext: string
    if (bulkCrosslistingSession.hasUserRequestCancellation) {
        subtext = 'Cancelling'
    } else {
        subtext = subtextMap[status]
    }
    const src = destinationDomains[0] ? CommonImages.markets[destinationDomains[0]] : CommonImages.general.foxtailLogo
    const image = <FoxImage src={src} style={{ ...styles.image }} />

    return <BaseStatusRow onFoxClick={{ kind: 'internal', to: '/app/bulk-crosslist' }} text={text} subtext={subtext} image={image} />
}

const styles = {
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    }
}
