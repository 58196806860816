import { SvgIcon } from '@mui/material'

type CheckIconProps = {
    color?: string
    height?: number
    width?: number
}

export const CheckIcon = (props: CheckIconProps) => {
    const height = props.height ?? 16
    const width = props.width ?? 16

    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='17px' height='16px' viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>check-primary</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Subscription' transform='translate(-17.000000, -207.000000)' stroke={props.color ?? '#E87155'} strokeWidth='3'>
                        <polyline id='check-primary' points='19 214.512137 23.7478096 220.097543 32.8395906 208.25438'></polyline>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
