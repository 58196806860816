import { Colors, createImportListing, Logger, useAppDispatch } from '@foxtail-dev/user-clients'
import { Box, useTheme, Link } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'
import * as Intercom from '@intercom/messenger-js-sdk'
import { generateToast } from '../../lib/clients/ToastClient'
import { useState } from 'react'
import { assert } from '@foxtail-dev/datacontracts'

type ImportListingsEbayWarningProps = {
    setIsViewingEbayWarning: React.Dispatch<React.SetStateAction<boolean>>
}

export const ImportListingsEbayWarning = (props: ImportListingsEbayWarningProps) => {
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const [isCreatingImportListing, setIsCreatingImportListing] = useState<boolean>(false)

    const onReadMore = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User read more on ebay imports',
            payload: {
                kind: 'UserAction',
                entry: {
                    screen: 'Ebay Import warning Screen'
                }
            }
        })
        Intercom.showArticle('8563063')
    }

    const onGoBack = async () => {
        props.setIsViewingEbayWarning(false)
    }

    // TODO return a boolean when modal closes and do logic in the New Import listings session
    const onBeginEbayImport = async () => {
        try {
            if (isCreatingImportListing) return
            setIsCreatingImportListing(true)
            const response = await dispatch(createImportListing('ebay')).unwrap()
            props.setIsViewingEbayWarning(false)
            if (response.created) {
                Logger.I().log({
                    level: 'info',
                    message: 'User created import listing',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            sourceOfTruthMarket: 'ebay',
                            importListingSessionId: response.importListingSession._id
                        }
                    }
                })
            } else {
                // todo review that this logic is correct
                const importListingStatus = response.importListingSession.status
                assert(importListingStatus !== 'completed', 'ImportListingClient.getActiveListing returned a completed listing')
                Logger.I().log({
                    level: 'warning',
                    message: 'Tried to create import listing session when an import listing session already exists',
                    payload: {
                        kind: 'ImportListingSessionAlreadyExists',
                        entry: {
                            importListingSessionId: response.importListingSession._id,
                            importListingStatus
                        }
                    }
                })

                generateToast({ kind: 'info', message: 'Import listing session already exists', subText: 'Redirecting to existing import listing' })
            }
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'createImportListingError',
                        entry: {
                            sourceOfTruthMarket: 'ebay'
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error creating import listing' })
            setIsCreatingImportListing(false)
        }
        setIsCreatingImportListing(false)
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerInfoText}>
                <FoxImage src={CommonImages.markets.ebay} height={100} width={100} />
                <FoxTypography variant='h6' sx={styles.textInfo}>
                    Listings imported from eBay can be managed in Foxtail or eBay's web portal (browser). These listings can no longer be managed in the eBay
                    app.
                    <br />
                    <Link fontStyle={{ color: theme.palette.text.primary }} sx={{ cursor: 'pointer' }} onClick={onReadMore}>
                        Read more.
                    </Link>
                </FoxTypography>
            </Box>
            <FoxButton
                primary
                sx={styles.buttonImport}
                text='Import from eBay'
                onFoxClick={{
                    kind: 'button',
                    onClick: onBeginEbayImport,
                    preventDoubleClick: true
                }}
                loading={isCreatingImportListing}
            />
            <FoxButton
                grey
                sx={styles.buttonBack}
                text='Back'
                onFoxClick={{
                    kind: 'button',
                    onClick: onGoBack
                }}
            />
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerInfoText: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    textInfo: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    buttonImport: {
        width: '100%',
        justifyContent: 'center',
        marginBottom: '12px'
    },
    buttonBack: {
        width: '100%',
        justifyContent: 'center'
    }
}
