import { SvgIcon } from '@mui/material'

type BellIconProps = {
    color?: string
    height?: number
    width?: number
}

export const BellIcon = (props: BellIconProps) => {
    const height = props.height ?? 21
    const width = props.width ?? 19
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='19px' height='21px' viewBox='0 0 19 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>Group</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Home' transform='translate(-315.000000, -58.000000)'>
                        <g id='Group' transform='translate(315.000000, 58.000000)'>
                            <g id='notifications_FILL0_wght700_GRAD0_opsz48' fill={props.color ?? '#301A36'} fillRule='nonzero'>
                                <path
                                    d='M1.18844567,17.9754098 C0.841815681,17.9754098 0.557083906,17.8647541 0.334250344,17.6434426 C0.111416781,17.4221311 0,17.1393443 0,16.795082 C0,16.4672131 0.111416781,16.192623 0.334250344,15.9713115 C0.557083906,15.75 0.841815681,15.6393443 1.18844567,15.6393443 L2.30261348,15.6393443 L2.30261348,8.80327869 C2.30261348,7.29508197 2.74415406,5.92213115 3.62723521,4.68442623 C4.51031637,3.44672131 5.69463549,2.66393443 7.18019257,2.33606557 L7.18019257,1.74590164 C7.18019257,1.23770492 7.35350757,0.819672131 7.70013755,0.491803279 C8.04676754,0.163934426 8.47592847,0 8.98762036,0 C9.48280605,0 9.90784044,0.163934426 10.2627235,0.491803279 C10.6176066,0.819672131 10.7950481,1.23770492 10.7950481,1.74590164 L10.7950481,2.33606557 C12.2971114,2.66393443 13.4938102,3.44672131 14.3851444,4.68442623 C15.2764787,5.92213115 15.7221458,7.29508197 15.7221458,8.80327869 L15.7221458,15.6393443 L16.8115543,15.6393443 C17.1416781,15.6393443 17.4222834,15.75 17.65337,15.9713115 C17.8844567,16.192623 18,16.4754098 18,16.8196721 C18,17.147541 17.8844567,17.4221311 17.65337,17.6434426 C17.4222834,17.8647541 17.1416781,17.9754098 16.8115543,17.9754098 L1.18844567,17.9754098 Z M9.01237964,21 C8.41815681,21 7.90233838,20.7868852 7.46492435,20.3606557 C7.02751032,19.9344262 6.8088033,19.4262295 6.8088033,18.8360656 L11.1911967,18.8360656 C11.1911967,19.442623 10.9766162,19.954918 10.5474553,20.3729508 C10.1182944,20.7909836 9.60660248,21 9.01237964,21 Z M4.62998624,15.6393443 L13.3700138,15.6393443 L13.3700138,8.80327869 C13.3700138,7.57377049 12.9532325,6.53688525 12.1196699,5.69262295 C11.2861073,4.84836066 10.258597,4.42622951 9.03713893,4.42622951 C7.79917469,4.42622951 6.75515818,4.84836066 5.90508941,5.69262295 C5.05502063,6.53688525 4.62998624,7.57377049 4.62998624,8.80327869 L4.62998624,15.6393443 Z'
                                    id='icon-notification'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
