import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { AiListingConfigDetails } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import _ from 'lodash'

export const createListingDefaultsConfig = (existingValues: AiListingConfigDetails, domains: DomainCommonName[]): AiListingConfigDetails => {
    // TODO: Think if there's a better way to do this
    const newConfig = _.cloneDeep(existingValues)

    // Only add markets that aren't in there, don't get rid of any
    domains.forEach((domain) => {
        if (!newConfig.domainDetails[domain]) {
            newConfig.domainDetails[domain] = {
                domain,
                suggestionDefaults: {
                    requiredDetails: {},
                    optionalDetails: {}
                }
            }
        }
    })

    return newConfig
}
