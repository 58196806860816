import { Colors } from '@foxtail-dev/user-clients'
import { SelectChangeEvent, Box, FormControl, Select, MenuItem } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxIconButton } from '../../components/common/FoxIconButton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ChevronLeftIcon } from '../../components/icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { ChevronDownIcon } from '../../components/icons/ChevronDownIcon'
import { RowsPerPage } from './RowsPerPage'

// Select requires that you pass in the IconType, instead of an instance of an icon, so we create a custom icon component with the right props set by default
const CustomChevronDownIcon = () => {
    return <ChevronDownIcon width={12} color={Colors.light.tertiary} />
}

type ListingTablePaginationProps = {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    onRowsPerPageChange: (rowsPerPage: RowsPerPage) => void
    hasMoreListings: boolean
    showAllListings: boolean
}

export const ListingTablePagination = (props: ListingTablePaginationProps) => {
    const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange, hasMoreListings, showAllListings } = props

    const handleBackButtonClick = async () => {
        onPageChange(page - 1)
    }

    const handleNextButtonClick = async () => {
        onPageChange(page + 1)
    }

    const handleRowsPerPageChange = (event: SelectChangeEvent) => {
        onRowsPerPageChange(RowsPerPage.parse(event.target.value))
    }

    const highestListingIndex = page * rowsPerPage + count

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '32px', width: '100%', marginTop: '24px', marginBottom: '120px' }}>
            <FoxTypography variant='body2' sx={{ marginTop: '2px', lineHeight: '26px', marginRight: '10px' }}>
                Rows per page
            </FoxTypography>
            <Box sx={{ width: '62px' }}>
                <FormControl fullWidth>
                    <Select
                        disabled={showAllListings}
                        value={rowsPerPage.toString()}
                        onChange={handleRowsPerPageChange}
                        IconComponent={CustomChevronDownIcon}
                        SelectDisplayProps={{
                            style: {
                                fontSize: '16px',
                                lineHeight: '24px',
                                fontWeight: '400',
                                letterSpacing: '0.01071em',
                                textOverflow: 'clip'
                            }
                        }}
                        sx={{
                            height: '32px',
                            backgroundColor: '#FAF7FA',
                            borderRadius: '10px',
                            border: 'none',
                            '& .MuiSvgIcon-root': {
                                position: 'absolute',
                                right: '12px',
                                top: 'calc(50% - 5px)',
                                pointerEvents: 'none'
                            },
                            '& fieldset': {
                                border: 'none'
                            }
                        }}>
                        {RowsPerPage.options.map(rowsPerPageOption => (
                            <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                                {rowsPerPageOption}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <FlexGrow />
            <FoxTypography variant='body2' sx={{ marginTop: '2px', marginRight: '14px' }}>
                {page * rowsPerPage + 1} - {highestListingIndex}
            </FoxTypography>
            <FoxIconButton onFoxClick={{ kind: 'button', onClick: handleBackButtonClick }} sx={styles.paginationLeft} disabled={page === 0}>
                <ChevronLeftIcon height={12} color={Colors.light.tertiary} />
            </FoxIconButton>
            <FoxIconButton onFoxClick={{ kind: 'button', onClick: handleNextButtonClick }} sx={styles.paginationRight} disabled={!hasMoreListings}>
                <ChevronRightIcon height={12} color={Colors.light.tertiary} />
            </FoxIconButton>
        </Box>
    )
}

const styles = {
    paginationRight: {
        width: '35px',
        height: '32px',
        backgroundColor: Colors.light.unfocusedInput,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px'
    },
    paginationLeft: {
        width: '35px',
        height: '32px',
        backgroundColor: Colors.light.unfocusedInput,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}
