import { PoshmarkListingDetailsDraft, PoshmarkColor } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { sanitizeZodObject, getPoshmarkCategoryIdFromCategoryNames } from '@foxtail-dev/user-clients'
import React, { useMemo, useState } from 'react'
import { Collapsible } from '../../../../components/common/Collapsible'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { FoxTextField } from '../../../../components/formik/FoxTextField'
import { PriceField } from '../../../../components/formik/PriceField'
import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { PoshmarkCategoryModal } from '../../../../modals/domainSpecific/PoshmarkCategoryModal'
import { useTaxonomyProvider } from '../../../../lib/TaxonomyProvider'

export const PoshmarkFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props
    const taxonomyProvider = useTaxonomyProvider()
    const poshmarkTaxonomy = taxonomyProvider?.poshmark

    const [modalOpen, setModalOpen] = useState<string>('none')

    const poshmarkDetails = useMemo(() => {
        return sanitizeZodObject(values, PoshmarkListingDetailsDraft)
    }, [values])
    const requiredDetails = poshmarkDetails.requiredDetails
    const poshmarkCategoryNames = requiredDetails.categoryNames ?? []

    const onCloseModal = () => {
        setModalOpen('none')
    }

    // TODO: Consider saving poshmark categoryId instead of recreating it from categoryNames
    const poshmarkSizeCategoriesOptions = useMemo(() => {
        if (poshmarkCategoryNames.length === 0 || !poshmarkTaxonomy) {
            return []
        }

        const categoryId = getPoshmarkCategoryIdFromCategoryNames(poshmarkCategoryNames)

        const sizes = poshmarkTaxonomy.tree.getNodeById(categoryId).data.sizes
        const poshmarkSizeCategories = sizes ? sizes.map((size) => size.sizeCategoryName) : []

        if (poshmarkSizeCategories && poshmarkSizeCategories.length > 0) {
            return poshmarkSizeCategories
        } else {
            return []
        }
    }, [poshmarkCategoryNames, poshmarkTaxonomy])

    const poshmarkSizeOptions = useMemo(() => {
        if (poshmarkCategoryNames.length === 0 || !requiredDetails?.sizeCategoryName || !poshmarkTaxonomy) {
            return []
        }

        const categoryId = getPoshmarkCategoryIdFromCategoryNames(poshmarkCategoryNames)

        const sizes = poshmarkTaxonomy.tree.getNodeById(categoryId).data.sizes
        const sizeOptions = sizes ? sizes.filter((size) => size.sizeCategoryName === requiredDetails?.sizeCategoryName)[0].sizes : []
        return sizeOptions
    }, [poshmarkCategoryNames, poshmarkSizeCategoriesOptions, poshmarkTaxonomy])

    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.poshmark.requiredDetails.categoryNames'
                label='Category'
                placeholder='Select Category'
                renderValue={(value) => (value ? <FoxTypography variant='body1'>{value.join(', ')}</FoxTypography> : null)}
                onDetail={() => setModalOpen('PoshmarkCategory')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'PoshmarkCategory'} title={'Category'} closeModal={onCloseModal}>
                <PoshmarkCategoryModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            {poshmarkCategoryNames.length > 0 && (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.poshmark.requiredDetails.sizeCategoryName'
                        label='Size Category'
                        placeholder='Select Size Category'
                        renderValue={(value) => (value ? <FoxTypography variant='body1'>{value}</FoxTypography> : null)}
                        onDetail={() => setModalOpen('PoshmarkSizeCategory')}
                    />

                    <DetailSelectionModal
                        open={modalOpen === 'PoshmarkSizeCategory'}
                        headerTitle={'Size category'}
                        options={poshmarkSizeCategoriesOptions}
                        paramName={'sizeCategoryName'}
                        domainName={'poshmark'}
                        isRequired={true}
                        currentValue={requiredDetails?.sizeCategoryName}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            )}

            {poshmarkCategoryNames.length > 0 && requiredDetails.sizeCategoryName && (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.poshmark.requiredDetails.size'
                        label='Size '
                        placeholder='Select Size'
                        renderValue={(value) => (value ? <FoxTypography variant='body1'>{value}</FoxTypography> : null)}
                        onDetail={() => setModalOpen('PoshmarkSize')}
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'PoshmarkSize'}
                        headerTitle={'Size category'}
                        options={poshmarkSizeOptions}
                        paramName={'size'}
                        domainName={'poshmark'}
                        isRequired={true}
                        currentValue={requiredDetails?.size}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            )}

            <PriceField
                name='domainSpecificDetails.poshmark.requiredDetails.originalPrice'
                label='Original Price'
                logOnBlur
                initialValue={requiredDetails.originalPrice}
            />

            <Collapsible label='More Details' buttonStyle={{ fontWeight: 'bold' }}>
                <>
                    <FoxTextField
                        name='domainSpecificDetails.poshmark.optionalDetails.brand'
                        label='Brand'
                        placeholder='Your brand'
                        titleSubtext='(optional)'
                        value={poshmarkDetails.optionalDetails?.brand}
                        logOnBlur
                    />

                    <RadioBoolGroupField
                        label='New with tags'
                        name='domainSpecificDetails.poshmark.optionalDetails.tagsAttached'
                        iconType='Radio'
                        options={[
                            [true, 'Yes'],
                            [false, 'No']
                        ]}
                        titleSubtext='(optional)'
                        logOnChange
                    />

                    <DetailSelectionField
                        name='domainSpecificDetails.poshmark.optionalDetails.primaryColor'
                        label='Primary color'
                        placeholder='Select primary color'
                        onDetail={() => setModalOpen('PoshmarkPrimaryColor')}
                        titleSubtext='(optional)'
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'PoshmarkPrimaryColor'}
                        headerTitle={'Primary color'}
                        options={PoshmarkColor.options}
                        paramName={'primaryColor'}
                        domainName={'poshmark'}
                        isRequired={false}
                        currentValue={poshmarkDetails.optionalDetails?.primaryColor}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />

                    <DetailSelectionField
                        name='domainSpecificDetails.poshmark.optionalDetails.secondaryColor'
                        label='Secondary color'
                        placeholder='Select secondary color'
                        onDetail={() => setModalOpen('PoshmarkSecondaryColor')}
                        titleSubtext='(optional)'
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'PoshmarkSecondaryColor'}
                        headerTitle={'Secondary color'}
                        options={PoshmarkColor.options}
                        paramName={'secondaryColor'}
                        domainName={'poshmark'}
                        isRequired={false}
                        currentValue={poshmarkDetails.optionalDetails?.secondaryColor}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            </Collapsible>
        </>
    )
}
