import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

type SetupNeededModalProps = {
    open: boolean
    onClose: (isConfirmed: boolean) => void
    domainName: DomainCommonName
}

export const SetupNeededModal = ({ open, onClose, domainName }: SetupNeededModalProps) => {
    return (
        <FoxModalDialog open={open} title={'Account needs setup'} leftButtonKind={'close'} onClose={() => onClose(false)} closeOnBackdropClick>
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    Your {Domains[domainName].displayName} account is not properly setup
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginBottom: '40px' }}>
                    For account requirements{' '}
                    <Link href='https://help.foxtail.ai/en/articles/8054964-marketplace-setup-and-requirements-etsy-mercari' target='_blank' rel='noopener'>
                        click here.
                    </Link>
                </FoxTypography>

                <FoxButton
                    primary
                    onFoxClick={{ kind: 'button', onClick: async () => onClose(true) }}
                    text={'I fixed my account, retry linking'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
