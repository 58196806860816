import { SvgIcon } from '@mui/material'

type PlusIconProps = {
    color?: string
    height?: number
    width?: number
}

export const PlusIcon = (props: PlusIconProps) => {
    const height = props.height ?? 21
    const width = props.width ?? 21
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>icon-add</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Listings' transform='translate(-356.000000, -58.000000)' fill={props.color ?? '#301A36'} fillRule='nonzero'>
                        <g id='icon-add' transform='translate(356.000000, 58.000000)'>
                            <path
                                d='M10.5,21 C10.0286195,21 9.63383838,20.8409091 9.31565657,20.5227273 C8.99747475,20.2045455 8.83838384,19.8097643 8.83838384,19.3383838 L8.83838384,12.1616162 L1.66161616,12.1616162 C1.19023569,12.1616162 0.795454545,12.0025253 0.477272727,11.6843434 C0.159090909,11.3661616 0,10.9713805 0,10.5 C0,10.0286195 0.159090909,9.63383838 0.477272727,9.31565657 C0.795454545,8.99747475 1.19023569,8.83838384 1.66161616,8.83838384 L8.83838384,8.83838384 L8.83838384,1.66161616 C8.83838384,1.19023569 8.99747475,0.795454545 9.31565657,0.477272727 C9.63383838,0.159090909 10.0286195,0 10.5,0 C10.9713805,0 11.3661616,0.159090909 11.6843434,0.477272727 C12.0025253,0.795454545 12.1616162,1.19023569 12.1616162,1.66161616 L12.1616162,8.83838384 L19.3383838,8.83838384 C19.8097643,8.83838384 20.2045455,8.99747475 20.5227273,9.31565657 C20.8409091,9.63383838 21,10.0286195 21,10.5 C21,10.9713805 20.8409091,11.3661616 20.5227273,11.6843434 C20.2045455,12.0025253 19.8097643,12.1616162 19.3383838,12.1616162 L12.1616162,12.1616162 L12.1616162,19.3383838 C12.1616162,19.8097643 12.0025253,20.2045455 11.6843434,20.5227273 C11.3661616,20.8409091 10.9713805,21 10.5,21 Z'
                                id='Path'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
