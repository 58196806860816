import { Box, SvgIcon } from '@mui/material'

type MoreIconProps = {
    height?: number
    width?: number
}
export const MoreIcon = (props: MoreIconProps) => {
    const { height, width } = props
    return (
        <SvgIcon viewBox='0 0 14 4' style={{ height, width }}>
            <svg width={'14px'} height={'4px'} viewBox='0 0 14 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>icon-more</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Marketplace-conections' transform='translate(-353.000000, -213.000000)' fill='#301A36' fillRule='nonzero'>
                        <g id='Group-4' transform='translate(0.000000, 167.000000)'>
                            <g id='Group-3' transform='translate(342.000000, 32.000000)'>
                                <g id='icon-more' transform='translate(11.928000, 14.156000)'>
                                    <path
                                        d='M3.264,1.608 C3.264,1.824 3.226,2.03 3.15,2.226 C3.074,2.422 2.964,2.594 2.82,2.742 C2.676,2.89 2.504,3.008 2.304,3.096 C2.104,3.184 1.88,3.228 1.632,3.228 C1.376,3.228 1.148,3.184 0.948,3.096 C0.748,3.008 0.578,2.89 0.438,2.742 C0.298,2.594 0.19,2.422 0.114,2.226 C0.038,2.03 0,1.824 0,1.608 C0,1.392 0.038,1.186 0.114,0.99 C0.19,0.794 0.298,0.624 0.438,0.48 C0.578,0.336 0.748,0.22 0.948,0.132 C1.148,0.044 1.376,0 1.632,0 C1.88,0 2.104,0.044 2.304,0.132 C2.504,0.22 2.676,0.336 2.82,0.48 C2.964,0.624 3.074,0.794 3.15,0.99 C3.226,1.186 3.264,1.392 3.264,1.608 Z'
                                        id='Path'
                                    ></path>
                                    <path
                                        d='M7.704,1.608 C7.704,1.824 7.666,2.03 7.59,2.226 C7.514,2.422 7.404,2.594 7.26,2.742 C7.116,2.89 6.944,3.008 6.744,3.096 C6.544,3.184 6.32,3.228 6.072,3.228 C5.816,3.228 5.588,3.184 5.388,3.096 C5.188,3.008 5.018,2.89 4.878,2.742 C4.738,2.594 4.63,2.422 4.554,2.226 C4.478,2.03 4.44,1.824 4.44,1.608 C4.44,1.392 4.478,1.186 4.554,0.99 C4.63,0.794 4.738,0.624 4.878,0.48 C5.018,0.336 5.188,0.22 5.388,0.132 C5.588,0.044 5.816,0 6.072,0 C6.32,0 6.544,0.044 6.744,0.132 C6.944,0.22 7.116,0.336 7.26,0.48 C7.404,0.624 7.514,0.794 7.59,0.99 C7.666,1.186 7.704,1.392 7.704,1.608 Z'
                                        id='Path'
                                    ></path>
                                    <path
                                        d='M12.144,1.608 C12.144,1.824 12.106,2.03 12.03,2.226 C11.954,2.422 11.844,2.594 11.7,2.742 C11.556,2.89 11.384,3.008 11.184,3.096 C10.984,3.184 10.76,3.228 10.512,3.228 C10.256,3.228 10.028,3.184 9.828,3.096 C9.628,3.008 9.458,2.89 9.318,2.742 C9.178,2.594 9.07,2.422 8.994,2.226 C8.918,2.03 8.88,1.824 8.88,1.608 C8.88,1.392 8.918,1.186 8.994,0.99 C9.07,0.794 9.178,0.624 9.318,0.48 C9.458,0.336 9.628,0.22 9.828,0.132 C10.028,0.044 10.256,0 10.512,0 C10.76,0 10.984,0.044 11.184,0.132 C11.384,0.22 11.556,0.336 11.7,0.48 C11.844,0.624 11.954,0.794 12.03,0.99 C12.106,1.186 12.144,1.392 12.144,1.608 Z'
                                        id='Path'
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
