import { SvgIcon } from '@mui/material'

type ExtensionIconProps = {
    color?: string
    height?: number
    width?: number
}

export const ExtensionIcon = (props: ExtensionIconProps) => {
    const height = props.height ?? 33
    const width = props.width ?? 40
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='40px' height='33px' viewBox='0 0 40 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>Group</title>
                <defs>
                    <linearGradient x1='32.8097502%' y1='8.15374792%' x2='79.7850204%' y2='73.9843885%' id='linearGradient-1'>
                        <stop stopColor='#E84856' offset='0%'></stop>
                        <stop stopColor='#E77155' offset='100%'></stop>
                    </linearGradient>
                </defs>
                <g id='Web-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='extension-install' transform='translate(-31, -862)'>
                        <g id='Group' transform='translate(31, 862)'>
                            <rect id='Rectangle' stroke='#BA897E' strokeWidth='3' fill='#FFFFFF' x='1.5' y='4.5' width='32' height='27' rx='6.5'></rect>
                            <rect id='Rectangle' fill='#BA897E' x='3' y='6' width='29' height='8'></rect>
                            <circle id='Oval' fill='#FFF2EF' cx='31' cy='9' r='9'></circle>
                            <path
                                d='M36.8290154,5.17677708 C36.6388681,4.99206375 36.3421685,4.94731995 36.1002277,5.06687219 L31.0000555,7.5934213 L25.8998833,5.06687219 C25.6580946,4.94664486 25.3609532,4.99159254 25.171363,5.17707324 C24.9817729,5.36255394 24.9455339,5.64376048 25.0825136,5.86652498 L27.2822984,9.43906542 L26.0743628,10.0391208 C25.8949813,10.1255587 25.7720056,10.2892646 25.7452313,10.4772625 C25.718457,10.6652605 25.7912851,10.8536711 25.9401478,10.9815236 L30.5490929,14.8534602 C30.6600718,14.946518 30.8031145,14.9985774 30.951738,15 L30.9933447,15 L31.0362935,15 C31.1848706,14.9983498 31.3278315,14.9463202 31.4389387,14.8534602 L36.058621,10.9815236 C36.2083299,10.8551469 36.2828974,10.6678365 36.2582836,10.4799796 C36.2336697,10.2921227 36.1129873,10.1274763 35.9351431,10.0391208 L34.7272076,9.43906542 L36.9175974,5.86652498 C37.0543428,5.64365497 37.0182427,5.36255946 36.8290154,5.17677708 Z M27.4,7.27272727 L29.8,8.45796676 L28.5192182,9.09090909 L27.4,7.27272727 Z M27.4,10.7803468 L28.1124528,10.4545455 L29.32,12.2727273 L27.4,10.7803468 Z M31.2349038,13.1818182 L29.32,10.0448538 L31.24,9.09090909 L33.16,10.0448538 L31.2349038,13.1818182 Z M33.16,12.2727273 L34.3675472,10.4545455 L35.08,10.7803468 L33.16,12.2727273 Z M33.9514286,9.09090909 L32.68,8.45637584 L35.08,7.27272727 L33.9514286,9.09090909 Z'
                                id='Shape'
                                fill='url(#linearGradient-1)'
                                fillRule='nonzero'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
