import {
    UserEngagementBoosterKind,
    UserEngagementBoostersConfigData
} from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'

export const createInitialEngagementBoosterConfig = <T extends UserEngagementBoosterKind>(currentBooster: T): UserEngagementBoostersConfigData[T] => {
    switch (currentBooster) {
        case UserEngagementBoosterKind.Enum.poshmarkShareCloset:
            return {
                shouldShareUsersCloset: false,
                sharePeriodMs: 24 * 60 * 60 * 1000 // 24 hours
            }
        default:
            throw new Error(`Unknown booster kind: ${currentBooster}`)
    }
}
