import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxTypography } from '../common/FoxTypography'
import { ArrowRightIcon } from '../icons/ArrowRightIcon'
import { FoxImage } from '../common/FoxImage'
import { FoxButtonBase } from '../common/FoxButtonBase'

export const CreateFirstListingBanner = () => {
    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/create-listing/new' }} sx={styles.container}>
            <Box sx={styles.containerText}>
                <FoxTypography variant='h6' sx={styles.textTitle}>
                    Create your first listing
                </FoxTypography>
                <FoxTypography variant='body2' light sx={styles.textSubtitle}>
                    Crosslist to any of your linked markets
                </FoxTypography>

                <Box sx={styles.containerCreateYourFirstListing}>
                    <FoxTypography variant='subtitle1' sx={styles.textCreateYourFirstListing}>
                        Create your first listing
                    </FoxTypography>
                    <ArrowRightIcon height={16} width={16} color={Colors.light.primary} />
                </Box>
            </Box>
            <Box sx={styles.containerImage}>
                <FoxImage src={CommonImages.general.zeroListingsHome} style={styles.image} />
            </Box>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        height: '214px',
        backgroundColor: Colors.light.unfocusedInput,
        borderColor: Colors.light.divider,
        borderRadius: '10px',
        borderWidth: '1px',
        borderStyle: 'solid',
        marginBottom: '33px',
        flexDirection: 'row',
        display: 'flex',
        cursor: 'pointer'
    },
    containerText: {
        flex: 1
    },
    containerCreateYourFirstListing: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '22.23px',
        alignItems: 'center'
    },
    containerImage: {
        height: '214px',
        flex: 1,
        justifyContent: 'flex-end',
        display: 'flex'
    },
    image: {
        height: '214px'
    },
    textCreateYourFirstListing: {
        color: Colors.light.primary,
        marginRight: '9.5px',
        lineHeight: '24px'
    },
    textTitle: {
        marginTop: '37px',
        marginLeft: '22.23px'
    },
    textSubtitle: {
        lineHeight: '26px',
        marginLeft: '22.23px',
        marginBottom: '44px'
    }
}
