import { FileRejection, DropEvent } from 'react-dropzone'
import { FoxTypography } from '../common/FoxTypography'
import { AddVideoDropzone } from '../upload/AddVideoDropzone'
import { StageContentAndActions } from '../../screens/listing/ListingsFromVideoScreen'

type WaitingForVideoContentProps = {
    onAddVideo: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void
}

export const SelectingVideoContent = ({ onAddVideo }: WaitingForVideoContentProps): StageContentAndActions => {
    const actions = null

    const content = (
        <>
            <AddVideoDropzone onAddVideo={onAddVideo} />
            <FoxTypography variant='body2' sx={{ marginTop: '16px' }}>
                Upload a video of your items to create listings.
                <br />
                {/* {Click here to learn more.} */}
            </FoxTypography>
            <FoxTypography light variant='body1'>
                Your video can be 100MB or less and in the following formats: .mp4, .mov, .avi, .wmv, .flv, .mkv, .webm
            </FoxTypography>
        </>
    )

    return {
        actions,
        content
    }
}
