import { Box, useMediaQuery } from '@mui/material'
import { FoxCircleLoader } from '../components/LoadingCircle'

// TODO: Improve loading screen
export const LoadingScreen = () => {
    const isMobile = useMediaQuery('(max-width:600px)')
    return (
        <Box sx={styles.container(isMobile)}>
            <FoxCircleLoader size={60} />
        </Box>
    )
}

const styles = {
    container: (isMobile: boolean) => ({
        background: 'white',
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0
    })
}
