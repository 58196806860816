import { Colors } from '@foxtail-dev/user-clients'
import { Box, Menu, MenuItem } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { MoreIcon } from '../icons/MoreIcon'
import { useState } from 'react'
import { FoxIconButton } from '../common/FoxIconButton'
import { ListingRowAction } from './BaseListingRow'

type ListingActionsButtonProps = {
    actions: ListingRowAction[]
}

// TODO: Make ListingSummaryHeaderRow use this component
export const ListingActionsButton = ({ actions }: ListingActionsButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // TODO: There is a weird bug where if you click away from the menu, it propagates to the listingRow parent component onClick, even if the click is not where the listingRow appears to be
    // This is a way to avoid it. A better way may be to separate the menu from the button component, but that also seems harder to manage.
    // This way works, but it means that the first click only dismisses the menu, even if you would like to click something else right away. The second click will work as expected.
    const handleClickAway = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null)
        event.stopPropagation()
    }

    return (
        <>
            <FoxIconButton
                onFoxClick={{ kind: 'button', onClick: handleClick }}
                sx={{
                    backgroundColor: Colors.light.unfocusedInput,
                    height: '32px',
                    width: '35px',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <MoreIcon height={16} width={14} />
            </FoxIconButton>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                onClick={handleClickAway}
                slotProps={{
                    paper: {
                        sx: {
                            width: '204px',
                            maxHeight: '400px',
                            borderRadius: '10px',
                            overflow: 'visible'
                        }
                    }
                }}>
                {actions.map((action, index) => (
                    <MenuItem key={index} onClick={action.onAction}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px' }}>
                            <Box sx={{ marginLeft: '21px', marginTop: '8px', marginRight: '22px' }}>{action.icon}</Box>
                            <FoxTypography sx={{ marginTop: '6px' }}>{action.label}</FoxTypography>
                        </Box>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
