import { useState } from 'react'
import { FoxTextField } from './FoxTextField'

type DimensionInputFieldProps = {
    name: string
    label: string
    logOnBlur?: boolean
    suffix: string
    initialValue?: string
}

export const DimensionInputField = (params: DimensionInputFieldProps) => {
    const { name, label, logOnBlur, initialValue } = params
    const [value, setValue] = useState<string | undefined>(initialValue)

    // Update the locally stored value after each keystroke
    const transformText = (inputtedValue: string): string => {
        setValue(inputtedValue)
        return inputtedValue
    }

    const onBlur = (value: string): string => {
        const valueAsFloat = parseFloat(value)
        if (!isFinite(valueAsFloat)) {
            return value
        }

        // Round to 2 places
        const newValue = (Math.round(valueAsFloat * 100) / 100).toString()
        setValue(newValue)
        return newValue
    }

    return (
        <FoxTextField
            name={name}
            label={label}
            labelVariant='body1'
            placeholder='0'
            suffix={params.suffix}
            value={value}
            onBlur={onBlur}
            transformText={transformText}
            logOnBlur={logOnBlur}
        />
    )
}
