import { UserActionSuccessResult } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/UserActionDescriptions'
import { UploadVideoActionDescription } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/concreteSchemas/user'
import { IVideoClient, IVideoClientFactory, VideoClientFactoryParams } from '@foxtail-dev/user-clients'
import { SubmitVideoUploadCompleteResponse, VideoClient } from './VideoClient'

export class VideoClientFactory implements IVideoClientFactory {
    create = async (params: VideoClientFactoryParams): Promise<IVideoClient> => {
        const { executeActionRequest, getVideoDownloadUrl } = params

        const createVideoDocAndUploadUrl = async () => {
            const executedAction = await executeActionRequest<UploadVideoActionDescription>({
                kind: 'user/uploadVideo',
                params: {
                    kind: 'getUploadUrl'
                }
            })

            if (!executedAction.wasSuccessfullyExecuted) {
                throw new Error('expected upload image result')
            }
            if (executedAction.successResult.kind !== 'gotUploadUrl') {
                throw new Error('expected gotUploadUrl result kind')
            }
            const result: UserActionSuccessResult<UploadVideoActionDescription> = executedAction.successResult
            return result
        }

        const submitUploadComplete = async (videoId: string): SubmitVideoUploadCompleteResponse => {
            const executedAction = await executeActionRequest<UploadVideoActionDescription>({
                kind: 'user/uploadVideo',
                params: {
                    kind: 'submitUploadComplete',
                    videoId
                }
            })

            if (!executedAction.wasSuccessfullyExecuted) {
                throw new Error('expected upload image result')
            }
            if (executedAction.successResult.kind !== 'uploadCompleted') {
                throw new Error('expected gotUploadUrl result kind')
            }
            const result: UserActionSuccessResult<UploadVideoActionDescription> = executedAction.successResult
            return result
        }

        return new VideoClient({
            createVideoDocAndUploadUrl,
            getVideoDownloadUrl,
            submitUploadComplete
        })
    }
}
