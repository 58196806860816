import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { SaveIcon } from '../icons/SaveIcon'

type CancellingBulkCrosslistProps = {}

export const CancellingBulkCrosslist = (props: CancellingBulkCrosslistProps) => {
    return (
        <Box sx={styles.container}>
            <SaveIcon color={Colors.light.tertiary} height={70} width={200} />
            <FoxTypography variant='h6' sx={styles.text}>
                Your bulk crosslist session is cancelling. <br />
                You can start a new bulk crosslist once it finishes
            </FoxTypography>
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    text: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    }
}
