import { Box, Divider, Menu, MenuItem } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { MoreIcon } from '../../components/icons/MoreIcon'
import { Colors } from '../../theme/Colors'
import { CommonImages } from '../../theme/CommonImages'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import {
    DomainLoginStatus,
    Logger,
    MarketInfo,
    selectIsSyncingInProgressByDomain,
    selectUser,
    setDomainLoginStatus,
    signOutOfMarket,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { SignOutIcon } from '../../components/icons/SignOutIcon'
import { useEffect, useState } from 'react'
import { EditIcon } from '../../components/icons/EditIcon'
import { FoxImage } from '../../components/common/FoxImage'
import { AreYouSureYouWantToSignOutModal } from '../../modals/account/marketConnections/AreYouSureYouWantToSignOutModal'
import { generateToast } from '../../lib/clients/ToastClient'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { ShimmerText } from '../../components/common/ShimmerText'
import { FoxIconButton } from '../../components/common/FoxIconButton'
import { BetaChip } from './BetaChip'

type LinkedMarketplaceRowProps = {
    domainName: DomainCommonName
    status: DomainLoginStatus
    setEbayInventoryLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const LinkedMarketplaceRow = (props: LinkedMarketplaceRowProps): JSX.Element => {
    const { domainName, status, setEbayInventoryLocationModalOpen } = props
    const isSyncing = useAppSelector(selectIsSyncingInProgressByDomain[props.domainName])
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [accountName, setAccountName] = useState<string | null>(user?.domainDetails[domainName]?.accountName || null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const onUpdateInventoryLocation = () => {
        setEbayInventoryLocationModalOpen(true)
    }

    const onSignOut = async () => {
        setAnchorEl(null)
        setModalOpen(true)
    }

    const closeModal = async (result: boolean) => {
        setModalOpen(false)

        if (result) {
            try {
                // if (isSigningOut) return
                // setIsSigningOut(true)
                dispatch(setDomainLoginStatus({ domainName, loginStatus: 'logging-out' }))
                await dispatch(signOutOfMarket(domainName)).unwrap()
                // await clearCookiesByDomainName(domainName)

                Logger.I().log({
                    level: 'info',
                    message: 'User signed out of market',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            domainName
                        }
                    }
                })
            } catch (error) {
                generateToast({ kind: 'info', message: 'Error signing out of ' + Domains[domainName].displayName })
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Error signing out of market',
                        payload: {
                            kind: 'SignOutError',
                            entry: {
                                domainName
                            }
                        }
                    },
                    error
                )
            }

            // setIsSigningOut(false)
        }
    }
    useEffect(() => {
        setAccountName(user?.domainDetails[domainName]?.accountName || null)
    }, [user?.domainDetails[domainName]?.accountName])

    // Todo seems like the rows don't get rerendered when the account name changes
    return (
        <>
            <Box sx={styles.container}>
                <Box sx={styles.containerDomainImage}>
                    <FoxImage src={CommonImages.markets[domainName]} alt={domainName} style={styles.domainImage} />
                </Box>
                <Box
                    sx={{
                        marginTop: '24px',
                        marginBottom: '30px',
                        display: 'flex',
                        justifyContent: isSyncing === false && !!accountName ? undefined : 'center',
                        flexDirection: 'column'
                    }}
                >
                    <FoxTypography variant='subtitle1' sx={{ marginBottom: '2px' }}>
                        {MarketInfo[domainName].displayName}
                    </FoxTypography>

                    {status === 'Logged In' &&
                        (isSyncing ? (
                            <FoxTypography variant='body1' light sx={{  width: '100%', height: '100%' }}>
                                Syncing account details
                            </FoxTypography>
                        ) : !accountName ? (
                            <Box sx={{ backgroundColor: 'red', width: '100%', height: '100%' }}></Box>
                        ) : (
                            <FoxTypography variant='body1' light sx={{ width: '100%', height: '100%' }}>
                                {user?.domainDetails[domainName]?.accountName}
                            </FoxTypography>
                        ))}
                </Box>
                {domainName === 'facebook' && <BetaChip />}
                <FlexGrow />
                <Box>
                    {status === 'Logging In' ? (
                        <ShimmerText sx={styles.shimmerTextPrimary}>Linking</ShimmerText>
                    ) : status === 'Logging Out' ? (
                        <ShimmerText sx={styles.shimmerTextPrimary}>Logging out</ShimmerText>
                    ) : (
                        <FoxTypography sx={styles.textLinked}>Linked</FoxTypography>
                    )}
                </Box>
                {status === 'Logged In' ? (
                    <FoxIconButton
                        onFoxClick={{ kind: 'button', onClick: handleClick }}
                        sx={{
                            height: '32px',
                            width: '35px',
                            borderRadius: '10px',
                            backgroundColor: Colors.light.unfocusedInput,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '32px',
                            marginRight: '20px'
                        }}
                    >
                        <MoreIcon height={5} />
                    </FoxIconButton>
                ) : (
                    <Box
                        sx={{
                            height: '32px',
                            width: '35px',
                            borderRadius: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '32px',
                            marginRight: '20px'
                        }}
                    />
                )}
                <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                width: domainName === 'ebay' ? '256px' : '204px',
                                maxHeight: '400px',
                                borderRadius: '10px',
                                overflow: 'visible' // Prevents scroll view
                            }
                        }
                    }}
                >
                    {domainName === 'ebay' && (
                        <MenuItem onClick={onUpdateInventoryLocation}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px' }}>
                                <Box sx={{ marginTop: '8px', marginRight: '22px' }}>
                                    <EditIcon height={24} width={24} />
                                </Box>
                                <FoxTypography sx={{ marginTop: '6px' }}>Manage inventory locations</FoxTypography>
                            </Box>
                        </MenuItem>
                    )}
                    <MenuItem onClick={onSignOut}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px' }}>
                            <Box sx={{ marginTop: '8px', marginRight: '22px' }}>
                                <SignOutIcon height={24} width={24} />
                            </Box>
                            <FoxTypography variant='body1' sx={{ marginTop: '8px' }}>
                                Sign out
                            </FoxTypography>
                        </Box>
                    </MenuItem>
                </Menu>
            </Box>
            <Divider sx={{ marginLeft: '31px' }} />
            {modalOpen &&
                AreYouSureYouWantToSignOutModal({
                    domainName,
                    onClose: closeModal,
                    open: modalOpen
                })}
        </>
    )
}

const styles = {
    container: {
        flexDirection: 'row',
        display: 'flex',
        height: 96,
        marginLeft: '31px'
    },
    containerDomainImage: {
        height: '52px',
        width: '52px',
        marginLeft: '15px',
        marginTop: '18px',
        marginRight: '26px',
        borderRadius: '10px'
    },
    domainImage: {
        borderRadius: '10px',
        height: '52px',
        width: '52px'
    },
    shimmerTextPrimary: {
        marginTop: '38px',
        marginRight: '15px',
        color: Colors.light.primary
    },
    textLinked: {
        marginTop: '38px',
        marginRight: '15px',
        color: Colors.light.success
    }
}
