import moment from 'moment'

export const isDateWithinTheLastHour = (date: Date) => {
    const now = new Date()
    const hourAgo = new Date(now)
    hourAgo.setHours(hourAgo.getHours() - 1)
    return moment(date).isAfter(hourAgo)
}

export const getMinsSinceDate = (date: Date) => {
    const now = new Date()
    return moment(now).diff(moment(date), 'minutes')
}

export const notificationDateFormatBasedOnDate = (date: Date): string => {
    if (isDateWithinTheLastHour(date)) {
        return getMinsSinceDate(date) + 'm ago'
    } else if (moment(date).isSame(new Date(), 'day')) {
        return moment(date).format('h:mm a')
    } else {
        return moment(date).format('MMMM D ')
    }
}
