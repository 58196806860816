import {
    useAppSelector,
    selectImportListingPublishedListingsCount,
    selectImportListingPublishingListingsCount,
    selectIsImportListingPublishing,
    selectImportListingDomain
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxCircleLoader } from '../LoadingCircle'
import { FoxTypography } from '../common/FoxTypography'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FlexGrow } from '../common/FlexGrow'
import { FoxButton } from '../common/FoxButton'

type ImportingImportListingSession = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const ImportingImportListingSession = (props: ImportingImportListingSession) => {
    const publishedListingsCount = useAppSelector(selectImportListingPublishedListingsCount)
    const totalPublishingCount = useAppSelector(selectImportListingPublishingListingsCount)
    const sourceOfTruthMarket = useAppSelector(selectImportListingDomain)

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerLoading}>
                <FoxCircleLoader size={60} />
                <FoxTypography variant='h6' sx={styles.textLoading}>
                    Importing {publishedListingsCount} of {totalPublishingCount} listings{' '}
                    {sourceOfTruthMarket ? `from ${Domains[sourceOfTruthMarket].displayName}` : ''}
                </FoxTypography>
            </Box>
            <FoxButton
                grey
                sx={styles.button}
                text='Cancel import'
                onFoxClick={{
                    kind: 'button',
                    onClick: async () => {
                        props.setIsRequestingCancel(true)
                    }
                }}
            />
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    textLoading: {
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    button: {
        width: '100%',
        justifyContent: 'center'
    }
}
