import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Colors } from '@foxtail-dev/user-clients'
import { Box, useTheme } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'
import { PlusIcon } from '../icons/PlusIcon'
import { FoxButtonBase } from '../common/FoxButtonBase'

type LinkedMarketCardsProps = {
    authedOrAuthingDomains: (
        | {
              domainName: 'etsy' | 'poshmark' | 'mercari' | 'depop' | 'facebook' | 'ebay'
              authStatus: 'Logged In'
          }
        | {
              domainName: 'etsy' | 'poshmark' | 'mercari' | 'depop' | 'facebook' | 'ebay'
              authStatus: 'Logging In'
          }
    )[]
}

export const LinkedMarketCards = (props: LinkedMarketCardsProps) => {
    const { authedOrAuthingDomains } = props

    const theme = useTheme()

    return (
        authedOrAuthingDomains.length > 0 && (
            <Box sx={styles.container}>
                <FoxTypography variant='subtitle2' sx={styles.textTitle}>
                    Linked marketplaces
                </FoxTypography>
                <Box sx={styles.containerRow}>
                    {authedOrAuthingDomains.map(({ domainName, authStatus }) => (
                        <FoxButtonBase key={'DomainImg-' + domainName} onFoxClick={{ kind: 'internal', to: '/app/account/marketplace-connections' }}>
                            <Box key={domainName} sx={styles.containerMarketImage}>
                                <Box
                                    sx={{
                                        ...styles.marketImage,
                                        backgroundColor: authStatus === 'Logged In' ? theme.palette.success.main : theme.palette.warning.main,
                                        borderColor: theme.palette.background.default
                                    }}
                                />
                                <FoxImage src={CommonImages.markets[DomainCommonName.parse(domainName)]} alt={domainName} style={styles.image} />
                            </Box>
                        </FoxButtonBase>
                    ))}
                    <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/account/marketplace-connections' }} sx={styles.plusIcon}>
                        <PlusIcon height={10.5} width={10.5} />
                    </FoxButtonBase>
                </Box>
            </Box>
        )
    )
}

const styles = {
    container: {
        marginTop: '46px',
        height: '116px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '10px',
        marginBottom: '80px'
    },
    containerRow: {
        flexDirection: 'row',
        display: 'flex',
        marginLeft: '24px'
    },
    containerMarketImage: {
        marginRight: '17.5px',
        height: '35px',
        width: '35px',
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer'
    },
    textTitle: {
        marginTop: '19px',
        marginLeft: '24px',
        marginBottom: '18px'
    },
    plusIcon: {
        borderStyle: 'dashed',
        borderWidth: '2px',
        borderRadius: '8px',
        height: '35px',
        width: '35px',
        borderColor: Colors.light.chevronGrey,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginRight: '17.5',
        cursor: 'pointer'
    },
    marketImage: {
        width: '9px',
        height: '9px',
        borderWidth: '1px',
        borderRadius: '4.5px',
        borderStyle: 'solid',
        position: 'absolute',
        top: '-3px',
        right: '-4px'
    },
    image: {
        borderRadius: '10px',
        height: '100%',
        width: '100%'
    }
}
