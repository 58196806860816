import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { clearDepopSizeInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

export const DepopCategoryModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = (result: CategorySimilarityMatch) => {
        updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
            paramName: 'categoryId',
            domainName: 'depop',
            isRequired: true,
            value: result.categoryId
        })

        clearDepopSizeInFormik(setFieldValue, setFieldTouched)

        closeModal()
    }

    return (
        <CategorySelection
            title='Depop Category'
            renderItem={(item) => <DetailSelection key={item.categoryId} onDetail={() => handleSave(item)} value={item.categoryNameArray.join(', ')} />}
            domain='depop'
        />
    )
}
