import { Box, Menu, MenuItem } from '@mui/material'
import { useAppDispatch, ANALYTIC_CHART_KINDS, updateChartStatus, AnalyticsChartKind, useAppSelector, activeChartsSelector } from '@foxtail-dev/user-clients'
import { useState } from 'react'
import { FoxButton } from '../common/FoxButton'
import { ChevronDownIcon } from '../icons/ChevronDownIcon'
import { FoxCheckbox } from '../common/FoxCheckbox'
import { Logger } from '../../lib/clients/Logger'

type EditChartsMenuProps = {}

export const EditChartsMenu = ({}: EditChartsMenuProps) => {
    const dispatch = useAppDispatch()

    const activeCharts = useAppSelector(activeChartsSelector)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChecked = (event: React.ChangeEvent<Element>, analyticsChartKind: AnalyticsChartKind) => {
        dispatch(updateChartStatus(analyticsChartKind))
        event.stopPropagation()

        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'UserAction',
                entry: { analyticsChartKind }
            },
            message: 'User selected chart to display'
        })
    }

    return (
        <Box>
            <FoxButton
                id='EditChartsMenuButton'
                variant='outlined'
                text={'Select charts'}
                onFoxClick={{ kind: 'button', onClick: handleClick }}
                endIcon={<ChevronDownIcon />}
                sx={{ height: '48px' }}
            />
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'EditChartsMenuButton'
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: '200px',
                            maxHeight: '400px',
                            borderRadius: '10px',
                            overflow: 'visible'
                        }
                    }
                }}
            >
                {ANALYTIC_CHART_KINDS.map((kind) => {
                    const isActive = activeCharts.includes(kind)

                    return (
                        <MenuItem key={kind} disableRipple>
                            <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px' }}>
                                <FoxCheckbox
                                    checked={isActive}
                                    iconType={'Checkbox'}
                                    onChange={(event: React.ChangeEvent<Element>) => handleChecked(event, kind)}
                                    label={kind}
                                />
                            </Box>
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}
