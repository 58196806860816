import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'

type ListingDefaultRowProps = {
    isSet: boolean
    domainName: DomainCommonName
    editListingDefault: () => void
}

export const ListingDefaultRow = (props: ListingDefaultRowProps) => {
    const { isSet: isSet, domainName, editListingDefault } = props

    return (
        <FoxButtonBase
            onFoxClick={{
                kind: 'button',
                onClick: async () => {
                    editListingDefault()
                }
            }}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '80px' }}>
            <Box sx={styles.containerDomainIcon}>
                <FoxImage src={CommonImages.markets[domainName]} alt={Domains[domainName].displayName} style={styles.domainIcon} />
            </Box>
            <FoxTypography variant='body2' sx={{ marginLeft: '25px' }}>
                {Domains[domainName].displayName}
            </FoxTypography>
            <FlexGrow />
            {isSet ? (
                <FoxTypography variant='body1' sx={styles.activeText}>
                    Completed
                </FoxTypography>
            ) : (
                <FoxTypography variant='body1' sx={styles.inActiveText}>
                    Not set
                </FoxTypography>
            )}
        </FoxButtonBase>
    )
}

const styles = {
    containerChevron: {
        marginRight: '23px'
    },
    activeText: {
        marginRight: '21px',
        fontSize: '12px',
        lineHeight: '16px',
        color: Colors.light.success
    },
    inActiveText: {
        marginRight: '21px',
        fontSize: '12px',
        lineHeight: '16px',
        color: Colors.light.lightText
    },
    containerDomainIcon: {
        height: '48px',
        width: '48px',
        marginLeft: '24px'
    },
    domainIcon: {
        borderRadius: '10px',
        height: '48px',
        width: '48px'
    }
}
