import { assert } from '@foxtail-dev/datacontracts'
import { UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { initializeTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { Logger } from '../../lib/clients/Logger'

export const setTaxonomyProviderAsync = async () => {
    const runtimeContext = await UserRuntimeContextProvider.getContext()
    const taxonomies = runtimeContext.taxonomies
    assert(taxonomies, 'taxonomies not initialized')

    taxonomies
        .then((taxonomyDict) => {
            Logger.I().log({
                message: 'Taxonomy provider initialized',
                level: 'info',
                payload: {
                    kind: 'System'
                }
            })

            initializeTaxonomyProvider(taxonomyDict)
        })
        .catch((error) => {
            Logger.I().log(
                {
                    message: 'Failed to initialize taxonomy provider',
                    level: 'error',
                    payload: {
                        kind: 'System'
                    }
                },
                error
            )
        })
}
