import { SvgIcon } from '@mui/material'

type ZeroNotificationsIconProps = {
    color?: string
    height?: number
    width?: number
}

export const ZeroNotificationsIcon = (props: ZeroNotificationsIconProps) => {
    const height = props.height ?? 115
    const width = props.width ?? 98
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='98px' height='115px' viewBox='0 0 98 115' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>7D49ADF1-19F8-45A7-A5ED-DCCF1AD074B1</title>
                <defs>
                    <linearGradient x1='40.233107%' y1='23.961673%' x2='82.0493705%' y2='100%' id='linearGradient-1'>
                        <stop stopColor='#F4F1F5' offset='0%'></stop>
                        <stop stopColor='#FBF9FC' offset='100%'></stop>
                    </linearGradient>
                </defs>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g
                        id='Notifications-zero'
                        transform='translate(-146.000000, -301.000000)'
                        fill={props.color ?? 'url(#linearGradient-1)'}
                        fillRule='nonzero'
                    >
                        <g id='zero-notifications' transform='translate(146.000000, 301.000000)'>
                            <path
                                d='M6.52973865,98.4367681 C4.62545621,98.4367681 3.06109583,97.8229411 1.8366575,96.5952869 C0.612219166,95.3675429 0,93.8413915 0,92.0168326 C0,90.1921838 0.612219166,88.6738876 1.8366575,87.4619438 C3.06109583,86.25 4.60568547,85.6440281 6.47042641,85.6440281 L12.5364512,85.6440281 L12.5364512,48.2084309 C12.5364512,39.9416276 14.9403943,32.4212022 19.7482806,25.6471546 C24.5561669,18.8730172 31.0041265,14.5694945 39.0921596,12.7365867 L39.0921596,9.56088993 C39.0921596,6.82279469 40.0457836,4.54478923 41.9530316,2.72687354 C43.8602797,0.908957845 46.176243,0 48.9009216,0 C51.6255103,0 53.952033,0.908957845 55.8804897,2.72687354 C57.8088565,4.54478923 58.7730399,6.82279469 58.7730399,9.56088993 L58.7730399,12.7365867 C66.9509399,14.5694945 73.4662999,18.8730172 78.3191197,25.6471546 C83.1719395,32.4212022 85.5983494,39.9416276 85.5983494,48.2084309 L85.5983494,85.6440281 L91.5295736,85.6440281 C93.2932141,85.6440281 94.8125062,86.2578552 96.0874498,87.4855094 C97.3624833,88.7132533 98,90.2394048 98,92.0639637 C98,93.8886124 97.3624833,95.4069087 96.0874498,96.6188525 C94.8125062,97.8307963 93.2932141,98.4367681 91.5295736,98.4367681 L6.52973865,98.4367681 Z M49,115 C45.8142137,115 43.029459,113.839676 40.6457359,111.519028 C38.2620128,109.19838 37.0701513,106.408665 37.0701513,103.149883 L60.9298487,103.149883 C60.9298487,106.471507 59.7511976,109.276932 57.3938955,111.566159 C55.0365034,113.855386 52.2385383,115 49,115 Z M25.2077029,85.6440281 L72.7922971,85.6440281 L72.7922971,48.2084309 C72.7922971,41.4754098 70.5231545,35.7972287 65.9848693,31.1738876 C61.4465841,26.5505464 55.8298945,24.2388759 49.1348006,24.2388759 C42.4397066,24.2388759 36.7780834,26.5505464 32.1499312,31.1738876 C27.521779,35.7972287 25.2077029,41.4754098 25.2077029,48.2084309 L25.2077029,85.6440281 Z'
                                id='Shape'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
