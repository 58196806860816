import { useAppDispatch } from '@foxtail-dev/user-clients'
import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { assert } from '@foxtail-dev/datacontracts'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { clearPoshmarkSizeCategoryNameInFormik, clearPoshmarkSizeInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

export const PoshmarkCategoryModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const dispatch = useAppDispatch()
    const taxonomyProvider = useTaxonomyProvider()
    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    // TODO: Save categoryId instead the categoryNameArray
    const handleSave = (result: CategorySimilarityMatch) => {
        assert(taxonomyProvider)

        clearPoshmarkSizeCategoryNameInFormik(setFieldValue, setFieldTouched)
        clearPoshmarkSizeInFormik(setFieldValue, setFieldTouched)
        const node = taxonomyProvider.poshmark.tree.getNodeById(result.categoryId)
        updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
            paramName: 'categoryNames',
            domainName: 'poshmark',
            isRequired: true,
            value: node.id.split(':')
        })

        closeModal()
    }

    return (
        <CategorySelection
            title='Poshmark Category'
            renderItem={(item) => <DetailSelection key={item.categoryId} onDetail={() => handleSave(item)} value={item.categoryNameArray.join(', ')} />}
            domain='poshmark'
        />
    )
}
