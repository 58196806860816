import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { BulkCrosslistingSessionData } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import {
    DomainListingDetailsDraftDict,
    DomainListingDetailsDict,
    ListingDetailsDraft
} from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { EbayListingDetailsDraft, EtsyListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import {
    useAppDispatch,
    useAppSelector,
    selectBulkCrosslistingSessionData,
    selectBulkCrosslistingUserSelectedListingByListingId,
    selectListingById,
    selectListingDetails,
    RuleFailure,
    selectBulkCrosslistingDomains,
    selectCurrentEbayConditions,
    selectCurrentEtsyAttributes,
    selectCurrentEbayAspects,
    maybeGetEbayAspects,
    getEbayConditions,
    getEbayFulfillmentPolicies,
    getEtsyConditionalAttributes,
    getSelectedMarkets,
    createNewListingDetails,
    runExtraRules,
    Logger,
    createDefaultDomainSpecificDetailsDict,
    setListingDetails,
    setRuleFailureFormikState,
    addBulkCrosslistItems,
    clearBulkCrosslistFailedListingId,
    clearListingDetails,
    removeBulkCrosslistItem,
    normalizePrice
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FormikHelpers, Formik, FormikProps } from 'formik'
import { useState, useCallback, useRef, useEffect } from 'react'
import { MarketSpecificFieldsMap } from '../../containers/forms/domainSpecificFields/createListing/MarketSpecificFieldsMap'
import { MarketSpecificFieldsLayout } from '../../layouts/MarketSpecificFieldsLayout'
import { generateToast } from '../../lib/clients/ToastClient'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { sharedStyles } from '../../theme/SharedStyling'
import { shouldDisplayErrorMessage } from '../../utils/shouldDisplayErrorMessage'
import { CreateListingSkeleton } from '../../components/skeletons/CreateListingSkeleton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButton } from '../../components/common/FoxButton'
import { useNavigate, useParams } from 'react-router-dom'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { BaseListingRow } from '../../components/listing/BaseListingRow'

// Add the extra layer so that we can reuse the formik fields easier
// This makes it so that they have the same name path, e.g. domainSpecificDetails.etsy.requiredDetails.categoryIds
type BulkCrosslistEditItemFormProps = {
    domainSpecificDetails: DomainListingDetailsDraftDict
}

export const BulkCrosslistEditItemScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const listingId = params.listingId ?? ''
    const data = useAppSelector(selectBulkCrosslistingSessionData)
    const userSelectedListing = useAppSelector(selectBulkCrosslistingUserSelectedListingByListingId(listingId))
    const listing = useAppSelector(selectListingById(listingId))
    const listingDetails = listing?.listingDescription.listingDetails
    const [isRejecting, setIsRejecting] = useState(false)
    const editListingDetails = useAppSelector(selectListingDetails)
    const domainSpecificDetails = editListingDetails?.domainSpecificDetails
    const [extraRuleFailures, setExtraRuleFailures] = useState<RuleFailure[]>([])
    const destinationDomainsForSession = useAppSelector(selectBulkCrosslistingDomains)
    const [destinationDomainsForItem, setDestinationDomainsForItem] = useState<DomainCommonName[]>([])
    const currentEbayConditions = useAppSelector(selectCurrentEbayConditions)
    const currentEtsyAttributes = useAppSelector(selectCurrentEtsyAttributes)
    const currentEbayAspects = useAppSelector(selectCurrentEbayAspects)
    const taxonomyProvider = useTaxonomyProvider()

    const [hasEverythingLoaded, setHasEverythingLoaded] = useState(false)

    const loadDomainSpecificAspects = (domainDetailsDraft: DomainListingDetailsDraftDict) => {
        const promises: Promise<any>[] = []
        const isEbayListing = destinationDomainsForItem.includes(DomainCommonName.Enum.ebay)
        if (isEbayListing) {
            const ebayDetails = EbayListingDetailsDraft.parse(domainDetailsDraft.ebay)
            promises.push(loadEbayAspectsAsync(ebayDetails))
        }

        const isEtsyListing = destinationDomainsForItem.includes(DomainCommonName.Enum.etsy)
        if (isEtsyListing) {
            const etsyDetails = EtsyListingDetailsDraft.parse(domainDetailsDraft.etsy)
            promises.push(loadEtsyAttributesAsync(etsyDetails))
        }

        Promise.all(promises)
            .then(() => {
                Logger.I().log({
                    level: 'info',
                    message: 'Loaded domain specific aspects',
                    payload: {
                        kind: 'BulkCrosslistAspectsLoaded',
                        entry: {
                            listingId,
                            bulkCrosslistingSessionId: data?.bulkCrosslistingSessionId,
                            domainSpecificDetails: domainDetailsDraft,
                            destinationDomainsForItem
                        }
                    }
                })
                setHasEverythingLoaded(true)
            })
            .catch((error) => {
                Logger.I().log(
                    {
                        level: 'error',
                        payload: {
                            kind: 'loadDomainSpecificAspectsFailed',
                            entry: {
                                listingId,
                                bulkCrosslistingSessionId: data?.bulkCrosslistingSessionId,
                                domainSpecificDetails: domainDetailsDraft,
                                destinationDomainsForItem
                            }
                        }
                    },
                    error
                )
                generateToast({ kind: 'error', message: `Error while loading the item's details. Please try again` })
            })
    }

    // TODO: Consider combining these functions
    const loadEbayAspectsAsync = async (ebayDetails: EbayListingDetailsDraft) => {
        const category = ebayDetails.requiredDetails.category
        const currentAspects = ebayDetails.requiredDetails.aspects

        if (category) {
            await Promise.all([
                dispatch(maybeGetEbayAspects({ categoryId: category.id, currentAspects })).unwrap(),
                dispatch(getEbayConditions({ categoryId: category.id, categoryName: category.name })).unwrap(),
                dispatch(getEbayFulfillmentPolicies()).unwrap()
            ])
        }
    }

    const loadEtsyAttributesAsync = async (etsyDetails: EtsyListingDetailsDraft) => {
        const categoryIds = etsyDetails.requiredDetails.categoryIds
        if (categoryIds) {
            await dispatch(getEtsyConditionalAttributes(categoryIds[categoryIds.length - 1])).unwrap()
        }
    }

    // Set the initial state of the form based on the original listing details and the domain specific details generated by the AI
    useEffect(() => {
        if (
            domainSpecificDetails ||
            !data ||
            listing?.listingDescription.kind !== 'active' ||
            !listing?.listingDescription.listingDetails ||
            !taxonomyProvider ||
            hasEverythingLoaded
        ) {
            return
        }

        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'BCEditItemInitialLoad',
                entry: {
                    listingId,
                    bulkCrosslistingSessionId: data.bulkCrosslistingSessionId
                }
            },
            message: 'Starting initial load of bulk crosslist edit item screen'
        })

        const listingDetails = listing?.listingDescription.listingDetails
        const sessionData = new BulkCrosslistingSessionData(data)
        const succeededGeneration = sessionData.successfulGenerationListingIds.find((succeeded) => succeeded === listingId)

        let domainDetailsDraft: DomainListingDetailsDraftDict
        const listingDomains = getSelectedMarkets(listingDetails)
        const destinationDomainsForItem = destinationDomainsForSession.filter((domain) => !listingDomains.includes(domain))
        setDestinationDomainsForItem(destinationDomainsForItem)

        const willRunExtraRules = userSelectedListing?.readyToCrosslistDetails || succeededGeneration
        if (willRunExtraRules) {
            let details: DomainListingDetailsDict
            if (userSelectedListing?.readyToCrosslistDetails) {
                details = userSelectedListing.readyToCrosslistDetails // These details are the ones that the user modified, they take priority
            } else {
                details = sessionData.extractSuccessfulGenerationDetails(listingId) // Otherwise, use the details generated by the AI
            }

            const newListingDetails = createNewListingDetails(listingDetails, details)

            const runExtraRulesResponse: RuleFailure[] = runExtraRules({
                values: newListingDetails,
                currentEbayConditions,
                currentEtsyConditionalAttributes: currentEtsyAttributes,
                requiredEbayAspects: currentEbayAspects,
                domains: destinationDomainsForItem,
                taxonomies: taxonomyProvider
            })

            if (runExtraRulesResponse.length > 0) {
                Logger.I().log({
                    level: 'info',
                    message: 'Failed to pass extra rules on initial load',
                    payload: {
                        kind: 'BulkCrosslistExtraRuleError',
                        entry: {
                            listingId,
                            bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                            listingDetails: newListingDetails,
                            failedRules: runExtraRulesResponse,
                            domains: destinationDomainsForItem
                        }
                    }
                })
            }

            setExtraRuleFailures(runExtraRulesResponse)

            domainDetailsDraft = details
        } else {
            domainDetailsDraft = {} // If the generation failed altogether, start with default details

            destinationDomainsForItem.forEach((domain) => {
                domainDetailsDraft[domain] = createDefaultDomainSpecificDetailsDict[domain]()
            })
        }

        const listingDetailsDraft: ListingDetailsDraft = {
            domainSpecificDetails: domainDetailsDraft
        }

        dispatch(setListingDetails({ listingId, listingDetails: listingDetailsDraft }))

        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'BCEditItemInitialLoadSuccess',
                entry: {
                    listingId,
                    bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                    domainSpecificDetails: domainDetailsDraft,
                    destinationDomainsForItem
                }
            },
            message: 'Initial load of bulk crosslist edit item screen successful'
        })

        loadDomainSpecificAspects(domainDetailsDraft)
    }, [domainSpecificDetails, taxonomyProvider, listing, hasEverythingLoaded])

    if (!hasEverythingLoaded || !listing || !data || !taxonomyProvider || !domainSpecificDetails) {
        return <CreateListingSkeleton />
    }

    const imageIds = listingDetails?.imageIds ?? []

    const title = listingDetails?.commonDetails?.title ?? 'Title'
    const price = listingDetails?.commonDetails?.price ?? '0.00'

    const onSubmit = async (values: BulkCrosslistEditItemFormProps, actions: FormikHelpers<BulkCrosslistEditItemFormProps>) => {
        const { setFieldError, setFieldTouched, setStatus } = actions

        Logger.I().log({
            level: 'info',
            message: 'User is attempting to approve listing',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId,
                    bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                    domainSpecificDetails: values.domainSpecificDetails,
                    destinationDomainsForItem
                }
            }
        })

        const parseResult = DomainListingDetailsDict.safeParse(values.domainSpecificDetails)

        if (!parseResult.success) {
            parseResult.error.issues.forEach((issue) => {
                const field = 'domainSpecificDetails.' + issue.path.join('.')
                setFieldError(field, issue.message)
                setFieldTouched(field)
            })
            Logger.I().log(
                {
                    level: 'info',
                    message: 'Failed to parse domain specific details',
                    payload: {
                        kind: 'BulkCrosslistApproveError',
                        entry: {
                            listingId,
                            bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                            domainSpecificDetails: values.domainSpecificDetails,
                            destinationDomainsForItem
                        }
                    }
                },
                parseResult.error
            )
            return
        }

        const parsedDomainListingDetails = parseResult.data

        if (listing?.listingDescription.kind !== 'active' || !listing?.listingDescription.listingDetails) {
            Logger.I().log({
                level: 'info',
                message: 'listing is not active or did not have listing details',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                        domainSpecificDetails: parsedDomainListingDetails,
                        destinationDomainsForItem,
                        listing
                    }
                }
            })
            return
        }
        const listingDetails = listing.listingDescription.listingDetails
        const newListingDetails = createNewListingDetails(listingDetails, parsedDomainListingDetails)

        const runExtraRulesResponse: RuleFailure[] = runExtraRules({
            values: newListingDetails,
            currentEbayConditions,
            currentEtsyConditionalAttributes: currentEtsyAttributes,
            requiredEbayAspects: currentEbayAspects,
            domains: destinationDomainsForItem,
            taxonomies: taxonomyProvider
        })

        if (runExtraRulesResponse.length > 0) {
            setRuleFailureFormikState({
                ruleFailures: runExtraRulesResponse,
                setFieldError,
                setFieldTouched,
                setStatus,
                createStatusString: true
            })
            setExtraRuleFailures(runExtraRulesResponse)
            Logger.I().log({
                level: 'info',
                message: 'Failed to pass extra rules when approving',
                payload: {
                    kind: 'BulkCrosslistExtraRuleError',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                        listingDetails: newListingDetails,
                        failedRules: runExtraRulesResponse,
                        destinationDomainsForItem
                    }
                }
            })
            return
        }

        try {
            await dispatch(addBulkCrosslistItems([{ listingId, details: parsedDomainListingDetails }])).unwrap()
            dispatch(clearBulkCrosslistFailedListingId(listingId))
            dispatch(clearListingDetails())

            Logger.I().log({
                level: 'info',
                message: 'User approved listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                        domainSpecificDetails: parsedDomainListingDetails,
                        destinationDomainsForItem
                    }
                }
            })
            navigate(-1)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'editBulkCrosslistItemFailed',
                        entry: {
                            listingId: listingId,
                            bulkCrosslistingSessionId: data.bulkCrosslistingSessionId,
                            domainSpecificDetails: parsedDomainListingDetails,
                            destinationDomainsForItem
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error while editing listing', subText: 'Please try again' })
        }
    }

    const onCancel = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User cancelled editing listing',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId,
                    bulkCrosslistingSessionId: data.bulkCrosslistingSessionId
                }
            }
        })
        dispatch(clearListingDetails())
        navigate(-1)
    }

    const onReject = async () => {
        setIsRejecting(true)
        try {
            await dispatch(removeBulkCrosslistItem(listingId)).unwrap()
            dispatch(clearListingDetails())

            Logger.I().log({
                level: 'info',
                message: 'User rejected listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        bulkCrosslistingSessionId: data.bulkCrosslistingSessionId
                    }
                }
            })
            navigate(-1)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'rejectBulkCrosslistItemFailed',
                        entry: {
                            listingId: listingId,
                            bulkCrosslistingSessionId: data.bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error while rejecting listing', subText: 'Please try again' })
        }

        setIsRejecting(false)
    }

    const initialValues: BulkCrosslistEditItemFormProps = {
        domainSpecificDetails
    }

    return (
        <Formik initialValues={initialValues} validateOnBlur={false} validateOnChange={false} onSubmit={onSubmit}>
            {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldError,
                setFieldTouched,
                status,
                setStatus,
                touched,
                errors
            }: FormikProps<BulkCrosslistEditItemFormProps>) => {
                useEffect(() => {
                    setRuleFailureFormikState({
                        ruleFailures: extraRuleFailures,
                        setFieldError,
                        setFieldTouched,
                        setStatus,
                        createStatusString: true
                    })
                }, [extraRuleFailures])

                return (
                    <FoxModalDialog
                        open={true}
                        title={'Edit details'}
                        leftButtonKind={'close'}
                        onClose={onCancel}
                        actions={
                            <>
                                <FoxButton
                                    primary
                                    text='Approve item'
                                    loading={isSubmitting}
                                    disabled={isSubmitting || isRejecting}
                                    onFoxClick={{
                                        kind: 'button',
                                        onClick: async () => {
                                            await handleSubmit()
                                        },
                                        preventDoubleClick: true
                                    }}
                                    sx={{ height: '48px', width: 'fit-content' }}
                                />
                                <FoxButton
                                    grey
                                    text='Reject item'
                                    loading={isRejecting}
                                    disabled={isSubmitting || isRejecting}
                                    onFoxClick={{ kind: 'button', onClick: onReject, preventDoubleClick: true }}
                                    sx={{ height: '48px', width: 'fit-content' }}
                                />
                            </>
                        }>
                        <BaseListingRow title={title} description={`$${normalizePrice(price)}`} imageId={imageIds[0]} onClick={() => {}} />

                        {status && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body2' danger sx={styles.textError}>
                                    {`Fix form errors: \n${status}`}
                                </FoxTypography>
                            </Box>
                        )}

                        <>
                            {destinationDomainsForItem.map((domain) => {
                                const Fields = MarketSpecificFieldsMap[domain]
                                return (
                                    <MarketSpecificFieldsLayout key={domain} domain={domain}>
                                        <Fields values={values.domainSpecificDetails[domain]} listingOperationKind='bulkCrosslist' />
                                    </MarketSpecificFieldsLayout>
                                )
                            })}
                        </>

                        {shouldDisplayErrorMessage(touched, errors) && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body2' danger sx={styles.textError}>
                                    Fix form errors to post listing
                                </FoxTypography>
                            </Box>
                        )}
                    </FoxModalDialog>
                )
            }}
        </Formik>
    )
}

const styles = {
    container: {
        width: '80px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        height: '24px',
        width: '80px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px'
    },
    buttonContainerBottom: {
        marginBottom: '32px',
        marginTop: '32px'
    },
    loadingTextContainer: {
        flex: 1,
        justifyContent: 'center'
    },
    loadingText: {
        textAlign: 'center'
    },
    textNoListingsFound: {
        textAlign: 'center',
        paddingHorizontal: '24px',
        lineHeight: '24px'
    },
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
