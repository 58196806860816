import { CommonImages } from '../../theme/CommonImages'
import { FoxListingImage } from '../../components/common/FoxListingImage'
import { FoxImage } from '../../components/common/FoxImage'
import { BaseStatusRow } from './BaseStatusRow'

type ListingStatusRowProps = {
    title: string
    description: string
    imageId?: string
    listingId: string
}
export const ListingStatusRow = (props: ListingStatusRowProps): JSX.Element => {
    const { title, description, imageId, listingId } = props

    const image = imageId ? (
        <FoxListingImage imageId={imageId} style={{ ...styles.image }} />
    ) : (
        <FoxImage src={CommonImages.general.foxtailLogo} alt='foxtail logo' style={{ ...styles.image }} />
    )

    return <BaseStatusRow text={title} subtext={description} image={image} onFoxClick={{ kind: 'internal', to: `/app/listings/${listingId}` }} />
}

const styles = {
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    }
}
