import { SvgIcon } from '@mui/material'

type PageNotFoundIconProps = {
    height?: number
    width?: number
}

export const PageNotFoundIcon = (props: PageNotFoundIconProps) => {
    const height = props.height ?? 189
    const width = props.width ?? 194
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='194px' height='189px' viewBox='0 0 194 189' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>404-graphic</title>
                <defs>
                    <linearGradient x1='27.2600234%' y1='77.540391%' x2='94.0858754%' y2='72.4964954%' id='linearGradient-1'>
                        <stop stopColor='#E84855' offset='0%'></stop>
                        <stop stopColor='#E87155' offset='100%'></stop>
                    </linearGradient>
                    <linearGradient x1='57.4263389%' y1='140.609541%' x2='57.4263389%' y2='23.2622362%' id='linearGradient-2'>
                        <stop stopColor='#E84855' stopOpacity='0.72618007' offset='0%'></stop>
                        <stop stopColor='#E87155' stopOpacity='0' offset='100%'></stop>
                    </linearGradient>
                </defs>
                <g id='Web-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='404' transform='translate(-654, -260)'>
                        <g id='404-graphic' transform='translate(654, 260)'>
                            <g id='Group-3' transform='translate(0, 49)'>
                                <g id='Group'>
                                    <rect
                                        id='Rectangle'
                                        stroke='#E86855'
                                        strokeWidth='2.7'
                                        fillOpacity='0'
                                        fill='#D8D8D8'
                                        x='1.35'
                                        y='1.35'
                                        width='191.3'
                                        height='137.3'
                                    ></rect>
                                    <rect
                                        id='Rectangle'
                                        stroke='#E86855'
                                        strokeWidth='2.7'
                                        fill='#E86555'
                                        x='1.35'
                                        y='1.35'
                                        width='191.3'
                                        height='17.1709677'
                                    ></rect>
                                    <ellipse id='Oval' fill='#FFFFFF' cx='8.08333333' cy='9.03225806' rx='1.7962963' ry='1.80645161'></ellipse>
                                    <ellipse id='Oval' fill='#FFFFFF' cx='14.3703704' cy='9.03225806' rx='1.7962963' ry='1.80645161'></ellipse>
                                    <ellipse id='Oval' fill='#FFFFFF' cx='20.6574074' cy='9.03225806' rx='1.7962963' ry='1.80645161'></ellipse>
                                </g>
                                <path
                                    d='M136.277693,42.7938101 C135.011071,41.4924749 133.034677,41.1772476 131.423044,42.0195125 L97.4494435,59.8194584 L63.4758427,42.0195125 C61.8652243,41.1724915 59.8858867,41.4891552 58.6229762,42.7958966 C57.3600658,44.1026381 57.1186681,46.0837835 58.0311262,47.6531954 L72.6844766,72.8223189 L64.6380975,77.0498061 C63.4431896,77.6587745 62.6240155,78.8121096 62.445665,80.1365852 C62.2673145,81.4610608 62.7524412,82.7884441 63.7440554,83.6891859 L94.445462,110.967603 C95.1847224,111.623211 96.1375672,111.989977 97.1275883,112 L97.4047414,112 L97.6908349,112 C98.6805463,111.988374 99.6328466,111.621817 100.372961,110.967603 L131.145891,83.6891859 C132.143142,82.7988413 132.639856,81.4792092 132.475897,80.1557271 C132.311937,78.832245 131.508039,77.6722844 130.323372,77.0498061 L122.276993,72.8223189 L136.867761,47.6531954 C137.778659,46.0830402 137.538186,44.1026769 136.277693,42.7938101 Z M73.6481481,56 L89.8148148,64.8319459 L81.1873266,69.5483871 L73.6481481,56 Z M73.6481481,81.0083908 L78.3139995,78.5806452 L86.2222222,92.1290323 L73.6481481,81.0083908 Z M96.9642409,99.3548387 L83.5277778,75.8062432 L97,68.6451613 L110.472222,75.8062432 L96.9642409,99.3548387 Z M108.675926,92.1290323 L116.584149,78.5806452 L121.25,81.0083908 L108.675926,92.1290323 Z M114.545966,69.5483871 L105.981481,64.8200909 L122.148148,56 L114.545966,69.5483871 Z'
                                    id='Shape'
                                    fill='url(#linearGradient-1)'
                                    opacity='0.158249628'
                                ></path>
                            </g>
                            <polygon
                                id='Rectangle'
                                fill='url(#linearGradient-2)'
                                opacity='0.215983073'
                                points='2 6.21190599e-13 188 6.21190599e-13 168 49 22 49'
                            ></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
