import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { selectSubscription, useAppSelector } from '@foxtail-dev/user-clients'
import * as Intercom from '@intercom/messenger-js-sdk'

type PotentialSubscriptionKinds = 'unknown' | 'apple' | 'googleplay'

const CannotUpgradeOnPlatFormMap: Record<PotentialSubscriptionKinds, { name: string; description: string }> = {
    googleplay: {
        name: 'Google Play',
        description: 'To upgrade your subscription, you must go to the Foxtail subscription tab on an Android device or go to your google account subscriptions'
    },
    apple: {
        name: 'Apple',
        description: 'To upgrade your subscription, you must go to the Foxtail subscription tab on an Apple device or go to your apple account subscriptions'
    },
    unknown: {
        name: 'an unknown platform',
        description: 'Please contact support about your subscription'
    }
}

type MustGoOnDeviceToSubscribeModalProps = {
    open: boolean
    onClose: (isConfirmed: boolean) => void
}

export const MustGoOnDeviceToSubscribeModal = ({ open, onClose }: MustGoOnDeviceToSubscribeModalProps) => {
    const subscription = useAppSelector(selectSubscription)
    const subscriptionPlatform: PotentialSubscriptionKinds =
        subscription &&
        subscription.kind === 'active' &&
        subscription.details.kind &&
        (subscription.details.kind === 'apple' || subscription.details.kind === 'googleplay')
            ? subscription.details.kind
            : 'unknown'
    return (
        <FoxModalDialog
            open={open}
            title={subscriptionPlatform === 'unknown' ? `Non-Stripe Subscription` : `${CannotUpgradeOnPlatFormMap[subscriptionPlatform].name} Subscription`}
            leftButtonKind={'close'}
            onClose={() => onClose(false)}
            closeOnBackdropClick
        >
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    You currently are subscribed with {CannotUpgradeOnPlatFormMap[subscriptionPlatform].name}
                </FoxTypography>

                <FoxTypography variant='body2' sx={{ marginBottom: '40px', marginTop: '40px', marginLeft: '20px', marginRight: '20px' }}>
                    {CannotUpgradeOnPlatFormMap[subscriptionPlatform].description}
                </FoxTypography>

                <FoxButton
                    primary
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            onClose(true)
                            Intercom.show()
                        }
                    }}
                    text={'Contact us'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
                <FoxButton
                    grey
                    onFoxClick={{ kind: 'button', onClick: async () => onClose(true) }}
                    text={'Close'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
