import React, { useEffect } from 'react'
import Box from '@mui/material/Box/Box'
import ConfettiExplosion from 'react-confetti-explosion'
import { OnboardingHeader } from '../layouts/OnboardingHeader'
import { SubscriptionFeatureRow } from '../containers/subscription/SubscriptionFeatureRow'
import { FoxButton } from '../components/common/FoxButton'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { Logger, selectSubscription, selectUserId, SubscriptionTierInfoMap, useAppSelector, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { UpdateUserInfo, UserSyncSubscriptionActionDescription } from '@foxtail-dev/datacontracts/dist/lib/schemas/actions/concreteSchemas/user'
import { assert } from '@foxtail-dev/datacontracts'

export const SubscriptionSuccessScreen = (): JSX.Element => {
    // TODO: Use useLocation to get the query params
    const parsedUrl = new URL(window.location.href)
    const subscriptionTier = UserSubscriptionTier.parse(parsedUrl.searchParams.get('subscriptionTier'))
    const isUpgradeString = parsedUrl.searchParams.get('isUpgrade')
    const isUpgrade = isUpgradeString === 'true'
    const [isExploding, setIsExploding] = React.useState<boolean>(true)
    const subscription = useAppSelector(selectSubscription)
    const userId = useAppSelector(selectUserId)

    useEffect(() => {
        const setConfetti = async () => {
            const context = await UserRuntimeContextProvider.getContext()
            await context.userApiClient.setUserHasSeenConfetti()
        }
        setConfetti()
        setTimeout(() => {
            setIsExploding(false)
        }, 5000)
    }, [])

    useEffect(() => {
        // This is to cover a weird case when users have custom subscription and then subscribe through stripe
        const maybeSyncCustomSubscription = async () => {
            if (subscription?.kind === 'active' && subscription.details.kind == 'custom') {
                const context = await UserRuntimeContextProvider.getContext()
                assert(userId, 'userId is not defined')

                await context.userActionClient.executeActionRequest<UpdateUserInfo>({
                    kind: 'user/updateUserInfo',
                    params: {
                        preferredSubscriptionProvider: 'stripe'
                    }
                })

                await context.userActionClient.executeActionRequest<UserSyncSubscriptionActionDescription>({
                    kind: 'user/syncSubscription',
                    params: {
                        providerKind: 'stripe',
                        /**
                         * todo
                         * maybe put this notion in datacontracts rather than being in the stripe client
                         */
                        subscriptionLocator: userId
                    }
                })
            }
        }

        maybeSyncCustomSubscription().catch((error) => {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'SubscriptionSuccessSyncCustomSubscriptionError',
                        entry: {
                            subscription,
                            isUpgrade
                        }
                    },
                    message: 'Failed to sync custom subscription'
                },
                error
            )
        })
    })

    return (
        <Box sx={styles.container}>
            <OnboardingHeader
                title={isUpgrade ? 'Thanks for subscribing!' : 'You have downgraded!'}
                subtitle={`Start listing on Foxtail with ${SubscriptionTierInfoMap[subscriptionTier].displayName}.`}
            />
            {isExploding && (
                <ConfettiExplosion
                    force={0.2}
                    duration={5000}
                    particleCount={800}
                    width={window.innerWidth}
                    colors={['#E84F55', '#E85855', '#E86055', '#E86955', '#E87155']}
                    zIndex={100}
                    style={{
                        position: 'absolute',
                        top: '120px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />
            )}

            <Box sx={{ marginBottom: '36px' }}>
                <SubscriptionFeatureRow feature={`Manage up to ${SubscriptionTierInfoMap[subscriptionTier].listingLimit} listing`} />
                <SubscriptionFeatureRow feature='AI content generation' />
                <SubscriptionFeatureRow feature='Mobile application' />
                <SubscriptionFeatureRow feature='Customer support' />
            </Box>

            <FoxButton sx={styles.button} primary text='Go to dashboard' variant='contained' onFoxClick={{ kind: 'internal', to: '/home' }} />
        </Box>
    )
}

const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerButton: {
        marginTop: '48px'
    },
    button: {
        justifyContent: 'center',
        marginBottom: '57px',
        width: '474px',
        radius: '10px',
        height: '48px',
        fontWeight: 700,
        fontSize: '16px'
    }
}
