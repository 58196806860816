import { Outlet } from 'react-router-dom'
import { AppHeader } from './AppHeader'
import { AppSidebar } from './AppSidebar'
import { Box, Divider } from '@mui/material'
import { sharedStyles } from '../theme/SharedStyling'
import {
    DEFAULT_LISTING_PAGE_SIZE,
    getCachedListingQueryStateFromInitialResponse,
    getInitialListings,
    getListingsFromInitialResponse,
    getUntrackedListings,
    populateDocStore,
    selectIsAppInBackground,
    setCachedQueries,
    setCurrentListingQueryState,
    setUntrackedListingIds,
    useAppDispatch,
    useAppSelector,
    UserRuntimeContextProvider
} from '@foxtail-dev/user-clients'
import { useEffect, useState } from 'react'
import { assert, time } from '@foxtail-dev/datacontracts'
import { Logger } from '../lib/clients/Logger'

const APP_BACKGROUND_SYNC_MS = time.minutes(2)

export const AppLayout: React.FC = () => {
    const dispatch = useAppDispatch()

    const isAppInBackground = useAppSelector(selectIsAppInBackground)
    const [backgroundTimestampMs, setBackgroundTimestamp] = useState<number | null>(null)
    const [isSyncing, setIsSyncing] = useState(false) // TODO: Could be used to show an overlay spinner or something like that

    const syncApp = async () => {
        setIsSyncing(true)
        try {
            Logger.I().log({
                level: 'info',
                payload: {
                    kind: 'System',
                    entry: {
                        backgroundTimestampMs,
                        isAppInBackground
                    }
                },
                message: 'Syncing app due to background timer'
            })

            const context = await UserRuntimeContextProvider.getContext()
            const [populatedUser, initialListingsResult, untrackedListings] = await Promise.all([
                context.userApiClient.getPopulatedUser(),
                getInitialListings({ userId: context.initialUser._id, userApiClient: context.userApiClient, pageSize: DEFAULT_LISTING_PAGE_SIZE }),
                getUntrackedListings({ userApiClient: context.userApiClient })
            ])

            const uniqueListings = getListingsFromInitialResponse(initialListingsResult, untrackedListings)
            const listingQueryState = getCachedListingQueryStateFromInitialResponse(initialListingsResult, context.initialUser._id)

            const untrackedListingIds = untrackedListings.map((listing) => listing._id)
            dispatch(setUntrackedListingIds(untrackedListingIds))
            dispatch(setCachedQueries(Object.values(listingQueryState)))
            dispatch(setCurrentListingQueryState(listingQueryState.active))

            populateDocStore({
                populatedUser,
                initialListings: uniqueListings,
                docStore: context.docStore
            })

            assert(context.docStoreSyncer, 'Doc store syncer should be defined')
            context.docStoreSyncer.syncFromInitialDocs()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'System',
                        entry: {
                            backgroundTimestampMs,
                            isAppInBackground
                        }
                    },
                    message: 'Error syncing app'
                },
                error
            )
        }
        setIsSyncing(false)
    }

    useEffect(() => {
        // if the app has been in the background for more than 5 minutes, and then comes back into the app, we want to sync the app
        if (isAppInBackground) {
            setBackgroundTimestamp(Date.now())
        } else {
            if (backgroundTimestampMs && Date.now() - backgroundTimestampMs > APP_BACKGROUND_SYNC_MS) {
                setBackgroundTimestamp(null)
                syncApp()
            }
        }
    }, [isAppInBackground])

    return (
        <Box>
            <Box sx={[sharedStyles.flex]}>
                <Box sx={styles.containerSideBar}>
                    <AppSidebar />
                    <Divider orientation='vertical' flexItem />
                </Box>
                <Box sx={sharedStyles.fullSize}>
                    <AppHeader />
                    <Box sx={styles.containerMainContent}>
                        <Box component='main' sx={[sharedStyles.fullSize, styles.mainContent]}>
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    mainContent: {
        maxWidth: '1280px',
        marginLeft: '64px',
        marginRight: '64px',
        paddingTop: '32px',
        marginTop: '64px'
    },
    containerMainContent: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '240px',
        overflowX: 'hidden'
    },
    containerSideBar: {
        position: 'fixed',
        display: 'flex',
        zIndex: 1001
    }
}
