import { Box } from '@mui/material'
import { Colors } from '@foxtail-dev/user-clients'
import { FoxTypography } from '../common/FoxTypography'
import { SaveIcon } from '../icons/SaveIcon'
import { useNavigate } from 'react-router-dom'
import { FoxButton } from '../common/FoxButton'
import ConfettiExplosion from 'react-confetti-explosion'
import { ConfettiColors } from '../../theme/ConfettiColors'
import { useState } from 'react'

export const ImportSuccess = () => {
    const navigate = useNavigate()
    const [isExploding, setIsExploding] = useState<boolean>(true)

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerBody}>
                <SaveIcon color={Colors.light.tertiary} height={70} width={200} />
                <FoxTypography variant='h6' sx={styles.textSuccess}>
                    Import success! <br />
                    View imports on the listings tab
                </FoxTypography>
                {isExploding && (
                    <ConfettiExplosion
                        force={0.2}
                        duration={5000}
                        particleCount={800}
                        width={window.innerWidth}
                        colors={ConfettiColors}
                        zIndex={10000}
                        style={{
                            position: 'absolute',
                            top: '120px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 10000
                        }}
                    />
                )}
            </Box>
            <FoxButton
                primary
                sx={styles.button}
                text='View listings'
                onFoxClick={{
                    kind: 'button',
                    onClick: async () => {
                        navigate('/app/listings')
                    }
                }}
            />
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1
    },
    textSuccess: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    button: {
        width: '100%',
        justifyContent: 'center',
        marginBottom: '12px'
    }
}
