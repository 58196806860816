import { FoxTypography } from '../../components/common/FoxTypography'
import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useState } from 'react'
import { deleteUserAccount, Logger, resetUserResourcesAndStore, useAppDispatch } from '@foxtail-dev/user-clients'

type AreYouSureYouWantToDeleteYourAccountModalProps = {
    open: boolean
    onClose: () => void
}
export const AreYouSureYouWantToDeleteYourAccountModal = ({ open, onClose }: AreYouSureYouWantToDeleteYourAccountModalProps) => {
    const dispatch = useAppDispatch()
    const [isDeletingAccount, setIsDeletingAccount] = useState<boolean>(false)
    const onDeleteAccount = async () => {
        try {
            setIsDeletingAccount(true)
            Logger.I().log({
                level: 'info',
                message: 'User deleted account',
                payload: {
                    kind: 'UserAction'
                }
            })
            await dispatch(deleteUserAccount()).unwrap()
            await dispatch(
                resetUserResourcesAndStore({
                    clearCredentialsAndUnregisterDevice: true,
                    source: 'AreYouSureYouWantToDeleteYourAccountModal',
                    reason: 'User deleted account'
                })
            ).unwrap()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error deleting account',
                    payload: {
                        kind: 'DeleteAccountError'
                    }
                },
                error
            )
        }
        setIsDeletingAccount(false)
    }

    return (
        <FoxModalDialog
            open={open}
            title={'Delete account?'}
            leftButtonKind={'close'}
            onClose={onClose}
            closeOnBackdropClick
            actions={
                <FoxButton
                    primary
                    onFoxClick={{ kind: 'button', onClick: onDeleteAccount, preventDoubleClick: true }}
                    text={'Delete account'}
                    sx={{ width: '100%', justifyContent: 'center' }}
                    loading={isDeletingAccount}
                />
            }>
            <Box sx={{ height: '350px', display: 'flex', alignItems: 'center' }}>
                <FoxTypography variant="subtitle1" sx={{ textAlign: 'center', lineHeight: '32px' }}>
                    Are you sure you want to delete your account? <br />
                    <br />
                    This action cannot be undone. Foxtail will no longer track your listings and your markets will be unlinked.
                </FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}
