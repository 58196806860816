import { Colors, getAnalyticsChartData, Logger, setIsLoading, setTimeRange, TimeRange, useAppDispatch } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'
import { FoxTypography } from '../common/FoxTypography'
import { FoxButtonBase } from '../common/FoxButtonBase'

type ReportsOverviewHeaderProps = {
    currentTimeRangeRef: React.MutableRefObject<TimeRange>
}

export const ReportsOverviewHeader = (props: ReportsOverviewHeaderProps) => {
    const { currentTimeRangeRef } = props
    const dispatch = useAppDispatch()
    const handleTimeRangePress = async (timeRange: TimeRange) => {
        if (currentTimeRangeRef.current === timeRange) return
        dispatch(setTimeRange(timeRange))
        dispatch(setIsLoading(true))

        // get and set Charts Stats
        await dispatch(getAnalyticsChartData(timeRange)).unwrap()

        Logger.I().log({
            level: 'info',
            message: 'User clicked on time range button',
            payload: {
                kind: 'UserAction',
                entry: {
                    timeRange
                }
            }
        })
        dispatch(setIsLoading(false))
    }
    return (
        <Box>
            <Box sx={styles.container}>
                <FoxTypography variant='h6'>Reports overview</FoxTypography>
                <FlexGrow />
                <FoxButtonBase onFoxClick={{ kind: 'button', onClick: () => handleTimeRangePress('week') }}>
                    <FoxTypography
                        sx={{ ...styles.baseText, color: currentTimeRangeRef.current === 'week' ? Colors.light.primary : Colors.light.tertiary }}
                        variant='subtitle2'
                    >
                        1W
                    </FoxTypography>
                </FoxButtonBase>
                <FoxButtonBase onFoxClick={{ kind: 'button', onClick: () => handleTimeRangePress('month') }}>
                    <FoxTypography
                        sx={{ ...styles.baseText, color: currentTimeRangeRef.current === 'month' ? Colors.light.primary : Colors.light.tertiary }}
                        variant='subtitle2'
                    >
                        1M
                    </FoxTypography>
                </FoxButtonBase>
                <FoxButtonBase onFoxClick={{ kind: 'button', onClick: () => handleTimeRangePress('year') }}>
                    <FoxTypography
                        sx={{ ...styles.baseText, color: currentTimeRangeRef.current === 'year' ? Colors.light.primary : Colors.light.tertiary }}
                        variant='subtitle2'
                    >
                        1Y
                    </FoxTypography>
                </FoxButtonBase>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '49px'
    },
    baseText: {
        marginRight: '30px',
        cursor: 'pointer'
    }
}
