import Box from '@mui/material/Box/Box'
import { ListingDefaultsModal } from '../../modals/account/ListingDefaultsModal'
import { useEffect, useState } from 'react'
import {
    Colors,
    getListingDefaults,
    selectListingDefaultsConfig,
    selectLoggedInAndReadyToListDomains,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Header } from '../../components/common/Header'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ListingDefaultRow } from '../../containers/listingDefaults/ListingDefaultRow'
import { Logger } from '../../lib/clients/Logger'
import { generateToast } from '../../lib/clients/ToastClient'
import { Divider } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'

const ListingDefaultsScreen = (): JSX.Element => {
    const dispatch = useAppDispatch()

    const loggedInDomains = useAppSelector(selectLoggedInAndReadyToListDomains)
    const config = useAppSelector(selectListingDefaultsConfig)

    const [showListingDefaultsModal, setShowListingDefaultsModal] = useState<boolean>(false)

    const onShowListingDefaultsModal = () => {
        setShowListingDefaultsModal(true)
    }

    const onClose = () => {
        // TODO: Where should we navigate if the user wants to close the modal?
        // There's not a real other state for this screen besides editing the modal

        setShowListingDefaultsModal(false)
    }

    const getDefaults = async () => {
        try {
            const config = await dispatch(getListingDefaults()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Got listing defaults',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        config
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to get listing defaults',
                    payload: {
                        kind: 'ListingDefaultGetError'
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to get listing defaults' })
        }
    }

    useEffect(() => {
        getDefaults()
    }, [])

    return (
        <>
            <Header title='Edit listing defaults' />
            <FoxTypography light variant='body1' sx={{ marginBottom: '36px' }}>
                Foxtail's AI excels in creating listing details, however certain information is best provided by you. <br />
                All fields you fill out here will be used when generating listing details.
            </FoxTypography>
            <Box sx={styles.container}>
                <Box sx={styles.containerBoosterRows}>
                    {loggedInDomains.map((domain, index) => {
                        const isLast = index === loggedInDomains.length - 1

                        return (
                            <Box key={domain}>
                                <ListingDefaultRow domainName={domain} isSet={!!config.domainDetails[domain]} editListingDefault={onShowListingDefaultsModal} />
                                {!isLast && <Divider />}
                            </Box>
                        )
                    })}
                </Box>
            </Box>

            <FoxButton
                primary
                text={'Edit listing defaults'}
                onFoxClick={{
                    onClick: onShowListingDefaultsModal,
                    kind: 'button',
                    preventDoubleClick: true
                }}
                sx={{ marginTop: '24px', height: '48px', width: 'fit-content' }}
            />

            <ListingDefaultsModal open={showListingDefaultsModal} onClose={onClose} domains={loggedInDomains} fromBulkCrosslist={false} />
        </>
    )
}

export default ListingDefaultsScreen

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    containerBoosterRows: {
        borderRadius: '10px',
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        maxWidth: '649px'
    }
}
