import { AnalyticsChartKind, Colors, selectChartData, selectIsLoadingCharts, selectTimeRange, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { LineChart } from '@mui/x-charts/LineChart'
import { memo, useEffect, useMemo, useState } from 'react'
import { getXAxisLabelsFromTimeRange } from '../../utils/charts/getXAxisLabelsFromTimeRange'
import { formatXAxisLabelFromTimeRange } from '../../utils/charts/formatXAxisLabelFromTimeRange'
import _ from 'lodash'
import { SkeletonLineChart } from '../skeletons/SkeletonLineChart'

type FoxLineChartProps = {
    chartKind: AnalyticsChartKind
}

// TODO: Move this type to user-clients
type FoxGraphData = {
    labels: string[]
    yAxisInterval: number
    hidePointsAtIndex: number[]
    datasets: {
        data: number[]
        market: string
        color: string
    }[]
}

export const FoxLineChart = ({ chartKind }: FoxLineChartProps) => {
    const timeRange = useAppSelector(selectTimeRange)
    const graphData = useAppSelector(selectChartData(chartKind))
    const isLoadingCharts = useAppSelector(selectIsLoadingCharts)
    const [syntheticLoading, setSyntheticLoading] = useState(false)
    useEffect(() => {
        if (isLoadingCharts) {
            setSyntheticLoading(true)
            setTimeout(() => {
                setSyntheticLoading(false)
            }, 1000)
        }
    }, [isLoadingCharts])

    return <MemoizedFoxLineChart chartKind={chartKind} graphData={graphData} timeRange={timeRange} isLoadingCharts={isLoadingCharts || syntheticLoading} />
}

type MemoizedFoxLineChartProps = {
    chartKind: AnalyticsChartKind
    graphData: FoxGraphData
    timeRange: string
    isLoadingCharts: boolean
}

const MemoizedFoxLineChart = memo(
    ({ timeRange, graphData, chartKind, isLoadingCharts }: MemoizedFoxLineChartProps) => {
        const total = useMemo(() => {
            return graphData.datasets.reduce((sum, dataset) => sum + dataset.data.reduce((s, d) => s + d, 0), 0)
        }, [graphData])

        const isThereAtLeastOneNonZeroValue = useMemo(() => {
            return graphData.datasets.some((dataset) => dataset.data.some((value) => value > 0))
        }, [graphData])

        return isLoadingCharts ? (
            <SkeletonLineChart />
        ) : (
            <Box
                sx={{
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: Colors.light.divider,
                    height: '350px'
                }}
            >
                <FoxTypography variant='subtitle1' sx={{ marginTop: '26px', marginLeft: '25px' }}>
                    {chartKind}
                </FoxTypography>
                <FoxTypography variant='h3' sx={{ marginTop: '7px', marginLeft: '25px' }}>
                    {total}
                </FoxTypography>
                <Box sx={{ height: '16px', marginTop: '19px' }}></Box>
                <LineChart
                    xAxis={[
                        {
                            id: 'xAxis',
                            data: getXAxisLabelsFromTimeRange({ timeRange, graphData }),
                            tickLabelStyle: { fill: Colors.light.lightText },
                            disableTicks: true,
                            scaleType: 'point',
                            valueFormatter: (value: Date) => formatXAxisLabelFromTimeRange({ timeRange, value }),
                            tickLabelPlacement: 'tick',
                            classes: {
                                tickLabel: 'MuiChartsAxis-tickLabel-X'
                            }
                        }
                    ]}
                    yAxis={[
                        {
                            tickLabelStyle: { fill: Colors.light.lightText },
                            disableLine: true,
                            disableTicks: true,
                            min: -0.1, // This makes the line at 0 more visible
                            max: isThereAtLeastOneNonZeroValue ? undefined : 10,
                            classes: {
                                tickLabel: 'MuiChartsAxis-tickLabel-Y'
                            }
                        }
                    ]}
                    series={graphData.datasets.map((dataset, index) => ({
                        data: dataset.data,
                        showMark: false,
                        color: dataset.color,
                        curve: 'linear'
                    }))}
                    height={226}
                    margin={{ top: 10 }}
                    grid={{ horizontal: true }}
                    sx={{
                        '.MuiChartsAxis-tickLabel-Y': {
                            transform: 'translateX(-5px)'
                        },
                        '.MuiChartsAxis-tickLabel-X': {
                            transform: 'translateY(5px)'
                        }
                    }}
                />
            </Box>
        )
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps)
    }
)
