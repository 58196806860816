import { Box, Grid } from '@mui/material'
import { FoxLineChart } from './FoxLineChart'
import { AnalyticsChartKind } from '@foxtail-dev/user-clients'

type AllChartsProps = {
    activeCharts: AnalyticsChartKind[]
}

export const AllCharts = (props: AllChartsProps) => {
    const { activeCharts } = props
    return (
        <Box sx={styles.container}>
            <Grid container rowSpacing='32px' columnSpacing={'24px'}>
                {activeCharts.map((chartKind) => {
                    return (
                        <Grid item key={chartKind} xs={6}>
                            <FoxLineChart chartKind={chartKind} />
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

const styles = {
    container: {
        marginTop: '36px',
        marginBottom: '57px'
    }
}
