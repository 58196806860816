import { Colors } from '@foxtail-dev/user-clients'
import { FoxTypography } from '../../common/FoxTypography'
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon'
import { FoxButtonBase } from '../../common/FoxButtonBase'

export const BackToListingHeader = () => {
    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/listings' }} sx={styles.container}>
            <ChevronLeftIcon height={8} width={4.8} color={Colors.light.lightText} />
            <FoxTypography light sx={styles.text}>
                Back to listings
            </FoxTypography>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        height: '16px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '12px',
        cursor: 'pointer'
    },
    text: {
        fontSize: '12px',
        lineHeight: '16px',
        marginLeft: '6.6px',
        fontWeight: 500
    }
}
