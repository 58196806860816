import { Box, Divider } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { FoxStyle, sharedStyles } from '../theme/SharedStyling'
import { useLocation } from 'react-router-dom'
import { FoxIconButton } from '../components/common/FoxIconButton'
import { ListingsIcon } from '../components/icons/ListingsIcon'
import { UpcomingListingsIndicator } from '../components/listing/UpcomingListingsIndicator'
import { FlexGrow } from '../components/common/FlexGrow'
import { Colors } from '../theme/Colors'
import { selectIsExtensionInstalled, setIsExtensionInstalled, useAppDispatch, useAppSelector, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { FoxtailIcon } from '../components/icons/FoxtailIcon'
import { HomeIcon } from '../components/icons/HomeIcon'
import { SettingsIcon } from '../components/icons/SettingsIcon'
import { FoxTypography } from '../components/common/FoxTypography'
import { AppSidebarButton } from '../components/progress/SideBarButton'
import { ExtensionIcon } from '../components/icons/ExtensionIcon'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'
import { FoxButtonBase } from '../components/common/FoxButtonBase'
import { CheckBoxUncheckedIcon } from '../components/icons/CheckBoxUnchecked'
import { ExtensionReloadModal } from '../modals/account/marketConnections/ExtensionReloadModal'
import { ProgressChecklistNav } from '../components/progress/ProgressChecklistNav'
import { BoltUnfilledIcon } from '../components/icons/BoltUnfilledIcon'
import SidebarAccordion from './ChecklistAccordian'

// Todo update this for collapsed sidebar view
export const AppSidebar = () => {
    // TODO: When the sidebar is collapsed, it should only show icons
    const [collapsed, setCollapsed] = useState(false)
    const dispatch = useAppDispatch()
    const location = useLocation()

    const parsedUrlPath = location.pathname

    const isListingsScreen = parsedUrlPath.includes('/app/listings')
    const isHomeScreen = parsedUrlPath === '/app/home'
    const isAccountScreen = parsedUrlPath.includes('/app/account')
    const isEngagementBoostersScreen = parsedUrlPath.includes('/app/engagement-boosters')

    const chromeExtensionRef = useRef(false)
    chromeExtensionRef.current = useAppSelector(selectIsExtensionInstalled)
    const [extensionReloadModalOpen, setExtensionReloadModalOpen] = useState<boolean>(false)

    const closeExtensionReloadModal = (result: boolean) => {
        setExtensionReloadModalOpen(false)
    }

    const onCollapse = () => {
        setCollapsed(!collapsed)
    }

    const checkIfWebExtensionInstalled = async () => {
        try {
            const context = await UserRuntimeContextProvider.getContext()
            await context.extensionClient.ping(4000)
            const newValue = true
            if (chromeExtensionRef.current !== newValue) {
                dispatch(setIsExtensionInstalled(true))
            }
        } catch (error) {
            const newValue = false
            if (chromeExtensionRef.current !== newValue) {
                dispatch(setIsExtensionInstalled(false))
            }
        }
    }

    useEffect(() => {
        checkIfWebExtensionInstalled()
        const interval = setInterval(() => {
            checkIfWebExtensionInstalled()
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <Box sx={[sharedStyles.flexColumn, collapsed ? styles.sidebarCollapsed : styles.sidebar]}>
            {collapsed ? (
                <FoxIconButton
                    onFoxClick={{ kind: 'internal', to: '/app/home' }}
                    sx={{ height: '32px', marginTop: '21px', marginBottom: '78px', display: 'flex', justifyContent: 'center', padding: 0 }}
                >
                    <FoxtailIcon height={32} />
                </FoxIconButton>
            ) : (
                <FoxIconButton
                    onFoxClick={{ kind: 'internal', to: '/app/home' }}
                    sx={{ height: '32px', marginLeft: '35px', marginTop: '21px', marginBottom: '78px', alignItems: 'flex-start', padding: 0 }}
                >
                    <FoxtailIcon height={32} />
                </FoxIconButton>
            )}
            <Box sx={[styles.navigationPill]}>
                {collapsed ? (
                    <FoxIconButton onFoxClick={{ kind: 'internal', to: '/app/home' }} primary={isHomeScreen} sx={[styles.button]}>
                        <HomeIcon height={18.58} width={17.28} color={isHomeScreen ? Colors.light.primary : Colors.light.tertiary} />
                    </FoxIconButton>
                ) : (
                    <AppSidebarButton
                        icon={<HomeIcon height={18.58} width={17.28} color={isHomeScreen ? Colors.light.primary : Colors.light.tertiary} />}
                        text='Home'
                        to='/app/home'
                        active={isHomeScreen}
                    />
                )}
            </Box>
            <Box sx={[styles.navigationPill]}>
                {collapsed ? (
                    <FoxIconButton primary={isListingsScreen} onFoxClick={{ kind: 'internal', to: '/app/listings' }} sx={[styles.button]}>
                        <ListingsIcon height={19.25} width={21.35} color={isListingsScreen ? Colors.light.primary : Colors.light.tertiary} />
                    </FoxIconButton>
                ) : (
                    <AppSidebarButton
                        icon={<ListingsIcon height={19.25} width={21.35} color={isListingsScreen ? Colors.light.primary : Colors.light.tertiary} />}
                        text='Listings'
                        to='/app/listings'
                        active={isListingsScreen}
                    />
                )}
            </Box>
            <Box sx={[styles.navigationPill]}>
                {collapsed ? (
                    <FoxIconButton primary={isAccountScreen} onFoxClick={{ kind: 'internal', to: '/app/engagement-boosters' }} sx={[styles.button]}>
                        <BoltUnfilledIcon height={21} width={20.98} color={isEngagementBoostersScreen ? Colors.light.primary : Colors.light.tertiary} />
                    </FoxIconButton>
                ) : (
                    <AppSidebarButton
                        icon={<BoltUnfilledIcon height={21} width={20.98} color={isEngagementBoostersScreen ? Colors.light.primary : Colors.light.tertiary} />}
                        text='Boosters'
                        to='/app/engagement-boosters'
                        active={isEngagementBoostersScreen}
                    />
                )}
            </Box>
            <Box sx={[styles.navigationPill]}>
                {collapsed ? (
                    <FoxIconButton primary={isAccountScreen} onFoxClick={{ kind: 'internal', to: '/app/account' }} sx={[styles.button]}>
                        <SettingsIcon height={21} width={20.98} color={isAccountScreen ? Colors.light.primary : Colors.light.tertiary} />
                    </FoxIconButton>
                ) : (
                    <AppSidebarButton
                        icon={<SettingsIcon height={21} width={20.98} color={isAccountScreen ? Colors.light.primary : Colors.light.tertiary} />}
                        text='Settings'
                        to='/app/account'
                        active={isAccountScreen}
                    />
                )}
            </Box>
            <Divider sx={{ marginTop: '20px', marginBottom: '30px', marginRight: '10px', marginLeft: '10px' }} />
            {chromeExtensionRef.current === false &&
                (collapsed ? (
                    <FoxButtonBase
                        onFoxClick={{ kind: 'external', href: FoxtailWebConfig.config.extensionUrl }}
                        sx={{
                            height: '42px',
                            backgroundColor: 'rgb(232, 113, 85, 0.15)',
                            borderRadius: '21px',
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '3px',
                            marginRight: '6px',
                            marginBottom: '18px',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{ marginTop: '11px' }}>
                            <FoxtailIcon height={22} width={25} />
                        </Box>
                    </FoxButtonBase>
                ) : (
                    <FoxButtonBase
                        onFoxClick={{
                            kind: 'button',
                            onClick: async () => {
                                window.open(FoxtailWebConfig.config.extensionUrl)
                                setExtensionReloadModalOpen(true)
                            }
                        }}
                        sx={{
                            height: '122px',
                            backgroundColor: 'rgb(232, 113, 85, 0.15)',
                            borderRadius: '21px',
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '18px',
                            marginRight: '15px',
                            marginBottom: '18px'
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ marginLeft: '13px', marginRight: '12px', marginTop: '18px', height: '30px', width: '35px' }}>
                                <ExtensionIcon height={22} width={25} />
                            </Box>
                            <Box sx={{ marginTop: '12px', marginBottom: '11.5px' }}>
                                <FoxTypography sx={{ fontSize: '13px', lineHeight: '20px', fontWeight: 500, marginBottom: '3px' }}>
                                    Install free extension
                                </FoxTypography>
                                <FoxTypography light sx={{ fontSize: '12px', lineHeight: '15px', fontWeight: 400 }}>
                                    Get the Foxtail browser extension to link markets
                                </FoxTypography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: Colors.light.primary,
                                height: '30px',
                                borderRadius: '21px',
                                marginLeft: '15px',
                                marginRight: '13px',
                                marginBottom: '15.5px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start'
                            }}
                        >
                            <FoxTypography
                                sx={{
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                    height: '20px',
                                    fontWeight: 500,
                                    color: Colors.light.background,
                                    marginTop: '4.5px'
                                }}
                            >
                                Install now
                            </FoxTypography>
                        </Box>
                    </FoxButtonBase>
                ))}
            <FoxButtonBase sx={[styles.navigationPill]} onFoxClick={{ kind: 'internal', to: '/app/processing' }}>
                <UpcomingListingsIndicator collapsed={collapsed} />
            </FoxButtonBase>
            <FlexGrow />

            {/* <Box sx={[styles.collapseContainer]}>
                {collapsed ? (
                    <FoxIconButton onClick={onCollapse} sx={styles.button}>
                        <ChevronRight />
                    </FoxIconButton>
                ) : (
                    <FoxButton text={'Collapse'} onClick={onCollapse} sx={{ ...styles.button, ...styles.collapseButton }} endIcon={<ChevronLeft />} />
                )}
            </Box> */}
            <ExtensionReloadModal open={extensionReloadModalOpen} onClose={closeExtensionReloadModal} />
            {/* 
            <Box style={{ position: 'relative', marginLeft: '13px' }}>
                <SidebarAccordion />
            </Box> */}
        </Box>
    )
}

const styles = {
    sidebar: {
        width: '240px',
        height: 'calc(100vh - 48px)'
    },
    sidebarCollapsed: {
        width: '60px',
        height: '100vh',
        paddingLeft: '4px'
    },
    collapseContainer: {
        borderRadius: 0,
        width: '100%',
        display: 'flex',
        height: 40
    },
    navigationPill: {
        borderRadius: 0,
        width: '220px',
        display: 'flex',
        height: '58px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    button: {
        width: '100%',
        height: '100%'
    },
    collapseButton: {
        marginLeft: '20px',
        justifyContent: 'space-between'
    }
}
