import { Colors, formatDate, generateHistoryDescription, ListingSummaryHistoryRowProps } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../common/FoxTypography'

type ListingHistoryProps = {
    history: ListingSummaryHistoryRowProps[]
}

export const ListingHistory = ({ history }: ListingHistoryProps) => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitle}>
                <FoxTypography variant='subtitle1'>History</FoxTypography>
            </Box>
            <Divider />
            {history.length > 0 ? (
                <Box sx={styles.containerHistory}>
                    {history.map((historyDetails) => {
                        return (
                            <Box
                                key={'ListingHistory' + historyDetails.time + historyDetails.historyKind + historyDetails.domainName}
                                sx={styles.containerHistory}>
                                <FoxTypography variant='body2' light sx={styles.textTime}>
                                    {formatDate(historyDetails.time)}
                                </FoxTypography>
                                <FoxTypography variant='body2' sx={styles.textDescription}>
                                    {generateHistoryDescription(historyDetails)}
                                </FoxTypography>
                            </Box>
                        )
                    })}
                </Box>
            ) : (
                <Box sx={styles.containerZeroState}>
                    <FoxTypography variant='body1' light>
                        Listing history will appear here
                    </FoxTypography>
                </Box>
            )}
        </Box>
    )
}

const styles = {
    container: {
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        borderRadius: '10px',
        marginRight: '19px'
    },
    containerZeroState: {
        height: '286px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containerTitle: {
        paddingTop: '23px',
        marginLeft: '22px',
        height: '64px'
    },
    containerHistory: {
        marginTop: '16px',
        marginBottom: '24px'
    },
    containerHistoryRow: {
        height: '82px'
    },
    textDescription: {
        marginLeft: '24px'
    },
    textTime: {
        marginLeft: '24px',
        paddingTop: '13px',
        marginBottom: '8px'
    }
}
