import { useAppDispatch, useAppSelector, selectListingId, addManyImages, uploadImage, Logger } from '@foxtail-dev/user-clients'
import { useState, useRef, useEffect } from 'react'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { Box, useTheme } from '@mui/material'
import { PinturaEditor } from '@pqina/react-pintura'
import { generateToast } from '../../lib/clients/ToastClient'
import { generateObjectID } from '@foxtail-dev/datacontracts'
import { createMarkupEditorToolStyles, createMarkupEditorToolStyle, getEditorDefaults, PinturaDefaultImageWriterResult } from '@pqina/pintura'
import '@pqina/pintura/pintura.css'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { FoxButton } from '../../components/common/FoxButton'

export type ImageEditParams = {
    uri: string
    imageId: string
}

export type ImageModalParams = {
    open: boolean
} & ImageEditParams

type PhotoEditModalProps = {
    existingImageIds: string[]
    onClose: () => void
} & ImageModalParams

export const PhotoEditModal = (props: PhotoEditModalProps) => {
    const { uri, open, onClose, imageId, existingImageIds } = props

    const [editorImageSource, setEditorImageSource] = useState<string | undefined>(undefined)
    const editorRef = useRef(null)
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const currentImageId = imageId
    const listingId = useAppSelector(selectListingId)
    const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false)

    const loadImageAsync = async () => {
        if (!uri) {
            return
        }

        setEditorImageSource(uri)
    }

    useEffect(() => {
        loadImageAsync()
    }, [uri])

    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const onImageProcess = async (detail: PinturaDefaultImageWriterResult) => {
        const { dest, imageState, store } = detail

        Logger.I().log({
            level: 'info',
            message: 'PINTURA onImageProcess',
            payload: {
                kind: 'PinturaImageEditor',
                entry: {
                    listingId,
                    imageId
                }
            }
        })
        try {
            if (isProcessingImage) {
                return
            }
            setIsProcessingImage(true)

            const newImageUri = generateObjectID()
            const newImageLocator = await dispatch(uploadImage({ kind: 'web', file: dest })).unwrap()

            if (imageId) {
                const newImageIds = existingImageIds.map((id) => (id === imageId ? newImageLocator.id : id))
                setFieldValue('imageIds', newImageIds)
                setFieldTouched('imageIds', true)
            } else {
                setFieldTouched('imageIds', true)
                setFieldValue('imageIds', [newImageLocator.id, ...existingImageIds])
            }

            dispatch(addManyImages([newImageLocator]))
            Logger.I().log({
                level: 'info',
                message: 'PINTURA onImageProcess successful image process',
                payload: {
                    kind: 'PinturaImageEditorSuccess',
                    entry: {
                        listingId,
                        imageId,
                        newImageUri,
                        newImage: newImageLocator,
                        imageState
                    }
                }
            })

            setIsProcessingImage(false)
        } catch (error) {
            setIsProcessingImage(false)

            Logger.I().log(
                {
                    level: 'error',
                    message: 'threw error while trying to finish Pintura image upload',
                    payload: {
                        kind: 'PinturaImageEditorException',
                        entry: {
                            listingId,
                            imageId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error uploading image', subText: 'Please try again' })
        }
        onClose()
    }

    const onImageDelete = async () => {
        if (currentImageId) {
            const newImageIds = existingImageIds.filter((id) => id !== currentImageId)
            setFieldTouched('imageIds', true)
            setFieldValue('imageIds', newImageIds)
        }
        onClose()
    }

    const editorConfig = getEditorDefaults()

    return (
        <FoxModalDialog open={open} onClose={onClose} leftButtonKind='close' title='Edit Photo' removeOverlay>
            <Box sx={{ height: '1200px', width: '100%' }}>
                <PinturaEditor
                    {...editorConfig}
                    ref={editorRef}
                    markupEditorToolStyles={createMarkupEditorToolStyles({
                        text: createMarkupEditorToolStyle('text', {
                            fontSize: '10%'
                        })
                    })}
                    imageCropAspectRatio={1}
                    src={editorImageSource}
                    onLoaderror={(error) => {
                        Logger.I().log(
                            {
                                level: 'error',
                                message: 'PINTURA onLoaderror',
                                payload: {
                                    kind: 'PinturaImageEditorException',
                                    entry: {
                                        listingId,
                                        imageId
                                    }
                                }
                            },
                            error
                        )
                    }}
                    onProcesserror={(error) => {
                        Logger.I().log(
                            {
                                level: 'error',
                                message: 'PINTURA onProcesserror',
                                payload: {
                                    kind: 'PinturaImageEditorException',
                                    entry: {
                                        listingId,
                                        imageId
                                    }
                                }
                            },
                            error
                        )
                    }}
                    onProcess={onImageProcess}
                />
            </Box>
            <FoxButton
                variant='contained'
                size='medium'
                text='Delete'
                type='submit'
                loading={isProcessingImage}
                onFoxClick={{ kind: 'button', onClick: onImageDelete }}
            />
        </FoxModalDialog>
    )
}
