import { Box, BoxProps } from '@mui/material'
import React, { useMemo } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { useTaxonomyProvider } from '../../../lib/TaxonomyProvider'

export type EtsyCategoryPathProps = {
    path?: string[]
} & BoxProps

export const EtsyCategoryPath = ({ path, ...otherProps }: EtsyCategoryPathProps) => {
    if (!path || !path.length) return null

    const lastPathItem = Number(path[path.length - 1])
    const taxonomyProvider = useTaxonomyProvider()

    const lastPathItemValue = useMemo(() => {
        if (!taxonomyProvider) {
            return 'Loading...'
        }

        return taxonomyProvider.etsy.categories.tree.getNodeById(lastPathItem.toString()).data.value
    }, [taxonomyProvider])

    const pathValues = useMemo(() => {
        if (!taxonomyProvider) {
            return 'Loading...'
        }

        return path
            .map((p) => taxonomyProvider.etsy.categories.tree.getNodeById(p))
            .filter((p) => p)
            .map((p) => p.data.value)
            .join(', ')
    }, [taxonomyProvider])

    return (
        <Box {...otherProps}>
            <FoxTypography variant='body2'>{lastPathItemValue}</FoxTypography>
            <FoxTypography light sx={[styles.path]}>
                {pathValues}
            </FoxTypography>
        </Box>
    )
}

const styles = {
    path: {
        marginTop: '8px'
    }
}
