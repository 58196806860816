import { Box, Button, ButtonProps, CircularProgress } from '@mui/material'
import { Colors } from '../../theme/Colors'
import { OnFoxClick } from './FoxButtonBase'
import { Link } from 'react-router-dom'
import React, { useRef, useEffect, useState } from 'react'
import { Logger } from '@foxtail-dev/user-clients'

export type FoxButtonProps = {
    text: string
    loading?: boolean
    disabled?: boolean
    variant?: ButtonVariant
    primary?: boolean
    grey?: boolean
    onFoxClick: OnFoxClick
    startIcon?: any
    endIcon?: any
    showBetaChip?: boolean // TODO: Consider just making this an endIcon
} & ButtonProps

type ButtonVariant = 'text' | 'outlined' | 'contained'

const ButtonSizeToProgressSizeMap = {
    small: 16,
    medium: 20,
    large: 26
}

export const FoxButton = (props: FoxButtonProps) => {
    const { text, loading, disabled, startIcon, endIcon, showBetaChip, size, primary, grey, variant, onFoxClick, sx, onClick: _, ...rest } = props
    const disabledCSS = disabled ? styles.disabled : {}

    const color = primary ? 'primary' : 'secondary'

    const progressSize = ButtonSizeToProgressSizeMap[size || 'medium']

    const background = primary ? 'linear-gradient(84.45deg, #E84855 0%, #E87155 100%)' : grey ? Colors.light.buttonGrey : 'white'
    const textColor = primary ? 'white' : Colors.light.tertiary

    const component = onFoxClick.kind === 'external' ? 'a' : onFoxClick.kind === 'internal' ? Link : 'button'
    const href = onFoxClick.kind === 'external' ? onFoxClick.href : undefined
    const to = onFoxClick.kind === 'internal' ? onFoxClick.to : undefined

    const [awaitingOnClick, setAwaitingOnClick] = React.useState(false)
    const isButtonLoading = loading || awaitingOnClick

    const buttonRef = useRef<HTMLButtonElement>(null)
    const [buttonWidth, setButtonWidth] = useState<string | number>('auto')

    useEffect(() => {
        if (buttonRef.current && !isButtonLoading) {
            setButtonWidth(buttonRef.current.offsetWidth)
        }
    }, [isButtonLoading, text, startIcon, endIcon])

    let onClick: ((e: React.MouseEvent<HTMLAnchorElement>) => Promise<void> | void) | undefined
    if (onFoxClick.kind === 'button') {
        if (onFoxClick.preventDoubleClick) {
            onClick = async (e) => {
                if (awaitingOnClick) {
                    return
                }

                setAwaitingOnClick(true)
                try {
                    await onFoxClick.onClick(e)
                } finally {
                    setAwaitingOnClick(false)
                }
            }
        } else {
            onClick = onFoxClick.onClick
        }
    }

    return (
        <Button
            ref={buttonRef}
            disableRipple
            disabled={disabled || isButtonLoading}
            variant={variant}
            color={color}
            size={size}
            href={href}
            component={component}
            to={to}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            target={onFoxClick.kind === 'external' ? '_blank' : undefined}
            referrer={onFoxClick.kind === 'external' ? 'noreferrer' : undefined}
            disableElevation
            sx={{
                ...styles.button,
                ...disabledCSS,
                background,
                color: textColor,
                justifyContent: isButtonLoading ? 'center' : 'flex-start',
                width: buttonWidth,
                ...sx
            }}
            {...rest}>
            {isButtonLoading ? (
                <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', height: '24px' }}>
                    <CircularProgress size={progressSize} sx={{ color: primary ? 'white' : Colors.light.tertiary }} />
                </Box>
            ) : (
                text
            )}
        </Button>
    )
}

const styles = {
    button: {
        justifyContent: 'flex-start',
        width: 'fit-content',
        borderRadius: '10px'
    },
    disabled: {
        opacity: 0.7
    }
}
