import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import useNavigationLogger from '../router/UseNavigationLogger'

const UnauthenticatedLayout: React.FC = () => {
    useNavigationLogger()

    return (
        <Box className='Layout'>
            <Outlet />
        </Box>
    )
}

export default UnauthenticatedLayout
