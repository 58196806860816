import { SvgIcon } from '@mui/material'

type EditIconProps = {
    color?: string
    height?: number
    width?: number
}

export const EditIcon = (props: EditIconProps) => {
    const height = props.height ?? 32
    const width = props.width ?? 32
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>0ADF0AA9-1617-4CD4-8F4F-8FE50A3E908A</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Actions---active-listing-detail' transform='translate(-16.000000, -560.000000)'>
                        <g id='Group' transform='translate(16.000000, 544.000000)'>
                            <g id='action-copy-as-new' transform='translate(0.000000, 16.000000)'>
                                <rect id='Rectangle' fill='#301A36' x='0' y='0' width='32' height='32' rx='10'></rect>
                                <g id='edit_FILL0_wght600_GRAD0_opsz24' transform='translate(7.000000, 7.000000)' fill='#FFFFFF' fillRule='nonzero'>
                                    <path
                                        d='M2.225,16.2132096 L3.4,16.2132096 L11.875,7.71320961 L10.7,6.53820961 L2.225,15.0382096 L2.225,16.2132096 Z M16.45,6.26320961 L12.125,1.98820961 L13.45,0.663209607 C13.8666667,0.229876274 14.3793333,0.00887627365 14.988,0.000209606987 C15.596,-0.00779039301 16.125,0.213209607 16.575,0.663209607 L17.8,1.83820961 C18.25,2.28820961 18.471,2.80887627 18.463,3.40020961 C18.4543333,3.99220961 18.2333333,4.49654294 17.8,4.91320961 L16.45,6.26320961 Z M1.325,18.4132096 C0.958333333,18.4132096 0.646,18.2838763 0.388,18.0252096 C0.129333333,17.7672096 0,17.4548763 0,17.0882096 L0,14.6382096 C0,14.4548763 0.0333333333,14.2842096 0.1,14.1262096 C0.166666667,13.9675429 0.266666667,13.8215429 0.4,13.6882096 L10.675,3.41320961 L15.025,7.73820961 L4.725,18.0132096 C4.60833333,18.1465429 4.46666667,18.2465429 4.3,18.3132096 C4.13333333,18.3798763 3.95833333,18.4132096 3.775,18.4132096 L1.325,18.4132096 Z M11.3,7.11320961 L10.7,6.53820961 L11.875,7.71320961 L11.3,7.11320961 Z'
                                        id='Shape'
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
