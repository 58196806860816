import { SvgIcon } from '@mui/material'

type ChevronUpIconProps = {
    color: string
    width?: number
    height?: number
}

export const ChevronUpIcon = (props: ChevronUpIconProps) => {
    const height = props.height ?? 10
    const width = props.width ?? 17
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='17px' height='10px' viewBox='0 0 17 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>chev-down@1.5x</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Enter-phone-number' transform='translate(-82.000000, -263.000000)' fill={props.color} fillRule='nonzero'>
                        <g id='Group-7' transform='translate(11.000000, 219.000000)'>
                            <path
                                d='M74.9669798,57.0271637 C74.6676338,56.7007124 74.5122041,56.3217956 74.5006908,55.8904135 C74.4891775,55.4590313 74.6330939,55.0917736 74.9324399,54.7886402 L80.5969881,49.0524236 L74.8979,43.2812301 C74.6215805,42.9780967 74.4891775,42.59918 74.5006908,42.1444799 C74.5122041,41.6897798 74.6676338,41.310863 74.9669798,41.0077296 C75.2893525,40.6579603 75.6577784,40.4889052 76.0722575,40.5005641 C76.4867367,40.5122231 76.8551626,40.6812783 77.1775352,41.0077296 L84.016441,47.9331618 C84.1546007,48.0963875 84.2697338,48.2712722 84.3618403,48.4578158 C84.4539468,48.6443594 84.5,48.842562 84.5,49.0524236 C84.5,49.2622852 84.4539468,49.4604878 84.3618403,49.6470314 C84.2697338,49.8335751 84.1546007,50.0084597 84.016441,50.1716854 L77.2120752,57.0621407 C76.8897025,57.365274 76.5155199,57.5110113 76.0895275,57.4993523 C75.663535,57.4876933 75.2893525,57.3302971 74.9669798,57.0271637 Z'
                                id='chev-down'
                                transform='translate(79.500000, 49.000000) rotate(-90.000000) translate(-79.500000, -49.000000) '
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
