import { Box } from '@mui/material'
import { FoxtailLogoAndNameIcon } from '../../components/icons/FoxtailLogoAndName'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '@foxtail-dev/user-clients'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../../components/common/FoxImage'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useState } from 'react'
import { SubscriptionCard } from '../../components/account/SubscriptionCard'
import { MustGoOnDeviceToSubscribeModal } from './marketConnections/MustGoOnDeviceToSubscribe'

type UpgradeGuardKind = 'listingLimit' | 'engagementBooster'

const reasonTexts: Record<UpgradeGuardKind, string> = {
    listingLimit: `You've reached your listing limit. Upgrade to add more listings.`,
    engagementBooster:
        'Upgrade your account for access to our engagement boosters. Foxtail will automatically share all items in your closet at peak traffic times to ensure you are getting the most engagement on your listings as possible.'
}

type UpgradeGuardModalProps = {
    open: boolean
    onClose: () => void
    reason: UpgradeGuardKind
    markets: DomainCommonName[]
}

export const UpgradeGuardModal = ({ open, onClose, reason, markets }: UpgradeGuardModalProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const reasonText = reasonTexts[reason]

    return (
        <FoxModalDialog open={open} leftButtonKind={'back'} onClose={onClose} PaperProps={{ sx: { maxWidth: '90%' } }} title={'Upgrade your subscription'}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ marginTop: '37px', marginBottom: '60px' }}>
                    <FoxtailLogoAndNameIcon height={26} width={120} />
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex', marginBottom: '14px' }}>
                    {reason === 'listingLimit' ? (
                        <>
                            <FoxTypography sx={{ fontSize: '35px', fontWeight: 500, marginBottom: '14px', marginRight: '10px' }}>The Future of</FoxTypography>
                            <FoxTypography sx={{ color: Colors.light.primary, fontSize: '35px', fontWeight: 500 }}>Reselling</FoxTypography>
                        </>
                    ) : (
                        <FoxTypography sx={{ fontSize: '35px', fontWeight: 500, marginBottom: '14px', marginRight: '10px' }}>
                            Sell faster on Poshmark!
                        </FoxTypography>
                    )}
                </Box>
                <FoxTypography light sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 400, maxWidth: '800px', textAlign: 'center' }}>
                    {reasonText}
                </FoxTypography>

                <Box sx={{ marginTop: '51px', marginBottom: '96px', display: 'flex', flexDirection: 'row' }}>
                    <SubscriptionCard sx={{ marginRight: '20px' }} subscriptionKind='basic' setModalOpen={setModalOpen} />
                    <SubscriptionCard sx={{ marginRight: '20px' }} subscriptionKind='premium' setModalOpen={setModalOpen} />
                    <SubscriptionCard subscriptionKind='pro' setModalOpen={setModalOpen} />
                </Box>
                <FoxTypography light sx={{ fontSize: '15px' }}>
                    Increase sales from sites where you're already listing
                </FoxTypography>
                <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '48px', marginBottom: '48px' }}>
                    {markets.map((domainName) => {
                        return (
                            <Box key={'DomainImg-' + domainName}>
                                <Box key={domainName} sx={{ marginRight: '17.5px', height: '68px', width: '68px', borderRadius: '10px', position: 'relative' }}>
                                    <FoxImage
                                        src={CommonImages.markets[domainName]}
                                        alt={domainName}
                                        style={{ borderRadius: '10px', height: '100%', width: '100%' }}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {modalOpen && <MustGoOnDeviceToSubscribeModal open={modalOpen} onClose={() => setModalOpen(false)} />}
        </FoxModalDialog>
    )
}
