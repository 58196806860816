import { useNavigate } from 'react-router-dom'
import { DetailSelection } from '../common/DetailSelection'
import { Add } from '@mui/icons-material'

export const ConnectMoreSitesLink = () => {
    const navigate = useNavigate()

    return (
        <DetailSelection
            onDetail={() => {
                navigate('/app/account/marketplace-connections')
            }}
            iconElement={<Add sx={{ marginRight: '12px' }} color='primary' />}
            value='Connect more sites'
        />
    )
}
