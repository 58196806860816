type GenerateBulkCrosslistingTitleBasedOnListingStatusParams = {
    isBulkCrosslistCancelling?: boolean
    bulkCrosslistStatus: 'draft' | 'generating' | 'reviewing' | 'listing' | 'completed' | undefined
}
export const generateBulkCrosslistTitleBasedOnListingStatus = (props: GenerateBulkCrosslistingTitleBasedOnListingStatusParams) => {
    const { bulkCrosslistStatus, isBulkCrosslistCancelling } = props

    if (isBulkCrosslistCancelling) {
        return 'Cancelling bulk crosslist'
    } else if (bulkCrosslistStatus === 'draft') {
        return 'Bulk crosslist draft'
    } else if (bulkCrosslistStatus === 'generating') {
        return 'Generating listings'
    } else if (bulkCrosslistStatus === 'reviewing') {
        return 'Summary'
    } else if (bulkCrosslistStatus === 'listing') {
        return 'Bulk crosslist'
    } else {
        return 'New bulk crosslist'
    }
}
