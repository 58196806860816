import { Box } from '@mui/material'
import { CheckIcon } from '../../components/icons/CheckIcon'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '../../theme/Colors'

type SubscriptionFeatureRowProps = {
    feature: string
}
export const SubscriptionFeatureRow = (props: SubscriptionFeatureRowProps) => {
    return (
        <Box sx={{ flexDirection: 'row', display: 'flex', flex: 1, marginBottom: '10px' }}>
            <Box>
                <CheckIcon height={11.84} width={13.84} color={Colors.light.success} />
            </Box>
            <Box sx={{ marginLeft: '20px' }}>
                <FoxTypography>{props.feature}</FoxTypography>
            </Box>
        </Box>
    )
}
