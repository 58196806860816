import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { CheckIcon } from '../../components/icons/CheckIcon'
import { Colors } from '../../theme/Colors'

type SubscriptionCardFeatureRowMobileProps = {
    feature: string
}
export const SubscriptionCardFeatureRowMobile = (props: SubscriptionCardFeatureRowMobileProps) => {
    return (
        <Box sx={{ flexDirction: 'row', display: 'flex', marginLeft: '17px', alignItems: 'center' }}>
            <Box sx={{ marginRight: '12.43px', display: 'flex', justifyContent: 'center' }}>
                <CheckIcon height={14} width={12} color={Colors.light.primary} />
            </Box>
            <Box>
                <FoxTypography sx={{ lineHeight: '24px', fontSize: '12px', fontWeight: 400 }}>{props.feature}</FoxTypography>
            </Box>
        </Box>
    )
}
