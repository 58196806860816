import { Logger } from '@foxtail-dev/user-clients'
import { Box, Drawer, DrawerProps, Icon } from '@mui/material'
import { CSSProperties, useState } from 'react'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxButton } from './FoxButton'
import { FoxIconButton } from './FoxIconButton'
import { ChevronDownIcon } from '../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../icons/ChevronUpIcon'

type CollapsibleProps = {
    label: string
    buttonStyle?: CSSProperties
    children: React.ReactNode
}

export const Collapsible = (props: CollapsibleProps) => {
    const { label, buttonStyle = {} } = props

    const [expanded, setExpanded] = useState(false)

    const onExpand = (expand: boolean) => {
        Logger.I().log({
            level: 'info',
            message: 'User clicked on collapsible',
            payload: {
                kind: 'UserAction',
                entry: {
                    expand
                }
            }
        })
        setExpanded(expand)
    }

    const chevronIcon = expanded ? <ChevronUpIcon color='lightgrey' /> : <ChevronDownIcon color='lightgrey' />

    return (
        <Box sx={{ marginBottom: 2 }}>
            <FoxButton
                text={label}
                onFoxClick={{ kind: 'button', onClick: async () => onExpand(!expanded) }}
                endIcon={chevronIcon}
                sx={{ ...{ paddingLeft: 0, width: 'fit-content' }, ...buttonStyle }}
            />

            {expanded && <>{props.children}</>}
        </Box>
    )
}
