import { FileRejection } from 'react-dropzone'

type ImageValidationResult = {
    acceptedFiles: File[]
    rejectedFiles: FileRejection[]
}

type SingleImageValidationResult = {
    file: File
    isValid: boolean
}

export const validateImageResolutions = async (files: File[], minWidth: number, minHeight: number): Promise<ImageValidationResult> => {
    const validationResults: SingleImageValidationResult[] = await Promise.all(
        files.map(async (file) => {
            const isValid = await doesImageHaveValidResolution(file, minWidth, minHeight)
            return { file, isValid }
        })
    )
    const acceptedFiles = validationResults.filter((result) => result.isValid).map((result) => result.file)
    const rejectedFiles = validationResults
        .filter((result) => !result.isValid)
        .map((result) => result.file)
        .map((file) => ({ file, errors: [{ code: 'resolution', message: `Image resolution must be at least ${minWidth}x${minHeight}` }] }))

    return { acceptedFiles, rejectedFiles }
}

const doesImageHaveValidResolution = async (file: File, minWidth: number, minHeight: number): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
            const isValid = img.width >= minWidth && img.height >= minHeight
            URL.revokeObjectURL(img.src)
            resolve(isValid)
        }
    })
}
