import { Colors } from '@foxtail-dev/user-clients'
import { SvgIcon } from '@mui/material'

type HomeIconProps = {
    color?: string
    height?: number
    width?: number
}

export const HomeIcon = (props: HomeIconProps) => {
    const height = props.height ?? 20
    const width = props.width ?? 18
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='18px' height='20px' viewBox='0 0 18 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>nav-home</title>
                <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='navbar' transform='translate(-56.000000, -16.000000)' fill={props.color ?? Colors.light.tertiary} fillRule='nonzero'>
                        <g id='nav-home' transform='translate(56.000000, 16.987074)'>
                            <path
                                d='M2.35077944,16.2620447 L5.45180763,16.2620447 L5.45180763,9.89541716 L11.8039138,9.89541716 L11.8039138,16.2620447 L14.904942,16.2620447 L14.904942,6.95887192 L8.6278607,2.31962397 L2.35077944,6.95887192 L2.35077944,16.2620447 Z M2.35077944,18.5816686 C1.70056385,18.5816686 1.14621338,18.3554642 0.687728027,17.9030552 C0.229242676,17.4506463 0,16.9036428 0,16.2620447 L0,6.95887192 C0,6.59694477 0.0833609729,6.25146886 0.250082919,5.92244418 C0.416804865,5.59341951 0.650215589,5.32197415 0.950315091,5.10810811 L7.20238806,0.468860165 C7.41912659,0.32079906 7.65253731,0.205640423 7.90262023,0.123384254 C8.15270315,0.0411280846 8.39444997,-4.38348926e-16 8.6278607,-4.38348926e-16 C8.87794362,-4.38348926e-16 9.12385849,0.0411280846 9.36560531,0.123384254 C9.60735213,0.205640423 9.8365948,0.32079906 10.0533333,0.468860165 L16.3054063,5.10810811 C16.6055058,5.33842538 16.8430846,5.61398355 17.0181426,5.93478261 C17.1932007,6.25558167 17.2807297,6.59694477 17.2807297,6.95887192 L17.2807297,16.2620447 C17.2807297,16.9036428 17.047319,17.4506463 16.5804975,17.9030552 C16.1136761,18.3554642 15.5551575,18.5816686 14.904942,18.5816686 L9.72822554,18.5816686 L9.72822554,11.9435958 L7.52749585,11.9435958 L7.52749585,18.5816686 L2.35077944,18.5816686 Z'
                                id='Shape'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
