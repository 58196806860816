import { SvgIcon } from '@mui/material'

type RadioButtonCheckedIconProps = {
    color?: string
    height?: number
    width?: number
}

export const RadioButtonCheckedIcon = (props: RadioButtonCheckedIconProps) => {
    const height = props.height ?? 24
    const width = props.width ?? 24
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>radio-checked</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='New-listing---details' transform='translate(-20.000000, -1276.000000)'>
                        <g id='radio-checked' transform='translate(21.000000, 1277.000000)'>
                            <rect
                                id='Rectangle'
                                stroke={props.color || '#301A36'}
                                strokeWidth='2'
                                fill='#FFFFFF'
                                x='0'
                                y='0'
                                width='22'
                                height='22'
                                rx='11'
                            ></rect>
                            <circle id='Oval' fill={props.color || '#301A36'} cx='11' cy='11' r='6'></circle>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
