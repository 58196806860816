import { AddressDescription } from '@foxtail-dev/datacontracts/dist/lib/schemas/misc/AddressDescription'
import { InventoryLocationResponse } from '@foxtail-dev/datacontracts/src/lib/schemas/domainSpecific/ebay/response/InventoryLocationResponse'

export const addressObjectToString = (address: AddressDescription) => {
    return `${address.addressLine1} ${address.addressLine2 + ', '}${address.city} ${address.stateOrProvidence} ${address.countryCode} ${address.postalCode}`
}

export const inventoryResponseObjectToString = (address?: InventoryLocationResponse['location']['address']) => {
    if (!address) {
        return ''
    } else {
        return `${address.addressLine1} ${address.addressLine2 ? address.addressLine2 + ', ' : ''}${address.city} ${address.stateOrProvince} ${
            address.country
        } ${address.postalCode}`
    }
}
