import { ChangeEvent, useRef } from 'react'
import { z } from '@foxtail-dev/datacontracts'
import { Colors, doesTagHaveSpaces, isTagLongerThan25Characters } from '@foxtail-dev/user-clients'
import { FormikField } from './FormikField'
import { Tag } from '../common/Tag'
import { Box, BoxProps, TextField, useTheme } from '@mui/material'
import { FoxIconButton } from '../common/FoxIconButton'
import { Add, AutoAwesome, Delete } from '@mui/icons-material'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { FlexGrow } from '../common/FlexGrow'
import { sharedStyles } from '../../theme/SharedStyling'

export const TagValues = z.array(z.string())
export type TagValues = z.infer<typeof TagValues>

export type TagsFieldProps = {
    name: string
    label?: string
    limit: number
    placeholder?: string
    autogenerateButton?: boolean
    handleAutogenerate?: (existingTags: string[], onChange: (value: string[]) => void) => Promise<void>
    isGenerating?: boolean
} & BoxProps

// TODO: Add AI Generation
export const TagsField = (props: TagsFieldProps) => {
    const { name, label, limit, placeholder, autogenerateButton, handleAutogenerate, isGenerating, ...otherProps } = props
    const theme = useTheme()
    const textInputRef = useRef<HTMLInputElement>(null)

    const inputStyle = {
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius
    }

    return (
        <FormikField
            name={name}
            render={({ value = [], onChange, setFieldError }) => {
                const tags = TagValues.parse(value)
                const handleDelete = (tag: string) => onChange(tags.filter((t) => t !== tag))
                const handleAdd = async () => {
                    const newTag = textInputRef.current?.value ?? ''

                    if (newTag === '') {
                        return
                    }

                    if (tags.includes(newTag)) {
                        setFieldError('Cannot use the same tag twice')
                        return
                    }

                    if (doesTagHaveSpaces(newTag)) {
                        setFieldError('Cannot use spaces in tag')
                        return
                    }

                    if (isTagLongerThan25Characters(newTag)) {
                        setFieldError('Tag cannot be longer than 25 characters')
                        return
                    }

                    onChange([...tags, newTag])

                    if (textInputRef.current) {
                        textInputRef.current.value = ''
                        textInputRef.current.focus()
                    }
                }
                const handleClearTags = async () => onChange([])

                const shouldDisplayAutogenerateButton = autogenerateButton && handleAutogenerate && tags.length < 5
                const clearTagsButtonStyle = shouldDisplayAutogenerateButton ? { marginLeft: '20px' } : {}

                return (
                    <>
                        <Box sx={[styles.buttonContainer, { display: 'flex', flexDirection: 'row', marginBottom: '16px', alignItems: 'center' }]}>
                            <FoxTypography variant='body2' sx={{ fontWeight: 'bold' }}>
                                {label}
                            </FoxTypography>
                            <FlexGrow />
                            {shouldDisplayAutogenerateButton && (
                                <FoxButton
                                    variant='contained'
                                    text={'AI generate'}
                                    onFoxClick={{ kind: 'button', onClick: async () => await handleAutogenerate(tags, onChange), preventDoubleClick: true }}
                                    loading={isGenerating}
                                    startIcon={<AutoAwesome />}
                                    sx={{ fontWeight: 'bold' }}
                                    grey
                                />
                            )}
                            {tags.length > 0 && (
                                <FoxButton
                                    variant='contained'
                                    startIcon={<Delete />}
                                    text={'Clear tags'}
                                    onFoxClick={{ kind: 'button', onClick: handleClearTags }}
                                    loading={isGenerating}
                                    sx={{ ...clearTagsButtonStyle, ...{ fontWeight: 'bold' } }}
                                    grey
                                />
                            )}
                        </Box>
                        <Box sx={[styles.container]}>
                            {tags.map((t) => (
                                <Tag key={t} onDelete={() => handleDelete(t)}>
                                    {t}
                                </Tag>
                            ))}
                            {tags.length < limit && (
                                <Box sx={{ ...inputStyle, ...styles.inputContainer }}>
                                    <TextField
                                        inputRef={textInputRef}
                                        sx={{
                                            ...styles.textInput,
                                            ...inputStyle,
                                            ...sharedStyles.textField
                                        }}
                                        placeholder={placeholder ?? 'Add tag'}
                                        onSubmit={handleAdd}
                                        size={'small'}
                                        InputProps={{
                                            endAdornment: (
                                                <FoxIconButton onFoxClick={{ kind: 'button', onClick: handleAdd }} sx={{ width: '30px', height: '30px' }}>
                                                    <Add />
                                                </FoxIconButton>
                                            )
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </>
                )
            }}
            {...otherProps}
        />
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '16px'
    },
    inputContainer: {
        width: '120px',
        height: '40px'
    },
    textInput: {
        flex: 1,
        width: '120px',
        height: '40px',
        justifyContent: 'flex-end',
        paddingHorizontal: '16px'
    },
    buttonContainer: {
        marginVertical: '16px',
        marginBottom: '80px'
    }
}
