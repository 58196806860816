import { createTheme } from '@mui/material'
import { Colors } from './Colors'

export type PaletteKeys = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'text' | 'divider' | 'background' | 'action'

// Can also override settings for specific components here
export const foxTheme = createTheme({
    palette: {
        primary: {
            main: Colors.light.primary
        },
        secondary: {
            main: Colors.light.buttonGrey
        },
        error: {
            main: Colors.light.danger
        },
        warning: {
            main: Colors.light.yellow
        },
        info: {
            main: Colors.light.tertiary
        },
        success: {
            main: Colors.light.success
        },
        text: {
            primary: Colors.light.text,
            secondary: Colors.light.lightText
        },
        divider: Colors.light.divider,
        background: {
            default: Colors.light.background
        },
        action: {
            disabled: Colors.light.buttonGrey
        }
    },
    typography: {
        allVariants: {
            textTransform: 'none',
            fontFamily: 'Ubuntu'
        },
        h1: {
            fontSize: '80px',
            lineHeight: '96px',
            color: Colors.light.text,
            fontWeight: 400
        },
        h3: {
            fontSize: '36px',
            lineHeight: '40px',
            color: Colors.light.text,
            fontWeight: 500
        },
        h4: {
            fontSize: '32px',
            lineHeight: '40px',
            color: Colors.light.text,
            fontWeight: 500
        },
        h5: {
            fontSize: '24px',
            lineHeight: '32px',
            color: Colors.light.text,
            fontWeight: 500
        },
        h6: {
            fontSize: '20px',
            lineHeight: '32px',
            color: Colors.light.text,
            fontWeight: 500
        },
        body1: {
            fontSize: '14px',
            lineHeight: '20px',
            color: Colors.light.text,
            fontWeight: 400
        },
        body2: {
            fontSize: '16px',
            lineHeight: '24px',
            color: Colors.light.text,
            fontWeight: 400
        },
        subtitle1: {
            fontSize: '16px',
            lineHeight: '20px',
            color: Colors.light.text,
            fontWeight: 500
        },
        subtitle2: {
            fontSize: '14px',
            lineHeight: '20px',
            color: Colors.light.text,
            fontWeight: 500
        }
    },
    shape: {
        borderRadius: 4
    }
})
