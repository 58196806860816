import { useAppSelector, selectImportListingScrapedMarketListingInfos } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxCircleLoader } from '../LoadingCircle'
import { FoxTypography } from '../common/FoxTypography'
import { FoxButton } from '../common/FoxButton'

type ScrapingListingsImportListingSession = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}
export const ScrapingListingsImportListingSession = (props: ScrapingListingsImportListingSession) => {
    const scrapedMarketListingInfos = useAppSelector(selectImportListingScrapedMarketListingInfos)
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerBody}>
                <FoxCircleLoader size={64} />
                <FoxTypography variant='h6' sx={styles.textScrapeCount}>
                    {scrapedMarketListingInfos.length} listings found
                </FoxTypography>
                <FoxTypography variant='subtitle1' sx={styles.textLeavePage} light>
                    You can leave this page, importing your listings can take up to 30 minutes.
                </FoxTypography>
            </Box>
            <FoxButton
                primary
                sx={styles.button}
                text='Cancel import'
                onFoxClick={{
                    kind: 'button',
                    onClick: async () => {
                        props.setIsRequestingCancel(true)
                    }
                }}
            />
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerBody: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    textScrapeCount: {
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    textLeavePage: {
        marginTop: '24px',
        textAlign: 'center',
        marginLeft: '60px',
        marginRight: '60px'
    },
    button: {
        width: '100%',
        justifyContent: 'center'
    }
}
