import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'

export const PoshmarkListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    // TODO: I think this should be optional, but the zod parse doesn't work without it
    return (
        <>
            <RadioBoolGroupField
                label='New with tags'
                name='domainDetails.poshmark.suggestionDefaults.optionalDetails.tagsAttached'
                iconType='Radio'
                options={[
                    [true, 'Yes'],
                    [false, 'No']
                ]}
                logOnChange
            />
        </>
    )
}
