import { Actions, FoxError, assert } from '@foxtail-dev/datacontracts'
import { CreateImageDocAndUploadUrl, IImageClient, ImageId, ImageInfo, ImageLocator, ImageUri, SubmitUploadComplete } from '@foxtail-dev/user-clients'

export type ImageClientParams = {
    getImageDownloadUrl: (imageId: string) => Promise<string>
    createImageDocAndUploadUrl: CreateImageDocAndUploadUrl
    submitUploadComplete: SubmitUploadComplete
}

// ImageUri for web = downloadUrl

export class ImageClient implements IImageClient {
    readonly #getImageDownloadUrl
    readonly #createImageDocAndUploadUrl
    readonly #submitUploadComplete

    constructor(params: ImageClientParams) {
        this.#getImageDownloadUrl = params.getImageDownloadUrl
        this.#createImageDocAndUploadUrl = params.createImageDocAndUploadUrl
        this.#submitUploadComplete = params.submitUploadComplete
    }

    get = async (imageId: ImageId): Promise<ImageUri> => {
        const downloadUrl = await this.#getImageDownloadUrl(imageId)
        return downloadUrl
    }

    upload = async (imageInfo: ImageInfo): Promise<ImageLocator> => {
        assert(imageInfo.kind === 'web', 'Image file must be provided to web image client')

        const file = imageInfo.file

        const uploadUrlResponse = await this.#createImageDocAndUploadUrl()

        assert(uploadUrlResponse.kind === 'gotUploadUrl', 'Expected gotUploadUrl result kind')

        const { imageId, uploadUrl } = uploadUrlResponse

        const arrayBuffer = await file.arrayBuffer()

        const response = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            body: arrayBuffer
        })

        if (!response.ok) {
            throw new FoxError({
                message: `Upload failed with status: ${response.status}`,
                serializableObjects: { uploadUrl, response }
            })
        }

        const submitUploadCompleteResponse = await this.#submitUploadComplete(imageId)

        assert(submitUploadCompleteResponse.kind === 'uploadCompleted', 'Expected uploadComplete result kind')

        const downloadUrl = await this.#getImageDownloadUrl(imageId)

        return {
            id: imageId,
            uri: downloadUrl
        }
    }

    preload = async (imageUris: ImageUri[]): Promise<void> => {
        await Promise.all(imageUris.map((downloadUrl) => this.loadImageInBackground(downloadUrl)))
    }

    private loadImageInBackground = (url: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = url
            img.onload = () => resolve()
            img.onerror = (err) => reject(err)
        })
    }
}

export class DummyImageClient implements IImageClient {
    constructor() {}

    get = async (imageId: string): Promise<string> => {
        return ''
    }

    upload = async (imageInfo: ImageInfo): Promise<ImageLocator> => {
        return {
            id: '',
            uri: ''
        }
    }

    preload = async (imagePaths: string[]): Promise<void> => {}
}
