import { ListingOperationKind } from '@foxtail-dev/user-clients'
import { DetailSelection } from '../../components/common/DetailSelection'
import { EtsyProductionPartnersDetailSelection } from '../../containers/forms/listingDetails/EtsyProductionPartnersDetailSelection'
import { updateListingDefaultFieldInFormik, updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { useFormikContext } from 'formik'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'

type EtsyProductionPartnersModalParams = {
    listingOperationKind: ListingOperationKind
} & DomainSpecificDetailModalChildProps

export const EtsyProductionPartnersModal = (props: EtsyProductionPartnersModalParams) => {
    const { listingOperationKind, closeModal } = props
    const { setFieldTouched, setFieldValue } = useFormikContext() // TODO: Type this

    const handleSave = (item: any) => {
        if (listingOperationKind === 'create' || listingOperationKind === 'edit' || listingOperationKind === 'bulkCrosslist') {
            updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'productionPartner',
                domainName: 'etsy',
                isRequired: false,
                value: item
            })
        } else if (listingOperationKind === 'setDefault') {
            updateListingDefaultFieldInFormik(setFieldValue, setFieldTouched, {
                paramName: 'productionPartner',
                domainName: 'etsy',
                isRequired: false,
                value: item
            })
        }
        closeModal()
    }

    return (
        <EtsyProductionPartnersDetailSelection
            title='Production Partner'
            renderItem={(item) => {
                return <DetailSelection key={item} value={item} onDetail={() => handleSave(item)} />
            }}
            sentry-label='EtsyProductionPartnersScreen'
        />
    )
}
