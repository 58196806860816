import { DepopListingDetailsDraft, DepopCondition, DepopShippingOption } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { DepopShippingOptionSubTextMap, sanitizeZodObject, upperCaseFirstLetter } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { PriceField } from '../../../../components/formik/PriceField'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { DepopCategoryModal } from '../../../../modals/domainSpecific/DepopCategoryModal'
import { useTaxonomyProvider } from '../../../../lib/TaxonomyProvider'

export const DepopFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props

    const taxonomyProvider = useTaxonomyProvider()
    const depopTaxonomy = taxonomyProvider?.depop

    const [modalOpen, setModalOpen] = useState<string>('none')
    const depopDetails = useMemo(() => {
        return sanitizeZodObject(values, DepopListingDetailsDraft)
    }, [values])

    const requiredDetails = depopDetails.requiredDetails
    const optionalDetails = depopDetails.optionalDetails
    const depopCategoryId = requiredDetails.categoryId
    const isShipWithDepop = requiredDetails.shipWithDepop

    const onCloseModal = () => {
        setModalOpen('none')
    }

    const sizes = useMemo(() => {
        if (!depopCategoryId || !depopTaxonomy) {
            return []
        }

        const sizes = depopTaxonomy.tryFindSizeOptionsFromNodeId(depopCategoryId).sizes

        const sizesMapped = sizes.map((size) => size.text)
        return sizesMapped
    }, [depopCategoryId, depopTaxonomy])

    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.depop.requiredDetails.categoryId'
                label='Category'
                placeholder='Select Category'
                renderValue={(value) => (value ? <FoxTypography variant='body1'>{value}</FoxTypography> : null)}
                onDetail={() => setModalOpen('DepopCategory')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'DepopCategory'} title={'Category'} closeModal={onCloseModal}>
                <DepopCategoryModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            <DetailSelectionField
                name='domainSpecificDetails.depop.requiredDetails.condition'
                label='Condition'
                placeholder='Select condition'
                options={DepopCondition.options}
                onDetail={() => setModalOpen('DepopCondition')}
            />
            <DetailSelectionModal
                open={modalOpen === 'DepopCondition'}
                headerTitle={'Condition'}
                options={DepopCondition.options}
                paramName={'condition'}
                domainName={'depop'}
                isRequired={true}
                currentValue={requiredDetails.condition}
                closeModal={onCloseModal}
                listingOperationKind={listingOperationKind}
            />

            {sizes.length > 0 && (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.depop.requiredDetails.size'
                        label='Size'
                        placeholder='Select Size'
                        renderValue={(value) => (value ? <FoxTypography variant='body1'>{value}</FoxTypography> : null)}
                        onDetail={() => setModalOpen('DepopSize')}
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'DepopSize'}
                        headerTitle={'Size'}
                        options={sizes}
                        paramName={'size'}
                        domainName={'depop'}
                        isRequired={true}
                        currentValue={requiredDetails?.size ? upperCaseFirstLetter(requiredDetails.size) : ''}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                    />
                </>
            )}

            <RadioBoolGroupField
                label='Ship with Depop'
                name='domainSpecificDetails.depop.requiredDetails.shipWithDepop'
                iconType='Radio'
                options={[
                    [true, 'Depop Shipping'],
                    [false, 'Other'] // TODO: Fix text
                ]}
                logOnChange
            />

            {isShipWithDepop ? (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.depop.optionalDetails.shippingOption'
                        label='Shipping option'
                        placeholder='Select Shipping Option'
                        renderValue={(value) =>
                            value ? (
                                <Box sx={{ flexDirection: 'column' }}>
                                    <FoxTypography variant='body1'>{value}</FoxTypography>
                                    <FoxTypography variant='body1' light sx={{ fontSize: '12px', paddingLeft: '8px' }}>
                                        {DepopShippingOptionSubTextMap[DepopShippingOption.parse(value)]}
                                    </FoxTypography>
                                </Box>
                            ) : null
                        }
                        onDetail={() => setModalOpen('DepopShippingOption')}
                    />

                    <DetailSelectionModal
                        open={modalOpen === 'DepopShippingOption'}
                        headerTitle={'Shipping Option'}
                        options={DepopShippingOption.options}
                        paramName={'shippingOption'}
                        domainName={'depop'}
                        isRequired={false}
                        currentValue={optionalDetails?.shippingOption ?? ''}
                        closeModal={onCloseModal}
                        listingOperationKind={listingOperationKind}
                        subtextMap={DepopShippingOptionSubTextMap}
                    />
                </>
            ) : (
                <>
                    <PriceField
                        name='domainSpecificDetails.depop.optionalDetails.shippingPrice'
                        label='Shipping Price'
                        logOnBlur
                        initialValue={optionalDetails.shippingPrice}
                    />
                </>
            )}
        </>
    )
}
