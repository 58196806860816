import { getFacebookCategoryNamesFromId } from '@foxtail-dev/user-clients'
import { DetailSelection } from '../../components/common/DetailSelection'
import { CategorySelection } from '../../containers/forms/listingDetails/CategorySelection'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { updateMarketSpecificFieldInFormik } from './DomainSpecificFormikUtils'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

export const FacebookCategoryModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const taxonomyProvider = useTaxonomyProvider()
    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = (result: CategorySimilarityMatch) => {
        updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
            paramName: 'categoryId',
            domainName: 'facebook',
            isRequired: true,
            value: result.categoryId
        })

        closeModal()
    }

    const renderItem = (item: CategorySimilarityMatch) => {
        const categoryId = item.categoryId
        let categoryNames: string[]

        if (taxonomyProvider) {
            const facebookTaxonomy = taxonomyProvider.facebook
            categoryNames = getFacebookCategoryNamesFromId(facebookTaxonomy, categoryId)
        } else {
            categoryNames = item.categoryNameArray
        }

        return <DetailSelection key={categoryId} onDetail={() => handleSave(item)} value={categoryNames.join(', ')} />
    }

    return <CategorySelection title='Facebook Category' renderItem={renderItem} domain='facebook' />
}
