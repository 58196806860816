import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { AccountRequirementsModalProps } from '../../../containers/account/AccountRequirementsModal'

export const DepopAccountRequirementsModal = ({ open, onClose }: AccountRequirementsModalProps) => {
    return (
        <FoxModalDialog open={open} title={'Requirements for linking Depop'} leftButtonKind={'close'} onClose={() => onClose(false)} closeOnBackdropClick>
            <Box sx={{ textAlign: 'center' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    Seller hub fully set up
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginBottom: '40px' }}>
                    For more info{' '}
                    <Link href='https://help.foxtail.ai/en/articles/8054964-marketplace-setup-and-requirements#h_a12041f0c8' target='_blank' rel='noopener'>
                        click here.
                    </Link>
                </FoxTypography>
                <FoxButton
                    primary
                    onFoxClick={{ kind: 'button', onClick: async () => onClose(true) }}
                    text={'Link'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
