import { HydratedUserQuest, UserQuest, UserQuestKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'

// TODO: This should maybe be a selector, consider it
export const shouldCheckQuestCompletion = (kind: UserQuestKind, userQuests: HydratedUserQuest[]) => {
    let shouldCheck = false
    for (const userQuest of userQuests) {
        if (userQuest.quest.kind === kind && userQuest.quest.details.status === 'active') {
            shouldCheck = true
            break
        }
    }

    return shouldCheck
}
