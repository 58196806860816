import { Box } from '@mui/material'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { FoxButton, FoxButtonProps } from '../common/FoxButton'

// This component is a style-less version of the AddVideoDropzone component
// To be used when we need it to be a button instead of a dropzone
export type AddVideoButtonProps = {
    onAddVideo: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void
    buttonProps: FoxButtonProps
}

export const AddVideoButton = ({ onAddVideo, buttonProps }: AddVideoButtonProps) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onAddVideo,
        maxFiles: 1,
        accept: {
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
            'video/x-matroska': ['.mkv'],
            'video/x-ms-wmv': ['.wmv'],
            'video/x-flv': ['.flv'],
            'video/webm': ['.webm']
        },
        maxSize: 100 * 1024 * 1024 // 100MB, we'll see if this is too large
    })

    return (
        <Box
            {...getRootProps()}
            sx={{
                cursor: 'pointer'
            }}>
            <input {...getInputProps()} />
            <FoxButton {...buttonProps} />
        </Box>
    )
}
