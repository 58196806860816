import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

export const bulkCrosslistingMarketOptions: [DomainCommonName, string][] = [
    ['ebay', Domains.ebay.displayName],
    ['etsy', Domains.etsy.displayName],
    ['poshmark', Domains.poshmark.displayName],
    ['mercari', Domains.mercari.displayName],
    ['depop', Domains.depop.displayName],
    ['facebook', Domains.facebook.displayName]
]
