import React from 'react'
import { EtsyFields } from './EtsyFields'
import { PoshmarkFields } from './PoshmarkFields'
import { DomainCommonName } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { MercariFields } from './MercariFields'
import { EbayFields } from './EbayFields'
import { DomainListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { DepopFields } from './DepopFields'
import { FacebookFields } from './FacebookFields'
import { ListingOperationKind } from '@foxtail-dev/user-clients'

export const MarketSpecificFieldsMap = {
    [DomainCommonName.Enum.etsy]: EtsyFields,
    [DomainCommonName.Enum.poshmark]: PoshmarkFields,
    [DomainCommonName.Enum.mercari]: MercariFields,
    [DomainCommonName.Enum.ebay]: EbayFields,
    [DomainCommonName.Enum.depop]: DepopFields,
    [DomainCommonName.Enum.facebook]: FacebookFields
} as const

export type MarketSpecificFieldsProps = {
    values: DomainListingDetailsDraft | undefined
    listingOperationKind: ListingOperationKind
}
