import { exposeFoxtailAccessToken } from '../../lib/clients/foxtailWebExtensionClient/exposeFoxtailAccessToken'
import { AppDispatch, setCredentials } from '@foxtail-dev/user-clients'

type InitializeAppAsyncParams = {
    setFinishedReloginProcess: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: AppDispatch
    getAccessTokenSilently: () => Promise<string>
}

export const initializeAppAsync = async (params: InitializeAppAsyncParams) => {
    const { setFinishedReloginProcess, dispatch, getAccessTokenSilently } = params

    try {
        const accessToken = await getAccessTokenSilently()
        exposeFoxtailAccessToken(accessToken)
        dispatch(setCredentials({ credentials: { accessToken } }))
    } catch (error) {
        setFinishedReloginProcess(true)
    }
}
