import { StageContentAndActions } from '../../screens/listing/ListingsFromVideoScreen'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'

type FinishedContentProps = {}

export const FinishedContent = ({}: FinishedContentProps): StageContentAndActions => {
    return {
        actions: (
            <FoxButton
                text='Go to listings'
                primary
                onFoxClick={{ kind: 'internal', to: '/app/listings?tab=draft' }}
                sx={{ width: '141px', height: '48px', display: 'flex', justifyContent: 'center' }}
            />
        ),
        content: (
            <FoxTypography variant='h6' sx={{ marginTop: '36px' }}>
                Finished creating your listings! They are saved to your drafts.
            </FoxTypography>
        )
    }
}
