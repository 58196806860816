import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxButton } from '../../../components/common/FoxButton'
import { useState } from 'react'
import { EbaySelectInventoryLocationModal } from './EbaySelectInventoryLocationModal'
import { EbayCreateInventoryLocationModal } from './EbayCreateInventoryLocationModal'

type EbayInventoryLocationModalProps = {
    open: boolean
    onClose: () => void
}

type EbayInventoryLocationStage = 'select' | 'create'

export const EbayInventoryLocationModal = ({ open, onClose }: EbayInventoryLocationModalProps) => {
    const [stage, setStage] = useState<EbayInventoryLocationStage>('select')

    const onGoToSelectLocation = () => {
        setStage('select')
    }

    const onGoToCreateLocation = async () => {
        setStage('create')
    }

    const onSelectModalClose = (isConfirmed: boolean) => {
        if (isConfirmed) {
            onClose()
        } else {
            setStage('create')
        }
    }

    const onCreateModalClose = () => {
        onClose()
    }

    const isSelecting = stage === 'select'

    return (
        <FoxModalDialog
            open={open}
            title={'eBay Inventory Location'}
            leftButtonKind={isSelecting ? 'close' : 'back'}
            onClose={onClose}
            closeOnBackdropClick
            iconOverrideOnClose={isSelecting ? undefined : onGoToSelectLocation}
            actions={
                isSelecting ? (
                    <FoxButton
                        primary
                        onFoxClick={{ kind: 'button', onClick: onGoToCreateLocation }}
                        text={'Create new inventory location'}
                        sx={{ width: '100%', justifyContent: 'center' }}
                    />
                ) : undefined
            }
        >
            {isSelecting ? (
                <EbaySelectInventoryLocationModal onClose={onSelectModalClose} />
            ) : (
                <EbayCreateInventoryLocationModal onClose={onCreateModalClose} />
            )}
        </FoxModalDialog>
    )
}
