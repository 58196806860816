import { ButtonBase, ButtonBaseProps, SxProps, Theme } from '@mui/material'
import React from 'react'
import { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'

type FoxButtonOnClickProps = {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => Promise<void> | void
    kind: 'button'
    preventDoubleClick?: boolean
}

type FoxButtonInternalLinkProps = {
    to: string
    onClick?: MouseEventHandler<HTMLAnchorElement>
    kind: 'internal'
}

type FoxButtonExternalLinkProps = {
    href: string
    onClick?: MouseEventHandler<HTMLAnchorElement>
    kind: 'external'
}

export type OnFoxClick = FoxButtonOnClickProps | FoxButtonExternalLinkProps | FoxButtonInternalLinkProps

// Component for button behavior without default button styling, has basic hover and focus effects
type FoxButtonBaseProps = {
    children?: React.ReactNode
    disabled?: boolean
    onFoxClick: OnFoxClick
    loading?: boolean
    sx?: SxProps<ButtonBaseProps>
} & ButtonBaseProps

// TODO: Consider hover and pressed behavior, not exactly sure what we want
export const FoxButtonBase = ({ children, disabled, onFoxClick, loading, sx, onClick: _, ...rest }: FoxButtonBaseProps) => {
    const disabledCSS = disabled ? styles.disabled : {}

    const component = onFoxClick.kind === 'external' ? 'a' : onFoxClick.kind === 'internal' ? Link : 'button'
    const href = onFoxClick.kind === 'external' ? onFoxClick.href : undefined
    const to = onFoxClick.kind === 'internal' ? onFoxClick.to : undefined

    const [awaitingOnClick, setAwaitingOnClick] = React.useState(false)
    const isButtonLoading = loading || awaitingOnClick

    let onClick: ((e: React.MouseEvent<HTMLAnchorElement>) => Promise<void> | void) | undefined
    if (onFoxClick.kind === 'button') {
        if (onFoxClick.preventDoubleClick) {
            onClick = async (e) => {
                if (awaitingOnClick) {
                    return
                }

                setAwaitingOnClick(true)
                try {
                    await onFoxClick.onClick(e)
                } finally {
                    setAwaitingOnClick(false)
                }
            }
        } else {
            onClick = onFoxClick.onClick
        }
    }

    // TODO: Fix weird build error with sx
    return (
        <ButtonBase
            disableRipple
            disabled={disabled || isButtonLoading}
            component={component}
            href={href}
            to={to}
            target={onFoxClick.kind === 'external' ? '_blank' : undefined}
            referrer={onFoxClick.kind === 'external' ? 'noreferrer' : undefined}
            onClick={onClick}
            sx={{ ...styles.buttonBase, ...disabledCSS, ...sx } as SxProps<Theme>}
            {...rest}>
            {children}
        </ButtonBase>
    )
}

const styles = {
    buttonBase: {
        alignItems: 'initial',
        justifyContent: 'initial',
        textAlign: 'initial'
    },
    disabled: {
        opacity: 0.7
    }
}
