// TODO: Move to user clients
export const Colors = {
    light: {
        primary: '#E87155',
        secondary: '#E84855',
        tertiary: '#301A36',
        text: '#301A36',
        lightText: '#817783',
        background: '#FFFFFF',
        tint: '#E87155',
        unfocusedInput: '#FAF7FA',
        chevronGrey: '#CBC0CD',
        buttonGrey: '#EFEBF0',
        divider: '#F2ECF3',
        success: '#31C46C',
        danger: '#DA2F2F',
        black: '#000000',
        white: '#FFFFFF',
        yellow: '#FFB30F',
        google: '#4285F6',
        apple: '#000000',
        foxGradient: 'linear-gradient(84.45deg, #E84855 0%, #E87155 100%)'
    }
}
