import ReactGA from 'react-ga4'
import { Logger } from '../../lib/clients/Logger'

export const setGAUserInformation = (userId?: string) => {
    if (!userId) {
        Logger.I().log({
            level: 'error',
            payload: {
                kind: 'System'
            },
            message: 'UserId is not defined when trying to set GA user information'
        })

        return
    }
    ReactGA.set({
        userId
    })
}

export const resetGAUserInformation = () => {
    ReactGA.set({
        userId: null,
        email: null
    })
}
