import { selectLoggedInAndReadyToListDomains, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { RadioGroupField } from './RadioGroupField'
import { ConnectMoreSitesLink } from '../account/ConnectMoreSitesLink'

const ImportListingMarketOptionsEnabled: [DomainCommonName, string][] = [
    ['ebay', 'eBay'],
    ['poshmark', 'Poshmark']
]
type ImportListingMarketSelectionFieldProps = {}
export const ImportListingMarketSelectionField = ({}: ImportListingMarketSelectionFieldProps) => {
    const importReadyMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)

    // TODO: Make the divider start from the text
    return (
        <Box>
            <FoxTypography variant='body2' sx={styles.titleWhere}>
                Where?
            </FoxTypography>
            <RadioGroupField
                iconType='Checkbox'
                name='sourceOfTruthMarket'
                options={ImportListingMarketOptionsEnabled.filter(([market]) => importReadyMarkets.includes(market))}
                ignoreError={true}
            />
            <Box sx={{ marginRight: '12px' }}>
                <ConnectMoreSitesLink />
            </Box>
        </Box>
    )
}

const styles = {
    titleWhere: {
        marginTop: '24px',
        marginBottom: '12px',
        fontWeight: 'bold'
    },
    connectIcon: {
        marginRight: '10px',
        marginLeft: '4px'
    }
}
