export const getDeviceType = () => {
    const userAgent = navigator.userAgent

    // Check for iPhone
    if (/iPhone/i.test(userAgent)) {
        return 'ios'
    }

    // Check for Android
    if (/Android/i.test(userAgent)) {
        return 'android'
    }

    return 'web' // For other devices
}

// const getPlatform = () => {
//     const navigator = window.navigator

//     const isMobile = navigator.userAgentData?.mobile

//     const platform = navigator.userAgentData?.platform
// }
