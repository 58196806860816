import React, { useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    activeChartsSelector,
    getAnalyticsChartData,
    getAnalyticsStats,
    getDomainLinkingStatus,
    selectActiveAuthedOrAuthingDomains,
    selectAppLoaded,
    selectHasActiveSubscription,
    selectHasBasicInfo,
    selectHasSeenConfetti,
    selectHydratedQuestsSorted,
    selectIsLoggedInToADomain,
    selectIsUserVerified,
    selectLoggedInAndReadyToListDomains,
    selectTimeRange,
    selectUserInfo,
    TimeRange,
    totalListedListingsStatSelector,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box, useMediaQuery } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { LinkMarketsBanner } from '../../components/home/LinkMarketsBanner'
import { CreateFirstListingBanner } from '../../components/home/CreateFirstListingBanner'
import { getGreeting } from '../../utils/getGreeting'
import { ChartMarketButtons } from '../../components/home/ChartMarketButtons'
import { ReportsOverviewHeader } from '../../components/home/ReportsOverViewHeader'
import { LinkedMarketCards } from '../../components/home/LinkedMarketCards'
import { AllCharts } from '../../components/home/AllCharts'
import { AnalyticsBoxes } from '../../components/home/AnalyticsBoxes'
import { FlexGrow } from '../../components/common/FlexGrow'
import { EditChartsMenu } from '../../components/home/EditChartsMenu'
import { LoadingScreen } from '../LoadingScreen'
import { ChecklistBanner } from '../../containers/home/ChecklistBanner'
import { FoxButton } from '../../components/common/FoxButton'

const HomeScreen: React.FC = () => {
    const navigate = useNavigate()

    const appLoaded = useAppSelector(selectAppLoaded)
    const isUserVerified = useAppSelector(selectIsUserVerified)
    const hasBasicInfo = useAppSelector(selectHasBasicInfo)
    const hasActiveSubscription = useAppSelector(selectHasActiveSubscription)
    const userInfo = useAppSelector(selectUserInfo)
    const dispatch = useAppDispatch()

    const isLoggedIntoAMarket = useAppSelector(selectIsLoggedInToADomain)
    const activeCharts = useAppSelector(activeChartsSelector)
    const totalListedListings = useAppSelector(totalListedListingsStatSelector)

    const authedOrAuthingDomains = useAppSelector(selectActiveAuthedOrAuthingDomains)
    const hasSeenConfetti = useAppSelector(selectHasSeenConfetti)
    const currentTimeRangeRef = useRef<TimeRange>('week')
    currentTimeRangeRef.current = useAppSelector(selectTimeRange)
    const activeMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)
    const isMobile = useMediaQuery('(max-width:600px)')
    const [hasFinishedRedirecting, setHasFinishedRedirecting] = React.useState(false)
    const quests = useAppSelector(selectHydratedQuestsSorted)

    const areAllQuestsCompleted = useMemo(() => {
        return quests.every((quest) => quest.quest.details.status === 'completed')
    }, [quests])

    useEffect(() => {
        dispatch(getAnalyticsChartData(currentTimeRangeRef.current))
        dispatch(getAnalyticsStats())

        const domainLinkingAndAnalyticsTotalsStatusInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)

        const chartStatsInterval = setInterval(() => {
            dispatch(getAnalyticsChartData(currentTimeRangeRef.current))
            dispatch(getAnalyticsStats())
        }, 30000)

        return () => {
            clearInterval(domainLinkingAndAnalyticsTotalsStatusInterval)
            clearInterval(chartStatsInterval)
        }
    }, [])

    useEffect(() => {
        if (appLoaded) {
            if (isUserVerified === false) {
                navigate('/enter-phone-number')
            } else if (!hasBasicInfo) {
                navigate('/basic-info')
            } else if (hasSeenConfetti === false) {
                navigate('/sign-up-complete')
            } else if (isMobile) {
                navigate('/mobile')
            }
            setHasFinishedRedirecting(true)
        }
    }, [appLoaded, isMobile])

    // TODO: Sometimes seeing a brief flash of the home screen before redirecting to enter phone number, investigate and see why
    // In the mean time, show a loading screen until we navigate to the correct page
    if (!hasFinishedRedirecting) {
        return <LoadingScreen />
    }

    return (
        <Box sx={styles.container}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FoxTypography sx={{ marginBottom: '35px', marginTop: '15px' }} variant='h5'>
                    {getGreeting()}, {userInfo?.firstName}!
                </FoxTypography>
                <FlexGrow />
                <FoxButton
                    primary
                    variant='contained'
                    text={authedOrAuthingDomains.length > 0 ? '+ New Listing' : '+ Connect Markets'}
                    onFoxClick={{
                        kind: 'internal',
                        to: authedOrAuthingDomains.length > 0 ? '/app/create-listing/new' : '/app/account/marketplace-connections'
                    }}
                    sx={styles.headerButton}
                />
            </Box>

            {/* {!areAllQuestsCompleted && <ChecklistBanner imageSize='large' />} */}

            {authedOrAuthingDomains.length === 0 && <LinkMarketsBanner />}

            {authedOrAuthingDomains.length > 0 && totalListedListings === 0 && <CreateFirstListingBanner />}

            <AnalyticsBoxes />

            <ReportsOverviewHeader currentTimeRangeRef={currentTimeRangeRef} />

            <Box sx={{ display: 'flex', marginTop: '32px', height: '48px', alignItems: 'center' }}>
                <ChartMarketButtons activeMarkets={activeMarkets} />
                <FlexGrow />
                <EditChartsMenu />
            </Box>

            <AllCharts activeCharts={activeCharts} />

            <LinkedMarketCards authedOrAuthingDomains={authedOrAuthingDomains} />
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    headerButton: {
        height: '48px',
        width: 'fit-content'
    }
}

export default HomeScreen
