import { SvgIcon } from '@mui/material'

type BellIconProps = {
    color?: string
    height?: number
    width?: number
}

export const BoltIcon = (props: BellIconProps) => {
    const height = props.height ?? 14
    const width = props.width ?? 11
    return (
        <SvgIcon viewBox='0 0 11 14' style={{ height, width }}>
            <svg width='11px' height='14px' viewBox='0 0 11 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>bolt</title>
                <g id='Upgrade-components' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='upgrade-now' transform='translate(-1200, -23)' fill={props.color ?? '#E84855'} fillRule='nonzero'>
                        <g id='Group-15' transform='translate(1185, 14.5)'>
                            <g id='bolt_24dp_FILL1_wght400_GRAD0_opsz24' transform='translate(15, 8.5)'>
                                <polygon id='Path' points='2.75 14 3.4375 9.1 0 9.1 6.1875 0 7.5625 0 6.875 5.6 11 5.6 4.125 14'></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
