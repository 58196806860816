import { AiTaskInfo, Colors } from '@foxtail-dev/user-clients'
import { BaseStatusRow } from './BaseStatusRow'
import { Box } from '@mui/material'
import { OnFoxClick } from '../../components/common/FoxButtonBase'
import { SparkleIcon } from '../../components/icons/SparkleIcon'

type AiTaskStatusRowProps = {
    aiTask: AiTaskInfo
}

type BaseStatusRowProps = {
    text: string
    subtext: string
    onFoxClick: OnFoxClick
}

const getProps = (aiTask: AiTaskInfo): BaseStatusRowProps => {
    let text: string
    let subtext: string
    let onFoxClick: OnFoxClick

    switch (aiTask.kind) {
        case 'createDraftsFromVideo':
            text = `Generating draft listings from video "${aiTask.videoName}"`
            subtext = 'AI is working its magic'
            // TODO: Is there anything we want to show here
            onFoxClick = { kind: 'button', onClick: async () => {} }
            break
        case 'generateDetails':
            text = `Autofilling listing details for "${aiTask.listingTitle}"`
            subtext = 'Preparing your listing'
            onFoxClick = { kind: 'internal', to: `/app/create-listing/${aiTask.listingId}` }
            break
    }

    return {
        text,
        subtext,
        onFoxClick
    }
}

export const AiTaskStatusRow = ({ aiTask }: AiTaskStatusRowProps): JSX.Element => {
    const { text, subtext, onFoxClick } = getProps(aiTask)

    return (
        <BaseStatusRow
            text={text}
            subtext={subtext}
            image={
                <Box sx={{ ...styles.image }}>
                    <SparkleIcon height={36} width={36} color={Colors.light.secondary} />
                </Box>
            }
            onFoxClick={onFoxClick}
        />
    )
}

const styles = {
    image: {
        height: '64px',
        width: '64px',
        border: '1px solid #EBE7F1',
        backgroundColor: 'rgba(232, 113, 85, 0.10)',
        borderRadius: '10px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}
