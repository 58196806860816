import { Box, Divider } from '@mui/material'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import {
    EngagementBoosterSpecificFieldsMap,
    EngagementBoosterToDomainMap,
    EngagementBoosterToHelpArticleMap,
    EngagementBoosterToTitleMap
} from '../../containers/engagementBoosters/EngagementBoosterSpecificFieldsMap'
import { EngagementBoostersData } from '../../screens/engagementBoosters/EngagementBoostersScreen'
import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'
import { createInitialEngagementBoosterConfig } from '../../utils/createInitialEngagementBoosterConfig'
import {
    selectEngagementBoostersConfig,
    selectLoggedInAndReadyToListDomains,
    selectSubscription,
    setEngagementBoosters,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Logger } from '../../lib/clients/Logger'
import { FlexGrow } from '../../components/common/FlexGrow'
import { assert } from '@foxtail-dev/datacontracts'
import { DomainGuardModal } from '../account/DomainGuardModal'
import { useState } from 'react'
import { UpgradeGuardModal } from '../account/UpgradeGuardModal'
import { HelpIcon } from '../../components/icons/HelpIcon'
import * as Intercom from '@intercom/messenger-js-sdk'

type EditEngagementBoosterModalProps = {
    closeModal: () => void
    openBoosterKind: UserEngagementBoosterKind | null
}

export const EditEngagementBoosterModal = ({ closeModal, openBoosterKind }: EditEngagementBoosterModalProps) => {
    const title = openBoosterKind ? EngagementBoosterToTitleMap[openBoosterKind] : ''

    return (
        <FoxModalDialog open={!!openBoosterKind} title={title} leftButtonKind={'back'} onClose={closeModal}>
            {openBoosterKind && <EditEngagementBoosterForm closeModal={closeModal} boosterKind={openBoosterKind} />}
        </FoxModalDialog>
    )
}

type EditEngagementBoosterFormProps = {
    closeModal: () => void
    boosterKind: UserEngagementBoosterKind
}

const EditEngagementBoosterForm = ({ closeModal, boosterKind }: EditEngagementBoosterFormProps) => {
    const dispatch = useAppDispatch()
    const engagementBoosterConfig = useAppSelector(selectEngagementBoostersConfig)
    const loggedInDomains = useAppSelector(selectLoggedInAndReadyToListDomains)
    const subscription = useAppSelector(selectSubscription)

    const [showDomainGuardModal, setShowDomainGuardModal] = useState(false)
    const [showUpgradeGuardModal, setShowUpgradeGuardModal] = useState(false)

    let initialValues: EngagementBoostersData = {}
    if (engagementBoosterConfig && engagementBoosterConfig[boosterKind]) {
        initialValues[boosterKind] = engagementBoosterConfig[boosterKind]
    } else {
        initialValues[boosterKind] = createInitialEngagementBoosterConfig(boosterKind)
    }

    const onHelp = (articleId: string) => {
        Logger.I().log({
            level: 'info',
            message: 'Clicking info button',
            payload: {
                kind: 'UserAction',
                entry: {}
            }
        })
        Intercom.showArticle(articleId)
    }

    const onSubmit = async (values: EngagementBoostersData, helpers: FormikHelpers<EngagementBoostersData>) => {
        Logger.I().log({
            level: 'info',
            message: 'Submitting engagement boosters',
            payload: {
                kind: 'UserAction',
                entry: {
                    values: values
                }
            }
        })

        try {
            // Special checks, could be moved to another file
            if (boosterKind === 'poshmarkShareCloset') {
                assert(values.poshmarkShareCloset, 'poshmarkShareClosetValues is not defined')

                const sharePeriodMs = values.poshmarkShareCloset.sharePeriodMs
                const sharePeriodHours = sharePeriodMs === 0 ? 0 : sharePeriodMs / 1000 / 60 / 60

                if (sharePeriodHours < 1 || sharePeriodHours > 24) {
                    helpers.setFieldError('poshmarkShareCloset.sharePeriodMs', 'Share period must be between 1 and 24 hours')
                    Logger.I().log({
                        level: 'info',
                        message: 'User submitted values for poshmark share closet booster failed validation - share period must be between 1 and 24 hours',
                        payload: {
                            kind: 'UserAction',
                            entry: {
                                values: values
                            }
                        }
                    })
                    return
                }
            }

            const domain = EngagementBoosterToDomainMap[boosterKind]
            // Specifically showing the upgrade guard before the domain guard
            // TODO: Replace with selector
            const hasPaidSubscription = subscription?.kind === 'active' && subscription.details.tier !== 'freetrial'

            if (!hasPaidSubscription) {
                Logger.I().log({
                    level: 'info',
                    message: 'User is not on a paid subscription',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values,
                            domain: domain,
                            subscription: subscription
                        }
                    }
                })
                setShowUpgradeGuardModal(true)
                return
            }

            const isLoggedIntoDomain = loggedInDomains.includes(domain)

            if (!isLoggedIntoDomain) {
                Logger.I().log({
                    level: 'info',
                    message: 'User is not logged into domain',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values,
                            domain: domain
                        }
                    }
                })

                setShowDomainGuardModal(true)
                return
            }

            const response = await dispatch(setEngagementBoosters(values)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Successfully submitted engagement boosters',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        values: values,
                        response
                    }
                }
            })

            closeModal()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error submitting engagement boosters',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values
                        }
                    }
                },
                error
            )
        }
    }

    const onDomainGuardModalClose = () => {
        setShowDomainGuardModal(false)
    }

    const onUpgradeGuardModalClose = () => {
        setShowUpgradeGuardModal(false)
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, handleSubmit }: FormikProps<EngagementBoostersData>) => {
                const Field = EngagementBoosterSpecificFieldsMap[boosterKind]
                const domain = EngagementBoosterToDomainMap[boosterKind]
                const helpArticleId = EngagementBoosterToHelpArticleMap[boosterKind]

                const domainGuardText = `To share your Poshmark closet automatically, you need to connect your Poshmark account.`

                return (
                    <>
                        <Field />
                        <Divider />

                        <DomainGuardModal domain={domain} open={showDomainGuardModal} text={domainGuardText} onClose={onDomainGuardModalClose} />
                        <UpgradeGuardModal open={showUpgradeGuardModal} onClose={onUpgradeGuardModalClose} reason='engagementBooster' markets={[domain]} />

                        <Box sx={{ display: 'flex', marginTop: '16px' }}>
                            <FlexGrow />

                            {helpArticleId && (
                                <FoxButton
                                    variant='contained'
                                    grey
                                    size='large'
                                    text='Info'
                                    startIcon={<HelpIcon />}
                                    disabled={isSubmitting}
                                    onFoxClick={{
                                        kind: 'button',
                                        onClick: async () => {
                                            onHelp(helpArticleId)
                                        }
                                    }}
                                    sx={{ display: 'flex', justifyContent: 'center', height: '48px', marginRight: '24px', width: '150px' }}
                                />
                            )}
                            <FoxButton
                                variant='contained'
                                grey
                                size='large'
                                text='Discard changes'
                                disabled={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        closeModal()
                                    }
                                }}
                                sx={{ display: 'flex', justifyContent: 'center', height: '48px', marginRight: '24px' }}
                            />
                            <FoxButton
                                variant='contained'
                                primary
                                size='large'
                                text='Save changes'
                                type='submit'
                                loading={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        handleSubmit()
                                    },
                                    preventDoubleClick: true
                                }}
                                sx={{ display: 'flex', justifyContent: 'center', height: '48px' }}
                            />
                        </Box>
                    </>
                )
            }}
        </Formik>
    )
}
