import { Box, Link } from '@mui/material'
import { FoxModalDialog } from '../../../components/common/FoxModalDialog'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { UserRuntimeContextProvider, setIsExtensionInstalled, useAppDispatch } from '@foxtail-dev/user-clients'
import { ExtensionIcon } from '../../../components/icons/ExtensionIcon'
import { FoxtailWebConfig } from '../../../lib/config/FoxtailWebConfig'

type ExtensionInstallProps = {
    open: boolean
    onClose: () => void
}

export const ExtensionInstallModal = ({ open, onClose }: ExtensionInstallProps) => {
    const dispatch = useAppDispatch()

    const onInstallExtension = async () => {
        window.open(FoxtailWebConfig.config.extensionUrl)
        const context = await UserRuntimeContextProvider.getContext()
        try {
            await context.extensionClient.ping(4000)
            dispatch(setIsExtensionInstalled(true))
        } catch (error) {
            dispatch(setIsExtensionInstalled(false))
        }
    }

    return (
        <FoxModalDialog
            open={open}
            title={'Account needs setup'}
            leftButtonKind={'close'}
            onClose={onClose}
            closeOnBackdropClick
            actions={
                <FoxButton
                    onFoxClick={{
                        kind: 'button',
                        onClick: onInstallExtension,
                        preventDoubleClick: true
                    }}
                    text='Install'
                    primary
                    sx={styles.button}
                />
            }
        >
            <Box sx={styles.container}>
                <Box sx={styles.containerExtensionIcon}>
                    <ExtensionIcon height={165} width={316} />
                </Box>
                <FoxTypography variant='subtitle1' sx={styles.textTitle}>
                    Link markets with chrome extension
                </FoxTypography>
                <FoxTypography variant='body1' sx={styles.textBody}>
                    Install the Foxtail Chrome extension to connect your marketplace accounts.
                </FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}

const styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '32px'
    },
    containerExtensionIcon: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    textTitle: {
        lineHeight: '24px',
        marginTop: '38px'
    },
    textBody: {
        marginTop: '11px',
        textAlign: 'center',
        marginHorizontal: '32px'
    },
    button: {
        color: 'white',
        width: '167px',
        height: '48px',
        justifyContent: 'center'
    }
}
