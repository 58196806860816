export const getLastDayOfWeek = (dayName: string): Date => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const targetDayIndex = daysOfWeek.indexOf(dayName)

    if (targetDayIndex === -1) {
        throw new Error('Invalid day name')
    }

    const today = new Date()
    const todayIndex = today.getDay()

    // Calculate the difference in days
    let diff = todayIndex - targetDayIndex
    if (diff < 0) {
        diff += 7
    }

    // Subtract the difference in days to get the last occurrence of the target day
    const lastOccurrence = new Date(today)
    lastOccurrence.setDate(today.getDate() - diff)

    return lastOccurrence
}
