import { SvgIcon } from '@mui/material'

type CreditCardIconProps = {
    color?: string
    height?: number
    width?: number
}

export const CreditCardIcon = (props: CreditCardIconProps) => {
    const height = props.height ?? 24
    const width = props.width ?? 30
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='30px' height='24px' viewBox='0 0 30 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>icon-account-subscription</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Account' transform='translate(-15.000000, -600.000000)' fill={props.color ?? '#301A36'} fillRule='nonzero'>
                        <g id='icon-account-subscription' transform='translate(15.000000, 600.000000)'>
                            <path
                                d='M30,3.29956585 L30,20.7351664 C30,21.6382055 29.6709753,22.4081042 29.012926,23.0448625 C28.3548766,23.6816208 27.5675676,24 26.6509988,24 L3.31374853,24 C2.39717979,24 1.61574618,23.6816208 0.969447709,23.0448625 C0.323149236,22.4081042 0,21.6382055 0,20.7351664 L0,3.29956585 C0,2.39652677 0.323149236,1.62083936 0.969447709,0.972503618 C1.61574618,0.324167873 2.39717979,0 3.31374853,0 L26.6509988,0 C27.5675676,0 28.3548766,0.324167873 29.012926,0.972503618 C29.6709753,1.62083936 30,2.39652677 30,3.29956585 L30,3.29956585 Z M3.31374853,6.70332851 L26.6509988,6.70332851 L26.6509988,3.29956585 L3.31374853,3.29956585 L3.31374853,6.70332851 Z M3.31374853,11.1837916 L3.31374853,20.7351664 L26.6509988,20.7351664 L26.6509988,11.1837916 L3.31374853,11.1837916 Z M3.31374853,20.7351664 L3.31374853,3.29956585 L3.31374853,20.7351664 Z'
                                id='Shape'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
