import { Colors, Logger, preloadBatchImages, selectImageUriById, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { useEffect, useState } from 'react'
import { FoxImage, FoxImageProps } from './FoxImage'
import { Box, BoxProps } from '@mui/material'
import { FoxCircleLoader } from '../LoadingCircle'

export type ListingImageInfo = {
    loaded: boolean
    imageUri: string
}

type FoxListingImageProps = {
    imageId: string
    containerStyle?: BoxProps['sx']
    listingImageInfoRef?: React.MutableRefObject<ListingImageInfo | null>
} & FoxImageProps

export const FoxListingImage = ({ imageId, listingImageInfoRef, src, style, containerStyle, ...rest }: FoxListingImageProps) => {
    const dispatch = useAppDispatch()
    const imageUri = useAppSelector(selectImageUriById(imageId))

    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined)

    // TODO: Create a hook for this
    const preloadImageAsync = async () => {
        try {
            await dispatch(preloadBatchImages([imageId])).unwrap()
        } catch (error) {
            Logger.I().log({
                level: 'error',
                message: 'Error loading image in photo edit modal',
                payload: {
                    kind: 'errorLoadingImagePhotoEditModal',
                    entry: {
                        imageUri
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (!imageUri && imageId) {
            preloadImageAsync()
        }
    }, [imageId, imageUri])

    useEffect(() => {
        const loadImageAsync = async () => {
            if (!imageUri) {
                return
            }

            setImageSrc(imageUri)

            if (listingImageInfoRef) {
                listingImageInfoRef.current = { loaded: true, imageUri }
            }
        }

        loadImageAsync()
    }, [imageUri])

    return (
        <Box sx={{ ...containerStyle }}>
            <Box sx={{ position: 'relative', width: '100%', paddingTop: '100%', zIndex: 1 }}>
                {imageSrc ? (
                    <FoxImage
                        src={imageSrc}
                        {...rest}
                        style={{ ...{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }, ...style }}
                    />
                ) : (
                    <Box
                        sx={{
                            ...{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: Colors.light.background,
                                borderRadius: '10px'
                            }
                        }}>
                        <FoxCircleLoader size={40} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
