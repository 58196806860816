import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { NotificationSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/Notification'
import { NotificationInfo } from '../../layouts/NotificationInfo'
import { Box } from '@mui/material'

type SpecificNotificationModalProps = {
    closeModal: () => void
    open: boolean
    notification: NotificationSchema | null
}

export const SpecificNotificationModal = ({ open, closeModal, notification }: SpecificNotificationModalProps) => {
    if (!notification) {
        return null
    }

    return (
        <FoxModalDialog open={open} onClose={closeModal} title='Notification Details' leftButtonKind='close' closeOnBackdropClick>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: '16px' }}>
                <NotificationInfo notificationBody={notification.body.body} notificationDetails={notification.details} />
            </Box>
        </FoxModalDialog>
    )
}
