import { Box } from '@mui/material'
import { StageContentAndActions } from '../../screens/listing/ListingsFromVideoScreen'
import { FoxProgressSpinner } from '../common/FoxProgressSpinner'
import { FoxTypography } from '../common/FoxTypography'

type UploadingVideoContentProps = {}

export const UploadingVideoContent = ({}: UploadingVideoContentProps): StageContentAndActions => {
    // TODO: Look into how we could get the real progress of the upload
    return {
        actions: null,
        content: (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '48px',
                    alignItems: 'center'
                }}>
                <FoxProgressSpinner visible={true} />
                <FoxTypography variant='h6' sx={{ marginTop: '36px' }}>
                    Uploading video, please stay on this web page
                </FoxTypography>
            </Box>
        )
    }
}
