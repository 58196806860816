import { Box } from '@mui/material'
import { StageContentAndActions } from '../../screens/listing/ListingsFromVideoScreen'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { AiGuyIcon } from '../icons/AIGuy'

type CreatingListingsContentProps = {}

export const CreatingListingsContent = ({}: CreatingListingsContentProps): StageContentAndActions => {
    return {
        actions: (
            <>
                <FoxButton
                    text='Check progress'
                    grey
                    onFoxClick={{ kind: 'internal', to: '/app/processing' }}
                    sx={{ width: '141px', height: '48px', display: 'flex', justifyContent: 'center' }}
                />
                <FoxButton
                    text='Go to listings'
                    primary
                    onFoxClick={{ kind: 'internal', to: '/app/listings?tab=draft' }}
                    sx={{ width: '141px', height: '48px', display: 'flex', justifyContent: 'center' }}
                />
            </>
        ),
        content: (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <AiGuyIcon height={140} width={140} />
                <FoxTypography variant='h6' sx={{ marginTop: '36px', width: '100%' }}>
                    Your video is uploaded! <br />
                    We're now using AI to generate your listings. <br />
                </FoxTypography>
                <FoxTypography variant='body1'>
                    <br />
                    Listing generation can take up to 5 minutes. You can leave this page and continue using Foxtail; your listings will be saved as drafts.
                </FoxTypography>
            </Box>
        )
    }
}
