import { SvgIcon } from '@mui/material'

type PencilIconProps = {
    color?: string
    height?: number
    width?: number
}

export const PencilIcon = (props: PencilIconProps) => {
    const height = props.height ?? 13
    const width = props.width ?? 13
    return (
        <SvgIcon viewBox='0 0 13 13' style={{ height, width }}>
            <svg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>pencil</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill={props.color} fillRule='evenodd'>
                    <g
                        id='New-listing---details---photos-added'
                        transform='translate(-163.000000, -655.000000)'
                        fill={props.color ?? '#FFFFFF'}
                        fillRule='nonzero'
                    >
                        <g id='Group-9' transform='translate(107.000000, 599.000000)'>
                            <g id='Group-10' transform='translate(50.000000, 50.000000)'>
                                <g id='edit_FILL0_wght600_GRAD0_opsz48' transform='translate(6.000000, 6.000000)'>
                                    <path
                                        d='M1.17925063,11.8366304 L1.83047859,11.8366304 L9.55721033,4.08083301 L8.88838161,3.42864096 L1.17925063,11.1668115 L1.17925063,11.8366304 Z M11.9509131,3.3405069 L9.64521411,1.0313945 L10.3492443,0.326322011 C10.5721872,0.103049056 10.844999,-0.0056496193 11.1676795,0.000225984772 C11.49036,0.00610158885 11.7690386,0.120675868 12.0037154,0.343948823 L12.6725441,0.996140875 C12.9072208,1.23116504 13.0157588,1.50731843 12.9981581,1.82460105 C12.9805573,2.14188367 12.8602855,2.41216146 12.6373426,2.63543441 L11.9509131,3.3405069 Z M0.704030227,13 C0.504554996,13 0.337347817,12.9324306 0.20240869,12.7972917 C0.0674695634,12.6621528 0,12.494698 0,12.2949275 L0,10.9905434 C0,10.8965337 0.0176007557,10.8083997 0.052802267,10.7261412 C0.0880037783,10.6438828 0.140806045,10.5674999 0.211209068,10.4969927 L8.90598237,1.77172061 L11.2116814,4.0632062 L2.51690806,12.7884783 C2.44650504,12.8589855 2.36730164,12.9118659 2.27929786,12.9471196 C2.19129408,12.9823732 2.10035684,13 2.00648615,13 L0.704030227,13 Z M9.24039673,3.74592358 L8.88838161,3.42864096 L9.55721033,4.08083301 L9.24039673,3.74592358 Z'
                                        id='Shape'
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
