import { Box, Link, useTheme } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { useAuth0 } from '@auth0/auth0-react'
import { createLogoutUrl } from '../../utils/createLogoutUrl'
import { FoxtailWebConfig } from '../../lib/config/FoxtailWebConfig'

export const OnboardingSignOutButton = () => {
    const theme = useTheme()
    const { logout } = useAuth0()

    const onLogout = async () => {
        await logout({
            logoutParams: {
                returnTo: createLogoutUrl(window.location.origin, 'logout'),
                clientId: FoxtailWebConfig.config.auth0.clientId
            }
        })
    }

    return (
        <Box sx={styles.containerAlreadyHaveAccount}>
            <FoxTypography>
                Need to sign in with a different account?{' '}
                <Link fontStyle={{ color: theme.palette.text.primary }} sx={{ cursor: 'pointer' }} onClick={onLogout}>
                    Sign out
                </Link>
            </FoxTypography>
        </Box>
    )
}

const styles = {
    containerAlreadyHaveAccount: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }
}
