export const standardAiLoadingWords = [
    'Preparing your listing...',
    'Gathering market data...',
    'Filtering categories...',
    'Crafting captivating descriptions...',
    'Analyzing trends...',
    'Optimizing images...',
    'Customizing recommendations...',
    'Loading profiles...',
    'Being a robot...',
    'Compiling details...',
    'Sweet-talking SEO...',
    'Spicing up tags...',
    'Polishing your listing...',
    'Almost there...'
] as const

export const funnyAiLoadingWords = [
    'Validating your awesomeness...',
    'Fetching unicorns...',
    'Assembling the Avengers...',
    'Curating memes...',
    'Enhancing your cool factor...',
    'Calibrating coffee machine...',
    'Checking for intergalactic updates...',
    'Boosting your Wi-Fi signal...',
    'Finalizing the magic spell...',
    'Synchronizing with the Matrix...',
    'Reviewing your playlist...',
    'Setting up your fan club...',
    'Aligning with the stars...',
    'Mapping out fun...',
    'Refreshing your aura...',
    'Organizing your sock drawer...',
    'Tweaking the fun knob...',
    'Scanning for Easter eggs...',
    'Building the Batcave...',
    'Updating your social status...',
    'Linking your superhero cape...',
    'Merging with the awesome...',
    'Indexing your cat videos...',
    'Parsing the jokes...',
    'Gathering compliments...',
    'Resolving intergalactic conflicts...',
    'Applying the secret sauce...',
    'Formatting your coolness...',
    'Balancing your karma...',
    'Rendering puns...',
    'Connecting to the fun-o-meter...',
    'Filtering out the boring stuff...',
    'Compressing laughter...',
    'Initiating dance party protocols...',
    'Estimating your awesomeness...',
    'Designing your next adventure...',
    'Prioritizing fun...',
    'Logging your epic moments...',
    'Assessing your superhero status...',
    'Launching good vibes...',
    'Clearing bad jokes...',
    'Encrypting your happiness...',
    'Evaluating your high fives...',
    'Generating smiles...',
    'Tagging your adventures...',
    'Modifying fun levels...',
    'Crafting epic stories...',
    'Preloading surprises...',
    'Sealing the deal with a wink...',
    'Syncing your dance moves...'
] as const
