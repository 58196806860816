import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'
import React from 'react'
import { PoshmarkShareClosetFields } from './concretes/PoshmarkShareClosetFields'
import { DomainCommonName } from '@foxtail-dev/datacontracts'

export const EngagementBoosterSpecificFieldsMap: Record<UserEngagementBoosterKind, React.FC<EngagementBoosterSpecificFieldsProps>> = {
    poshmarkShareCloset: PoshmarkShareClosetFields
} as const

export const EngagementBoosterToDomainMap: Record<UserEngagementBoosterKind, DomainCommonName> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: DomainCommonName.Enum.poshmark
} as const

export const EngagementBoosterToTitleMap: Record<UserEngagementBoosterKind, string> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: 'Poshmark share closet'
} as const

export const EngagementBoosterToHelpArticleMap: Record<UserEngagementBoosterKind, string | undefined> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: '9750397'
}

export type EngagementBoosterSpecificFieldsProps = {}
