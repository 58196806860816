import { getSelectedMarkets, selectActiveAuthedOrAuthingDomains, useAppSelector } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { MarketSelectionCheckbox } from './MarketSelectionCheckbox'
import { FoxTypography } from '../common/FoxTypography'
import { ConnectMoreSitesLink } from '../account/ConnectMoreSitesLink'
import { useMemo } from 'react'
import _ from 'lodash'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'

type MarketSelectionFieldProps = {
    clearOnToggleOff: boolean
}

export const MarketSelectionField = (props: MarketSelectionFieldProps) => {
    const { clearOnToggleOff } = props

    const authedOrAuthingDomainsStatuses = useAppSelector(selectActiveAuthedOrAuthingDomains)
    const { values } = useFormikContext<ListingDetailsDraft>()
    const selectedDomains = getSelectedMarkets(values)

    const authedAuthingOrIsActiveDomains = useMemo(() => {
        const authedOrAuthingDomains = authedOrAuthingDomainsStatuses.map(({ domainName, authStatus }) => domainName)
        return _.uniq([...authedOrAuthingDomains, ...selectedDomains])
    }, [authedOrAuthingDomainsStatuses, selectedDomains])

    // TODO: Make the divider start from the text
    return (
        <Box>
            <FoxTypography variant='body2' sx={styles.titleWhere}>
                Where?
            </FoxTypography>
            {authedAuthingOrIsActiveDomains.map((domainName, index) => (
                <Box key={'marketSelectionCheckbox' + domainName}>
                    <MarketSelectionCheckbox
                        key={domainName}
                        name={`domainSpecificDetails.${domainName}`}
                        market={domainName}
                        clearOnToggleOff={clearOnToggleOff}
                    />
                    <Divider sx={styles.divider} />
                </Box>
            ))}
            <ConnectMoreSitesLink />
        </Box>
    )
}

const styles = {
    titleWhere: {
        marginTop: '24px',
        marginBottom: '12px',
        fontWeight: 'bold'
    },
    connectIcon: {
        marginRight: '10px',
        marginLeft: '4px'
    },
    divider: {
        marginLeft: '32px'
    }
}
