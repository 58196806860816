import { DomainCommonName, z } from '@foxtail-dev/datacontracts'
import { RadioOption, Logger, smartSearch, ListingOperationKind } from '@foxtail-dev/user-clients'
import { Box, useTheme } from '@mui/material'
import { useState, useMemo } from 'react'
import { getRadioOptionLabel, getRadioOptionValue } from '../../components/formik/RadioGroupField'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxSearchBar } from '../../components/common/FoxSearchBar'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxCheckbox } from '../../components/common/FoxCheckbox'
import {
    updateEbayAspectInFormik,
    updateEbayConditionIdInFormik,
    updateEtsyConditionalAttributeInFormik,
    updateListingDefaultFieldInFormik,
    updateMarketSpecificFieldInFormik
} from '../domainSpecific/DomainSpecificFormikUtils'
import { useFormikContext } from 'formik'
import { EtsyConditionalAttribute } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { DomainSpecificDetailModal } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'

export type RadioOptionSearchable = {
    label: string
    radioOption: RadioOption
}

export type DetailNavigationParam = {
    open: boolean
    headerTitle: string
    options: RadioOption[]
    paramName: string
    domainName: DomainCommonName
    isRequired: boolean
    currentValue: string | undefined
    closeModal: () => void
    etsyAttribute?: {
        attributeId: number
        propertyId: number
        attributeName: string
    }
    existingEtsyAttributes?: EtsyConditionalAttribute[]
    isEbayAspect?: boolean
    listingOperationKind: ListingOperationKind
    subtextMap?: Record<string, string>
}

export const DetailSelectionModal = (props: DetailNavigationParam) => {
    const theme = useTheme()
    const {
        open,
        headerTitle,
        options,
        currentValue,
        domainName,
        isRequired,
        paramName,
        isEbayAspect,
        listingOperationKind,
        subtextMap,
        etsyAttribute,
        existingEtsyAttributes,
        closeModal
    } = props
    const [value, setValue] = useState<string | undefined>(currentValue)
    const [label, setLabel] = useState<string>(currentValue ? getRadioOptionLabel(currentValue) : '')
    const [search, setSearch] = useState<string>('')
    const [isSaving, setIsSaving] = useState(false)
    const { setFieldValue, setFieldTouched, values } = useFormikContext() // TODO: Type this

    const handleChecked = (option: RadioOption) => {
        if (getRadioOptionValue(option) === value) {
            setValue(undefined)
            setLabel('')
        } else {
            setValue(getRadioOptionValue(option))
            setLabel(getRadioOptionLabel(option))
        }
    }

    const handleSave = async () => {
        if (isSaving) {
            return
        }
        setIsSaving(true)
        if (paramName === 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs') {
            if (!etsyAttribute || !existingEtsyAttributes) {
                return
            }

            updateEtsyConditionalAttributeInFormik(
                setFieldValue,
                setFieldTouched,
                {
                    attributeId: etsyAttribute.attributeId.toString(),
                    attributeName: etsyAttribute.attributeName,
                    propertyId: etsyAttribute.propertyId.toString(),
                    valueId: value ? value : '',
                    valueName: label
                },
                existingEtsyAttributes
            )
            Logger.I().log({
                level: 'info',
                message: 'User selected a conditional etsy attribute value',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        attributeId: etsyAttribute.attributeId,
                        attributeName: etsyAttribute.attributeName,
                        propertyId: etsyAttribute.propertyId,
                        valueId: value,
                        valueName: label,
                        listingOperationKind
                    }
                }
            })
        } else if (isEbayAspect && paramName !== 'brand' && paramName !== 'conditionId') {
            updateEbayAspectInFormik(setFieldValue, setFieldTouched, {
                paramName,
                value: value ? value : ''
            })
            Logger.I().log({
                level: 'info',
                message: 'User selected an ebay aspect value',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        paramName,
                        value,
                        listingOperationKind
                    }
                }
            })
        } else if (domainName === 'ebay' && paramName === 'conditionId') {
            if (value) {
                updateEbayConditionIdInFormik(setFieldValue, setFieldTouched, value)
            }
            Logger.I().log({
                level: 'info',
                message: 'User selected an ebay condition',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        conditionId: value,
                        listingOperationKind
                    }
                }
            })
        } else {
            if (listingOperationKind === 'create' || listingOperationKind === 'edit' || listingOperationKind === 'bulkCrosslist') {
                updateMarketSpecificFieldInFormik(setFieldValue, setFieldTouched, {
                    paramName,
                    domainName,
                    isRequired,
                    value: value
                })
                Logger.I().log({
                    level: 'info',
                    message: 'User selected a market specific field value',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            paramName,
                            domainName,
                            isRequired,
                            value,
                            listingOperationKind
                        }
                    }
                })
            } else if (listingOperationKind === 'setDefault') {
                // TODO: Listing defaults
                updateListingDefaultFieldInFormik(setFieldValue, setFieldTouched, {
                    paramName,
                    domainName,
                    isRequired,
                    value
                })
                Logger.I().log({
                    level: 'info',
                    message: 'User selected a default market specific field value',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            paramName,
                            domainName,
                            isRequired,
                            value,
                            listingOperationKind
                        }
                    }
                })
            }
        }
        closeModal()
        setIsSaving(false)
    }

    const filteredOptions = useMemo(() => {
        if (search === '') {
            return options
        }

        // Get the RadioOptions into a searchable format
        const optionsSearchable: RadioOptionSearchable[] = options.map((option) => {
            return {
                label: getRadioOptionLabel(option),
                radioOption: option
            }
        })

        const results = smartSearch<RadioOptionSearchable>({
            input: search,
            list: optionsSearchable,
            keys: ['label']
        })

        return results.map((result) => result.item.radioOption)
    }, [search, options])

    // TODO: Put the FoxButton into the actions of the modal, need to invert the relationship with the DomainSpecificDetailModal, so that this is a parent of the modal,
    // instead of just being the child content of the modal
    return (
        <DomainSpecificDetailModal
            open={open}
            title={headerTitle}
            closeModal={closeModal}
            actions={
                <FoxButton
                    primary
                    variant='contained'
                    text='Save option'
                    onFoxClick={{ kind: 'button', onClick: handleSave }}
                    loading={isSaving}
                    sx={styles.saveButton}
                />
            }>
            <Box sentry-label='DetailSelectionScreen' sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                <FoxSearchBar label={`Search ${headerTitle}`} value={search} onChange={setSearch} fullWidth sx={{ marginBottom: '16px' }} autoFocus />

                <Box sx={{ ...sharedStyles.flexColumn, marginBottom: '16px' }}>
                    {filteredOptions.map((option) => {
                        return (
                            <FoxCheckbox
                                key={getRadioOptionValue(option)}
                                iconType='Radio'
                                checked={getRadioOptionValue(option) === value}
                                label={getRadioOptionLabel(option)}
                                onChange={() => handleChecked(option)}
                                subtext={subtextMap ? subtextMap[z.string().parse(option)] : undefined}
                            />
                        )
                    })}
                </Box>
            </Box>
        </DomainSpecificDetailModal>
    )
}

const styles = {
    saveButton: {
        width: '100%',
        justifyContent: 'center'
    }
}
