import { Box } from '@mui/material'
import { sharedStyles } from '../../theme/SharedStyling'
import { FlexGrow } from './FlexGrow'
import { FoxTypography } from './FoxTypography'

type HeaderProps = {
    title: string
    rightComponent?: React.ReactNode
    rightComponent2?: React.ReactNode
    rightComponent3?: React.ReactNode
}

export const Header = (props: HeaderProps) => {
    const { title, rightComponent, rightComponent2, rightComponent3 } = props

    const marginComponent1 = rightComponent2 ? '14px' : '0'
    const marginComponent2 = rightComponent3 ? '14px' : '0'
    return (
        <Box sx={[sharedStyles.flex, styles.header]}>
            <FoxTypography variant='h5'>{title}</FoxTypography>
            <FlexGrow />
            <Box sx={[styles.rightComponentContainer]}>
                {rightComponent && <Box sx={{ marginRight: marginComponent1 }}>{rightComponent}</Box>}
                {rightComponent2 && <Box sx={{ marginRight: marginComponent2 }}>{rightComponent2}</Box>}
                {rightComponent3 && <Box>{rightComponent3}</Box>}
            </Box>
        </Box>
    )
}

const styles = {
    header: {
        marginBottom: '16px',
        paddingTop: '8px'
    },
    rightComponentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%'
    }
}
