import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '../../theme/Colors'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'

type AccountSideBarRowProps = {
    title: string
    to: string
    active: boolean
    Icon: JSX.Element
}

export const AccountSideBarRow = (props: AccountSideBarRowProps) => {
    const { active, Icon, to, title } = props

    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to }} sx={styles.container}>
            <Box sx={sharedStyles.flex}>{Icon}</Box>
            <Box sx={sharedStyles.flex}>
                <FoxTypography sx={{ marginLeft: '16px', color: active ? Colors.light.primary : Colors.light.text }} variant='body1'>
                    {title}
                </FoxTypography>
            </Box>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        height: '42px',
        width: '270px',
        cursor: 'pointer'
    }
}
