import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButton } from '../../components/common/FoxButton'
import { Box } from '@mui/material'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'

type DomainGuardModalProps = {
    domain: DomainCommonName
    open: boolean
    text: string
    onClose: () => void
}

export const DomainGuardModal = ({ domain, open, text, onClose }: DomainGuardModalProps) => {
    const title = `Log in to ${Domains[domain].displayName}`

    return (
        <FoxModalDialog
            open={open}
            title={title}
            leftButtonKind={'back'}
            onClose={onClose}
            closeOnBackdropClick
            actions={
                <Box sx={{ display: 'flex' }}>
                    <FoxButton
                        text={`Connect ${Domains[domain].displayName}`}
                        onFoxClick={{
                            kind: 'internal',
                            to: '/app/account/marketplace-connections'
                        }}
                        primary
                        sx={{ display: 'flex', justifyContent: 'center', height: '48px' }}
                        variant='contained'
                    />
                    <FoxButton
                        text={'Go back'}
                        onFoxClick={{
                            onClick: async () => {
                                onClose()
                            },
                            kind: 'button'
                        }}
                        grey
                        sx={{ display: 'flex', justifyContent: 'center', height: '48px', marginLeft: '24px' }}
                        variant='contained'
                    />
                </Box>
            }
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <FoxImage src={CommonImages.markets[domain]} style={{ width: '64px', height: 'auto', marginBottom: '24px' }} />
                <FoxTypography variant='body1'>{text}</FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}
