import { Box } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { FoxCircleLoader } from '../LoadingCircle'
import { useAppSelector, selectNumberOfBulkCrosslistingItemsGenerating, selectBulkCrosslistingDomains, createDomainNameList } from '@foxtail-dev/user-clients'

type BulkCrosslistGeneratingProps = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const BulkCrosslistGenerating = (props: BulkCrosslistGeneratingProps) => {
    const numberOfCrosslistingItems = useAppSelector(selectNumberOfBulkCrosslistingItemsGenerating)
    const destinationDomains = useAppSelector(selectBulkCrosslistingDomains)

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerLoading}>
                <FoxCircleLoader size={60} />
                <FoxTypography variant='h6' sx={styles.textLoading}>
                    Generating details for {numberOfCrosslistingItems} listings to {createDomainNameList(destinationDomains)}
                </FoxTypography>
                <FoxTypography variant='subtitle1' sx={styles.textLeavePage} light>
                    You can leave this screen and come back later to check on the progress. Generating may take more than 10 minutes depending on number of
                    listings.
                </FoxTypography>
            </Box>
            <FoxButton
                grey
                sx={styles.button}
                text='Cancel bulk crosslist'
                onFoxClick={{
                    kind: 'button',
                    onClick: () => {
                        props.setIsRequestingCancel(true)
                    }
                }}
            />
        </Box>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    textLoading: {
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    button: {
        width: '100%',
        justifyContent: 'center'
    },
    textLeavePage: {
        marginTop: '24px',
        textAlign: 'center',
        marginLeft: '60px',
        marginRight: '60px'
    }
}
