import { DomainListingDetails, ListingDetails, ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useEffect, useMemo, useState } from 'react'
import {
    Logger,
    useAppDispatch,
    useAppSelector,
    setRuleFailureFormikState,
    validateListingDetails,
    validateUserCanAct,
    selectCurrentEbayConditions,
    selectCurrentEtsyAttributes,
    selectHasActiveSubscription,
    loadListingDetails,
    addAMarketToActiveListingUserAction,
    clearListingDetails,
    removeMarketFromActiveListingUserAction,
    updateActiveListingGeneralDetails,
    filterTags,
    generateDescription,
    generateTags,
    getDomainLinkingStatus,
    getAddedDomains,
    getRemovedDomains,
    removeInactiveDomainsThatFailParsing,
    checkProgressCompletion,
    selectHydratedQuestsSorted
} from '@foxtail-dev/user-clients'
import { useNavigate, useParams } from 'react-router-dom'
import { DomainCommonName, assert, z } from '@foxtail-dev/datacontracts'
import { generateToast } from '../../lib/clients/ToastClient'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { myObjectDeepStrictEqual } from '../../utils/objectComparison'
import { MarketSelectionField } from '../../components/formik/MarketSelectionField'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { PriceField } from '../../components/formik/PriceField'
import { shouldDisplayErrorMessage } from '../../utils/shouldDisplayErrorMessage'
import { MarketSpecificFieldsMap } from '../../containers/forms/domainSpecificFields/createListing/MarketSpecificFieldsMap'
import { MarketSpecificFieldsLayout } from '../../layouts/MarketSpecificFieldsLayout'
import { DescriptionField } from '../../components/formik/DescriptionField'
import { TagsField } from '../../components/formik/TagsField'
import { CreateListingSkeleton } from '../../components/skeletons/CreateListingSkeleton'
import { shouldCheckQuestCompletion } from '../../utils/shouldCheckQuestCompletion'

export const EditListingScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const taxonomyProvider = useTaxonomyProvider()

    const listingId = params.listingId
    const [initialListingDetails, setInitialListingDetails] = useState<ListingDetailsDraft | null>(null)
    const hasActiveSubscription = useAppSelector(selectHasActiveSubscription)
    const currentEbayConditions = useAppSelector(selectCurrentEbayConditions)
    const currentEtsyAttributes = useAppSelector(selectCurrentEtsyAttributes)
    const [loadingDescription, setLoadingDescription] = useState(false)
    const [loadingTags, setLoadingTags] = useState(false)
    const [saving, setSaving] = useState<boolean>(false)
    const quests = useAppSelector(selectHydratedQuestsSorted)

    const loadListingAsync = async (listingId: string) => {
        try {
            const listingSchema = await dispatch(loadListingDetails(listingId)).unwrap()
            setInitialListingDetails(listingSchema.listingDescription.listingDetails)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to load listing details',
                    payload: {
                        kind: 'LoadListingError',
                        entry: {
                            listingId
                        }
                    }
                },
                error
            )
            // TODO: Show error screen
            generateToast({ kind: 'info', message: 'Unable to load listing', subText: 'Please try again later' })
        }
    }

    useEffect(() => {
        if (listingId) {
            loadListingAsync(listingId)
        }
    }, [listingId])

    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    if (initialListingDetails === null || !listingId || !taxonomyProvider) {
        return (
            <FoxModalDialog leftButtonKind={'close'} open={true} title='Edit listing' onClose={() => {}}>
                <CreateListingSkeleton />
            </FoxModalDialog>
        )
    }

    const onDiscardChanges = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User discarded changes from edit active listing form',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId
                }
            }
        })

        dispatch(clearListingDetails())
        navigate('/app/listings')
    }

    const publish = async (listingDetails: ListingDetails, addedDomains: DomainCommonName[], removedDomains: DomainCommonName[]) => {
        try {
            // if common details updated dispatch update common details action
            const areCommonDetailsDifferentThanCurrent = myObjectDeepStrictEqual(initialListingDetails.commonDetails, listingDetails.commonDetails)
            if (!areCommonDetailsDifferentThanCurrent) {
                assert(listingDetails.commonDetails, 'common details should be defined')
                await dispatch(
                    updateActiveListingGeneralDetails({
                        commonDetails: listingDetails.commonDetails,
                        listingId
                    })
                ).unwrap()
            }

            // if some of removable markets have true then dispatch remove market action
            await Promise.all(
                removedDomains.map(async (domain: DomainCommonName) => {
                    return dispatch(
                        removeMarketFromActiveListingUserAction({
                            domain,
                            listingId
                        })
                    )
                })
            )

            // // if some of addable markets have true then dispatch add market action
            await Promise.all(
                addedDomains.map(async (domain: DomainCommonName) => {
                    assert(listingDetails, 'market specific details should be defined')
                    assert(listingDetails.domainSpecificDetails, 'market specific details should be defined')
                    return dispatch(
                        addAMarketToActiveListingUserAction({
                            domain,
                            listingId,
                            domainSpecificDetails: DomainListingDetails.parse(listingDetails.domainSpecificDetails[domain])
                        })
                    )
                })
            )

            Logger.I().log({
                level: 'info',
                message: 'User has successfully updated active listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        addedDomains,
                        removedDomains,
                        areCommonDetailsDifferentThanCurrent,
                        listingDetails
                    }
                }
            })

            const shouldCheckProgress = shouldCheckQuestCompletion('listAnItem', quests ?? [])

            if (shouldCheckProgress) {
                // Don't block the user from using the app, just log if it fails
                dispatch(checkProgressCompletion())
                    .unwrap()
                    .catch((error) => {
                        Logger.I().log(
                            {
                                level: 'error',
                                message: 'Failed to check progress completion, but listing was posted',
                                payload: {
                                    kind: 'ProgressCompletionError',
                                    entry: {
                                        listingId,
                                        listingDetails,
                                        source: 'EditListingScreen'
                                    }
                                }
                            },
                            error
                        )
                    })
            }

            dispatch(clearListingDetails())
            navigate('/app/listings')
        } catch (error) {
            generateToast({ kind: 'error', message: 'Unable to update active listing at this time' })

            Logger.I().log({
                level: 'error',
                message: 'User hit error trying to update active listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        addedDomains,
                        removedDomains
                    }
                }
            })
        }
    }

    const onUpdateActiveListing = async (values: ListingDetailsDraft, formikHelpers: FormikHelpers<ListingDetailsDraft>) => {
        const { setFieldError, setFieldTouched, setStatus } = formikHelpers

        // TODO: We may not need this now that we're using isSubmitting
        if (saving === true) {
            return
        }

        const addedDomains = getAddedDomains(initialListingDetails, values)
        const removedDomains = getRemovedDomains(initialListingDetails, values)

        try {
            setSaving(true)

            Logger.I().log({
                level: 'info',
                message: 'User is attempting to update active listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        addedDomains,
                        removedDomains,
                        listingDetails: values
                    }
                }
            })

            // Edit does not create more listings so we don't need to check if the user has reached their listing limit

            const validateUserResult = validateUserCanAct({
                hasActiveSubscription,
                caller: 'EditActiveListingScreen'
            })

            if (!validateUserResult.canAct) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'UserValidationError',
                        entry: {
                            listingId,
                            listingDetails: values,
                            validationResult: validateUserResult,
                            screen: 'EditListingScreen'
                        }
                    }
                })

                if (validateUserResult.reasons.includes('No active subscription')) {
                    navigate('/inactive-subscription')
                }
                return
            }

            // Get rid of any inactive domain specific details that fail validation
            const sanitizedDomainSpecificDetails = removeInactiveDomainsThatFailParsing(values)

            const sanitizedValues: ListingDetailsDraft = {
                ...values,
                domainSpecificDetails: sanitizedDomainSpecificDetails.domainSpecificDetails
            }

            // Only validate domain listing details for newly added domains
            const listingDetailsValidationResult = validateListingDetails({
                values: sanitizedValues,
                marketsToPublishTo: addedDomains,
                currentEbayConditions,
                currentEtsyAttributes,
                caller: 'EditActiveListingForm',
                taxonomies: taxonomyProvider
            })

            if (listingDetailsValidationResult.hasError) {
                setRuleFailureFormikState({
                    ruleFailures: listingDetailsValidationResult.failedRules,
                    setFieldError,
                    setFieldTouched,
                    setStatus,
                    createStatusString: !listingDetailsValidationResult.failedParsing
                })
                setSaving(false)

                return
            }

            const listingDetails = listingDetailsValidationResult.listingDetails
            assert(listingDetails, 'listingDetails should be defined')

            await publish(listingDetails, addedDomains, removedDomains)

            setSaving(false)
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'User failed to update active listing',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            listingId,
                            addedDomains,
                            removedDomains,
                            listingDetails: values
                        }
                    }
                },
                error
            )
            setSaving(false)
            generateToast({ kind: 'info', message: 'Unable to post listing at this time' })
        }
    }

    return (
        <Formik initialValues={initialListingDetails} onSubmit={onUpdateActiveListing} validateOnBlur={false}>
            {({ values, handleSubmit, isSubmitting, touched, errors, status }: FormikProps<ListingDetailsDraft>) => {
                const commonDetails = values.commonDetails

                // TODO: See if we can unify this with the one in CreateListingDomainSpecificFields
                const onGenerateDescription = async (onChange: (value: string) => void) => {
                    try {
                        if (loadingDescription === false) {
                            setLoadingDescription(true)
                            console.log('Generating description edit listing', values)
                            const result = await dispatch(
                                generateDescription({
                                    commonDetails: values.commonDetails,
                                    domainSpecificDetails: values.domainSpecificDetails,
                                    foxtailSku: values.foxtailSku,
                                    imageIds: values.imageIds
                                })
                            ).unwrap()
                            setLoadingDescription(false)
                            const trimmedDescription = result.description.trim()
                            Logger.I().log({
                                level: 'info',
                                message: 'User generated description',
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        listingId,
                                        description: trimmedDescription
                                    }
                                }
                            })
                            onChange(trimmedDescription)
                        }
                    } catch (error) {
                        Logger.I().log(
                            {
                                level: 'error',
                                message: 'threw error while trying to generate description',
                                payload: {
                                    kind: 'GenerateDescriptionError',
                                    entry: {
                                        listingId: listingId,
                                        form: 'EditListingForm'
                                    }
                                }
                            },
                            error
                        )

                        setLoadingDescription(false)
                    }
                }

                const onGenerateTags = async (existingTags: string[], onChange: (value: string[]) => void) => {
                    if (loadingTags === false) {
                        try {
                            setLoadingTags(true)
                            const generatedTags = await dispatch(
                                generateTags({
                                    commonDetails: values.commonDetails,
                                    domainSpecificDetails: values.domainSpecificDetails,
                                    foxtailSku: values.foxtailSku,
                                    imageIds: values.imageIds
                                })
                            ).unwrap()
                            const newTags = filterTags(generatedTags, existingTags)
                            setLoadingTags(false)

                            Logger.I().log({
                                level: 'info',
                                message: 'User generated tags',
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        listingId,
                                        tags: newTags
                                    }
                                }
                            })

                            onChange(newTags)
                        } catch (error) {
                            Logger.I().log(
                                {
                                    level: 'error',
                                    message: 'threw error while trying to generate tags',
                                    payload: {
                                        kind: 'GenerateTagsError',
                                        entry: {
                                            listingId,
                                            form: 'ReviewListingForm',
                                            existingTags: existingTags
                                        }
                                    }
                                },
                                error
                            )
                            generateToast({ kind: 'info', message: 'Unable to generate tags' })
                            setLoadingTags(false)
                        }
                    }
                }

                const addedDomains = useMemo(() => {
                    return getAddedDomains(initialListingDetails, values)
                }, [values, initialListingDetails])

                return (
                    <FoxModalDialog leftButtonKind={'close'} open={true} title='Edit listing' onClose={onDiscardChanges}>
                        <MarketSelectionField clearOnToggleOff={false} />

                        <FoxTypography variant={'body2'} sx={{ ...styles.subSectionHeader }} bold>
                            Info
                        </FoxTypography>
                        <Box sx={{ ...styles.subSectionContainer }}>
                            <FoxTextField
                                name='commonDetails.title'
                                label='Title'
                                value={commonDetails?.title}
                                labelVariant='body1'
                                inputStyle={{ width: '100%' }}
                                placeholder='Your title'
                            />
                            <Box sx={{ display: 'flex', width: '100%' }}>
                                <PriceField
                                    name='commonDetails.price'
                                    label='Price'
                                    sx={{ flexGrow: 1 }}
                                    inputStyle={{ paddingRight: '16px', width: '100%' }}
                                    initialValue={commonDetails?.price?.toString()}
                                />
                                <FoxTextField
                                    name='commonDetails.quantity'
                                    label='Quantity'
                                    value={commonDetails?.quantity?.toString()}
                                    labelVariant='body1'
                                    sx={{ flexGrow: 1 }}
                                    inputStyle={{ width: '100%' }}
                                    placeholder='0'
                                />
                            </Box>
                        </Box>

                        <Divider sx={{ marginBottom: '16px' }} />

                        <DescriptionField
                            name='commonDetails.description'
                            label='Description'
                            placeholder='Your description'
                            disabled={loadingDescription}
                            showWordCount={true}
                            maxCharacterCount={1000}
                            autogenerateButton
                            handleAutogenerate={onGenerateDescription}
                            logOnBlur
                            value={commonDetails?.description}
                        />

                        <TagsField
                            name='commonDetails.tags'
                            label='Tags'
                            placeholder='Add tags'
                            autogenerateButton
                            handleAutogenerate={onGenerateTags}
                            isGenerating={loadingTags}
                            limit={5}
                        />

                        <Box sx={{ marginBottom: 2 }}>
                            {addedDomains.map((domain, index) => {
                                const Fields = MarketSpecificFieldsMap[domain]
                                const isLastField = index === addedDomains.length - 1
                                return (
                                    <MarketSpecificFieldsLayout key={domain} domain={domain} divider={!isLastField}>
                                        <Fields values={values.domainSpecificDetails?.[domain]} listingOperationKind='edit' />
                                    </MarketSpecificFieldsLayout>
                                )
                            })}
                        </Box>

                        {status && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body1' danger sx={styles.textError}>
                                    {status}
                                </FoxTypography>
                            </Box>
                        )}

                        {shouldDisplayErrorMessage(touched, errors) && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body1' danger sx={styles.textError}>
                                    Fix form errors to post listing
                                </FoxTypography>
                            </Box>
                        )}

                        <Box sx={{ ...styles.actionButtonContainer }}>
                            <FlexGrow />
                            <FoxButton
                                primary
                                variant='contained'
                                size='large'
                                text={'Submit'}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onFoxClick={{ kind: 'button', onClick: async () => await handleSubmit() }}
                                sx={{ marginRight: '16px', fontWeight: 'bold' }}
                            />
                            <FoxButton
                                variant='contained'
                                size='large'
                                text='Discard changes'
                                onFoxClick={{ kind: 'button', onClick: onDiscardChanges, preventDoubleClick: true }}
                                disabled={isSubmitting}
                                sx={{ fontWeight: 'bold' }}
                                grey
                            />
                        </Box>
                    </FoxModalDialog>
                )
            }}
        </Formik>
    )
}

const styles = {
    subSectionHeader: {
        marginTop: '24px',
        marginBottom: '12px',
        fontWeight: 'bold'
    },
    subSectionContainer: {
        paddingLeft: '8px'
    },
    actionButtonContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center'
    },
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
