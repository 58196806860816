import { BoxProps, Box, CircularProgress, useTheme } from '@mui/material'
import React from 'react'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxCircleLoader } from '../LoadingCircle'
import { Colors } from '@foxtail-dev/user-clients'

const ProductImagePlaceholder = (props: BoxProps) => {
    const theme = useTheme()

    return (
        <Box sx={props.sx}>
            <Box
                sx={{
                    ...{
                        backgroundColor: Colors.light.background,
                        width: '80px',
                        height: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: Colors.light.divider
                    }
                }}
            >
                <FoxCircleLoader size={40} />
            </Box>
        </Box>
    )
}

export default ProductImagePlaceholder
