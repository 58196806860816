// TODO: Move to user clients
const areValuesEqual = (a: any, b: any): boolean => {
    // Both are the same type and value
    if (a === b) return true

    // One is number and the other is its string representation
    if (typeof a === 'string' && typeof b === 'number') return a === b.toString()
    if (typeof b === 'string' && typeof a === 'number') return b === a.toString()

    // Both are objects (includes arrays and null)
    if (typeof a === 'object' && typeof b === 'object') {
        if (a === null || b === null) return a === b // because typeof null is "object"

        // Get keys of both objects
        const aKeys = Object.keys(a)
        const bKeys = Object.keys(b)

        // If they don't have the same number of keys, they aren't equal
        if (aKeys.length !== bKeys.length) return false

        // Recursively check all properties for deep equality
        for (const key of aKeys) {
            if (!bKeys.includes(key) || !areValuesEqual(a[key], b[key])) return false
        }

        return true
    }

    return false
}

export const myObjectDeepStrictEqual = (a: any, b: any): boolean => {
    return areValuesEqual(a, b)
}
