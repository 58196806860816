import { EbayDimensionUnitsRadioOptions, EbayWeightUnitsRadioOptions } from '@foxtail-dev/user-clients'
import { RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { EbayAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'

export const EbayListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props

    const suggestionDefaults = values.domainDetails.ebay?.suggestionDefaults
    const parseResult = EbayAiListingSuggestionDefaultsConfig.safeParse(suggestionDefaults)
    const suggestionDefaultsParsed = parseResult.success ? parseResult.data : undefined

    return (
        <>
            <RadioGroupField
                name='domainDetails.ebay.suggestionDefaults.requiredDetails.weightUnit'
                iconType='Radio'
                label='Weight unit'
                options={EbayWeightUnitsRadioOptions}
                logOnChange
            />
            <RadioGroupField
                name='domainDetails.ebay.suggestionDefaults.optionalDetails.dimensionUnit'
                iconType='Radio'
                label='Dimension unit'
                options={EbayDimensionUnitsRadioOptions}
                logOnChange
            />
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: 5,
        marginTop: 4
    },
    textError: {
        fontSize: 12,
        lineHeight: 16
    }
}
