import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { CheckIcon } from '../../components/icons/CheckIcon'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { HydratedUserQuest, UserQuest } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { RadioButtonUncheckedIcon } from '../../components/icons/RadioButtonUnchecked'
import { Colors } from '@foxtail-dev/user-clients'
import { RadioButtonCheckedIcon } from '../../components/icons/RadioButtonChecked'
import { CheckBoxCheckedIcon } from '../../components/icons/CheckBoxChecked'
import { FoxButton } from '../../components/common/FoxButton'

type ChecklistBannerRowProps = {
    quest: HydratedUserQuest
    isTopPriorityIncompleteQuest: boolean
    buttonText: string
    route: string
}

export const ChecklistBannerRow = (props: ChecklistBannerRowProps) => {
    const { quest, isTopPriorityIncompleteQuest, buttonText, route } = props

    const isCompleted = quest.quest.details.status === 'completed'
    return (
        <>
            <Box sx={styles.container}>
                <Box sx={{ marginLeft: '23.73px' }}>
                    {isCompleted ? (
                        <CheckBoxCheckedIcon height={20} width={20} borderRaidus={10} color={Colors.light.divider} />
                    ) : (
                        <RadioButtonUncheckedIcon color={Colors.light.lightText} height={20} width={20} />
                    )}
                </Box>
                <FoxTypography
                    variant='subtitle1'
                    light={isCompleted}
                    sx={{ lineHeight: '24px', marginLeft: '20.5px', textDecorationLine: isCompleted ? 'line-through' : 'none' }}
                >
                    {quest.data.title}
                </FoxTypography>
                <FlexGrow />
                {!isTopPriorityIncompleteQuest && !isCompleted && (
                    <Box sx={{ marginRight: '79.89px' }}>
                        <ChevronRightIcon height={10} color='#301A36' />
                    </Box>
                )}
            </Box>
            {isTopPriorityIncompleteQuest && (
                <Box sx={{ marginLeft: '64.23px' }}>
                    <Box sx={{ marginTop: '6px' }}>
                        <FoxTypography>{quest.data.description}</FoxTypography>
                    </Box>
                    <FoxButton
                        sx={{ marginTop: '19px', width: '180px', justifyContent: 'center', marginBottom: '28px' }}
                        primary
                        text={buttonText}
                        onFoxClick={{ kind: 'internal', to: route }}
                    ></FoxButton>
                </Box>
            )}
            {quest.data.kind !== 'importExistingListings' && <Divider sx={{ height: '1px', marginLeft: '64.23px', marginRight: '66.7px' }} />}
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '48px',
        alignItems: 'center',
        width: '100%'
    }
}
