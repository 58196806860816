export {}
import React, { useEffect, useState } from 'react'
import {
    getActiveListingCount,
    Logger,
    selectActiveListingCount,
    selectListingsLimit,
    selectSubscription,
    selectSubscriptionTier,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box, Divider, LinearProgress } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { AccountHeader } from '../../layouts/AccountHeader'
import { SubscriptionCard } from '../../components/account/SubscriptionCard'
import { MustGoOnDeviceToSubscribeModal } from '../../modals/account/marketConnections/MustGoOnDeviceToSubscribe'
import { ListingManagementTitle } from '../../components/account/ListingManagementTitle'
import { CurrentSubscriptionCard } from '../../components/account/CurrentSubscriptionCard'
import { BillingDetailsButton } from '../../components/account/BillingDetailsButton'

const SubscriptionScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const subscription = useAppSelector(selectSubscription)
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const listingsLimit = useAppSelector(selectListingsLimit)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const progress = subscriptionTier === 'pro' || listingsLimit === 0 ? 100 : (currentActiveListingCount / listingsLimit) * 100

    useEffect(() => {
        Logger.I().log({
            level: 'info',
            message: 'User viewed subscription screen',
            payload: {
                kind: 'UserAction',
                entry: {
                    subscription,
                    currentActiveListingCount
                }
            }
        })

        dispatch(getActiveListingCount())

        const activeListingCountsInterval = setInterval(() => {
            dispatch(getActiveListingCount())
        }, 5000)

        return () => {
            clearInterval(activeListingCountsInterval)
        }
    }, [])

    return (
        <Box sx={styles.container}>
            <AccountHeader title='Subscription' />

            <Box sx={styles.containerMain}>
                <CurrentSubscriptionCard />

                <BillingDetailsButton />

                {subscriptionTier !== 'pro' && (
                    <>
                        <FoxTypography sx={styles.textAllPlans} variant='subtitle1'>
                            All plans
                        </FoxTypography>

                        <Box sx={styles.containerSubscriptionCards}>
                            {(subscriptionTier === 'freetrial' || (subscription?.kind === 'active' && subscription.details.kind === 'custom')) && (
                                <SubscriptionCard sx={styles.subscriptionCard} subscriptionKind='basic' setModalOpen={setModalOpen} />
                            )}

                            {(subscriptionTier === 'freetrial' ||
                                subscriptionTier === 'basic' ||
                                (subscription?.kind === 'active' && subscription.details.kind === 'custom')) && (
                                <SubscriptionCard sx={styles.subscriptionCard} subscriptionKind='premium' setModalOpen={setModalOpen} />
                            )}
                            {(subscriptionTier === 'freetrial' ||
                                subscriptionTier === 'basic' ||
                                subscriptionTier === 'premium' ||
                                (subscription?.kind === 'active' && subscription.details.kind === 'custom')) && (
                                <SubscriptionCard subscriptionKind='pro' setModalOpen={setModalOpen} />
                            )}
                        </Box>
                    </>
                )}
                <ListingManagementTitle />

                <Divider sx={styles.divider} />

                {/* TODO make this linear progress bar the fox gradient */}
                <LinearProgress sx={styles.linearProgress} value={progress} variant='determinate' />

                {/* <ActiveListingCountsTable /> */}
            </Box>

            {modalOpen && <MustGoOnDeviceToSubscribeModal open={modalOpen} onClose={() => setModalOpen(false)} />}
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    },
    containerMain: {
        marginBottom: '32px',
        marginLeft: '32px'
    },
    containerSubscriptionCards: {
        flexDirection: 'row',
        display: 'flex',
        marginBottom: '38px'
    },
    subscriptionCard: {
        marginRight: '20px'
    },
    divider: {
        color: '#F2ECF3',
        marginBottom: '35px'
    },
    linearProgress: {
        height: '13px'
    },
    textAllPlans: {
        marginTop: '47px',
        marginBottom: '24px'
    }
}

export default SubscriptionScreen
