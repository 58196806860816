export const createDateFromMonth = (monthStr: string): Date => {
    // Array to map month strings to month numbers
    const monthMap: { [key: string]: number } = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11
    }

    // Get the current year
    const currentYear = new Date().getFullYear()

    // Get the month number from the month string
    const monthNumber = monthMap[monthStr]

    if (monthNumber === undefined) {
        throw new Error('Invalid month string')
    }

    // Create and return the new Date object
    return new Date(currentYear, monthNumber, 1)
}
