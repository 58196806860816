import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { Header } from '../../components/common/Header'
import { FoxTypography } from '../../components/common/FoxTypography'
import { getEngagementBoosters, useAppDispatch, UserEngagementBoostersPartialConfigData } from '@foxtail-dev/user-clients'
import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'
import { EngagementBoosterDomainSection } from '../../containers/engagementBoosters/EngagementBoosterDomainSection'
import { EditEngagementBoosterModal } from '../../modals/engagementBoosters/EditEngagementBoosterModal'
import { Logger } from '../../lib/clients/Logger'

export type EngagementBoostersData = UserEngagementBoostersPartialConfigData

const EngagementBoostersScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const [currentBooster, setCurrentBooster] = React.useState<UserEngagementBoosterKind | null>(null)

    const onEditEngagementBooster = (boosterName: UserEngagementBoosterKind) => {
        setCurrentBooster(boosterName)
    }

    const onCancelEditing = () => {
        setCurrentBooster(null)
    }

    const loadEngagementBoosters = async () => {
        try {
            const engagementBoosters = await dispatch(getEngagementBoosters()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Loaded engagement boosters',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        engagementBoosters
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error loading engagement boosters',
                    payload: {
                        kind: 'Error',
                        entry: {}
                    }
                },
                error
            )
        }
    }

    useEffect(() => {
        loadEngagementBoosters()
    }, [])

    return (
        <Box sx={styles.container}>
            <Header title='Engagement boosters' />
            <FoxTypography light variant='body1' sx={{ marginBottom: '36px' }}>
                Automate market actions to increase listing engagement
            </FoxTypography>
            <EngagementBoosterDomainSection domainName='poshmark' onEditEngagementBooster={onEditEngagementBooster} />
            <EditEngagementBoosterModal closeModal={onCancelEditing} openBoosterKind={currentBooster} />
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }
}

export default EngagementBoostersScreen
