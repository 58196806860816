import { Box, CircularProgress } from '@mui/material'
import { FoxtailIcon } from '../icons/FoxtailIcon'
import { useState, useEffect } from 'react'

type FoxProgressSpinnerProps = {
    timer?: number
    maxProgress?: number
    visible: boolean
}

export const FoxProgressSpinner = ({ visible, timer = 2000, maxProgress = 95 }: FoxProgressSpinnerProps) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (!visible) {
            setProgress(0)
            return
        }

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const randomStep = Math.floor(Math.random() * 5) + 1 // Random step between 1 and 5
                const newProgress = Math.min(prevProgress + randomStep, maxProgress) // Don't quite finish it
                return newProgress
            })
        }, timer) // 2000ms interval for approximately 30 steps over 60 seconds

        return () => {
            clearInterval(interval)
        }
    }, [visible])

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant='determinate'
                value={100}
                size={180}
                sx={{
                    color: 'primary',
                    opacity: 0.3,
                    zIndex: 1
                }}
                thickness={2}
            />
            <CircularProgress
                variant='determinate'
                value={progress}
                size={180}
                sx={{
                    color: 'secondary',
                    position: 'absolute',
                    left: 0
                }}
                thickness={2}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2
                }}>
                <FoxtailIcon height={100} width={80} />
            </Box>
        </Box>
    )
}
