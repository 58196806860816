import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'

type AccountHeaderProps = {
    title: string
    hideDivider?: boolean
}
export const AccountHeader = (props: AccountHeaderProps) => {
    return (
        <>
            <Box sx={[styles.container]}>
                <FoxTypography variant="h6">{props.title}</FoxTypography>
            </Box>
            {!props.hideDivider && <Divider sx={styles.divider} />}
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '32px',
        marginBottom: '13px',
        alignItems: 'center'
    },
    divider: {
        marginBottom: '22px',
        marginLeft: '32px'
    }
}
