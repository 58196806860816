import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { FoxButton } from '../../../components/common/FoxButton'
import { useEffect, useState } from 'react'
import {
    Colors,
    createEbayDefaultPolicies,
    createEbayInventoryLocation,
    getAddressSuggestions,
    Logger,
    selectUser,
    setDomainLoginStatus,
    syncUserMarketDetailsCampaign,
    updateUserDomainDetails,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { generateToast } from '../../../lib/clients/ToastClient'
import { AddressDescription } from '@foxtail-dev/datacontracts/dist/lib/schemas/misc/AddressDescription'
import { addressObjectToString } from '../../../utils/addressObjectToString'
import { FoxSearchBar } from '../../../components/common/FoxSearchBar'
import { FlexGrow } from '../../../components/common/FlexGrow'
import { ChevronRightIcon } from '../../../components/icons/ChevronRightIcon'
import { FoxButtonBase } from '../../../components/common/FoxButtonBase'

type EbayCreateInventoryLocationModalProps = {
    onClose: () => void
}

export const EbayCreateInventoryLocationModal = ({ onClose }: EbayCreateInventoryLocationModalProps) => {
    const dispatch = useAppDispatch()

    const [search, setSearch] = useState<string>('')
    const [items, setItems] = useState<AddressDescription[]>([])
    const [searching, setSearching] = useState<boolean>(false)
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
    const [currentSelectedAddress, setCurrentSelectedAddress] = useState<AddressDescription | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const user = useAppSelector(selectUser)
    const ebayDomainDetails = user?.domainDetails.ebay

    const handleSave = async () => {
        if (currentSelectedAddress) {
            setLoading(true)
            try {
                const [policies, merchantLocationKey] = await Promise.all([
                    dispatch(createEbayDefaultPolicies()).unwrap(),
                    dispatch(createEbayInventoryLocation(currentSelectedAddress)).unwrap()
                ])

                await dispatch(
                    updateUserDomainDetails({
                        domainName: 'ebay',
                        fundamentals: {
                            marketplaceId: 'EBAY_US',
                            policies: {
                                paymentPolicyId: policies.paymentPolicy?.id,
                                returnPolicyId: policies.returnPolicy?.id,
                                fulfillmentPolicyId: policies.fulfillmentPolicy?.id,
                                ...ebayDomainDetails?.fundamentals?.policies
                            },
                            merchantLocationKey
                        },
                        accountName: ebayDomainDetails?.accountName ?? '',
                        isUserReadyToList: ebayDomainDetails?.isUserReadyToList ?? {
                            isReady: false,
                            reason: 'No syncUserMarketDetails has been ran'
                        }
                    })
                ).unwrap()
                await dispatch(syncUserMarketDetailsCampaign('ebay')).unwrap()
                dispatch(setDomainLoginStatus({ domainName: 'ebay', loginStatus: 'logging-in' }))

                Logger.I().log({
                    level: 'info',
                    message: 'User created inventory location',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            userId: user?._id,
                            location: currentSelectedAddress
                        }
                    }
                })
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Unable to create inventory location',
                        payload: {
                            kind: 'CreateInventoryLocationError',
                            entry: {
                                location: currentSelectedAddress
                            }
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Unable to create inventory location' })
            }

            setLoading(false)

            onClose()
        }
    }

    const handleClear = async () => {
        setCurrentSelectedAddress(undefined)
        setSearch('')
    }

    useEffect(() => {
        if (search !== '') {
            setSearching(true)
            if (timer) {
                clearTimeout(timer)
            }
            const newTimer = setTimeout(async () => {
                try {
                    const searchResult = await dispatch(getAddressSuggestions(search)).unwrap()
                    setItems(searchResult)
                } catch (error) {
                    generateToast({ kind: 'info', message: 'Unable to get address suggestions' })
                    Logger.I().log(
                        {
                            level: 'error',
                            message: 'Error getting address suggestions',
                            payload: {
                                kind: 'GetAddressSuggestionsError',
                                entry: {
                                    search
                                }
                            }
                        },
                        error
                    )
                }
                setSearching(false)
            }, 500)
            setTimer(newTimer)
        } else {
            if (timer) {
                clearTimeout(timer)
            }
            setItems([])
            setSearching(false)
        }

        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [search])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '324px' }}>
            {currentSelectedAddress ? (
                <Box sx={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <FoxTypography style={{ marginBottom: '30px', justifyContent: 'center', marginTop: '64px', display: 'flex' }} variant='subtitle1'>
                        Create Inventory Location?
                    </FoxTypography>

                    <FoxTypography style={{ textAlign: 'center' }} variant='body1'>
                        {addressObjectToString(currentSelectedAddress)}
                    </FoxTypography>
                    <FlexGrow />
                    <FoxButton
                        sx={{ marginTop: '16px', width: '100%', justifyContent: 'center' }}
                        primary
                        text='Save'
                        onFoxClick={{ kind: 'button', onClick: handleSave, preventDoubleClick: true }}
                        loading={loading}
                    />
                    <FoxButton
                        grey
                        sx={{ marginTop: '16px', width: '100%', justifyContent: 'center' }}
                        text='Clear'
                        onFoxClick={{ kind: 'button', onClick: handleClear }}
                    />
                </Box>
            ) : (
                <>
                    <FoxSearchBar
                        sx={{ marginTop: '16px', marginBottom: '16px', width: '100%' }}
                        autoFocus
                        label={`Search for address`}
                        value={search}
                        onChange={setSearch}
                        loading={searching}
                    />

                    {items.length === 0 && !searching ? (
                        <FoxTypography variant='subtitle1' light style={{ textAlign: 'center', marginTop: '80px' }}>
                            {search.length > 4 ? 'No addresses found' : 'Start typing to get address suggestions'}
                        </FoxTypography>
                    ) : (
                        <Box>
                            {items.map((item, index) => (
                                <Box key={'location' + index}>
                                    <FoxButtonBase
                                        sx={{ height: '47px', display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}
                                        key={item.addressLine1 + item.addressLine2 + item.city + item.stateOrProvidence + item.countryCode + item.postalCode}
                                        onFoxClick={{ kind: 'button', onClick: async () => setCurrentSelectedAddress(item) }}
                                    >
                                        <FoxTypography>{`${item.addressLine1} ${item.addressLine2} ${item.city} ${item.stateOrProvidence} ${item.countryCode} ${item.postalCode}`}</FoxTypography>
                                        <FlexGrow />
                                        <ChevronRightIcon color={Colors.light.chevronGrey} />
                                    </FoxButtonBase>
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}
