import { createDomainNameList, Logger, selectBulkCrosslistingListingInfoByListingId, useAppSelector } from '@foxtail-dev/user-clients'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { useFormikContext } from 'formik'
import { useCallback } from 'react'
import { ListingSelectedMap } from './BulkCrosslistDraft'
import { BaseListingRow } from '../listing/BaseListingRow'

type BulkCrosslistDraftRowProps = {
    listingId: string
    isChecked: boolean
}

export const BulkCrosslistDraftRow = (props: BulkCrosslistDraftRowProps) => {
    const { listingId, isChecked } = props

    const listingInfo = useAppSelector(selectBulkCrosslistingListingInfoByListingId(listingId))

    if (!listingInfo) {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'BulkCrosslistDraftRow'
            },
            message: 'No listing info found for listingId'
        })
        return null
    }

    const imageIds = listingInfo.imageIds
    const title = listingInfo.title
    const domains: DomainCommonName[] = Object.entries(listingInfo.activeDomains)
        .filter(([, isActive]) => isActive)
        .map(([domain]) => DomainCommonName.parse(domain))

    const { setFieldValue, setFieldTouched } = useFormikContext<ListingSelectedMap>()

    const handleChecked = useCallback(
        (checked: boolean) => {
            setFieldValue(listingId, checked)
            setFieldTouched(listingId)
        },
        [setFieldValue, setFieldTouched, listingId]
    )

    const handleToggleChecked = useCallback(() => {
        handleChecked(!isChecked)
    }, [isChecked, handleChecked])

    return (
        <BaseListingRow
            imageId={imageIds[0]}
            title={title}
            description={`${createDomainNameList(domains)}`}
            onClick={handleToggleChecked}
            isChecked={isChecked}
            onChecked={handleChecked}
        />
    )
}
