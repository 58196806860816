import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { NotificationKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/NotificationKind'
import { Logger, selectUser, updateNotificationSetting, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { generateToast } from '../../lib/clients/ToastClient'
import { IOSSwitch } from '../../components/common/IOSSwitch'
import { useState } from 'react'

type NotificationSettingRowProps = {
    settingName: string
    notificationKind: NotificationKind
}

type SyntheticNotificationSetting = {
    isEnabled: boolean
}

export const NotificationSettingRow = ({ notificationKind, settingName }: NotificationSettingRowProps): JSX.Element => {
    const notificationSetting = useAppSelector((state) => state.user.user?.notificationSettings[notificationKind])
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    // We initially get the notification setting from the user's info in redux, but if the user changes it, we store the new value in a synthetic state
    // This avoids awkward UI behavior where the switch would flicker back to the old value if the selector fired
    // with a new user object that came after the user input, but before it had round tripped to the back end
    // TODO: A more precise selector that only updates to the specific notification setting would probably work just as well.
    const [syntheticValue, setSyntheticValue] = useState<SyntheticNotificationSetting | null>(null)
    const isEnabled = syntheticValue ? syntheticValue.isEnabled : notificationSetting?.isEnabled ?? false

    const handleValueChange = async () => {
        try {
            const newValue = !isEnabled
            await dispatch(
                updateNotificationSetting({
                    isEnabled: newValue,
                    notificationKind: notificationKind
                })
            ).unwrap()
            setSyntheticValue({ isEnabled: newValue })

            Logger.I().log({
                level: 'info',
                message: 'User updated notification setting',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        userId: user?._id,
                        notificationKind: notificationKind,
                        isEnabled: newValue
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to update notification setting',
                    payload: {
                        kind: 'UpdateNotificationSettingError',
                        entry: {
                            notificationKind: notificationKind
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Cannot update notification setting' })
        }
    }

    return (
        <>
            <Box sx={styles.container}>
                <Box sx={styles.containerText}>
                    <FoxTypography sx={styles.text}>{settingName}</FoxTypography>
                </Box>
                <FlexGrow />
                <Box sx={styles.containerSwitch}>
                    <IOSSwitch color='info' onChange={handleValueChange} checked={isEnabled} />
                </Box>
            </Box>
            <Divider sx={styles.divider} />
        </>
    )
}

const styles = {
    container: {
        flexDirection: 'row',
        display: 'flex',
        height: '64px',
        marginLeft: '31px'
    },
    containerSwitch: {
        marginRight: '19px',
        marginTop: '16.5px',
        marginBottom: '16.5px',
        alignItems: 'center',
        display: 'flex'
    },
    containerText: {
        marginTop: '16px',
        marginLeft: '10px'
    },
    text: {
        lineHeight: '26px',
        fontSize: '16px',
        fontWeight: 400
    },
    divider: {
        marginLeft: '32px'
    }
}
