import { Colors } from '@foxtail-dev/user-clients'
import { Box, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { FoxTypography } from '../common/FoxTypography'
import { MoreIcon } from '../icons/MoreIcon'
import { ImportActionIcon } from '../icons/ImportActionIcon'
import { FoxIconButton } from '../common/FoxIconButton'

type BulkCrosslistMoreMenuProps = {
    onCancel: () => void
}

export const BulkCrosslistMoreMenu = ({ onCancel }: BulkCrosslistMoreMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box>
            <FoxIconButton
                sx={{ height: '48px', width: '57px', backgroundColor: Colors.light.buttonGrey, borderRadius: '10px' }}
                id='BulkCrosslistMoreMenu'
                onFoxClick={{ kind: 'button', onClick: handleClick }}>
                <MoreIcon height={21} width={18} />
            </FoxIconButton>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'BulkCrosslistMoreMenuButton'
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: '250px',
                            maxHeight: '400px',
                            borderRadius: '10px',
                            overflow: 'visible'
                        }
                    }
                }}>
                <MenuItem disableRipple>
                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px', alignItems: 'center' }} onClick={onCancel}>
                        <Box sx={{ marginTop: '6px', marginRight: '21px' }}>
                            <ImportActionIcon />
                        </Box>

                        <FoxTypography variant='body1'>Cancel bulk crosslist</FoxTypography>
                    </Box>
                </MenuItem>
            </Menu>
        </Box>
    )
}
