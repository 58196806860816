import { Box, useTheme } from '@mui/material'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxTypography } from '../common/FoxTypography'
import { CameraAlt } from '@mui/icons-material'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { Colors } from '@foxtail-dev/user-clients'
import { PlusIcon } from '../icons/PlusIcon'

// TODO: Do we need to support heic and heif for apple or do they automatically get converted?

export type AddImageButtonProps = {
    onAddImage: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void
    limit: number
    small?: boolean
}

export const AddImageDropzone = ({ onAddImage, limit, small }: AddImageButtonProps) => {
    const theme = useTheme()

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onAddImage,
        maxFiles: limit,
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': []
        },
        maxSize: 10 * 1024 * 1024
    })

    return small ? (
        <Box
            {...getRootProps()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '80px',
                width: '80px',
                backgroundColor: Colors.light.tertiary,
                borderRadius: '10px'
            }}
        >
            <input {...getInputProps()} />

            <Box sx={{ marginTop: '20px', marginBottom: '5px' }}>
                <PlusIcon color={Colors.light.background} />
            </Box>
            <FoxTypography sx={{ color: Colors.light.background, fontWeight: 500, fontSize: '12px' }}>Add photo</FoxTypography>
        </Box>
    ) : (
        <Box
            {...getRootProps()}
            sx={{
                border: '2px solid #F2ECF3',
                padding: '20px',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#e3f2fd' : '#FFF',
                marginTop: '8px'
            }}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <FoxTypography variant='body1' color='primary'>
                    Drop the files here...
                </FoxTypography>
            ) : (
                <Box sx={{ ...sharedStyles.flexColumn, width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <CameraAlt />
                    <FoxTypography variant='body1'>+ Add Photos</FoxTypography>
                </Box>
            )}
        </Box>
    )
}
