import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { PriceField } from '../../../../components/formik/PriceField'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'
import { DepopAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'

export const DepopListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props

    const suggestionDefaults = values.domainDetails.depop?.suggestionDefaults
    const parseResult = DepopAiListingSuggestionDefaultsConfig.safeParse(suggestionDefaults)
    const suggestionDefaultsParsed = parseResult.success ? parseResult.data : undefined

    const isBuyerPays = parseResult.success ? suggestionDefaultsParsed?.requiredDetails.shipWithDepop === false : false

    return (
        <>
            <RadioBoolGroupField
                label='Ship with Depop'
                name='domainDetails.depop.suggestionDefaults.requiredDetails.shipWithDepop'
                iconType='Radio'
                options={[
                    [true, 'Depop Shipping'],
                    [false, 'Other'] // TODO: Fix text
                ]}
                logOnChange
            />
            {isBuyerPays && (
                <PriceField
                    name='domainDetails.depop.suggestionDefaults.optionalDetails.shippingPrice'
                    label='Shipping Price'
                    logOnBlur
                    initialValue={suggestionDefaultsParsed?.optionalDetails.shippingPrice}
                />
            )}
        </>
    )
}
