import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box/Box'
import { useMediaQuery } from '@mui/material'
import { Logger } from '@foxtail-dev/user-clients'

export const SignUpScreen = (): JSX.Element => {
    const { loginWithRedirect } = useAuth0()

    const isMobile = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup'
            }
        }).catch((error) => {
            Logger.I().log({
                level: 'error',
                message: 'Error logging in with redirect on signup screen',
                payload: {
                    kind: 'SignInScreenError'
                }
            })
        })
    })

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', flex: 1, display: 'flex', flexDirection: 'column', padding: isMobile ? '16px' : '0' }}></Box>
    )
}

