import {
    averageSalesPriceStatSelector,
    normalizePrice,
    totalListedListingsStatSelector,
    totalSoldListingsStatSelector,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { AnalyticsBox } from './AnalyticsBox'

export const AnalyticsBoxes = () => {
    const totalSoldListings = useAppSelector(totalSoldListingsStatSelector)
    const averageSalePrice = useAppSelector(averageSalesPriceStatSelector)
    const totalListedListings = useAppSelector(totalListedListingsStatSelector)

    return (
        <>
            <FoxTypography variant='h6' sx={styles.text}>
                Totals for all time
            </FoxTypography>

            <Box sx={styles.containerAnalyticsBoxes}>
                <AnalyticsBox sx={styles.analyticsBox} title='Listed' value={totalListedListings.toString()} />
                <AnalyticsBox sx={styles.analyticsBox} title='Sold' value={totalSoldListings.toString()} />
                <AnalyticsBox title='Avg. price' value={normalizePrice(averageSalePrice)} includeDollarSign />
            </Box>
        </>
    )
}

const styles = {
    containerAnalyticsBoxes: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    analyticsBox: {
        marginRight: '22px'
    },
    text: {
        marginBottom: '18px'
    }
}
