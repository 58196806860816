import { Logger } from './clients/Logger'

export const startHeartbeatLogs = (time: number) => {
    return setInterval(() => logHeartbeat(), time)
}

const logHeartbeat = () => {
    Logger.I().log({
        level: 'info',
        message: 'Heartbeat',
        payload: {
            kind: 'heartbeat',
            entry: {
                internetConnection: navigator.onLine,
                userAgent: navigator.userAgent
            }
        }
    })
}
