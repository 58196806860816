import { Box, Divider, Skeleton } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'

export const SkeletonCategoryRows = () => {
    return (
        <Box>
            {Array.from({ length: 10 }).map((_, index) => {
                return (
                    <Box key={'skeletonCategoryRows' + index}>
                        <Box sx={{ height: '48px', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                            <Skeleton animation='wave' variant='rounded' sx={{ width: (Math.random() * 150 + 300).toString() + 'px', height: '20px' }} />
                            <FlexGrow />
                            <Skeleton animation='wave' variant='rounded' sx={{ width: '10px', height: '17px' }} />
                        </Box>
                        <Divider />
                    </Box>
                )
            })}
        </Box>
    )
}
