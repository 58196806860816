import { z } from '@foxtail-dev/datacontracts'

const FOXTAIL_ENV = ['dev', 'beta', 'prod'] as const
const FoxtailEnv = z.enum(FOXTAIL_ENV)
type FoxtailEnv = z.infer<typeof FoxtailEnv>

const FoxtailWebConfigSchema = z.object({
    env: FoxtailEnv,
    auth0: z.object({
        domain: z.string(),
        clientId: z.string(),
        audience: z.string()
    }),
    intercomAppId: z.string(),
    appInfo: z.object({
        version: z.string()
    }),
    foxtailApiGateway: z.object({
        host: z.string(),
        port: z.string(),
        protocol: z.string().default('https')
    }),
    sentry: z.object({
        dsn: z.string(),
        org: z.string(),
        project: z.coerce.number()
    }),
    firebase: z.object({
        apiKey: z.string(),
        authDomain: z.string(),
        projectId: z.string(),
        storageBucket: z.string(),
        messagingSenderId: z.string(),
        appId: z.string(),
        measurementId: z.string(),
        vapid: z.string()
    }),
    googleAnalytics: z.object({
        measurementId: z.string()
    }),
    posthog: z.object({
        apiKey: z.string(),
        host: z.string()
    }),
    extensionUrl: z.string()
})
export type FoxtailWebConfig = z.infer<typeof FoxtailWebConfigSchema>

const getConfig = (): FoxtailWebConfig => {
    const config: FoxtailWebConfig = {
        env: import.meta.env.VITE_ENV,
        auth0: {
            domain: import.meta.env.VITE_AUTH0_DOMAIN,
            clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE
        },
        intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
        appInfo: {
            version: import.meta.env.VITE_APP_VERSION
        },
        foxtailApiGateway: {
            host: import.meta.env.VITE_FOXTAIL_BACKEND_HOST,
            port: import.meta.env.VITE_FOXTAIL_BACKEND_PORT,
            protocol: import.meta.env.VITE_FOXTAIL_BACKEND_PROTOCOL
        },
        sentry: {
            dsn: import.meta.env.VITE_SENTRY_DSN,
            org: import.meta.env.VITE_SENTRY_ORG_NAME,
            project: import.meta.env.VITE_SENTRY_PROJECT_ID
        },
        firebase: {
            apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
            authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
            projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
            storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDING_ID,
            appId: import.meta.env.VITE_FIREBASE_APP_ID,
            measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
            vapid: import.meta.env.VITE_FIREBASE_VAPID
        },
        googleAnalytics: {
            measurementId: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID
        },
        posthog: {
            apiKey: import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
            host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST
        },
        extensionUrl: `https://chrome.google.com/webstore/detail/${import.meta.env.VITE_FOXTAIL_EXTENSION_ID}`
    }

    const parsed = FoxtailWebConfigSchema.parse(config)

    return parsed
}

export const FoxtailWebConfig = {
    get config() {
        return getConfig()
    }
} as const
