import { assert, DomainCommonName } from '@foxtail-dev/datacontracts'
import { Box, Divider } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { FoxButton } from '../common/FoxButton'
import { generateToast } from '../../lib/clients/ToastClient'
import { createImportListing, Logger, useAppDispatch } from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'
import { ImportListingMarketSelectionField } from '../formik/ImportListingMarketSelectionField'
import { ImportListingSkeleton } from '../skeletons/ImportListingSkeleton'

type NewImportListingSessionProps = {
    loggedInMarkets: DomainCommonName[]
    setIsViewingEbayWarning: React.Dispatch<React.SetStateAction<boolean>>
}

type CreateImportListingProps = {
    sourceOfTruthMarket: DomainCommonName | null
}
export const NewImportListingSession = (props: NewImportListingSessionProps) => {
    const [isLoadingSynthetic, setIsLoadingSynthetic] = useState<boolean>(true)
    const [isCreatingImportListing, setIsCreatingImportListing] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingSynthetic(false)
        }, 1000)
    }, [])

    const onBeginImport = async (values: CreateImportListingProps) => {
        if (values.sourceOfTruthMarket === null) {
            generateToast({ kind: 'error', message: 'Please select a marketplace' })
            return
        }
        if (values.sourceOfTruthMarket === 'ebay') {
            props.setIsViewingEbayWarning(true)
        } else {
            try {
                if (isCreatingImportListing) return
                setIsCreatingImportListing(true)
                const response = await dispatch(createImportListing(values.sourceOfTruthMarket)).unwrap()
                if (response.created) {
                    Logger.I().log({
                        level: 'info',
                        message: 'User created import listing',
                        payload: {
                            kind: 'UserAction',
                            entry: {
                                sourceOfTruthMarket: values.sourceOfTruthMarket,
                                importListingSessionId: response.importListingSession._id
                            }
                        }
                    })
                } else {
                    // todo review that this logic is correct
                    const importListingStatus = response.importListingSession.status
                    assert(importListingStatus !== 'completed', 'ImportListingClient.getActiveListing returned a completed listing')
                    Logger.I().log({
                        level: 'warning',
                        message: 'Tried to create import listing session when an import listing session already exists',
                        payload: {
                            kind: 'ImportListingSessionAlreadyExists',
                            entry: {
                                importListingSessionId: response.importListingSession._id,
                                importListingStatus
                            }
                        }
                    })

                    generateToast({ kind: 'info', message: 'Import listing session already exists', subText: 'Redirecting to existing import listing' })
                }
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        payload: {
                            kind: 'createImportListingError',
                            entry: {
                                sourceOfTruthMarket: 'ebay'
                            }
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Error creating import listing' })
                setIsCreatingImportListing(false)
            }
            setIsCreatingImportListing(false)
        }
    }

    const initialValues: CreateImportListingProps = {
        sourceOfTruthMarket: null
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onBeginImport} validateOnBlur={false}>
            {({ values, isSubmitting }: FormikProps<CreateImportListingProps>) => {
                return isLoadingSynthetic ? (
                    <ImportListingSkeleton />
                ) : (
                    <Box sx={styles.container}>
                        <Box sx={{ flexGrow: 1, marginLeft: '20px' }}>
                            <ImportListingMarketSelectionField />
                        </Box>
                        <Divider sx={{ marginBottom: '12px' }} />
                        <Box sx={styles.actionButtonContainer}>
                            <FoxButton
                                variant='contained'
                                size='large'
                                text='Begin import'
                                loading={isCreatingImportListing}
                                disabled={isSubmitting}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: async () => {
                                        await onBeginImport(values)
                                    },
                                    preventDoubleClick: true
                                }}
                                sx={styles.button}
                                primary
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        flexDirection: 'column'
    },
    actionButtonContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '20px'
    },
    button: {
        marginRight: '16px',
        fontWeight: 'bold',
        width: '160px',
        justifyContent: 'center'
    }
}
