import { Colors } from '@foxtail-dev/user-clients'
import { Box, Skeleton } from '@mui/material'

export const SkeletonLineChart = () => {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: Colors.light.divider,
                height: '350px'
            }}
        >
            <Skeleton animation='wave' variant='rounded' sx={{ width: '88px', height: '20px', marginTop: '26px', marginLeft: '25px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ width: '41px', height: '40px', marginTop: '7px', marginLeft: '25px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ height: '169px', marginTop: '61px', marginLeft: '27px', marginRight: '31px' }} />
        </Box>
    )
}
