import { RootState } from '@foxtail-dev/user-clients'
import * as Sentry from '@sentry/react'

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    attachReduxState: true,
    stateTransformer: (state: RootState) => {
        return {
            user: state.user,
            listings: {
                listingIds: state.listings.listings.ids,
                currentQuery: state.listings.currentQuery
            },
            listingDetails: {
                listingId: state.listingDetails.listingId,
                listingDetails: state.listingDetails.listingDetails
            },
            listingStatus: state.listingStatus,
            app: state.app,
            images: {
                count: state.images.ids.length
            },
            notifications: state.notifications.data.ids,
            listingDefaults: state.listingDefaults,
            importListing: {
                ...state.importListing,
                scrapedMarketListings: state.importListing.scrapedMarketListings.map((listing) => listing.scrapedMarketListing._id)
            },
            domainAuth: state.domainAuth,
            bulkCrosslisting: {
                sessionId: state.bulkCrosslist.session?._id,
                dataId: state.bulkCrosslist.data?._id,
                isInitialized: state.bulkCrosslist.isInitialized,
                listingInfosCount: state.bulkCrosslist.listingInfos.length,
                generationResponsesCount: state.bulkCrosslist.data?.data.generationResponses
            },
            analytics: 'if you need this modify FA sentryReduxEnhancer',
            toasts: 'if you need this modify FA sentryReduxEnhancer',
            auth: 'if you need this modify FA sentryReduxEnhancer',
            enabledDomain: 'if you need this modify FA sentryReduxEnhancer',
            video: state.videos,
            progress: state.progress.hydratedQuests,
            aiTasks: state.aiTasks,
            engagementBoosters: state.engagementBoosters
        }
    }
})
