import { Box } from '@mui/material'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxTypography } from '../common/FoxTypography'
import { VideoCall } from '@mui/icons-material'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

// TODO: See which video formats we need to support

export type AddVideoButtonProps = {
    onAddVideo: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void
}

export const AddVideoDropzone = ({ onAddVideo }: AddVideoButtonProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onAddVideo,
        maxFiles: 1,
        accept: {
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
            'video/x-matroska': ['.mkv'],
            'video/x-ms-wmv': ['.wmv'],
            'video/x-flv': ['.flv'],
            'video/webm': ['.webm']
        },
        maxSize: 100 * 1024 * 1024 // 100MB, we'll see if this is too large
    })

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: '2px solid #F2ECF3',
                padding: '20px',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#e3f2fd' : '#FFF',
                marginTop: '8px',
                height: '280px'
            }}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <FoxTypography variant='body1' color='primary'>
                    Drop the files here...
                </FoxTypography>
            ) : (
                <Box sx={{ ...sharedStyles.flexColumn, width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <VideoCall sx={{ height: '36px', width: '36px' }} />
                    <FoxTypography variant='body1'>+ Add Video</FoxTypography>
                </Box>
            )}
        </Box>
    )
}
