import { Colors } from '@foxtail-dev/user-clients'
import { SvgIcon } from '@mui/material'

type ChevronRightIcon = {
    color?: string
    height?: number
    width?: number
}

export const ChevronRightIcon = (props: ChevronRightIcon) => {
    const height = props.height ?? 17
    const width = props.width ?? 10
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='10px' height='17px' viewBox='0 0 10 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>chevron</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g
                        id='New-listing---details'
                        transform='translate(-358.000000, -1080.000000)'
                        fill={props.color ?? Colors.light.chevronGrey}
                        fillRule='nonzero'
                    >
                        <g id='Group-4' transform='translate(12.000000, 1064.000000)'>
                            <path
                                d='M346.46698,32.5271637 C346.167634,32.2007124 346.012204,31.8217956 346.000691,31.3904135 C345.989177,30.9590313 346.133094,30.5917736 346.43244,30.2886402 L352.096988,24.5524236 L346.3979,18.7812301 C346.121581,18.4780967 345.989177,18.09918 346.000691,17.6444799 C346.012204,17.1897798 346.167634,16.810863 346.46698,16.5077296 C346.789352,16.1579603 347.157778,15.9889052 347.572258,16.0005641 C347.986737,16.0122231 348.355163,16.1812783 348.677535,16.5077296 L355.516441,23.4331618 C355.654601,23.5963875 355.769734,23.7712722 355.86184,23.9578158 C355.953947,24.1443594 356,24.342562 356,24.5524236 C356,24.7622852 355.953947,24.9604878 355.86184,25.1470314 C355.769734,25.3335751 355.654601,25.5084597 355.516441,25.6716854 L348.712075,32.5621407 C348.389702,32.865274 348.01552,33.0110113 347.589527,32.9993523 C347.163535,32.9876933 346.789352,32.8302971 346.46698,32.5271637 Z'
                                id='chevron'
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
