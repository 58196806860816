import { FormikField } from './FormikField'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FoxCheckbox } from '../common/FoxCheckbox'
import {
    Logger,
    createDefaultDepopDetails,
    createDefaultEbayDetails,
    createDefaultEtsyDetails,
    createDefaultFacebookDetails,
    createDefaultMercariDetails,
    createDefaultPoshmarkDetails
} from '@foxtail-dev/user-clients'

type MarketSelectionCheckboxProps = {
    name: string
    market: DomainCommonName
    clearOnToggleOff?: boolean
}

export const MarketSelectionCheckbox = ({ name, market, clearOnToggleOff }: MarketSelectionCheckboxProps) => {
    return (
        <FormikField
            name={name}
            ignoreError
            render={({ value, onChange }) => {
                return (
                    <FoxCheckbox
                        iconType='Checkbox'
                        label={Domains[market].displayName}
                        checked={value?.isActive ?? false}
                        onChange={() => {
                            const message = value?.isActive ? `User toggled off ${market} market` : `User toggled on ${market} market`

                            Logger.I().log({
                                level: 'info',
                                message: message,
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        market,
                                        isActive: !value?.isActive,
                                        screen: 'CreateListing'
                                    }
                                }
                            })

                            if (!value && market === 'poshmark') {
                                onChange(createDefaultPoshmarkDetails())
                            } else if (!value && market === 'mercari') {
                                onChange(createDefaultMercariDetails())
                            } else if (!value && market === 'etsy') {
                                onChange(createDefaultEtsyDetails())
                            } else if (!value && market === 'ebay') {
                                onChange(createDefaultEbayDetails())
                            } else if (!value && market === 'depop') {
                                onChange(createDefaultDepopDetails())
                            } else if (!value && market === 'facebook') {
                                onChange(createDefaultFacebookDetails())
                            } else {
                                if (clearOnToggleOff) {
                                    onChange(undefined)
                                } else {
                                    onChange({
                                        ...value,
                                        isActive: !value?.isActive
                                    })
                                }
                            }
                        }}
                    />
                )
            }}
        />
    )
}

const styles = {
    checkbox: {}
}
