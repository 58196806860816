import { EbayEnums } from '@foxtail-dev/datacontracts/src/lib/schemas/domainSpecific/ebay/ebayApi.exports'

// TODO: move to user clients
export const TraditionalConditionValues = {
    New: 1000,
    NewOther: 1500,
    NewWithDefects: 1750,
    CertifiedRefurbished: 2000,
    ExcellentRefurbished: 2010,
    VeryGoodRefurbished: 2020,
    GoodRefurbished: 2030,
    SellerRefurbished: 2500,
    LikeNew: 2750,
    Used: 3000,
    VeryGood: 4000,
    Good: 5000,
    Acceptable: 6000,
    ForPartsOrNotWorking: 7000
} as const

export const ebayWeightUnitLabelMap: Record<EbayEnums.WeightUnitOfMeasureEnum, string> = {
    OUNCE: 'oz',
    POUND: 'lbs',
    GRAM: 'g',
    KILOGRAM: 'kg'
}

export const TraditionalConditionIdsToValues = {
    1000: 'New',
    1500: 'New other',
    1750: 'New with defects',
    2000: 'Certified refurbished',
    2010: 'Excellent refurbished',
    2020: 'Very good refurbished',
    2030: 'Good refurbished',
    2500: 'Seller refurbished',
    2750: 'Like new',
    3000: 'Used',
    4000: 'Very good',
    5000: 'Good',
    6000: 'Acceptable',
    7000: 'For parts or not working'
} as const
