import { SubscriptionTierInfoMap } from '@foxtail-dev/user-clients'
import { LinearProgress } from '@mui/material'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { FoxTypography } from '../../components/common/FoxTypography'

type UpgradeProgressHeaderProps = {
    currentActiveListingCount: number
    listingLimit: number
}

export const UpgradeProgressHeader = (props: UpgradeProgressHeaderProps): JSX.Element => {
    const { currentActiveListingCount, listingLimit } = props
    return (
        <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/account/subscription' }} sx={styles.containerListingLimit}>
            <FoxTypography variant='body2' sx={styles.textListingProgress}>
                {currentActiveListingCount}/{listingLimit} listings
            </FoxTypography>
            <LinearProgress variant='determinate' value={(currentActiveListingCount / listingLimit) * 100} />
        </FoxButtonBase>
    )
}

const styles = {
    containerListingLimit: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20px',
        alignItems: 'normal',
        overflow: 'hidden'
    },
    textListingProgress: {
        fontSize: '12px',
        marginBottom: '5px',
        lineHeight: '20px',
        cursor: 'pointer'
    }
}
