import { selectLoggedInAndReadyToListDomains, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { ConnectMoreSitesLink } from '../account/ConnectMoreSitesLink'
import { bulkCrosslistingMarketOptions } from '../../constants/bulkCrosslistingMarketOptions'
import { CheckboxGroupField } from '../formik/CheckboxGroupField'

type BulkCrosslistingMarketSelectionFieldProps = {}

export const BulkCrosslistingMarketSelectionField = (props: BulkCrosslistingMarketSelectionFieldProps) => {
    const loggedInMarkets = useAppSelector(selectLoggedInAndReadyToListDomains)

    const options = bulkCrosslistingMarketOptions.filter((option) => loggedInMarkets.includes(option[0]))
    return (
        <Box>
            <FoxTypography variant='body2' sx={styles.titleWhere}>
                Crosslist listings to where?
            </FoxTypography>
            <CheckboxGroupField iconType='Checkbox' name='selectedCrosslistingDomains' options={options} logOnChange ignoreError={true} />
            <Box sx={{ marginRight: '12px' }}>
                <ConnectMoreSitesLink />
            </Box>
        </Box>
    )
}

const styles = {
    titleWhere: {
        marginTop: '24px',
        marginBottom: '12px',
        fontWeight: 'bold'
    },
    connectIcon: {
        marginRight: '10px',
        marginLeft: '4px'
    }
}
