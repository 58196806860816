import { SvgIcon } from '@mui/material'

type CheckBoxUncheckedIconProps = {
    color?: string
    height?: number
    width?: number
}

export const CheckBoxUncheckedIcon = (props: CheckBoxUncheckedIconProps) => {
    const height = props.height ?? 24
    const width = props.width ?? 24
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>checkbox-unchecked</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g
                        id='New-listing---details'
                        transform='translate(-20.000000, -301.000000)'
                        fill='#FFFFFF'
                        stroke={props.color ?? '#301A36'}
                        strokeWidth='2'
                    >
                        <rect id='checkbox-unchecked' x='21' y='302' width='22' height='22' rx='4'></rect>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
