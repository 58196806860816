import { assert } from '@foxtail-dev/datacontracts'
import { FulfillmentPolicy } from '@foxtail-dev/datacontracts/dist/lib/schemas/domainSpecific/ebay/response/FulfillmentPolicy'
import { useAppDispatch, useAppSelector, selectCurrentEbayFulfillmentPolicy, getEbayFulfillmentPoliciesVerbose, Logger } from '@foxtail-dev/user-clients'
import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { sharedStyles } from '../../../theme/SharedStyling'
import { FoxButton } from '../../../components/common/FoxButton'

export type CategorySelectionProps = {
    title: string
    renderItem: (item: any) => JSX.Element
    handleSave: () => Promise<void>
}

export const EbayFulfillmentPolicyDetailSelection = ({ title, renderItem, handleSave }: CategorySelectionProps) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [fulfillmentPolicies, setFulfillmentPolicies] = useState<FulfillmentPolicy[]>([])
    const currentlySelectedEbayFulfillmentPolicy = useAppSelector(selectCurrentEbayFulfillmentPolicy)

    useEffect(() => {
        setIsLoading(true)
        const getFulfillmentPolicies = async () => {
            try {
                const fulfillmentPolicies = await dispatch(getEbayFulfillmentPoliciesVerbose()).unwrap()
                const currentFulfillmentPolicy = fulfillmentPolicies.find((i) => i.fulfillmentPolicyId === currentlySelectedEbayFulfillmentPolicy)
                if (!currentFulfillmentPolicy || currentFulfillmentPolicy === undefined) {
                    Logger.I().log({
                        level: 'error',
                        message: 'Could not find current fulfillment policy',
                        payload: {
                            kind: 'NoDefaultFulfillmentPolicyFound'
                        }
                    })
                }

                assert(currentFulfillmentPolicy, 'NoDefaultFulfillmentPolicyFound')
                // reorder fulfillment policies so that current policy is first
                setFulfillmentPolicies([
                    currentFulfillmentPolicy,
                    ...fulfillmentPolicies.filter((i) => i.fulfillmentPolicyId !== currentlySelectedEbayFulfillmentPolicy)
                ])
                setIsLoading(false)
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Error getting fulfillment policies',
                        payload: {
                            kind: 'ErrorGettingFulfillmentPolicies'
                        }
                    },
                    error
                )
                setIsLoading(false)
            }
        }
        getFulfillmentPolicies()
    }, [])

    return (
        <Box sentry-label='EbayFulfillmentPolicyDetailSelectionScreen'>
            {isLoading ? (
                <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginTop: '140px', marginHorizontal: '20px' }}>
                    Syncing your Fulfillment Policies from eBay
                </FoxTypography>
            ) : fulfillmentPolicies?.length === 0 ? (
                <Box sx={sharedStyles.flex1}>
                    <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginBottom: '28px', marginTop: '140px' }}>
                        We didn't find any Fulfillment Policies on your account. Please add them manually on eBay and come back. {`\n\n`}If you need more help
                        visit our support center.
                    </FoxTypography>
                    <Box sx={sharedStyles.flexGrow} />
                </Box>
            ) : (
                <Box sx={sharedStyles.flex1}>
                    {fulfillmentPolicies?.map((i) => renderItem(i))}
                    <Box sx={sharedStyles.flexGrow} />
                    <FoxTypography variant='body1' light sx={{ textAlign: 'center', marginBottom: '12px', marginTop: '12px' }}>
                        If you don't see your Fulfillment Policy here or want to add a new Fulfillment Policy, you must manually add it to your eBay account and
                        click the Sync fulfillment policies button below.
                    </FoxTypography>
                    <Box sx={{ marginTop: '36px' }}>
                        <FoxButton
                            primary
                            onFoxClick={{
                                kind: 'button',
                                onClick: async () => {
                                    await handleSave()
                                },
                                preventDoubleClick: true
                            }}
                            text='Save'
                            sx={{ justifyContent: 'center', marginRight: '16px' }}
                        />
                        <FoxButton
                            grey
                            onFoxClick={{
                                kind: 'button',
                                onClick: async () => {
                                    try {
                                        setIsLoading(true)
                                        const allPolicies = await dispatch(getEbayFulfillmentPoliciesVerbose()).unwrap()
                                        setFulfillmentPolicies(allPolicies)
                                        setIsLoading(false)
                                    } catch (error) {
                                        Logger.I().log(
                                            {
                                                level: 'error',
                                                message: 'Error getting fulfillment policies',
                                                payload: {
                                                    kind: 'ErrorGettingFulfillmentPolicies'
                                                }
                                            },
                                            error
                                        )
                                        setIsLoading(false)
                                    }
                                },
                                preventDoubleClick: true
                            }}
                            text='Sync fulfillment policies'
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
