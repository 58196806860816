import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ImportListingCancelInfo } from '@foxtail-dev/user-clients'
import { isLoggedIntoAMarketThatCanImport } from './isLoggedIntoAMarketThatCanImport'

type GenerateImportListingsTitleBasedOnListingStatusParams = {
    loggedInMarkets: DomainCommonName[]
    importListingStatus: 'importing' | 'awaiting-user-input' | 'scraping-listings' | 'draft' | 'completed' | undefined
    importListingCancelInfo: ImportListingCancelInfo
    isRequestingCancel: boolean
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
    isViewingEbayWarning: boolean
    isPublishing: boolean
}
export const generateImportListingsTitleBasedOnListingStatus = (props: GenerateImportListingsTitleBasedOnListingStatusParams) => {
    const { loggedInMarkets, importListingStatus, importListingCancelInfo, isRequestingCancel, isViewingEbayWarning, isPublishing } = props
    if (isRequestingCancel) {
        return 'Cancel import?'
    } else if (isLoggedIntoAMarketThatCanImport(loggedInMarkets) === false) {
        return 'No linked import markets'
    } else if (importListingCancelInfo.isCancelling) {
        return 'Cancelling import session'
    } else if (isViewingEbayWarning) {
        return 'Ebay disclosure'
    } else if (importListingStatus === 'importing' || (isPublishing && importListingStatus === 'awaiting-user-input')) {
        return 'Importing listings'
    } else if (importListingStatus === 'awaiting-user-input') {
        return 'Awaiting import selections'
    } else if (importListingStatus === 'scraping-listings' || importListingStatus === 'draft') {
        return 'Import listing'
    } else {
        return 'New import listing'
    }
}
