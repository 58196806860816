import { Colors } from '@foxtail-dev/user-clients'
import { SvgIcon } from '@mui/material'

type SchoolHatIconProps = {
    color?: string
    height?: number
    width?: number
}

export const SchoolHatIcon = (props: SchoolHatIconProps) => {
    const height = props.height ?? 18
    const width = props.width ?? 22
    return (
        <SvgIcon viewBox='0 0 22 18' style={{ height, width }}>
            <svg width='22px' height='18px' viewBox='0 0 22 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>school_hat</title>
                <g id='Checklist' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='Marketplace-connections' transform='translate(-298, -397)' fill={props.color ?? '#301A36'} fillRule='nonzero'>
                        <g id='Group' transform='translate(290, 383)'>
                            <g id='school_24dp_5F6368_FILL0_wght400_GRAD0_opsz24-(1)' transform='translate(8, 14)'>
                                <path
                                    d='M11,18 L4,14.2 L4,8.2 L0,6 L11,0 L22,6 L22,14 L20,14 L20,7.1 L18,8.2 L18,14.2 L11,18 Z M11,9.7 L17.85,6 L11,2.3 L4.15,6 L11,9.7 Z M11,15.725 L16,13.025 L16,9.25 L11,12 L6,9.25 L6,13.025 L11,15.725 Z'
                                    id='Shape'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
