import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { useEffect, useState } from 'react'
import {
    Colors,
    createEbayDefaultPolicies,
    getEbayInventoryLocations,
    Logger,
    selectUser,
    setDomainLoginStatus,
    syncUserMarketDetailsCampaign,
    updateUserDomainDetails,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { InventoryLocationResponse } from '@foxtail-dev/datacontracts/src/lib/schemas/domainSpecific/ebay/response/InventoryLocationResponse'
import { generateToast } from '../../../lib/clients/ToastClient'
import { FoxCircleLoader } from '../../../components/LoadingCircle'
import { FlexGrow } from '../../../components/common/FlexGrow'
import { ChevronRightIcon } from '../../../components/icons/ChevronRightIcon'
import { FoxButtonBase } from '../../../components/common/FoxButtonBase'

type EbaySelectInventoryLocationModalProps = {
    onClose: (isConfirmed: boolean) => void
}

export const EbaySelectInventoryLocationModal = ({ onClose }: EbaySelectInventoryLocationModalProps) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const [locations, setLocations] = useState<InventoryLocationResponse[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [syntheticLoading, setSyntheticLoading] = useState<boolean>(true)
    const ebayDomainDetails = user?.domainDetails.ebay

    useEffect(() => {
        setTimeout(() => {
            setSyntheticLoading(false)
        }, 1000)
    }, [])

    useEffect(() => {
        dispatch(getEbayInventoryLocations())
            .unwrap()
            .then((locations) => {
                // only keep unique locations
                const uniqueLocations = locations
                    .filter(
                        (location, index, self) =>
                            self.findIndex((l) => JSON.stringify(l.location.address) === JSON.stringify(location.location.address)) === index
                    )
                    .filter(
                        (location) =>
                            location.location.address.addressLine1 &&
                            location.location.address.city &&
                            location.location.address.stateOrProvince &&
                            location.location.address.postalCode
                    )
                setLocations(uniqueLocations)
                setLoading(false)
            })
            .catch((error) => {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Unable to fetch inventory locations',
                        payload: {
                            kind: 'FetchInventoryLocationsError'
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Unable to fetch inventory locations' })
                setLoading(false)
            })
    }, [])

    const handleSave = async (location: InventoryLocationResponse) => {
        setLoading(true)
        try {
            const policies = await dispatch(createEbayDefaultPolicies()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User is saving ebay inventory location',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        userId: user?._id,
                        policies,
                        location
                    }
                }
            })

            await dispatch(
                updateUserDomainDetails({
                    domainName: 'ebay',
                    fundamentals: {
                        marketplaceId: 'EBAY_US',
                        policies: {
                            paymentPolicyId: policies.paymentPolicy?.id,
                            returnPolicyId: policies.returnPolicy?.id,
                            fulfillmentPolicyId: policies.fulfillmentPolicy?.id,
                            ...ebayDomainDetails?.fundamentals?.policies
                        },
                        merchantLocationKey: location.merchantLocationKey
                    },
                    accountName: ebayDomainDetails?.accountName ?? '',
                    isUserReadyToList: ebayDomainDetails?.isUserReadyToList ?? {
                        isReady: false,
                        reason: 'No syncUserMarketDetails has been ran'
                    }
                })
            ).unwrap()
            await dispatch(syncUserMarketDetailsCampaign('ebay')).unwrap()
            dispatch(setDomainLoginStatus({ domainName: 'ebay', loginStatus: 'logging-in' }))
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Unable to save inventory location',
                    payload: {
                        kind: 'SaveInventoryLocationError',
                        entry: {
                            location
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to save inventory location' })
        }
        setLoading(false)
        onClose(true)
    }

    return (
        <Box sx={{ width: '100%', minHeight: '256px', display: 'flex', flexDirection: 'column' }}>
            {loading || syntheticLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', marginTop: '64px' }}>
                    <FoxCircleLoader />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '17px', marginBottom: '32px' }}>
                    <Box>
                        <FoxTypography variant='subtitle1' sx={{ alignItems: 'flex-start', display: 'flex' }}>
                            Current inventory locations
                        </FoxTypography>

                        {locations.length === 0 && !loading ? (
                            <FoxTypography sx={{ marginTop: '120px', marginBottom: '120px' }}>You don't have any inventory locations yet</FoxTypography>
                        ) : (
                            <>
                                {locations.map((location, index) => {
                                    return (
                                        <Box key={'location' + index}>
                                            <FoxButtonBase
                                                key={location.location.locationId}
                                                sx={{ display: 'flex', flexDirection: 'row', height: '47px', alignItems: 'center', width: '100%' }}
                                                onFoxClick={{ kind: 'button', onClick: () => handleSave(location) }}>
                                                <FoxTypography>{location.location.address.addressLine1},</FoxTypography>
                                                <FoxTypography>
                                                    <span>&nbsp;</span>
                                                    {location.location.address.city},
                                                </FoxTypography>
                                                <FoxTypography>
                                                    <span>&nbsp;</span>
                                                    {location.location.address.stateOrProvince},
                                                </FoxTypography>
                                                <FoxTypography>
                                                    <span>&nbsp;</span>
                                                    {location.location.address.postalCode}
                                                </FoxTypography>
                                                <FlexGrow />
                                                <ChevronRightIcon color={Colors.light.chevronGrey} />
                                            </FoxButtonBase>
                                            <Divider />
                                        </Box>
                                    )
                                })}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}
