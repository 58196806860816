import { Colors } from '@foxtail-dev/user-clients'
import { Box, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { FoxTypography } from '../common/FoxTypography'
import { MoreIcon } from '../icons/MoreIcon'
import { ImportActionIcon } from '../icons/ImportActionIcon'
import { useNavigate } from 'react-router-dom'
import { FoxIconButton } from '../common/FoxIconButton'
import { FoxButton } from '../common/FoxButton'
import { BulkCrosslistActionIcon } from '../icons/BulkCrosslistActionIcon'
import { VideoCall } from '@mui/icons-material'

type ListingMoreMenuProps = {}

export const ListingMoreMenu = (props: ListingMoreMenuProps) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // TODO: Box has the right styling, but we should replace it with the button
    return (
        <Box>
            <FoxIconButton
                sx={{ height: '48px', width: '57px', backgroundColor: Colors.light.buttonGrey, borderRadius: '10px' }}
                id='ListingMoreMenu'
                onFoxClick={{ kind: 'button', onClick: handleClick }}>
                <MoreIcon height={21} width={18} />
            </FoxIconButton>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'ListingMoreMenuButton'
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: '250px',
                            maxHeight: '400px',
                            borderRadius: '10px',
                            overflow: 'visible'
                        }
                    }
                }}>
                <MenuItem disableRipple>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'row', height: '36px', alignItems: 'center' }}
                        onClick={() => {
                            navigate('/app/import-listing')
                        }}>
                        <Box sx={{ marginTop: '6px', marginRight: '21px' }}>
                            <ImportActionIcon />
                        </Box>

                        <FoxTypography variant='body1'>Import listings</FoxTypography>
                    </Box>
                </MenuItem>
                {/* <MenuItem disableRipple>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'row', height: '36px', alignItems: 'center' }}
                        onClick={() => {
                            navigate('/app/listings-from-video')
                        }}>
                        <Box sx={{ marginTop: '6px', marginRight: '21px' }}>
                            <VideoCall sx={{ height: '24px', width: '24px' }} />
                        </Box>

                        <FoxTypography variant='body1'>Create listings from video</FoxTypography>
                    </Box>
                </MenuItem> */}
                <MenuItem disableRipple>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'row', height: '36px', alignItems: 'center' }}
                        onClick={() => {
                            navigate('/app/bulk-crosslist')
                        }}>
                        <Box sx={{ marginTop: '6px', marginRight: '21px' }}>
                            <BulkCrosslistActionIcon />
                        </Box>

                        <FoxTypography variant='body1'>Bulk crosslist</FoxTypography>
                    </Box>
                </MenuItem>
            </Menu>
        </Box>
    )
}
