import { Colors } from '@foxtail-dev/user-clients'
import { CircularProgressProps, Box, CircularProgress, circularProgressClasses } from '@mui/material'

export const FoxCircleLoader = (props: CircularProgressProps) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant='determinate'
                sx={{
                    color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
                }}
                size={60}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant='indeterminate'
                disableShrink
                sx={{
                    color: (theme) => (theme.palette.mode === 'light' ? Colors.light.primary : '#308fe8'),
                    animationDuration: '850ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                    }
                }}
                size={60}
                thickness={4}
                {...props}
            />
        </Box>
    )
}
