import { Colors } from '@foxtail-dev/user-clients'
import { SvgIcon } from '@mui/material'

type SparkleIconProps = {
    color?: string
    height?: number
    width?: number
}

export const SparkleIcon = (props: SparkleIconProps) => {
    const height = props.height ?? 17
    const width = props.width ?? 19
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='19px' height='17px' viewBox='0 0 19 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>auto-generate</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g
                        id='New-listing---additional-details'
                        transform='translate(-209.000000, -1368.000000)'
                        fill={props.color ?? Colors.light.tertiary}
                        fillRule='nonzero'
                    >
                        <g id='Group-2' transform='translate(195.000000, 1358.000000)'>
                            <g id='auto-generate' transform='translate(14.000000, 10.000000)'>
                                <path
                                    d='M7.22,14.3555556 L4.96375,9.42083333 L0,7.17777778 L4.96375,4.93472222 L7.22,0 L9.47625,4.93472222 L14.44,7.17777778 L9.47625,9.42083333 L7.22,14.3555556 Z M15.39,17 L14.27375,14.5208333 L11.78,13.4111111 L14.27375,12.2777778 L15.39,9.82222222 L16.53,12.2777778 L19,13.4111111 L16.53,14.5208333 L15.39,17 Z'
                                    id='Shape'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
