import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'

type DeleteDraftListingConfirmationModalProps = {
    open: boolean
    onClose: (result: boolean) => void
}

export const DeleteDraftListingConfirmationModal = ({ open, onClose }: DeleteDraftListingConfirmationModalProps) => {
    const handleDelete = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation()
        onClose(true)
    }

    return (
        <FoxModalDialog open={open} title={'Delete draft listing?'} leftButtonKind={'close'} onClose={() => onClose(false)} closeOnBackdropClick>
            <Box sx={{ textAlign: 'center' }}>
                <FoxTypography variant='body2' sx={{ marginBottom: '8px' }}>
                    Are you sure you want to delete this draft listing?
                </FoxTypography>
                <FoxButton
                    primary
                    onFoxClick={{ kind: 'button', onClick: handleDelete, preventDoubleClick: true }}
                    text={'Delete draft listing'}
                    sx={{ justifyContent: 'center', alignItems: 'center', width: 'fit-content', height: '48px', marginTop: '24px' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
