import { funnyAiLoadingWords, standardAiLoadingWords } from '../constants/aiLoadingWords'

export const getAiLoadingWords = (): string[] => {
    // Clone arr2 to avoid modifying the original array
    const availableForOddIndexes = [...funnyAiLoadingWords]

    // Helper function to get a random element from availableForOddIndexes and remove it
    function getRandomFromArr2(): string {
        const randomIndex = Math.floor(Math.random() * availableForOddIndexes.length)
        return availableForOddIndexes.splice(randomIndex, 1)[0]
    }

    const result: string[] = []

    // Populate the result array
    for (let i = 0; i < standardAiLoadingWords.length; i++) {
        result.push(standardAiLoadingWords[i])
        if (i < standardAiLoadingWords.length - 1) {
            result.push(getRandomFromArr2())
        }
    }

    return result
}
