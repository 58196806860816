import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { Colors } from '../../theme/Colors'
import { FoxButtonBase } from '../common/FoxButtonBase'

type AppSidebarButtonProps = {
    icon: React.ReactNode
    to: string
    text: string
    active: boolean
}
export const    AppSidebarButton = (props: AppSidebarButtonProps) => {
    const { to, text, icon, active } = props
    return (
        <FoxButtonBase
            sx={{
                flexDirection: 'row',
                display: 'flex',
                height: '58px',
                marginBottom: '6px',
                width: '220px',
                borderRadius: '32px',
                backgroundColor: active ? 'rgb(232, 113, 85, 0.05)' : undefined,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: active ? 'rgb(232, 113, 85, 0.09)' : Colors.light.background
            }}
            onFoxClick={{ kind: 'internal', to }}
        >
            <Box
                sx={{
                    marginTop: '19px',
                    marginLeft: '29px',
                    width: '21px',
                    justifyContent: 'center',
                    display: 'flex'
                }}
            >
                {icon}
            </Box>
            <Box sx={{ marginTop: '18px', marginLeft: '22.72px' }}>
                <FoxTypography variant='subtitle1' sx={{ color: active ? Colors.light.primary : Colors.light.tertiary, cursor: 'pointer' }}>
                    {text}
                </FoxTypography>
            </Box>
        </FoxButtonBase>
    )
}
