import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '../../theme/Colors'
import { FoxButtonBase, OnFoxClick } from '../../components/common/FoxButtonBase'

type BaseStatusRowProps = {
    text: string
    subtext: string
    image: JSX.Element
    onFoxClick: OnFoxClick
}
export const BaseStatusRow = (props: BaseStatusRowProps): JSX.Element => {
    const { text, subtext, image, onFoxClick } = props

    return (
        <FoxButtonBase
            sx={{
                height: '96px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: Colors.light.background
            }}
            onFoxClick={onFoxClick}>
            <Box
                sx={{
                    height: '61px',
                    width: '61px',
                    backgroundColor: 'rgba(255, 255, 255, 0.93)'
                }}>
                {image}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: '20px' }}>
                <FoxTypography variant='body2' bold>
                    {text}
                </FoxTypography>
                <FoxTypography variant='body1' light>
                    {subtext}
                </FoxTypography>
            </Box>
        </FoxButtonBase>
    )
}

const styles = {
    image: {
        height: '61px',
        width: '61px',
        border: '1px solid #EBE7F1',
        borderRadius: '10px'
    }
}
