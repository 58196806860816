import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, Typography } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxProgressSpinner } from '../../components/common/FoxProgressSpinner'
import { Colors, selectHydratedQuestsSorted, useAppSelector } from '@foxtail-dev/user-clients'
import { CheckIcon } from '../../components/icons/CheckIcon'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { FlexGrow } from '../../components/common/FlexGrow'
import { useMemo } from 'react'
import { ChecklistBannerRow } from './CheckListBannerRow'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'

type ChecklistBannerProps = {
    imageSize: 'small' | 'large'
}

export const ChecklistBanner = (props: ChecklistBannerProps): JSX.Element => {
    const { imageSize } = props

    const quests = useAppSelector(selectHydratedQuestsSorted)

    const totalQuestsCompleted = useMemo(() => {
        return quests.filter(quest => quest.quest.details.status === 'completed').length
    }, [quests])

    const topPriorityIncompleteQuest = useMemo(() => {
        const incompleteQuests = quests.filter(quest => quest.quest.details.status !== 'completed')

        // get highest priority incomplete quest
        const topPriorityIncompleteQuest = incompleteQuests.reduce((prevQuest, currentQuest) => {
            if (prevQuest.data.priority < currentQuest.data.priority) {
                return prevQuest
            }
            return currentQuest
        }, incompleteQuests[0])
        return topPriorityIncompleteQuest
    }, [quests])

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitle}>
                <Box sx={styles.containerProgress}>
                    <CircularProgress
                        variant='determinate'
                        value={(totalQuestsCompleted / 3) * 100}
                        size={16}
                        sx={{
                            color: Colors.light.success,
                            position: 'relative',
                            zIndex: 2
                        }}
                        thickness={6}
                    />

                    <CircularProgress
                        variant='determinate'
                        value={100}
                        size={16}
                        sx={{
                            color: Colors.light.divider,
                            position: 'absolute',
                            left: 0,
                            zIndex: 1
                        }}
                        thickness={6}
                    />
                </Box>
                <FoxTypography variant='h6'>Get started</FoxTypography>
                <FoxTypography light variant='body2' sx={{ lineHeight: '26px', marginLeft: '8px' }}>
                    • {totalQuestsCompleted}/3 steps
                </FoxTypography>
            </Box>
            <Box sx={styles.containerChecklistItems}>
                <Box sx={{ flex: 3, borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', marginTop: '10px' }}>
                    <ChecklistBannerRow
                        quest={quests[0]}
                        isTopPriorityIncompleteQuest={topPriorityIncompleteQuest.data.kind === quests[0].data.kind}
                        buttonText='Connect marketplaces'
                        route='/app/account/marketplace-connections'
                    />
                    <ChecklistBannerRow
                        quest={quests[1]}
                        isTopPriorityIncompleteQuest={topPriorityIncompleteQuest.data.kind === quests[1].data.kind}
                        buttonText='Crosslist'
                        route='/app/create-listing'
                    />
                    <ChecklistBannerRow
                        quest={quests[2]}
                        isTopPriorityIncompleteQuest={topPriorityIncompleteQuest.data.kind === quests[2].data.kind}
                        buttonText='Import listings'
                        route='/app/import-listing'
                    />
                </Box>

                <Box sx={styles.containerImage}>
                    <FoxImage
                        src={CommonImages.general.checklistBanner}
                        style={{ height: imageSize === 'large' ? '230px' : '173px', width: imageSize === 'large' ? '310px' : '233px' }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    containerTitle: {
        height: '32px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    containerChecklistItems: {
        height: '294px',
        marginBottom: '49px',
        marginTop: '26px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        borderRadius: '10px',
        boxShadow: '0px 3px 11px rgba(0, 0, 0, 0.06)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        boxSizing: 'border-box'
    },
    containerImage: {
        flex: 2,
        height: '294px',
        backgroundColor: '#FAF7FA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    containerProgress: {
        marginRight: '16.96px',
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center'
    }
}
