import { useFormikContext } from 'formik'
import { EngagementBoosterSpecificFieldsProps } from '../EngagementBoosterSpecificFieldsMap'
import { EngagementBoostersData } from '../../../screens/engagementBoosters/EngagementBoostersScreen'
import { FoxTextField } from '../../../components/formik/FoxTextField'
import { RadioBoolGroupField } from '../../../components/formik/RadioGroupField'

export const PoshmarkShareClosetFields = (props: EngagementBoosterSpecificFieldsProps) => {
    const { values } = useFormikContext<EngagementBoostersData>()
    const poshmarkShareClosetValues = values.poshmarkShareCloset

    const sharePeriodInHours = (poshmarkShareClosetValues?.sharePeriodMs ?? 0) / 1000 / 60 / 60

    const convertSharePeriodToMs = (value: string) => {
        const number = parseFloat(value)
        if (isNaN(number)) {
            return '0'
        }

        return (number * 60 * 60 * 1000).toString()
    }

    return (
        <>
            <RadioBoolGroupField
                name={'poshmarkShareCloset.shouldShareUsersCloset'}
                options={[
                    [true, 'Yes'],
                    [false, 'No']
                ]}
                iconType={'Radio'}
                label='Share your closet?'
                logOnChange
            />
            <FoxTextField
                name={'poshmarkShareCloset.sharePeriodMs'}
                label='Share period'
                labelVariant='body1'
                isNumber={true}
                suffix='hour(s)'
                transformText={convertSharePeriodToMs}
                value={sharePeriodInHours.toString()}
                logOnBlur
            />
        </>
    )
}
