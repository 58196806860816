import { FieldInputProps, FieldMetaProps, FormikHandlers, Field } from 'formik'
import { sharedStyles } from '../../theme/SharedStyling'
import { Box, BoxProps, TypographyVariant, useTheme } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'

export type FormikForm<ValueType> = {
    setFieldValue: (name: string, value: ValueType) => void
    setFieldTouched: (name: string, value: boolean) => void
    setFieldError: (name: string, error: string) => void
}

export type FormikFieldRenderParams<ValueType> = {
    field: FieldInputProps<ValueType>
    meta: FieldMetaProps<ValueType>
    form: FormikForm<ValueType>
}

export type FormikFieldRenderProps = {
    touched: boolean
    error?: string
    value: any
    onChange: (value: any) => void // TODO: better typing
    onBlur: FormikHandlers['handleBlur']
    setFieldError: (error: string) => void
}

export type FormikFieldProps = {
    name: string
    label?: string
    labelVariant?: TypographyVariant
    ignoreError?: boolean
    actionLabel?: string
    onAction?: <ValueType>() => Promise<ValueType>
    actionLabel2?: string
    onAction2?: () => void
    render: (renderProps: FormikFieldRenderProps) => JSX.Element
    titleSubtext?: string
}

// TODO: Fix styles, variants in particular
export const FormikField = <ValueType,>({ sx, render, label, name, ignoreError, labelVariant = 'body2', titleSubtext }: FormikFieldProps & BoxProps) => {
    const theme = useTheme()
    return (
        <Field name={name} id={name} type='text'>
            {({ field, meta, form }: FormikFieldRenderParams<ValueType>) => {
                const inputContainerStyle = ignoreError ? {} : styles.inputContainer
                return (
                    <Box sx={{ ...inputContainerStyle, ...styles.container, ...sx }}>
                        {label && (
                            <Box sx={{ ...{ display: 'flex', marginBottom: '8px', marginTop: '8px' } }}>
                                <FoxTypography variant={labelVariant} sx={{ color: theme.palette.text.primary }} bold>
                                    {label}
                                </FoxTypography>
                                {titleSubtext && (
                                    <FoxTypography variant={labelVariant} light sx={{ marginLeft: '8px' }}>
                                        {titleSubtext}
                                    </FoxTypography>
                                )}
                            </Box>
                        )}
                        {render({
                            touched: meta.touched,
                            error: meta.error,
                            value: field.value,
                            onChange: (value: ValueType) => {
                                form.setFieldValue(name, value)
                                form.setFieldTouched(name, true)
                            },
                            onBlur: () => {
                                form.setFieldTouched(name, true)
                            },
                            setFieldError: (error: string) => {
                                form.setFieldError(name, error)
                            }
                        })}
                        {meta.touched && meta.error && !ignoreError && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body2' danger sx={styles.textError}>
                                    {meta.error}
                                </FoxTypography>
                            </Box>
                        )}
                    </Box>
                )
            }}
        </Field>
    )
}

const styles = {
    container: {
        paddingBottom: '8px'
    },
    inputContainer: {
        marginBottom: '16px'
    },
    containerValidation: {
        marginLeft: '4px',
        marginTop: '12px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
