import { Colors, MarketInfo, setSelectedMarkets, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FoxButtonBase } from '../common/FoxButtonBase'

export type ChartMarketButtonsProps = {
    activeMarkets: DomainCommonName[]
}

export const ChartMarketButtons = (props: ChartMarketButtonsProps) => {
    const { activeMarkets } = props
    const analytics = useAppSelector((state) => state.analytics)
    const dispatch = useAppDispatch()

    const onAllMarketButtonClick = async () => {
        dispatch(setSelectedMarkets('All markets'))
    }

    const onMarketButtonPress = (domainName: DomainCommonName) => {
        dispatch(setSelectedMarkets(domainName))
    }

    const isAllMarketsSelected = analytics.selectedMarkets.includes('All markets')

    return (
        <Box sx={styles.container}>
            <FoxButtonBase
                sx={{
                    ...{
                        backgroundColor: isAllMarketsSelected ? MarketInfo['All markets'].buttonColor : Colors.light.unfocusedInput,
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: isAllMarketsSelected ? Colors.light.background : Colors.light.divider,
                        '&.hover': {
                            backgroundColor: isAllMarketsSelected ? MarketInfo['All markets'].buttonColor : '#AAA'
                        }
                    },
                    ...styles.buttonBase
                }}
                onFoxClick={{ kind: 'button', onClick: onAllMarketButtonClick }}
            >
                <FoxTypography variant='subtitle2' sx={{ color: isAllMarketsSelected ? Colors.light.background : Colors.light.lightText, cursor: 'pointer' }}>
                    All markets
                </FoxTypography>
            </FoxButtonBase>
            {activeMarkets.map((domainName) => {
                const isActive = analytics.selectedMarkets.includes(domainName)
                return (
                    <FoxButtonBase
                        key={domainName}
                        onFoxClick={{ kind: 'button', onClick: async () => onMarketButtonPress(domainName) }}
                        sx={{
                            ...{
                                backgroundColor: isActive ? MarketInfo[domainName].buttonColor : Colors.light.unfocusedInput,
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: isActive ? Colors.light.background : Colors.light.divider
                            },
                            ...styles.buttonBase
                        }}
                    >
                        <FoxTypography
                            light={!isActive}
                            variant='subtitle2'
                            sx={{ color: isActive ? Colors.light.background : Colors.light.lightText, cursor: 'pointer' }}
                        >
                            {Domains[domainName].displayName}
                        </FoxTypography>
                    </FoxButtonBase>
                )
            })}
        </Box>
    )
}

const styles = {
    container: { display: 'flex', flexDirection: 'row' },
    buttonBase: {
        width: '100px',
        height: '24px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '24px',
        cursor: 'pointer'
    }
}
